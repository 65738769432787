import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import dayjs from "dayjs";

import { CustomError, getEmptyValue } from "../../pages/pageConfig/category/utilities";
import { convertRuleStringToObject, RepeatOptions } from "../../types/dashboard";
import { RecurrencyRuleEndType, RecurrentAction, RecurrentActionState } from "../../types/recurrentAction";
import { ExplicitAdditionalProps } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { shouldUpdate } from "./common";

export const initialState: RecurrentActionState = {
  data: [],
  isLoading: false,
  singleData: null,
  subData: {},
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
};

export const newRecurrentAction: RecurrentAction = {
  id: 0,
  title: "",
  responsibleUserId: null,
  responsibleUserName: "",
  projectId: null,
  projectName: "",
  isActive: true,
  startDate: dayjs().toISOString(),
  recurrencyRule: "FREQ=DAILY;INTERVAL=1",
  recurrencyRuleEndDate: "",
  recurrencyRuleOccurrences: 1,
  description: "",
  durationHours: 24,
  followUpReviewId: null,
  employeeId: null,
  equipmentId: null,
  locationId: null,
  issueTypeId: null,
  isFormBased: false,
  recurrencyRuleEndType: RecurrencyRuleEndType.Date,
  recurrenceRule: {
    repeat: RepeatOptions.Daily,
    amount: "1",
  },
};

export const fetchReccurentActions = createAsyncThunk<
  { primaryData: RecurrentAction[]; defaultCustomProperties: ExplicitAdditionalProps[] },
  boolean | undefined,
  { state: RootState }
>(
  "@@RECURRENTACTIONS/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<RecurrentAction[]>("/RecurrentAction", hbApiOptions(user.jwt));
    return {
      primaryData: response.data,
      subData: {},
      defaultCustomProperties: [],
    };
  },
  {
    condition: (forceUpdate, { getState }) => {
      const { recurrentActions } = getState();
      return shouldUpdate(recurrentActions.lastUpdated, recurrentActions.isLoading, forceUpdate);
    },
  }
);

export const fetchSingleRecurrentAction = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { singleData: RecurrentAction; subData: any; defaultCustomProperties: ExplicitAdditionalProps[] },
  string,
  { state: RootState }
>(
  "@@RECURRENTACTIONS/FETCH_SINGLE",
  async (id, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const cardDataResponse = await hbApi.get<RecurrentAction>(`/RecurrentAction/${id}`, hbApiOptions(user.jwt));

      return {
        singleData: {
          ...cardDataResponse.data,
          isFormBased: !!cardDataResponse.data.followUpReviewId,
          recurrenceRule: convertRuleStringToObject(cardDataResponse.data.recurrencyRule ?? ""),
        },
        subData: {},
        defaultCustomProperties: [],
      };
    } catch (e) {
      if (e.status === 400 || e.status === 404) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();
      return !!user.jwt;
    },
  }
);

export const addRecurrentAction = createAsyncThunk<RecurrentAction, { entity: RecurrentAction }, { state: RootState }>(
  "@@RECURRENTACTIONS/CREATE",
  async ({ entity }, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const response = await hbApi.post<RecurrentAction>(
        "/RecurrentAction",
        {
          ...entity,
          startDate: dayjs(entity.startDate).toISOString(),
          followUpReviewId: entity.isFormBased ? entity.followUpReviewId : null,
          issueTypeId: entity.issueTypeId === getEmptyValue().key ? null : entity.issueTypeId,
        },
        hbApiOptions(user.jwt)
      );

      return response.data;
    } catch (e) {
      if (e.status === 400 || e.status === 404) {
        return rejectWithValue(e.errors);
      }
      throw new CustomError();
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions, user } = getState();
      return !actions.isLoading && !!user.jwt;
    },
  }
);

export const updateRecurrentAction = createAsyncThunk<RecurrentAction, RecurrentAction, { state: RootState }>(
  "@@RECURRENTACTIONS/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const response = await hbApi.put<RecurrentAction>(
        `/RecurrentAction/${entity.id}`,
        {
          ...entity,
          startDate: dayjs(entity.startDate).toISOString(),
          followUpReviewId: entity.isFormBased ? entity.followUpReviewId : null,
          issueTypeId: entity.issueTypeId === getEmptyValue().key ? null : entity.issueTypeId,
        },
        hbApiOptions(user.jwt)
      );

      return response.data;
    } catch (e) {
      if (e.status === 400 || e.status === 404) {
        return rejectWithValue(e.errors);
      }
      throw new CustomError();
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions, user } = getState();
      return !actions.isLoading && !!user.jwt;
    },
  }
);

export const slice = createSlice({
  name: "recurrentActions",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    createRecurrentActionTemplate: state => {
      state.singleData = newRecurrentAction;
    },
    clearReccurentActionsError: state => {
      state.error = null;
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchReccurentActions.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleRecurrentAction.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addRecurrentAction.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateRecurrentAction.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Note - Rejected:
      .addCase(fetchReccurentActions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleRecurrentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addRecurrentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateRecurrentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Note - Fulfilled:
      .addCase(fetchReccurentActions.fulfilled, (state, action) => {
        state.data = action.payload.primaryData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchSingleRecurrentAction.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(addRecurrentAction.fulfilled, (state, action) => {
        state.singleData = {
          ...action.payload,
          isFormBased: !!action.payload.followUpReviewId,
        };
        state.data.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateRecurrentAction.fulfilled, (state, action) => {
        state.singleData = {
          ...action.payload,
          isFormBased: !!action.payload.followUpReviewId,
          recurrencyRuleEndType: action.payload.recurrencyRuleEndDate
            ? RecurrencyRuleEndType.Date
            : action.payload.recurrencyRuleOccurrences
            ? RecurrencyRuleEndType.OccurrencesCount
            : RecurrencyRuleEndType.Never,
        };
        const updatedEntityIndex = state.data.findIndex(task => task.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { createRecurrentActionTemplate, clearReccurentActionsError } = slice.actions;
export default slice.reducer;
