import "./editableInputmobilefirst.less";

import { createSelector } from "@reduxjs/toolkit";

import { Row, Typography } from "antd";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { TreeData } from "../../pages/pageConfig/category/utilities";
import { pageSelectors } from "../../selectors";
import { RootState } from "../../store/store";
import { StringNumberNull } from "../../types/common";
import { FieldType, TOption } from "../../types/page";
import { AppThunk } from "../../utils/types";
import InputRenderer from "../InputRender";
import { useLabelAndValueCheck } from "./useLabel";

export type EditableInputMobileFirstProps = {
  onChange: (v: string | null | boolean | number) => void; // The change handler for the new value coming from the input
  fieldValue: StringNumberNull; // The current value
  fieldText?: StringNumberNull; // The current value Text
  placeholder?: string; // Placeholder for the input;
  usePlacehoder?: boolean;
  multilineInput?: boolean;
  type: FieldType;
  id: string; // Name of the field (type/name/email/etc.)
  title?: string | undefined; // Translatable name of the field (type/name/email/etc.)
  options?: TOption[];
  globalEdit: boolean;
  icon?: string | null | React.ReactNode;
  optionSelector: (state: RootState) => TOption[] | null;
  treeData?: {
    treeDataSelector: (state: RootState) => Record<string, unknown>[];
    treeDataSet: (dataSet: Record<string, unknown>[]) => TreeData[];
  };
  tabSwitch?: () => string | undefined;
  handleSetActiveTab?: (tabKey: string) => void;
  isNewEntity?: boolean;
  editForbidden?: boolean | null;
  onEditableModeChanged?: (value: boolean) => void;
  isEditMode?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  currentData?: Record<string, unknown> | null;
  selector?: (state: RootState) => Record<string, unknown>[];
  basedOnDependensiesOptions?: (currentData: unknown, selectedDependentData: unknown[] | null) => TOption[] | null;
  searchById?: boolean;
  canEdit?: boolean | undefined;
  localizedValue?: StringNumberNull;
  className?: string;
  enableMobileScanners?: boolean;
  onCardEdited?: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataFetchAction?: AppThunk<any, any>;
  prefixIcon?: React.ReactNode;
  label?: string;
};

const EditableInputMobileFirst: React.FC<EditableInputMobileFirstProps> = ({
  fieldValue,
  fieldText,
  placeholder,
  onChange,
  type,
  id,
  icon,
  title,
  options,
  globalEdit,
  optionSelector,
  children,
  isNewEntity,
  editForbidden,
  isEditMode,
  treeData,
  disabledDate,
  currentData,
  selector,
  basedOnDependensiesOptions,
  searchById,
  canEdit,
  className,
  localizedValue,
  enableMobileScanners,
  onCardEdited,
  dataFetchAction,
  prefixIcon,
  label,
}): JSX.Element => {
  const { t } = useTranslation();

  const [isEditing, setEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(canEdit);

  const { computedLabel, valueCheck } = useLabelAndValueCheck({
    fieldValue,
    type,
    options,
    optionSelector,
    treeData,
    currentData,
    selector,
    basedOnDependensiesOptions,
    localizedValue,
  });

  // Note: will be filled with options depending on the config implementation (which are used in 'autocomplete'/'dropdown'). Will return 'null' otherwise.
  const optionsSelector = createSelector(pageSelectors.stateSelector, optionSelector);
  const dependentDataSelector = createSelector(pageSelectors.stateSelector, selector ? selector : () => null);
  const selectedDependentData = useSelector(dependentDataSelector);
  const autoCompleteOptions = useSelector(optionsSelector);
  const direction = useSelector((state: RootState) => state.user.settings.direction);

  useEffect(() => {
    setIsEditable(canEdit);
  }, [canEdit]);

  useEffect(() => {
    if (isNewEntity) {
      setEditing(false);
    }
  }, [isNewEntity]);

  useEffect(() => {
    if (!globalEdit) {
      setEditing(false);
    }
  }, [globalEdit]);

  useEffect(() => {
    if (isEditMode !== undefined) {
      setEditing(isEditMode);
    }
  }, [isEditMode]);

  const renderValue = () => {
    return fieldText || (computedLabel !== "0" && computedLabel) || placeholder || null;
  };

  const translatedLabel = label ? t(label) : null;

  return (
    <div id={id} data-test={`EditableInput=${id}`}>
      {isEditing ? (
        <Row style={{ width: "100%" }}>
          <InputRenderer
            enableMobileScanners={enableMobileScanners}
            dataFetchAction={dataFetchAction}
            onCardEdited={onCardEdited}
            onChange={onChange}
            value={type === "text" ? renderValue() : valueCheck}
            managedValue={valueCheck}
            type={type}
            options={
              options ||
              autoCompleteOptions ||
              (basedOnDependensiesOptions && basedOnDependensiesOptions(currentData, selectedDependentData))
            }
            id={id}
            icon={icon}
            title={title}
            treeData={treeData}
            label={type === "treeselect" ? computedLabel : translatedLabel}
            disabledDate={disabledDate}
            searchById={searchById}
            prefixIcon={prefixIcon}
            placeholder={placeholder ? t(placeholder) : undefined}
          >
            {children}
          </InputRenderer>
        </Row>
      ) : (
        <Row className={`${!editForbidden && isEditable ? "pointer " : ""} static-view`}>
          <Row>
            {icon && (
              <Typography.Text dir={direction} ellipsis>
                {icon}
              </Typography.Text>
            )}
            {title && (
              <Typography.Text dir={direction} className="editableInput-wrapper__addl-info" ellipsis title={title}>
                {`${title}:`}
              </Typography.Text>
            )}
            {renderValue() && (
              <Typography.Text
                className={
                  type === "phoneInput"
                    ? "ltr-only-phone-input"
                    : `${className}-${valueCheck} ${valueCheck && className}`
                }
                ellipsis
                title={computedLabel !== null ? computedLabel?.toString() : undefined}
              >
                {renderValue()}
              </Typography.Text>
            )}
          </Row>
        </Row>
      )}
    </div>
  );
};

export default EditableInputMobileFirst;
