import DotsPopover from "../../SingleViewCommon/DotsMenu/DotsPopover";
import { useMobileSingleViewTabContext } from "../context/useMobileSingleViewTabContext";

const MobileCardBulkActions: React.FC = () => {
  const { selectedEntities, tabConfig, handleDotsMenuClick } = useMobileSingleViewTabContext();

  return (
    <DotsPopover
      tabConfig={tabConfig}
      selectedEntities={selectedEntities}
      handleDotsMenuClick={handleDotsMenuClick}
      isExportEnabled={false}
    />
  );
};

export default MobileCardBulkActions;
