import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PageConfigurationContext from "../../../context/pageContext";
import { RootState } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage } from "../../../types/page";
import DetailsComponent from "./DetailsComponent";
import IconComponent from "./IconComponent";

type SingleViewHeaderProps = {
  editIcon?: JSX.Element;
  actionComponent?: JSX.Element | null;
  isNewEntity?: boolean;
};

const SingleViewHeader: React.FC<SingleViewHeaderProps> = ({ editIcon, actionComponent }) => {
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const { t } = useTranslation();

  const { title, mobileFirstTitle } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;

  return (
    <div dir={direction} className="entity-card">
      <div className="header">
        <div className="employee-info">
          <div className="image-container">
            <IconComponent />
            <span className="title">{t(mobileFirstTitle || title)}</span>
          </div>
          <DetailsComponent isNewEntity />
          <div className="icons-container">
            {editIcon}
            {actionComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleViewHeader;
