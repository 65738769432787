import "./CardTitle.less";

import { Typography } from "antd";

import { CategoryId } from "../../../../../types/page";
import useCategoryRedirect from "../../../../../utils/hooks/useCategoryRedirect";

type TProps = {
  title: string | number;
  category?: CategoryId;
  id?: string | number;
};

const CardTitle: React.FC<TProps> = ({ title, category, id }) => {
  const handleRedirect = useCategoryRedirect(category, id);
  const { Text } = Typography;

  if (!title) return null;

  return (
    <Text className="card-title" ellipsis onClick={handleRedirect}>
      {title}
    </Text>
  );
};

export default CardTitle;
