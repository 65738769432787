import "./MobileCardTemplate.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { useAppDispatch } from "../../../../store/store";
import { ColumnWithDifferentCells, FieldType } from "../../../../types/page";
import { TrainingTrainee } from "../../../../types/training";
import { PrivilegeData } from "../../../../types/utility";
import EditableInputMobileFirst from "../../../EditableInput/EditableInputMobileFirst";
import { useMobileSingleViewContext } from "../../context/useMobileSingleViewContext";
import { useMobileSingleViewTabContext } from "../../context/useMobileSingleViewTabContext";

type TProps =
  | {
      entity: PrivilegeData;
      config: ColumnWithDifferentCells<PrivilegeData>;
    }
  | {
      entity: TrainingTrainee;
      config: ColumnWithDifferentCells<TrainingTrainee>;
    };

const EditableInputMobileFirstWithConfig: React.FC<TProps> = ({ entity, config }) => {
  const { setIsTableDataLoading } = useMobileSingleViewTabContext();
  const { setErrors } = useMobileSingleViewContext();

  const dispatch = useAppDispatch();

  const handleColumnDropdownOnChange = async (newValue: string | number | boolean | null) => {
    try {
      if (config.cellDropdownOnChange) {
        setIsTableDataLoading(true);
        const actionResponse = await dispatch(config.cellDropdownOnChange(entity, newValue));
        await unwrapResult(actionResponse);
        setIsTableDataLoading(false);
      }
    } catch (customError) {
      setErrors(customError as Record<string, string[]>);
      setIsTableDataLoading(false);
    }
  };

  return (
    <EditableInputMobileFirst
      className="action-select"
      key={String(config.id)}
      isEditMode={true}
      id={String(config.id)}
      type={config.cellType?.(entity) as FieldType}
      optionSelector={config.optionsSelector}
      globalEdit={true}
      onChange={handleColumnDropdownOnChange}
      fieldValue={config.renderValue(entity)}
      options={config.cellDropdownOptions?.(entity) || []}
      selector={config.cellSearchSelector!}
      prefixIcon={config.cellPrefixIcon}
    />
  );
};

export default EditableInputMobileFirstWithConfig;
