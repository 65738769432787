import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import jwt_decode from "jwt-decode";

import {
  AuthType,
  ChangeCompanyContext,
  ChangePasswordModel,
  Credentials,
  FetchUISettingsResponse,
  ImpersonateData,
  Language,
  LoginWithKeyResp,
  Mode,
  NewPassAccess,
  ResetPasswordModel,
  SaveEntityColSelSettingsParams,
  SuMode,
  TCompanySettings,
  TCompanySettingsRequest,
  TCubeTokenResponse,
  TResponse,
  TUserContextResponse,
  UserPersonalSettings,
  UserRole,
  UserSettings,
  UserState,
  UserToken,
} from "../../types/user";
import { AuthStatus, LocalStorageKeys, TwoFactorType } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: UserState = {
  id: 0,
  isLoading: false,
  changeUserContextInProgress: false,
  error: null,
  jwt: "",
  cubeToken: "",
  userId: undefined,
  settings: {
    direction: "ltr",
    companyId: 0,
    defaultComapnyId: 0,
    availableComapnies: [],
    isSysAdmin: false,
    displayName: "",
    columns: [],
    avatarUrl: "",
    lang: Language["en-US"],
    role: UserRole.None,
  },
  companySettings: {
    firstDayOfWeek: "Monday",
    colors: {},
    phoneCountryCode: "",
    logo: "",
    currentUserId: 0,
    isMapEnabled: false,
    isNewPasEnabled: false,
    newPassSmartDashboardAccess: undefined,
    newPassAccess: NewPassAccess.NewPasHome,
    isTracked: true,
    legacyEnabled: false,
    agendaActionsMode: false,
    authType: AuthType.Username,
    twoFactorType: TwoFactorType.None,
    defaultPageSize: 100,
  },
  personalSettings: {
    language: "en-US",
    avatarUrl: "",
    timeZone: "",
    jobTitle: "",
    hasForms: false,
    userPrivileges: {
      orgUnitOwner: false,
    },
  },
  mode: Mode.Phone,
};

const rtlLanguages = [Language["he-IL"], Language["ar"]];

export const login = createAsyncThunk<TResponse, Credentials, { state: RootState }>(
  "@@USER/LOGIN",
  async (entity, { rejectWithValue, getState }) => {
    const lang = getState().user.settings.lang;
    let tempLoginResponse;
    try {
      tempLoginResponse = await hbApi.post<TResponse>(
        "/Account/Login",
        entity,
        hbApiOptions("", null, { LANGUAGE_KEY: lang })
      );
    } catch (e) {
      return rejectWithValue(e);
    }

    return tempLoginResponse.data;
  }
);

export const resetPassword = createAsyncThunk<TResponse, ResetPasswordModel, { state: RootState }>(
  "@@USER/RESET_PASSWORD",
  async (model, { rejectWithValue, getState }) => {
    const lang = getState().user.settings.lang;
    let tempLoginResponse;
    try {
      tempLoginResponse = await hbApi.post<TResponse>(
        "/Account/reset-password",
        model,
        hbApiOptions("", null, { LANGUAGE_KEY: lang })
      );
    } catch (e) {
      return rejectWithValue(e);
    }

    return tempLoginResponse.data;
  }
);

export const checkResetPassword = createAsyncThunk<boolean, string, { state: RootState }>(
  "@@USER/CHECK_RESET_PASSWORD",
  async (key, { rejectWithValue }) => {
    try {
      const checkResetPasswordResponse = await hbApi.get<boolean>(`/Account/check-reset-password/${key}`);

      return checkResetPasswordResponse.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const loginTfa = createAsyncThunk<TResponse, { temporaryJwt: string; tfaCode: string }, { state: RootState }>(
  "@@USER/LOGIN_TFA",
  async (model, { rejectWithValue, getState }) => {
    const lang = getState().user.settings.lang;
    try {
      const tempLoginResponse = await hbApi.post<TResponse>(
        "/Account/LoginWithCode",
        { Code: model.tfaCode },
        hbApiOptions(model.temporaryJwt, null, { LANGUAGE_KEY: lang })
      );

      return tempLoginResponse.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const otp = createAsyncThunk<TResponse, { Phone?: string; OtpCode?: string }, { state: RootState }>(
  "@@USER/OTP",
  async (model, { rejectWithValue, getState }) => {
    const lang = getState().user.settings.lang;
    try {
      const otp = await hbApi.post<TResponse>("/Account/Otp", model, hbApiOptions("", null, { LANGUAGE_KEY: lang }));

      return otp.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchUserContext = createAsyncThunk<TUserContextResponse, UserToken, { state: RootState }>(
  "@@USER/GET_USER_CONTEXT",
  async (entity, { getState }) => {
    const { user } = getState();

    const getUserContextResponse = await hbApi.get<TUserContextResponse>(
      "/Account/GetUserContext",
      hbApiOptions(entity.token || user.jwt)
    );

    return getUserContextResponse.data;
  }
);

export const updateUserContext = createAsyncThunk<TResponse, ChangeCompanyContext, { state: RootState }>(
  "@@USER/UPDATE_USER_CONTEXT",
  async (entity, { getState }) => {
    const { user } = getState();
    let chosenCompanyId;
    if (entity.token) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decoded: any = jwt_decode(entity.token);
      chosenCompanyId = decoded?._hbsafety_claims_company_
        ? +decoded._hbsafety_claims_company_
        : user.settings.companyId || user.settings.defaultComapnyId || user.settings.availableComapnies[0].id;
    } else {
      chosenCompanyId = entity.companyId;
    }

    const changeUserContextResponse = await hbApi.post<TResponse>(
      "/Account/ChangeUserContext",
      {
        companyId: chosenCompanyId,
      },
      hbApiOptions(entity.token || user.jwt)
    );

    return changeUserContextResponse.data;
  }
);

export const changePassword = createAsyncThunk<boolean, ChangePasswordModel, { state: RootState }>(
  "@@USER/CHANGE_PASSWORD",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const changePasswordResponse = await hbApi.post<boolean>(
        "/Account/change-password",
        entity,
        hbApiOptions(user.jwt)
      );
      return changePasswordResponse.data;
    } catch (e) {
      return rejectWithValue(e.Message);
    }
  }
);

export const fetchEntityColSelSettings = createAsyncThunk<
  FetchUISettingsResponse[],
  { keys: string[] },
  { state: RootState }
>("@@USER/FETCH_ENTITY_COLUMNS", async (entity, { getState }) => {
  const { user } = getState();

  // const modifiedBody = {
  //   key: entity.newValues.key,
  //   value: JSON.stringify(entity.newValues.value),
  // };

  const response = await hbApi.post<UserSettings[]>("/UserUISetting/getByKeys", entity.keys, hbApiOptions(user.jwt));
  const modifiedResponse: FetchUISettingsResponse[] = response.data.map(setting => ({
    key: setting.key,
    value: JSON.parse(setting.value),
  }));

  return modifiedResponse;
});

export const fetchCubeToken = createAsyncThunk<TCubeTokenResponse, boolean | undefined, { state: RootState }>(
  "@@USER/CUBE_TOKEN",
  async (crossProjects, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<TCubeTokenResponse>(
      `/SmartDashboard/get-token${crossProjects ? `?crossProjects=${crossProjects}` : ""}`,
      hbApiOptions(user.jwt)
    );
    const modifiedResponse: TCubeTokenResponse = response.data;

    return modifiedResponse;
  }
);

export const fetchCompanySettings = createAsyncThunk<TCompanySettings, TCompanySettingsRequest, { state: RootState }>(
  "@@USER/COMPANY_SETTINGS",
  async ({ jwt }, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<TCompanySettings>("/CompanySetting", hbApiOptions(jwt ? jwt : user.jwt));
    const modifiedResponse: TCompanySettings = response.data;
    return modifiedResponse;
  }
);

export const fetchEntitiesSettings = createAsyncThunk<FetchUISettingsResponse[], void, { state: RootState }>(
  "@@USER/FETCH_ALL_COLUMNS",
  async (_, { getState }) => {
    const { user } = getState();

    const response = await hbApi.get<UserSettings[]>("/UserUISetting", hbApiOptions(user.jwt));
    const modifiedResponse: FetchUISettingsResponse[] = response.data.map(setting => ({
      key: setting.key,
      value: JSON.parse(setting.value),
    }));

    return modifiedResponse;
  }
);

// TODO: Currently this would overwrite any other objects under "values"/ e.g. sortOrder/columnOrder/others
export const saveEntityColSelSettings = createAsyncThunk<
  FetchUISettingsResponse,
  SaveEntityColSelSettingsParams,
  { state: RootState }
>("@@USER/SAVE_ENTITY_COLUMNS", async (entity, { getState }) => {
  const { user } = getState();

  const modifiedBody = {
    key: entity.newValues.key,
    value: JSON.stringify(entity.newValues.value),
  };
  const response =
    entity.method === "put"
      ? await hbApi.put<UserSettings>(`/UserUISetting/${entity.newValues.key}`, modifiedBody, hbApiOptions(user.jwt))
      : await hbApi.post<UserSettings>("/UserUISetting", modifiedBody, hbApiOptions(user.jwt));

  const modifiedResponse: FetchUISettingsResponse = {
    key: response.data.key,
    value: JSON.parse(response.data.value),
  };

  return modifiedResponse;
});

export const getUserTokenByKey = createAsyncThunk<
  { token: string; companySettings: TCompanySettings },
  { key: string; mobile: boolean },
  { state: RootState }
>(
  "@@USER/GET_USER_TOKEN_BY_KEY",
  async ({ key, mobile }, {}) => {
    const tokenResponse = await hbApi.post<LoginWithKeyResp>(`/Account/LoginWithKey?key=${key}&mobile=${mobile}`);

    const companySettingsResponse = await hbApi.get<TCompanySettings>(
      "/CompanySetting",
      hbApiOptions(tokenResponse.data.data.token)
    );

    const token = tokenResponse.data.data.token;

    const modifiedResponse: { token: string; companySettings: TCompanySettings } = {
      token: token,
      companySettings: companySettingsResponse.data,
    };

    return modifiedResponse;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      return !user.isLoading;
    },
  }
);

export const getRedirectToLegacy = createAsyncThunk<
  { returnUrl: string; triggerLoader?: boolean },
  { returnUrl?: string | null | undefined; triggerLoader?: boolean; loginForm?: boolean | null | undefined },
  { state: RootState }
>("@@USER/REDIRECT_TO_LEGACY", async ({ returnUrl, triggerLoader, loginForm }, { getState }) => {
  const { user } = getState();
  const { data } = await hbApi.get<string>(
    `/Account/redirect-to-legacy?language=${user.settings.lang}${returnUrl ? `&returnUrl=${returnUrl}` : ""}${
      loginForm ? "&loginForm=True" : ""
    }`,
    hbApiOptions(user.jwt)
  );

  return { returnUrl: `${data}&companyId=${user.settings.companyId}`, triggerLoader: triggerLoader };
});

export const fetchUserPersonalSettings = createAsyncThunk<UserPersonalSettings, void, { state: RootState }>(
  "@@USER/FETCH_USER_PERSONAL_SETTINGS",
  async (_, { getState }) => {
    const { user } = getState();

    const response = await hbApi.get<UserPersonalSettings>("/UserSetting", hbApiOptions(user.jwt));
    const modifiedResponse: UserPersonalSettings = response.data;

    return modifiedResponse;
  }
);

export const setUserLanguage = createAsyncThunk<
  string,
  { language: string; updateSettings?: boolean },
  { state: RootState }
>("@@USER/SET_USER_LANGUAGE", async ({ language, updateSettings = true }, { getState }) => {
  const { user } = getState();

  try {
    if (user.jwt && updateSettings) {
      await hbApi.patch<UserPersonalSettings>(
        "/UserSetting",
        [{ path: "/language", op: "replace", value: language }],
        hbApiOptions(user.jwt)
      );
    }
  } catch (error) {
    console.error("UserSetting error:", error);
  }

  return language;
});

export const logout = createAsyncThunk<boolean, void, { state: RootState }>(
  "@@USER/LOGOUT",
  async (_, { getState }) => {
    const { user } = getState();

    try {
      const res = await hbApi.get("/Account/logout", hbApiOptions(user.jwt));
      localStorage.removeItem(LocalStorageKeys.VITRE_ENTERED);

      if (res.status === 200 && res.data) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(false);
      return false;
    }
  }
);

export const forgotPassword = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  { username: string; email: string } | null | undefined,
  { state: RootState }
>("@@USER/FORGOT_PASSWORD", async (values, { getState }) => {
  const { user, employee } = getState();
  try {
    const res = await hbApi.post<boolean>(
      "/Account/forgot-password",
      {
        //this thunk in use on login page and also in single view of employee
        ...(values ? values : { email: employee.singleData?.email, username: employee.singleData?.username }),
        success: true,
      },
      hbApiOptions(user.jwt)
    );
    if (res.data) {
      return res.data;
    } else {
      throw new Error("ErrorInvalidEmail");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const loginImpersonate = createAsyncThunk<string, ImpersonateData, { state: RootState }>(
  "@@USER/LOGIN_IMPERSONATE",
  async (entity, { rejectWithValue, getState }) => {
    const { user } = getState();
    const { jwt, settings } = user;

    let res;
    try {
      res = await hbApi.post<string>(
        "/Account/impersonate",
        entity,
        hbApiOptions(jwt, null, { LANGUAGE_KEY: settings.lang })
      );
    } catch (e) {
      return rejectWithValue(e);
    }

    return res.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRoleFromToken: (token: any) => UserRole = token => {
  if (token?._hbsafety_claims_su_.toLowerCase() === "true") {
    return UserRole.SU;
  }

  const tenantRole = token?._hbsafety_claims_tenant_role_;

  switch (tenantRole) {
    case UserRole.Admin:
      return UserRole.Admin;
    case UserRole.User:
      return UserRole.User;
    case UserRole.Reporter:
      return UserRole.Reporter;

    default:
      const companyRole = token?._hbsafety_claims_company_role_;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return companyRole ? (<any>UserRole)[companyRole] : UserRole.None;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIdFromToken: (token: any) => number = token => {
  return parseInt(token["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
};

const saveUserInfoFromToken = (state: UserState, token: string, companyId?: string, defaultCompanyId?: number) => {
  localStorage.setItem(LocalStorageKeys.JWT, token);
  state.jwt = token;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const decoded: any = jwt_decode(token);
  state.userId = decoded?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
  state.settings.companyId = decoded?._hbsafety_claims_company_
    ? +decoded._hbsafety_claims_company_
    : Number(companyId) ?? defaultCompanyId ?? 0;
  state.settings.displayName = decoded?._hbsafety_claims_displayname_ || "";
  state.settings.isSysAdmin = decoded?._hbsafety_claims_su_
    ? decoded._hbsafety_claims_su_.toLowerCase() === "true"
    : false;
  state.settings.role = getRoleFromToken(decoded);
  state.id = getIdFromToken(decoded);

  const impersonationSessionId = decoded._hbsafety_claims_impersonation_session_id_;
  if (impersonationSessionId) {
    state.settings.suMode = SuMode.Impersonate;
  }
};

const slice = createSlice({
  name: "user",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    changeSuMode: (state, mode) => {
      state.settings.suMode = mode.payload;
    },
    changeOtpMode: (state, mode) => {
      state.mode = mode.payload;
    },
    removeAuthToken: state => {
      state.jwt = "";
      localStorage.removeItem(LocalStorageKeys.JWT);
    },
    setCompanyLogoInternalUrl: (state, action: PayloadAction<string | undefined>) => {
      state.logoInternalUrl = action.payload;
    },
    setAvatarInternalUrl: (state, action: PayloadAction<string | undefined>) => {
      state.avatarInternalUrl = action.payload;
    },
    setUserTimezone: (state, action: PayloadAction<string>) => {
      state.personalSettings.timeZone = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        saveUserInfoFromToken(state, action.payload);
      }
    },
    setDirection: (state, action: PayloadAction<"rtl" | "ltr">) => {
      state.settings.direction = action.payload;
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(login.pending, state => {
        state.isLoading = true;
      })
      .addCase(loginImpersonate.pending, state => {
        state.isLoading = true;
      })
      .addCase(resetPassword.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchUserContext.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateUserContext.pending, state => {
        state.isLoading = true;
        state.changeUserContextInProgress = true;
      })
      .addCase(getUserTokenByKey.pending, state => {
        state.isLoading = true;
      })
      .addCase(changePassword.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchEntityColSelSettings.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchEntitiesSettings.pending, state => {
        state.isLoading = true;
      })
      .addCase(otp.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchCubeToken.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchCompanySettings.pending, state => {
        state.isLoading = true;
      })
      .addCase(saveEntityColSelSettings.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchUserPersonalSettings.pending, state => {
        state.isLoading = true;
      })
      .addCase(setUserLanguage.pending, state => {
        state.isLoading = true;
      })
      .addCase(logout.pending, state => {
        state.isLoading = true;
      })
      .addCase(getRedirectToLegacy.pending, (state, action) => {
        if (action.meta.arg?.triggerLoader) {
          state.isLoading = true;
        }
      })
      .addCase(checkResetPassword.pending, state => {
        state.isLoading = true;
      })
      // Note - Rejected:
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(loginImpersonate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(otp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchUserContext.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(updateUserContext.rejected, (state, action) => {
        state.isLoading = false;
        state.changeUserContextInProgress = false;
        state.error = action.error;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchEntityColSelSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getUserTokenByKey.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchEntitiesSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchCubeToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchCompanySettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(saveEntityColSelSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchUserPersonalSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(setUserLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getRedirectToLegacy.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(checkResetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      // Note - Fulfilled:
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.data.status === "Authorized") {
          saveUserInfoFromToken(
            state,
            action.payload.data.token,
            action.payload.data.companyId,
            action.payload.data.defaultCompanyId
          );
        }
        state.error = null;
        state.isLoading = false;
      })
      .addCase(loginImpersonate.fulfilled, (state, action) => {
        const token = action.payload;
        if (token) {
          saveUserInfoFromToken(state, token);
          state.settings.suMode = SuMode.Impersonate;
        }
        state.error = null;
        state.isLoading = false;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        if (action.payload.data?.status === "Authorized") {
          saveUserInfoFromToken(
            state,
            action.payload.data.token,
            action.payload.data.companyId,
            action.payload.data.defaultCompanyId
          );
        }
        state.error = null;
        state.isLoading = false;
      })
      .addCase(loginTfa.fulfilled, (state, action) => {
        if (action.payload.data.status === "Authorized") {
          saveUserInfoFromToken(
            state,
            action.payload.data.token,
            action.payload.data.companyId,
            action.payload.data.defaultCompanyId
          );
        }
        state.error = null;
        state.isLoading = false;
      })
      .addCase(otp.fulfilled, (state, action) => {
        if (action.payload.data.status === AuthStatus.Authorized) {
          saveUserInfoFromToken(
            state,
            action.payload.data.token,
            action.payload.data.companyId,
            action.payload.data.defaultCompanyId
          );
        }
        state.error = null;
        state.isLoading = false;
      })
      .addCase(updateUserContext.fulfilled, (state, action) => {
        state.jwt = action.payload.data.token;
        localStorage.setItem(LocalStorageKeys.JWT, action.payload.data.token);
        state.settings.companyId = action.payload.data.companyId
          ? parseInt(action.payload.data.companyId)
          : action.payload.data.defaultCompanyId;
        state.settings.defaultComapnyId = action.payload.data.defaultCompanyId;
        state.settings.isSysAdmin = action.payload.data.isSysAdmin;
        state.settings.displayName = action.payload.data.displayName;
        state.settings.avatarUrl = action.payload.data.avatarUrl;
        state.changeUserContextInProgress = false;
        state.isLoading = false;
      })
      .addCase(fetchUserContext.fulfilled, (state, action) => {
        state.settings.availableComapnies = action.payload.data.companies;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(changePassword.fulfilled, state => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchEntityColSelSettings.fulfilled, (state, action) => {
        state.settings.columns = [...state.settings.columns, ...action.payload];
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchEntitiesSettings.fulfilled, (state, action) => {
        state.settings.columns = action.payload.map(entity => ({
          key: entity.key,
          value: entity.value,
        }));
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchCubeToken.fulfilled, (state, action) => {
        state.cubeToken = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchCompanySettings.fulfilled, (state, action) => {
        state.companySettings = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(saveEntityColSelSettings.fulfilled, (state, action) => {
        const updatedEntityIndex = state.settings.columns.findIndex(e => e.key === action.payload.key);
        // Updating an already existing entity (/PUT)
        if (updatedEntityIndex >= 0) {
          state.settings.columns[updatedEntityIndex].value = action.payload.value;
          // Creating a new entity (/POST)
        } else {
          state.settings.columns.push({
            key: action.payload.key,
            value: action.payload.value,
          });
        }
        state.error = null;
        state.isLoading = false;
      })
      .addCase(getUserTokenByKey.fulfilled, (state, action) => {
        if (action.payload) {
          saveUserInfoFromToken(state, action.payload.token);
          state.companySettings = action.payload.companySettings;
        }
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchUserPersonalSettings.fulfilled, (state, action) => {
        state.personalSettings = {
          ...state.personalSettings,
          avatarUrl: action.payload.avatarUrl,
          jobTitle: action.payload.jobTitle,
          hasForms: action.payload.hasForms,
          userPrivileges: action.payload.userPrivileges,
        };
        state.error = null;
        state.isLoading = false;
      })
      .addCase(setUserLanguage.fulfilled, (state, action) => {
        if (action.payload) {
          const language = action.payload as Language;
          state.settings.lang = language;
          if (rtlLanguages.includes(language)) {
            state.settings.direction = "rtl";
          } else {
            state.settings.direction = "ltr";
          }
        }
        state.isLoading = false;
      })
      .addCase(logout.fulfilled, state => {
        state.logoInternalUrl = undefined;
        state.isLoading = false;
        state.settings.isSysAdmin = false;
        state.settings.suMode = undefined;
        state.jwt = "";
        localStorage.removeItem(LocalStorageKeys.JWT);
      })
      .addCase(getRedirectToLegacy.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(checkResetPassword.fulfilled, state => {
        state.isLoading = false;
      });
  },
});

export const {
  setAuthToken,
  setDirection,
  changeSuMode,
  changeOtpMode,
  setAvatarInternalUrl,
  setCompanyLogoInternalUrl,
  setUserTimezone,
  removeAuthToken,
} = slice.actions;

export default slice.reducer;
