import { PivotConfig, ResultSet } from "@cubejs-client/core";
import { CustomResultSet } from "./resultSetFactory";

class DefaultResultSet implements CustomResultSet {
  constructor(protected resultSet: ResultSet) {}

  pivot(pivotConfig?: PivotConfig) {
    return this.resultSet.pivot(pivotConfig);
  }

  tablePivot(pivotConfig?: PivotConfig) {
    return this.resultSet.tablePivot(pivotConfig);
  }

  series(pivotConfig?: PivotConfig) {
    return this.resultSet.series(pivotConfig);
  }

  categories(pivotConfig?: PivotConfig) {
    return this.resultSet.categories(pivotConfig);
  }

  tableColumns(pivotConfig?: PivotConfig) {
    return this.resultSet.tableColumns(pivotConfig);
  }

  totalRow(pivotConfig?: PivotConfig) {
    return this.resultSet.totalRow(pivotConfig);
  }

  decompose() {
    return this.resultSet.decompose();
  }

  seriesNames(pivotConfig?: PivotConfig) {
    return this.resultSet.seriesNames(pivotConfig);
  }

  annotation() {
    return this.resultSet.annotation();
  }

  query() {
    return this.resultSet.query();
  }

  get queryType() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.resultSet as any)?.queryType;
  }
}

export default DefaultResultSet;
