import "../MobileCardTemplate.less";

import { InspectionSingleView } from "../../../../../types/inspection";
import InspectionMobileCardTemplate from "./InspectionMobileCardTemplate";

type TProps = {
  entity: InspectionSingleView;
};

const InspectionLocationMobileCardTemplate: React.FC<TProps> = ({ entity }) => {
  return <InspectionMobileCardTemplate entity={entity} category="inspectionLocation" />;
};

export default InspectionLocationMobileCardTemplate;
