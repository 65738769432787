import dayjs from "dayjs";
import { DATE_FORMAT_WITH_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { WebhookContextConditionModal } from "../../../../components/WebhookContextConditionModal/WebhookContextConditionModal";
import {
  addNewEvent,
  deleteNewEventTemplate,
  updateEvent,
  updateEventTextField,
  updateNewEventActive,
  updateNewEventSubType,
  updateNewEventType,
} from "../../../../store/slices/webhooks";
import store from "../../../../store/store";
import { CellTypes, ColumnType, ColumnWithDifferentCells, InputTypes } from "../../../../types/page";
import { Webhook, WebhookEvent, WebhookKeys, WebhookLog } from "../../../../types/webhooks";
import {
  fetchWebhookEventSubTypeById,
  fetchWebhookEventTypeById,
  localizeText,
  renderBoolStatus,
  renderHttpStatus,
} from ".././utilities";
import { booleanOptions, boolOptions } from "../common";
export const webhooksTableColumns: ColumnType<Webhook>[] = [
  {
    id: WebhookKeys.Id,
    label: "ID",
    renderValue: entity => (entity.id && entity.id.toString()) || "",
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: WebhookKeys.Name,
    label: "FieldName",
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    renderValue: entity => entity.name,
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: WebhookKeys.Url,
    label: "FieldUrl",
    valueType: "string",
    filterType: InputTypes.MULTI_SELECT,
    renderValue: entity => entity.url,
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: WebhookKeys.Active,
    label: "FieldActive",
    filterType: InputTypes.MULTI_SELECT,
    renderValue: entity => renderBoolStatus(entity.active),
    optionsSelector: () =>
      booleanOptions.map(b => ({
        id: b.id,
        label: localizeText(b.label),
      })),
    sortable: true,
  },
];

export const webhookLogsTableColumns: ColumnType<WebhookLog>[] = [
  {
    id: "createdAt",
    label: "FieldCreatedAt",
    renderValue: value =>
      dayjs(value.createdAt).isValid() ? dayjs(value.createdAt).format(DATE_FORMAT_WITH_TIME) : null,
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "url",
    label: "FieldUrl",
    renderValue: value => value.url,
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "eventType",
    label: "FieldEventType",
    renderValue: value => fetchWebhookEventTypeById(value.eventType),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "eventSubType",
    label: "FieldEventSubType",
    renderValue: value => fetchWebhookEventSubTypeById(value.eventType, value.eventSubType),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "responseCode",
    label: "FieldResponseCode",
    renderValue: () => null,
    cellType: () => "icon",
    valueIcon: (value: WebhookLog): React.ReactNode | null => renderHttpStatus(value.responseCode),
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "error",
    label: "FieldError",
    renderValue: value => value.error,
    sortable: false,
    optionsSelector: () => null,
  },
];

export const webhookEventsTableColumns: ColumnWithDifferentCells<WebhookEvent>[] = [
  {
    id: "eventType",
    label: "FieldEventType",
    renderValue: e => fetchWebhookEventTypeById(e.eventType),
    cellType: e => (e.staging ? CellTypes.DROPDOWN : CellTypes.TEXT),
    cellDropdownOptions: () =>
      store.getState().webhooks.eventTypes.map(e => ({ label: e.eventTypeName, id: e.eventTypeId })),
    cellDropdownOnChange: (e, newValue) => updateNewEventType({ entity: e, newValue: newValue as number }),
    sortable: true,
    optionsSelector: () => null,
    width: 200,
  },
  {
    id: "eventSubType",
    label: "FieldEventSubType",
    renderValue: e => fetchWebhookEventSubTypeById(e.eventType, e.eventSubType),
    cellType: e => (e.staging ? CellTypes.DROPDOWN : CellTypes.TEXT),
    cellDropdownOptions: e =>
      store
        .getState()
        .webhooks.eventTypes.find(x => x.eventTypeId === e.eventType)
        ?.eventSubTypes.map(e => ({ label: e.eventSubTypeName, id: e.eventSubTypeId })) || [],
    cellDropdownOnChange: (e, newValue) => updateNewEventSubType({ entity: e, newValue: newValue as number }),
    sortable: true,
    optionsSelector: () => null,
    width: 200,
  },
  {
    id: "contextCondition",
    label: "FieldContextCondition",
    renderValue: value => value.contextCondition,
    cellType: () => CellTypes.CUSTOM,
    CustomComponent: WebhookContextConditionModal,
    onChange: (entity, value, fieldId) =>
      entity.staging
        ? updateEventTextField({ entity, value, fieldId })
        : updateEvent({ ...entity, contextCondition: String(value) }),
    isCellContentVisible: e => e.staging,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "metaData",
    label: "FieldMetaData",
    renderValue: value => value.metaData,
    cellType: e => (e.staging ? CellTypes.INPUT : CellTypes.TEXT),
    onChange: (entity, value, fieldId) => updateEventTextField({ entity, value, fieldId }),
    isCellContentVisible: e => e.staging,
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "active",
    label: "FieldActive",
    renderValue: value => renderBoolStatus(value.active),
    cellType: () => CellTypes.DROPDOWN,
    sortable: true,
    cellDropdownOptions: () =>
      boolOptions.map(b => ({
        id: b.id,
        label: localizeText(b.label),
      })),
    cellDropdownOnChange: (e, newValue) =>
      e.staging
        ? updateNewEventActive({ entity: e, newValue: newValue as boolean })
        : updateEvent({ ...e, active: newValue as boolean }),
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "description",
    label: "FieldDescription",
    renderValue: value => value.description,
    cellType: e => (e.staging ? CellTypes.INPUT : CellTypes.TEXT),
    onChange: (entity, value, fieldId) => updateEventTextField({ entity, value, fieldId }),
    isCellContentVisible: e => e.staging,
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "action",
    label: "FieldAction",
    renderValue: () => null,
    sortable: false,
    optionsSelector: () => null,
    cellType: () => CellTypes.BUTTON_ACTIONS,
    width: 100,
    stagingActions: [
      {
        label: "Add",
        className: "hb-primary-button",
        action: entity => addNewEvent(entity),
      },
      {
        label: "Cancel",
        className: "hb-tertiary-button",
        action: entity => deleteNewEventTemplate(entity),
      },
    ],
  },
];
