import { unwrapResult } from "@reduxjs/toolkit";
import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { routPaths } from "../../../router";
import { checkResetPassword } from "../../../store/slices/user";
import { RootState, useAppDispatch } from "../../../store/store";
import { NewPassAccess, ResetPasswordModel } from "../../../types/user";
import { LocalStorageKeys, ParamsKeys } from "../../../types/utility";
import { notSupportedFunc } from "../../../utils/functions";
import useLocalStorage from "../../../utils/hooks/useLocalStorage";
import useRouter from "../../../utils/hooks/useRouter";

export enum MessageType {
  Error = "error",
  Info = "info",
}
interface LoginProps {
  customReferer?: string;
  darkBackground?: boolean;
  setCustomTitle: (title: string) => void;
}

interface TfaInfo {
  temporaryJwt: string;
  tfaCode: string;
}

interface BackToLoginMessage {
  message: string;
  type: MessageType;
}

interface LoginContextData {
  customReferer?: string;
  darkBackground?: boolean;
  setCustomTitle: (title: string) => void;
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  tfaInfo: TfaInfo;
  setTfaInfo: React.Dispatch<React.SetStateAction<TfaInfo>>;
  backToLoginMessage: BackToLoginMessage | null;
  setBackToLoginMessage: (data: BackToLoginMessage | null) => void;
  resetPasswordInfo: ResetPasswordModel | null;
  setResetPasswordInfo: (data: ResetPasswordModel | null) => void;
  isForgotPassword: boolean;
  setIsForgotPassword: (data: boolean) => void;
  isChooseSuMode: boolean;
}

const DEFAUTL_CONTEXT_VALUE: LoginContextData = {
  setCustomTitle: notSupportedFunc,
  username: "",
  setUsername: notSupportedFunc,
  email: "",
  setEmail: notSupportedFunc,
  password: "",
  setPassword: notSupportedFunc,
  errorMessage: "",
  setErrorMessage: notSupportedFunc,
  tfaInfo: { temporaryJwt: "", tfaCode: "" },
  setTfaInfo: notSupportedFunc,
  backToLoginMessage: null,
  setBackToLoginMessage: notSupportedFunc,
  resetPasswordInfo: null,
  setResetPasswordInfo: notSupportedFunc,
  isForgotPassword: false,
  setIsForgotPassword: notSupportedFunc,
  isChooseSuMode: false,
};

const LoginContext = createContext(DEFAUTL_CONTEXT_VALUE);

const LoginProvider: FC<PropsWithChildren<LoginProps>> = ({
  children,
  customReferer,
  darkBackground,
  setCustomTitle,
}) => {
  const [username, setUsername] = useLocalStorage<string>(LocalStorageKeys.Username);
  const [email, setEmail] = useLocalStorage<string>(LocalStorageKeys.Email);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [resetPasswordInfo, setResetPasswordInfo] = useState<ResetPasswordModel | null>(null);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [tfaInfo, setTfaInfo] = useState<TfaInfo>({ temporaryJwt: "", tfaCode: "" });
  const dispatch = useAppDispatch();
  const { history, location } = useRouter<{ referer: Location }>();
  const referer = customReferer || location.state?.referer || "/actions";
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const isSysAdmin = useSelector((state: RootState) => state.user.settings.isSysAdmin);
  const suMode = useSelector((state: RootState) => state.user.settings.suMode);
  const { companySettings } = useSelector((state: RootState) => state.user);
  const [backToLoginMessage, setBackToLoginMessage] = useState<BackToLoginMessage | null>(null);

  const query = new URLSearchParams(location.search);
  const key = query.get(ParamsKeys.KEY);

  const [isChooseSuMode, setIsChooseSuMode] = useState(false);

  useEffect(() => {
    if (isSysAdmin && suMode === undefined) {
      setIsChooseSuMode(true);
      return;
    }

    if (jwt && !key && !resetPasswordInfo) {
      if (companySettings.newPassAccess == NewPassAccess.NewPasDisabled) {
        history.push(routPaths.redirectToLegacy);
      } else {
        const returnUrl = query.get(ParamsKeys.RETURN_URL);
        history.push(returnUrl ?? referer);
      }
    }
  }, [jwt, resetPasswordInfo, suMode]);

  useEffect(() => {
    if (key) {
      dispatch(checkResetPassword(key))
        .then(unwrapResult)
        .then(res => {
          if (res) {
            setCustomTitle("ResetPasswordTitle");
            setResetPasswordInfo({ key: key, password: "", repeatPassword: "" });
          } else {
            setBackToLoginMessage({ message: "ExpiredUrl", type: MessageType.Error });
          }
        })
        .catch(e => {
          if (e.userError) {
            setBackToLoginMessage({ message: e.userError, type: MessageType.Error });
          }
          console.error(e);
        })
        .finally(() => {
          query.delete(ParamsKeys.KEY);
          query.delete(ParamsKeys.USER_ID);
          history.replace({ search: query.toString() });
        });
    }
  }, [key]);

  const value = useMemo(
    () => ({
      customReferer,
      darkBackground,
      setCustomTitle,
      username,
      setUsername,
      email,
      setEmail,
      password,
      setPassword,
      errorMessage,
      setErrorMessage,
      tfaInfo,
      setTfaInfo,

      backToLoginMessage,
      setBackToLoginMessage,
      resetPasswordInfo,
      setResetPasswordInfo,
      isForgotPassword,
      setIsForgotPassword,

      isChooseSuMode,
    }),
    [
      setCustomTitle,
      username,
      email,
      password,
      errorMessage,
      tfaInfo,
      backToLoginMessage,
      resetPasswordInfo,
      isForgotPassword,
      isChooseSuMode,
      suMode,
    ]
  );

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};

const useLoginContext = () => useContext(LoginContext);

export { useLoginContext, LoginProvider, LoginContext };
