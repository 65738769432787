import dayjs from "dayjs";

import { ReactComponent as DatePickerIcon } from "../../../../media/datePicker-icon.svg";
import { issueTypeSelectors } from "../../../../selectors";
import { BaseField, InputTypes } from "../../../../types/page";
import { RecurrentAction } from "../../../../types/recurrentAction";
import { ReviewOptions } from "../../../../types/tasks";
import { boolOptions } from "../common";
import { constructTableTree, fetchEmployeeById, fetchLocationById, localizeText, renderBoolStatus } from "../utilities";

export const titleField: BaseField<RecurrentAction> = {
  id: "title",
  title: "FieldTitle",
  value: value => value.title,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const projectIdField: BaseField<RecurrentAction> = {
  id: "projectId",
  placeholder: "Select",
  title: "FieldProject",
  value: (e: RecurrentAction) => e.projectId,
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    [...new Map(state.actions.usersQuickCreateData.map(item => [item.projectId, item])).values()]
      .filter(x => x.projectId)
      .map(x => ({ id: x.projectId as number, label: x.projectName })),
  cleanOnChange: ["responsibleUserId", "followUpReviewId"],
};

export const responsibleUserIdField: BaseField<RecurrentAction> = {
  id: "responsibleUserId",
  title: "FieldResponsible",
  value: (e: RecurrentAction) => e.responsibleUserId,
  valueText: (e: RecurrentAction) => fetchEmployeeById(e.responsibleUserId),
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    state.employee.basicData?.map(l => ({
      id: l.id,
      label: l.displayName,
    })),
  placeholder: localizeText("Select"),
};

export const isActiveField: BaseField<RecurrentAction> = {
  id: "isActive",
  title: "FieldIsActive",
  value: e => renderBoolStatus(e.isActive),
  type: InputTypes.BOOLEAN,
  options: boolOptions,
  optionsSelector: () =>
    boolOptions.map(l => ({
      id: l.id,
      label: localizeText(l.label),
    })),
};

export const isFormBasedField: BaseField<RecurrentAction> = {
  id: "isFormBased",
  title: "FormBased",
  value: e => renderBoolStatus(e.isFormBased),
  type: InputTypes.BOOLEAN,
  options: boolOptions,
  optionsSelector: () =>
    boolOptions.map(l => ({
      id: l.id,
      label: localizeText(l.label),
    })),
  dependentFields: [
    {
      id: "followUpReviewId",
      title: "FieldReview",
      value: (e: RecurrentAction) => e.followUpReviewId,
      type: InputTypes.AUTOCOMPLETE,
      optionsSelector: () => null,
      selector: state => [
        ...new Map(
          state.actions.usersQuickCreateData
            .map(x => ({
              id: x.reviewId,
              label: x.reviewName,
              projectId: x.projectId,
            }))
            .map(item => [item.id, item])
        ).values(),
      ],
      basedOnDependensiesOptions: (currentData: RecurrentAction, selectedDependentData: ReviewOptions[] | null) =>
        selectedDependentData
          ?.filter(x => (currentData?.projectId ? currentData?.projectId === x.projectId : true))
          .map(l => ({
            id: l.id,
            label: l.label,
          })) || [],
      placeholder: localizeText("Select"),
    },
  ],
};

export const issueTypeField: BaseField<RecurrentAction> = {
  id: "issueTypeId",
  title: "FieldIssueType",
  value: (e: RecurrentAction) => e.issueTypeId,
  type: InputTypes.TREESELECT,
  treeData: {
    treeDataSelector: issueTypeSelectors.all,
    treeDataSet: dataSet =>
      constructTableTree(
        {
          data: dataSet,
          itemValueKey: "id",
          itemLabelKey: "name",
          isNullableField: true,
          disableInactiveItems: true,
        },
        true
      ),
  },
  placeholder: localizeText("Select"),
  optionsSelector: () => null,
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "issueType",
  },
  enableMobileScanners: true,
};

export const employeeIdField: BaseField<RecurrentAction> = {
  id: "employeeId",
  title: "FieldEmployee",
  value: (e: RecurrentAction) => e.employeeId,
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    state.employee.basicData?.map(l => ({
      id: l.id,
      label: l.displayName,
    })),
  placeholder: localizeText("Select"),
  enableMobileScanners: true,
};

export const equipmentIdField: BaseField<RecurrentAction> = {
  id: "equipmentId",
  title: "FieldEquipment",
  value: (e: RecurrentAction) => e.equipmentId,
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    state.equipment.basicData?.map(l => ({
      id: l.id,
      label: l.name,
    })),
  placeholder: localizeText("Select"),
  enableMobileScanners: true,
};

export const locationIdField: BaseField<RecurrentAction> = {
  id: "locationId",
  title: "FieldLocation",
  value: (e: RecurrentAction) => e.locationId,
  valueText: entity => fetchLocationById(entity.locationId),
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    state.location.basicData?.map(l => ({
      id: l.id,
      label: l.name,
    })),
  placeholder: localizeText("Select"),
  enableMobileScanners: true,
};

export const startDateField: BaseField<RecurrentAction> = {
  id: "startDate",
  title: "FieldStartDate",
  Icon: DatePickerIcon,
  value: e => (e.startDate ? dayjs(e.startDate).format("DD/MM/YYYY HH:mm") : null),
  type: InputTypes.DATE,
  placeholder: localizeText("Undefined"),
  optionsSelector: () => null,
};

export const durationHoursField: BaseField<RecurrentAction> = {
  id: "durationHours",
  title: "FieldTaskDuration",
  value: entity => entity.durationHours,
  type: InputTypes.NUMBER,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const primaryFields: BaseField<RecurrentAction>[] = [
  titleField,
  projectIdField,
  responsibleUserIdField,
  isActiveField,
];

export const secondaryFields: BaseField<RecurrentAction>[] = [
  isFormBasedField,
  issueTypeField,
  employeeIdField,
  equipmentIdField,
  locationIdField,
  startDateField,
  durationHoursField,
];
