import "../MobileCardTemplate.less";

import {
  locationAccountableIsAssignedToOUBranchField,
  locationAccountableRoleField,
} from "../../../../../pages/pageConfig/category/location/locationConfigPageData";
import { MobileCardTemplateTProps } from "../../../../../types/page";
import { PrivilegeData } from "../../../../../types/utility";
import AccountableMobileCardTemplateWithSelection from "./AccountableMobileCardTemplateWithSelection";

const LocationAccountableMobileCardTemplate: React.FC<MobileCardTemplateTProps<PrivilegeData>> = ({ entity }) => {
  const roleSelectConfig = locationAccountableRoleField;
  const isAssignedToOUBranchSelectConfig = locationAccountableIsAssignedToOUBranchField;

  return (
    <AccountableMobileCardTemplateWithSelection
      entity={entity}
      roleSelectConfig={roleSelectConfig}
      isAssignedToOUBranchSelectConfig={isAssignedToOUBranchSelectConfig}
    />
  );
};

export default LocationAccountableMobileCardTemplate;
