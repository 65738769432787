import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Image } from "antd";
import { getRedirectToLegacy } from "../../store/slices/user";
import { useAppDispatch } from "../../store/store";
import { LocalStorageKeys } from "../../types/utility";

export default function RedirectToPAS() {
  const dispatch = useAppDispatch();

  const redirectToLegacy = () => {
    dispatch(getRedirectToLegacy({}))
      .then(res => unwrapResult(res))
      .then(data => {
        localStorage.removeItem(LocalStorageKeys.JWT);
        window.location.href = data.returnUrl;
      })
      .catch(e => {
        console.error(e);
      });
  };
  return <Button onClick={redirectToLegacy} icon={<Image preview={false} src="media/back-vitre.png" />} type="text" />;
}
