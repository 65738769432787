import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import HBInput, { InputType } from "../../../components/HBComponents/Input/HBInput";
import { changePassword } from "../../../store/slices/user";
import { useAppDispatch } from "../../../store/store";
import { useUIErrorHandling } from "../../../utils/hooks/useUIErrorHandling";
import "./changePasswordModal.less";

type TProps = {
  isVisible: boolean;
  closeModal: () => void;
};

export const ChangePasswordModal = ({ isVisible, closeModal }: TProps): JSX.Element => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const { errorMessages, setErrors, handleClearErrors } = useUIErrorHandling();
  const [message, setMessage] = useState("");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onChangeCurrentPassword = (value: string | number | null) => {
    setCurrentPassword(value?.toString() || "");
  };

  const onChangeNewPassword = (value: string | number | null) => {
    setNewPassword(value?.toString() || "");
  };

  const onChangeRepeatPassword = (value: string | number | null) => {
    setRepeatPassword(value?.toString() || "");
  };

  const resetState = () => {
    setCurrentPassword("");
    setNewPassword("");
    setRepeatPassword("");
  };

  const onSave = () => {
    dispatch(changePassword({ currentPassword, newPassword, repeatPassword }))
      .then(unwrapResult)
      .then(response => {
        if (response) {
          setMessage(t("SuccessChangePasswordMessage"));
          resetState();
          closeModal();
        }
      })
      .catch(error => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setErrors([error] as any);
      });
  };

  const onCancel = () => {
    resetState();
    closeModal();
  };

  return (
    <>
      <ConfirmationModal
        visible={errorMessages.length > 0}
        confirmationType="cardValidationErrors"
        confirmOkClick={handleClearErrors}
        messages={errorMessages}
      />
      <ConfirmationModal
        visible={!!message}
        confirmationType="notification"
        confirmOkClick={() => setMessage("")}
        message={message}
      />
      <Modal
        closable
        maskClosable
        title={t("ChangePassword")}
        centered
        open={isVisible}
        footer={null}
        onCancel={onCancel}
      >
        <div className="modal-change-password">
          <span>{t("OldPassword")}</span>
          <span>
            <HBInput inputType={InputType.Password} onChange={onChangeCurrentPassword} value={currentPassword} />
          </span>
        </div>
        <div className="modal-change-password">
          <span>{t("NewPassword")}</span>
          <span>
            <HBInput inputType={InputType.Password} onChange={onChangeNewPassword} value={newPassword} />
          </span>
        </div>
        <div className="modal-change-password">
          <span>{t("RepeatPassword")}</span>
          <span>
            <HBInput inputType={InputType.Password} onChange={onChangeRepeatPassword} value={repeatPassword} />
          </span>
        </div>
        <div className="btns-container">
          <div className="upload-modal-action-button">
            <Button type="default" className="btn" onClick={onCancel}>
              {t("Back")}
            </Button>
          </div>
          <div className="upload-modal-action-button">
            <Button type="primary" className="btn" onClick={onSave}>
              {t("Save")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
