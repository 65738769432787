import { Space } from "antd";

import { FieldType, MobileAddEntitySectionTProps } from "../../../../types/page";
import EditableInputMobileFirst from "../../../EditableInput/EditableInputMobileFirst";
import { useMobileSingleViewTabContext } from "../../context/useMobileSingleViewTabContext";

const AddManualInspectionSection: React.FC<MobileAddEntitySectionTProps> = ({ entity, onChange }) => {
  const { tabConfig } = useMobileSingleViewTabContext();

  if (!entity) return null;

  const fields = tabConfig.addButton?.addEntityFields || [];

  return (
    <Space direction="vertical" size={9} className="buttons-space">
      {fields.map(field => {
        return (
          <EditableInputMobileFirst
            className="action-select"
            key={String(field.id)}
            isEditMode={true}
            id={String(field.id)}
            type={field.cellType?.(entity) as FieldType}
            optionSelector={field.optionsSelector}
            globalEdit={true}
            onChange={onChange(String(field.id))}
            fieldValue={entity.status as string}
            options={field.cellDropdownOptions?.(entity) || []}
            selector={field.cellSearchSelector!}
            prefixIcon={field.cellPrefixIcon}
          />
        );
      })}
    </Space>
  );
};

export default AddManualInspectionSection;
