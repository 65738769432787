import {
  ExclamationCircleOutlined,
  LinkOutlined,
  PlayCircleOutlined,
  RedoOutlined,
  RollbackOutlined,
  SyncOutlined,
  ToolOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tag, Modal, Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCompanyList } from "../../../store/slices/common";
import { addMigration, fetchMigrations, runMigration } from "../../../store/slices/companyMigration";
import { RootState, useAppDispatch } from "../../../store/store";
import { CompanyMigration, CompanyMigrationSelection, MigrationStatus } from "../../../types/companyMigration";
import MigrationForm from "./MigrationForm";
import MigrationTools, { MigrationTool } from "./MigrationTools";

import "../ManagmentToolsPage.less";

const { useForm } = Form;
const { confirm } = Modal;

type TreeCompanyMigration = CompanyMigration & { children: CompanyMigration[] | undefined; key: number };

export default function CompanyMigrationManager() {
  const dispatch = useAppDispatch();
  const [migrationForm] = useForm();
  const [migrationToolsModalVisible, setMigrationToolsModalVisible] = useState<boolean>(false);
  const [migrationFormModalVisible, setMigrationFormModalVisible] = useState<boolean>(false);
  const [selectedMigrationId, setSelectedMigrationId] = useState<number | undefined>();
  const [treeStructuredMigrations, setTreeStructuredMigrations] = useState<TreeCompanyMigration[]>();

  const { migrations, migrationHangfireJobs } = useSelector((state: RootState) => state.companyMigration);
  const { companies } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    dispatch(fetchCompanyList());
    dispatch(fetchMigrations());
  }, []);

  const openMigrationToolsModal: (migrationId: number) => void = migrationId => {
    setMigrationToolsModalVisible(true);
    setSelectedMigrationId(migrationId);
  };
  const closeMigrationToolsModal = () => {
    setMigrationToolsModalVisible(false);
    setSelectedMigrationId(undefined);
  };

  const openMigrationFormModal = () => {
    setMigrationFormModalVisible(true);
  };
  const closeMigrationFormModal = () => {
    setMigrationFormModalVisible(false);
    setSelectedMigrationId(undefined);
    migrationForm.submit();
    migrationForm.resetFields();
  };

  const isMigrationComleted: (migration: CompanyMigration) => boolean = migration =>
    !migration.isRunning && (migration.isSuccess || migration.errorDescription?.length > 0);

  const findMigrationTrail: (migration: CompanyMigration) => CompanyMigration[] = migration => {
    return migrations.reduce<CompanyMigration[]>((acc, m) => {
      if (m.id <= migration.id) return acc;
      if (acc.includes(m)) return acc;
      if (acc.filter(x => isMigrationComleted(x) && x.isRollback && x.isSuccess && !x.errorDescription)?.length > 0) {
        return acc;
      }
      if (
        m.isRollback &&
        m.sourceCompanyId === migration.destinationCompanyId &&
        m.destinationCompanyId === migration.sourceCompanyId
      ) {
        acc.push(m);
        return acc;
      }
      if (
        m.sourceCompanyId === migration.sourceCompanyId &&
        m.destinationCompanyId === migration.destinationCompanyId
      ) {
        acc.push(m);
        return acc;
      }
      return acc;
    }, []);
  };

  const showRollbackButton: (migration: CompanyMigration) => boolean = migration => {
    if (migrations) {
      if (!isMigrationComleted(migration) || migration.isRollback) {
        return false;
      }

      const rollbacks = findMigrationTrail(migration);

      return rollbacks.length === 0;
    }
    return false;
  };

  const createMigration: (migrationDetails: CompanyMigrationSelection) => void = migrationDetails => {
    dispatch(addMigration({ ...migrationDetails, isRollback: false }));
  };

  const runCompanyMigration: (migration: CompanyMigration) => void = migration => {
    confirm({
      title: "Run Migration",
      icon: <ExclamationCircleOutlined className="orange-color" />,
      content: `Are you sure you want to Migrate ${companies.find(x => x.id === migration.sourceCompanyId)?.name} - ${
        migration.sourceCompanyId
      } to ${companies.find(x => x.id === migration.destinationCompanyId)?.name} - ${migration.destinationCompanyId}`,
      onOk: () => {
        dispatch(runMigration(migration.id));
      },
    });
  };

  const rollbackMigration: (migration: CompanyMigration) => void = migration => {
    const rollbackMigration: CompanyMigrationSelection = {
      ...migration,
      sourceCompanyId: migration.destinationCompanyId,
      destinationCompanyId: migration.sourceCompanyId,
      isRollback: true,
    };
    confirm({
      icon: <ExclamationCircleOutlined className="orange-color" />,
      title: "Rollback Migration",
      content: `Are you sure you want to Rollback ${
        companies.find(x => x.id === rollbackMigration.sourceCompanyId)?.name
      } - ${rollbackMigration.sourceCompanyId} to ${
        companies.find(x => x.id === rollbackMigration.destinationCompanyId)?.name
      } - ${rollbackMigration.destinationCompanyId}`,
      onOk: () => {
        dispatch(addMigration(rollbackMigration));
      },
    });
  };

  const openHangfireLink: (jobId: number) => void = jobId => {
    window.open(`${process.env.REACT_APP_OLD_PAS_URL}/hangfire/jobs/details/${jobId}`, "_blank");
  };

  const getMigrationStatus: (migration: CompanyMigration) => MigrationStatus = migration => {
    if (migration.isRunning) {
      return MigrationStatus.Running;
    } else if (migration.isBlobRunning) {
      return MigrationStatus.BlobRunning;
    } else if (migration.isSuccess) {
      return MigrationStatus.Success;
    } else if (migration.errorDescription?.length > 0) {
      return MigrationStatus.Failed;
    } else {
      return MigrationStatus.NotExecuted;
    }
  };

  const getHangfireJobForMigration: (migration: CompanyMigration) => number[] | undefined = migration => {
    const jobs = migrationHangfireJobs.find(m => m.migrationId === migration.id);
    return jobs?.hangfireJobs ? jobs.hangfireJobs : undefined;
  };

  const columns: ColumnsType<TreeCompanyMigration> = [
    {
      title: "Migration Id",
      dataIndex: "id",
      key: "id",
      width: "12rem",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      render: (text: any, record: CompanyMigration, index: number) => {
        return (
          <div className="id-field-block">
            <span>{`${record.id}`}</span>
            {record.isRollback ? <RollbackOutlined className="primary-color" /> : undefined}
          </div>
        ); // change to israel time
      },
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Created By",
      dataIndex: "createdByUserId",
      key: "createdByUserId",
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDate",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      render: (text: any, record: CompanyMigration, index: number) => {
        return dayjs(text).toLocaleString(); // change to israel time
      },
    },
    {
      title: "Source Comapny Id",
      dataIndex: "sourceCompanyId",
      key: "sourceCompanyId",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      render: (text: any, record: CompanyMigration, index: number) => {
        const company = companies.find(x => x.id === record.sourceCompanyId);
        return `${company ? company.name + " -" : ""}${record.sourceCompanyId}`;
      },
      filtered: true,
      filters: companies.map(company => {
        return { text: company.name, value: company.id };
      }),
      onFilter: (value, record) => {
        const childrenFilter = record.children?.filter(m => m.sourceCompanyId === value);
        return record.sourceCompanyId === value || (childrenFilter ? childrenFilter?.length > 0 : false);
      },
      sorter: (a, b) => a.sourceCompanyId - b.sourceCompanyId,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Destination Comapny Id",
      dataIndex: "destinationCompanyId",
      key: "destinationCompanyId",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      render: (text: any, record: CompanyMigration, index: number) => {
        const company = companies.find(x => x.id === record.destinationCompanyId);
        return `${company ? company.name + " -" : ""}${record.destinationCompanyId}`;
      },
      filtered: true,
      filters: companies.map(company => {
        return { text: company.name, value: company.id };
      }),
      onFilter: (value, record) => {
        const childrenFilter = record.children?.filter(m => m.destinationCompanyId === value);
        return record.destinationCompanyId === value || (childrenFilter ? childrenFilter?.length > 0 : false);
      },
      sorter: (a, b) => a.destinationCompanyId - b.destinationCompanyId,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Destination OrgUnit",
      dataIndex: "destinationOrgUnitId",
      key: "destinationCompanyId",
      sorter: (a, b) => a.destinationOrgUnitId - b.destinationOrgUnitId,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "status",
      key: "status",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      render: (text: any, record: CompanyMigration, index: number) => {
        const status = getMigrationStatus(record);
        switch (status) {
          case MigrationStatus.BlobRunning:
            return <Tag color="yellow">Blob Running</Tag>;
          case MigrationStatus.Running:
            return <Tag color="yellow">Running</Tag>;
          case MigrationStatus.Success:
            return <Tag color="green">Success</Tag>;
          case MigrationStatus.Failed:
            return <Tag color="red">Failed</Tag>;
          default:
            return <Tag>Not Executed</Tag>;
        }
      },
      filtered: true,
      filters: [
        { text: MigrationStatus.Running, value: MigrationStatus.Running },
        { text: MigrationStatus.Success, value: MigrationStatus.Success },
        { text: MigrationStatus.Failed, value: MigrationStatus.Failed },
        { text: MigrationStatus.NotExecuted, value: MigrationStatus.NotExecuted },
      ],
      onFilter: (value, record) => {
        const childrenFilter = record.children?.filter(() => getMigrationStatus(record) === value);
        return getMigrationStatus(record) === value || (childrenFilter ? childrenFilter?.length > 0 : false);
      },
      sorter: (a, b) => getMigrationStatus(b).localeCompare(getMigrationStatus(a)),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Error",
      dataIndex: "errorDescription",
      key: "errorDescription",
      width: "30rem",
    },
    {
      title: "Actions",
      key: "actions",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      render: (text: any, record: CompanyMigration, index: number) => {
        return (
          <Space align="start">
            <Button icon={<ToolOutlined />} onClick={() => openMigrationToolsModal(record.id)}>
              Run Tools
            </Button>
            {isMigrationComleted(record) && (
              <Button onClick={() => runCompanyMigration(record)} icon={<RedoOutlined />}>
                Re-Run
              </Button>
            )}
            {!isMigrationComleted(record) && !record.isRunning && (
              <Button onClick={() => runCompanyMigration(record)} icon={<PlayCircleOutlined />}>
                Run
              </Button>
            )}
            {showRollbackButton(record) && (
              <Button onClick={() => rollbackMigration(record)} icon={<UndoOutlined />}>
                Rollback
              </Button>
            )}
            {getHangfireJobForMigration(record) &&
              getHangfireJobForMigration(record)?.map(job => (
                <Button key={job} onClick={() => openHangfireLink(job)} icon={<LinkOutlined />} />
              ))}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const processedNodeIds: number[] = [];
    setTreeStructuredMigrations(
      migrations.reduce<TreeCompanyMigration[]>((acc, migration) => {
        if (!processedNodeIds.includes(migration.id)) {
          processedNodeIds.push(migration.id);
          const migrationTrail = findMigrationTrail(migration);
          processedNodeIds.push(...migrationTrail.map(m => m.id));
          acc.push({
            key: migration.id,
            children: migrationTrail.length > 0 ? migrationTrail : undefined,
            ...migration,
          });
          return acc;
        }
        return acc;
      }, [])
    );
  }, [migrations]);

  const fetchData = () => {
    dispatch(fetchMigrations());
  };
  return (
    <div className="migration-manager-container">
      <Modal
        destroyOnClose
        open={migrationToolsModalVisible}
        onCancel={closeMigrationToolsModal}
        onOk={closeMigrationToolsModal}
      >
        <MigrationTools
          migrationId={selectedMigrationId}
          allowedMigrationTools={[
            MigrationTool.DuplicateEntities,
            MigrationTool.CompanyUserInDestinationCompanyCheck,
            MigrationTool.BlobMigration,
          ]}
        />
      </Modal>
      <Modal
        title="Create Migration"
        destroyOnClose
        open={migrationFormModalVisible}
        onCancel={closeMigrationFormModal}
        onOk={closeMigrationFormModal}
      >
        <MigrationForm onFinish={createMigration} form={migrationForm} />
      </Modal>
      <div className="migration-manager-button-container">
        <Button onClick={() => openMigrationFormModal()}>Migrate</Button>
        <Button onClick={() => fetchData()} icon={<SyncOutlined />} />
      </div>
      <Table dataSource={treeStructuredMigrations} columns={columns} />
    </div>
  );
}
