import { ReactComponent as IssueTypeIcon } from "../../../../media/issue-type-icon.svg";
import { issueTypeSelectors } from "../../../../selectors";
import { IssueType } from "../../../../types/issueType";
import { BaseField, InputTypes } from "../../../../types/page";
import { options } from "../common";
import { constructTableTree, localizeText, renderOptionIcon } from "../utilities";

export const parentIdField: BaseField<IssueType> = {
  id: "parentId",
  title: "ParentIssueType",
  Icon: IssueTypeIcon,
  value: value => (value.parentId ? value.parentId : null),
  type: InputTypes.TREESELECT,
  optionsSelector: () => null,
  treeData: {
    treeDataSelector: issueTypeSelectors.all,
    treeDataSet: (dataSet, currentEntityId) =>
      constructTableTree(
        {
          data: dataSet,
          itemValueKey: "id",
          itemLabelKey: "name",
          currentEntityValue: currentEntityId,
          isNullableField: true,
          disableInactiveItems: true,
        },
        true
      ),
  },
  placeholder: "Root",
  placeholderMobileFirst: "Root",
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "issueType",
  },
  enableMobileScanners: true,
};

export const nameFiled: BaseField<IssueType> = {
  id: "name",
  title: "FieldName",
  value: value => value.name,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};
export const externalIdField: BaseField<IssueType> = {
  id: "externalId",
  title: "ExternalID",
  value: value => value.externalId,
  type: InputTypes.INPUT,
  placeholder: "Enter",
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const statusField: BaseField<IssueType> = {
  id: "status",
  title: "FieldStatus",
  value: value => value.status,
  valueText: value => localizeText(value.status),
  Icon: ({ value }) => renderOptionIcon(value.status),
  type: InputTypes.DROPDOWN,
  options,
  optionsSelector: () => null,
};

export const primaryFields: BaseField<IssueType>[] = [nameFiled, externalIdField, statusField];

export const secondaryFields: BaseField<IssueType>[] = [parentIdField];
