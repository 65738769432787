import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  CustomPropertyState,
  CustomProperty,
  CustomPropertySingleView,
  CustomPropertyType,
  CompanyDictionary,
} from "../../types/customProperty";
import { ExplicitAdditionalProps } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: CustomPropertyState = {
  data: [],
  defaultCustomProperties: [],
  subData: {},
  isLoading: true,
  singleData: null,
  error: null,
  lastUpdated: dayjs().toISOString(),
  companyDictionaries: [],
};

const newCustomProperty: CustomProperty = {
  id: 0,
  name: "",
  sortOrder: 0,
  type: CustomPropertyType.STRING,
  defaultValue: null,
  actAsIdentifier: false,
  dictionaryId: null,
};

const customPropEndpointMap: { [key: string]: string } = {
  employeeCustomProperty: "EmployeeCustomProperty",
  equipmentCustomProperty: "EquipmentCustomProperty",
  inspectionEquipmentCustomProperty: "InspectionCustomProperty",
  inspectionLocationCustomProperty: "InspectionCustomProperty",
  locationCustomProperty: "LocationCustomProperty",
  trainingCustomProperty: "TrainingCustomProperty",
};

export const fetchCustomProperties = createAsyncThunk<
  {
    primaryData: CustomProperty[];
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  void,
  { state: RootState }
>("@@CUSTOM_PROPERTY/FETCH", async (_, { getState }) => {
  const { user } = getState();
  const pageId = window.location.pathname.replace(/^\/([\w-]+)\/?.*$/, "$1");
  const endpointName = customPropEndpointMap[pageId];
  const customPropertiesResponse = await hbApi.get<CustomProperty[]>(`/${endpointName}`, hbApiOptions(user.jwt));

  return {
    primaryData: customPropertiesResponse.data,
    defaultCustomProperties: [],
  };
});

export const fetchCompanyDictionaries = createAsyncThunk<
  {
    companyDictionaries: CompanyDictionary[];
  },
  void,
  { state: RootState }
>("@@CUSTOM_PROPERTY/FETCH_COMPANY_DICTIONARIES", async (_, { getState }) => {
  const { user } = getState();
  const companyDictionaries = await hbApi.get<CompanyDictionary[]>(
    "/Dictionary/GetDictionariesWithValues",
    hbApiOptions(user.jwt)
  );
  return {
    companyDictionaries: companyDictionaries.data,
  };
});

export const fetchSingleCustomProperty = createAsyncThunk<
  {
    singleData: CustomPropertySingleView;
    subData: Record<string, never>;
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>("@@CUSTOM_PROPERTY/FETCH_SINGLE", async (id, { getState }) => {
  const { user } = getState();
  const pageId = window.location.pathname.replace(/^\/([\w-]+)\/?.*$/, "$1");
  const endpointName = customPropEndpointMap[pageId];
  const customProperty = await hbApi.get<CustomPropertySingleView>(`/${endpointName}/${id}`, hbApiOptions(user.jwt));

  return {
    singleData: customProperty.data,
    subData: {},
    defaultCustomProperties: [],
  };
});

export const addCustomProperty = createAsyncThunk<CustomProperty, { entity: CustomProperty }, { state: RootState }>(
  "@@CUSTOM_PROPERTY/ADD",
  async ({ entity }, { getState, rejectWithValue }) => {
    const { user } = getState();
    const pageId = window.location.pathname.replace(/^\/([\w-]+)\/?.*$/, "$1");
    const endpointName = customPropEndpointMap[pageId];
    const payload: CustomProperty = {
      ...entity,
      dictionaryId: entity.type === "Dictionary" ? entity.dictionaryId : null,
    };
    try {
      const response = await hbApi.post<CustomProperty>(`/${endpointName}`, payload, hbApiOptions(user.jwt));
      return response.data;
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  }
);

export const updateCustomProperty = createAsyncThunk<CustomProperty, CustomProperty, { state: RootState }>(
  "@@CUSTOM_PROPERTY/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();
    const pageId = window.location.pathname.replace(/^\/([\w-]+)\/?.*$/, "$1");
    const endpointName = customPropEndpointMap[pageId];
    const payload: CustomProperty = {
      ...entity,
      dictionaryId: entity.type === "Dictionary" ? entity.dictionaryId : null,
    };
    try {
      const response = await hbApi.put<CustomProperty>(
        `/${endpointName}/${entity.id}`,
        payload,
        hbApiOptions(user.jwt)
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  }
);

const slice = createSlice({
  name: "customProperty",
  initialState,
  reducers: {
    createCustomPropertyTemplate: state => {
      state.singleData = newCustomProperty;
    },
    clearCustomPropertyError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCustomProperties.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleCustomProperty.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCustomProperty.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCustomProperty.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(fetchCustomProperties.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleCustomProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addCustomProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateCustomProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchCustomProperties.fulfilled, (state, action) => {
        state.data = action.payload.primaryData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCompanyDictionaries.fulfilled, (state, action) => {
        state.companyDictionaries = action.payload.companyDictionaries;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSingleCustomProperty.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addCustomProperty.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateCustomProperty.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(et => et.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { createCustomPropertyTemplate, clearCustomPropertyError } = slice.actions;
export default slice.reducer;
