import "./LabelWithDate.less";

import { Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { DATE_FORMAT_SPLITTED_SPACES } from "../../../../HBComponents/DatePicker/HBDatePicker";

type TProps = {
  labelTranslationKey?: string;
  date: string | null;
  dateFormat?: string;
  className?: string;
};

const LabelWithDate: React.FC<TProps> = ({
  labelTranslationKey,
  date,
  dateFormat = DATE_FORMAT_SPLITTED_SPACES,
  className,
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  if (!date) return null;

  const formattedValue = dayjs(date).format(dateFormat);

  return <Text className={className}>{`${labelTranslationKey ? t(labelTranslationKey) : ""} ${formattedValue}`}</Text>;
};

export default LabelWithDate;
