export class SystemActions {
  public static readonly Import: string = "import";
  public static readonly Export: string = "export";
  public static readonly GetFilterUrl: string = "getFilterUrl";
  public static readonly SetFiltersToDefault: string = "setFiltersToDefault";
  public static readonly ColumnManagement: string = "columnManagement";
  public static readonly ResetColumnsWidth: string = "resetColumnWidth";
  public static readonly CreateTrainingWithEmployees: string = "createTrainingWithEmployees";
  public static readonly CreateTrainingFromEmpCertificates: string = "createTrainingFromEmpCertificates";
  public static readonly SetActionStatus: string = "setActionStatus";
  public static readonly FormSettings: string = "formSettings";
  public static readonly ModalAction: string = "exportSelectedSurveys";
  public static readonly AddNew: string = "addNew";
  public static readonly CallModal: string = "callModal";
}

export class SingleViewActions {
  public static readonly ResetPassword: string = "resetPassword";
  public static readonly CancelTraining: string = "cancelTraining";
}

export class AddButtonActions {
  public static readonly QuickCreateAppointment: string = "quickCreateAppointment";
  public static readonly AddNewAction: string = "addNewAction";
}
