import { CheckOutlined, DownOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";

import { Button, Input, Popover, Space, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { addNewDashboard, renameDashboard, updateDashboardName } from "../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../store/store";
import useDebounce from "../../../utils/hooks/useDebounce";
import DashboardSelectorItem from "./DashboardSelectorItem";

type DashboardSelectorProps = {
  editMode: boolean;
};

export default function DashboardSelector({ editMode }: DashboardSelectorProps) {
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const dashboards = useSelector((state: RootState) => state.dashboard.dashboards);
  const isSU = useSelector((state: RootState) => state.user.settings.isSysAdmin);

  const dashboardName = useSelector((state: RootState) =>
    state.dashboard.single.name ? state.dashboard.single.name : t("SelectADashboard")
  );
  const debouncedDashboardName = useDebounce(dashboardName, 500);
  const dashboardId = useSelector((state: RootState) => state.dashboard.single.id);
  const [addDashboard, setAddDashboard] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState("");
  const [updatedDashboardName, setUpdatedDashboardName] = useState("");
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const onAddDashboard: React.MouseEventHandler<HTMLElement> = () => {
    setAddDashboard(true);
  };

  const onAddNewDashboard: React.MouseEventHandler<HTMLElement> = useCallback(() => {
    dispatch(addNewDashboard(newDashboardName));
    setAddDashboard(false);
  }, [newDashboardName]);

  useEffect(() => {
    if (dashboardId !== 0 && debouncedDashboardName !== t("SelectADashboard") && updatedDashboardName !== "") {
      dispatch(renameDashboard({ dashboardId, title: debouncedDashboardName }));
      setUpdatedDashboardName("");
    }
  }, [debouncedDashboardName, dashboardId]);

  const onDashboardNameChanged: React.ChangeEventHandler<HTMLInputElement> = e => {
    dispatch(updateDashboardName(e.target.value));
    setUpdatedDashboardName(e.target.value);
  };

  const onNewDashboardNameChanged: React.ChangeEventHandler<HTMLInputElement> = e => {
    setNewDashboardName(e.target.value);
  };

  const dashboardsMenu = useMemo(() => {
    let dashboardList = dashboards;
    if (searchTerm) {
      dashboardList = dashboards.filter(d => d.name.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase()));
    }
    return dashboardList.map(dashboard => <DashboardSelectorItem key={dashboard.id} dashboard={dashboard} />);
  }, [dashboards, searchTerm]);

  const onSearchDashboard: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSearchTerm(event.target.value);
  };

  const menu = useMemo(
    () => (
      <Space direction="vertical">
        <div className="dashboardSelectorMenuContainer">
          <Input
            className="search"
            allowClear
            bordered={false}
            placeholder={t("SearchButton")}
            onChange={onSearchDashboard}
            // style={{ width: 200 }}
            value={searchTerm}
            suffix={<SearchOutlined style={{ color: "#d9d9d9" }} />}
          />
          {dashboardsMenu}
          {addDashboard && (
            <Space direction="horizontal">
              <Input
                placeholder={t("EnterDashboardName")}
                bordered={false}
                value={newDashboardName}
                onChange={onNewDashboardNameChanged}
              />
              <Button type="text" shape="round" icon={<CheckOutlined />} onClick={onAddNewDashboard} />
            </Space>
          )}
          {isSU && (
            <div className="addNewDashboardButton">
              <Tooltip title={t("AddNewDashboard")}>
                <a onClick={onAddDashboard}>
                  <PlusOutlined style={{ color: primaryColor }} />
                </a>
              </Tooltip>
            </div>
          )}
        </div>
      </Space>
    ),
    [primaryColor, addDashboard, newDashboardName, dashboardsMenu]
  );

  const getAlignment = () => {
    if (direction === "ltr") {
      return { offset: [5, -5] };
    } else {
      return { offset: [-5, 5] };
    }
  };

  return (
    <div id="dashboardSelector">
      <Popover
        trigger={!editMode ? "click" : ""}
        align={getAlignment()}
        placement={direction === "ltr" ? "topLeft" : "bottomRight"}
        content={menu}
      >
        <a onClick={e => e.preventDefault()}>
          <Input
            placeholder={t("EnterDashboardName")}
            disabled={!editMode || !isSU}
            bordered={false}
            className="title"
            htmlSize={dashboardName.length}
            value={dashboardName}
            style={{ color: primaryColor }}
            suffix={<DownOutlined style={{ fontSize: "20px", marginTop: 3 }} />}
            onChange={onDashboardNameChanged}
          />
        </a>
      </Popover>
    </div>
  );
}
