import { filterTodoListBySearchTerm, filterTodolistByStatus } from "../../store/slices/todolist";
import { useAppDispatch } from "../../store/store";
import { TodoListStatusFilter, TodoListViewFilter } from "../../types/todolist";

type ApplyFiltersProperties = {
  statusFilter?: TodoListStatusFilter;
  viewFilter?: TodoListViewFilter;
  searchTerm?: string;
};

export default function useApplyFilters() {
  const dispatch = useAppDispatch();
  const applyFilters: (properties: ApplyFiltersProperties) => void = ({ statusFilter, searchTerm }) => {
    dispatch(filterTodolistByStatus(statusFilter));
    dispatch(filterTodoListBySearchTerm(searchTerm));
  };

  return applyFilters;
}
