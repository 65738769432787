import { issueTypeSelectors } from "../../../../selectors";
import { IssueType } from "../../../../types/issueType";
import { ColumnType, InputTypes, TOption } from "../../../../types/page";
import { options } from "../common";
import { localizeText, renderTableStatus } from "../utilities";

export const fetchAllStatuses = (): TOption[] => {
  return options.map<TOption>(x => ({ id: x.id, label: localizeText(x.label) }));
};

export const issueTypeTableColumns: ColumnType<IssueType>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: issueTypeSelectors.uniqueIssueTypeId,
    width: 100,
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: issueTypeSelectors.uniqueIssueTypeName,
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: issueTypeSelectors.uniqueIssueTypeExternalId,
  },
  {
    id: "parentId",
    label: "ParentId",
    renderValue: value => (value.parentId ? value.parentId.toString() : null),
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: issueTypeSelectors.uniqueIssueTypeParentId,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null, // Note: We render the value together with the icon when we want to show a tag
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: fetchAllStatuses,
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },
  },
];
