import { Button, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import HBAutocomplete from "../../../components/HBComponents/Autocomplete/HBAutocomplete";
import HBTextArea from "../../../components/HBComponents/Input/HBTextArea";
import { fetchCompanyList, fetchTenantUserList } from "../../../store/slices/common";
import { loginImpersonate } from "../../../store/slices/user";
import { RootState, useAppDispatch } from "../../../store/store";
import { useLoginContext } from "../context/useLoginContext";

interface TProps {
  setIsImpersontaionFormOpen: (value: boolean) => void;
}

const ImpersonateForm: React.FC<TProps> = ({ setIsImpersontaionFormOpen }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const companies = useSelector((state: RootState) => state.common.companies);
  const tenantUsers = useSelector((state: RootState) => state.common.tenantUsers);

  const [companyId, setCompanyId] = useState<number>();
  const [userId, setUserId] = useState<number>();
  const [supportTicket, setSupportTicket] = useState<string>("");
  const [jiraTicket, setJiraTicket] = useState<string>("");
  const [reason, setReason] = useState<string>("");

  const { setCustomTitle } = useLoginContext();

  useEffect(() => {
    dispatch(fetchCompanyList());
    setCustomTitle("ImpersonateUser");
  }, []);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchTenantUserList(companyId));
    }
  }, [companyId]);

  const handleLogin = () => {
    if (!companyId || !userId) return;
    dispatch(
      loginImpersonate({
        companyId,
        userId,
        supportTicket,
        jiraTicket,
        reason,
      })
    );
  };

  const onCompanyChanged = (value: string | null | number | boolean) => {
    setCompanyId(value as number);
  };

  const onUserChanged = (value: string | null | number | boolean) => {
    setUserId(value as number);
  };

  const onReasonChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value);
  };

  const handleInputChange = (setInput: React.Dispatch<React.SetStateAction<string>>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setInput(e.target.value);

  const onCancelClick = () => {
    setIsImpersontaionFormOpen(false);
  };

  const companyOptions = (companies || []).map(compamny => ({
    id: compamny.id,
    label: compamny.name,
  }));

  const usersOptions = (tenantUsers || []).map(user => ({
    id: user.id,
    label: user.displayText,
  }));

  const selectedCompany = companies.find(company => company.id === companyId);

  return (
    <Form className="impersonate-form" layout="vertical" name="basic" onFinish={handleLogin}>
      <Form.Item name="company" className="company-input " data-testid="companyInput" label={t("SelectCompany")}>
        <HBAutocomplete onChange={onCompanyChanged} options={companyOptions} value={companyId} />
      </Form.Item>
      <Form.Item name="username" className="user-input" data-testid="usernameInput" label={t("SelectUser")}>
        <HBAutocomplete onChange={onUserChanged} options={usersOptions} value={userId} />
      </Form.Item>
      <Form.Item
        name="supportTicket"
        className="support-ticket-input"
        data-testid="supportTicketInput"
        label={t("ImpersonateUserSupportTicket")}
      >
        <Input
          placeholder={t("Enter")}
          onChange={handleInputChange(setSupportTicket)}
          data-testid="impersonate-support-ticket-input"
        />
      </Form.Item>
      <Form.Item
        name="jiraTicket"
        className="jira-ticket-input"
        data-testid="jiraTicketInput"
        label={t("ImpersonateUserJiraTicket")}
      >
        <Input
          placeholder={t("Enter")}
          onChange={handleInputChange(setJiraTicket)}
          data-testid="impersonate-jira-ticket-input"
        />
      </Form.Item>
      <Form.Item name="reason" className="reason-input" data-testid="reasonInput" label={t("ImpersonateUserReason")}>
        <HBTextArea data-testid="impersonate-reason-input" onChange={onReasonChanged} placeholder={t("Enter")} />
      </Form.Item>
      <Row justify="space-between" align="middle" wrap={false}>
        <Button type="default" data-testid="cancel-button" onClick={onCancelClick}>
          {t("Cancel")}
        </Button>
        <Button
          className="save"
          type="primary"
          htmlType="submit"
          data-testid="submit-button"
          disabled={!companyId || !userId || (selectedCompany?.isActive && !(supportTicket || jiraTicket || reason))}
        >
          {t("Continue")}
        </Button>
      </Row>
    </Form>
  );
};

export default ImpersonateForm;
