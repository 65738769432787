import { DataNode } from "rc-tree/lib/interface";
import { useState, useEffect } from "react";
import { AccumulatorType } from "../../components/SingleViewTabs/types";

export type TreeBase<T> = {
  id: number;
  children: T[];
  parentId: number;
};

// TODO: find a way to make this hook reusable.
// Note: currently it only works with data: any, but nevertheless needs type of Location[] in order to work properly
export const useConstructTableTree = (
  filteredData: Record<string, unknown>[],
  fullData?: Record<string, unknown>[]
) => {
  const [treeData, setTreeData] = useState<DataNode[]>([]);

  //We create a new array because we need the additional properties from the DataNode type (key, title, icon, etc.)
  useEffect(() => {
    if (filteredData) {
      setTreeData([]);

      let filteredWithAncestors: Record<string, unknown>[] = [];

      if (Array.isArray(fullData)) {
        filteredData.forEach(element => {
          const elementWithAncestors = getElementWithAncestors(element, fullData);
          elementWithAncestors.forEach(el => {
            if (!filteredWithAncestors.find(x => x.id === el.id)) filteredWithAncestors.push(el);
          });
        });
      } else {
        // Assume that data is not filtered (filteredData === fullData) if no fullData is provided.
        // In that case filteredData contains a complete data set, including all possible ancestors.
        filteredWithAncestors = filteredData;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const extendedFilteredData = filteredWithAncestors.map((item: any) => {
        return { ...item, key: item.id.toString() };
      });

      const idMapping = filteredWithAncestors.reduce<AccumulatorType>((acc, entity, i) => {
        const id = entity.id as number;
        acc[id] = i;
        return acc;
      }, {});

      extendedFilteredData.forEach(entity => {
        if (entity.parentId === null) {
          setTreeData(prevData => [...prevData, entity]);
          return;
        }

        const parentEl = extendedFilteredData[idMapping[entity.parentId]];

        if (parentEl) {
          parentEl.children = [...(parentEl.children || []), entity];
        } else {
          setTreeData(prevData => [...prevData, entity]);
        }
      });
    }
  }, [filteredData]);

  return treeData;
};

function getElementWithAncestors(
  element: Record<string, unknown>,
  list: Record<string, unknown>[]
): Record<string, unknown>[] {
  if (!element || !(Array.isArray(list) && list.length)) return [];

  const output = [element];

  if (element.parentId) {
    const parentEl = list.find(x => x.id === element.parentId);

    if (parentEl) {
      output.push(...getElementWithAncestors(parentEl, list));
    }
  }

  return output;
}
