import { ColumnType } from "antd/es/table";
import { ColumnsType } from "antd/lib/table";
import { debounce } from "lodash";
import { useContext, useRef, useState } from "react";
import PageConfigurationContext from "../../context/pageContext";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage } from "../../types/page";
import { LocalStorageKeys } from "../../types/utility";

interface StorageColumnWitdth {
  dataIndex: string;
  width: number;
}

function useTableResize<RecordType extends Record<string, unknown>>(
  columnsProps?: ColumnsType<RecordType>
): {
  columns: ColumnType<RecordType>[];
  setColumns: (columnsProps: ColumnType<RecordType>[]) => void;
  tableRef: {
    current: HTMLDivElement | null;
  };
} {
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const tableRef = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState<ColumnType<RecordType>[]>(columnsProps || []);

  const getColumnsWidth = (): StorageColumnWitdth[] => {
    const columnsWidthString = localStorage.getItem(LocalStorageKeys.TableColumnsWidth);
    const columnsWidth = columnsWidthString ? JSON.parse(columnsWidthString) : {};
    return columnsWidth[pageConfig.id] || [];
  };

  const saveNewWidth = debounce((columns: ColumnType<RecordType>[]) => {
    if (!columns.length) return;

    const allPagesColumnsWidthString = localStorage.getItem(LocalStorageKeys.TableColumnsWidth);
    const allPagesColumnsWidth = allPagesColumnsWidthString ? JSON.parse(allPagesColumnsWidthString) : {};

    const columnsWidth = columns.map(c => ({ dataIndex: c.dataIndex, width: c.width }));
    allPagesColumnsWidth[pageConfig.id] = columnsWidth;

    localStorage.setItem(LocalStorageKeys.TableColumnsWidth, JSON.stringify(allPagesColumnsWidth));
  }, 500);

  const handleResize = (index: number) => (e: never, { size }: { size: { width: number; height: number } }) => {
    const newWidth = Math.round(size.width);

    if (newWidth === columns[index]?.width) {
      return;
    }

    setColumns(columns => {
      const MIN_COLUMN_WIDTH = 100;
      const currentColumnWidth = Number(columns[index].width);
      const diffWidth = currentColumnWidth - newWidth;

      const nextColumns = columns.map((column, i) => {
        // We need to set a new width for the current column
        if (index === i) {
          return {
            ...column,
            width: newWidth,
          };
        }

        // We need to adjust the width of the columns after the current one
        if (index < i) {
          const newWidth = Number(column.width) + diffWidth / (columns.length - 1 - index);

          return {
            ...column,
            width: Math.max(newWidth, MIN_COLUMN_WIDTH),
          };
        }

        // We should not change the width of any of the columns to the left
        return column;
      });

      saveNewWidth(nextColumns);
      return nextColumns;
    });
  };

  const setColumnsFromProps = (columnsProps: ColumnType<RecordType>[]) => {
    const MIN_RENDER_COLUMN_WIDTH = 200;
    const CHECKBOX_COLUMN_WIDTH = 30;
    const SCROLL_COLUMN_WIDTH = 17;
    const tableWidth = tableRef.current?.offsetWidth ?? 0;
    const baseColumnWidth = Math.floor(
      (tableWidth - CHECKBOX_COLUMN_WIDTH - SCROLL_COLUMN_WIDTH) / (columnsProps?.length || 1)
    );

    const columnWidth = getColumnsWidth();

    const getColumnWidthByDataIndex = (dataIndex: string) => {
      return columnWidth.find(c => c.dataIndex === dataIndex)?.width;
    };

    const mergedColumns = columnsProps?.map((column: ColumnType<RecordType>, index: number) => {
      const dataIndex = String(column.dataIndex);
      const columnWidth = Math.max(baseColumnWidth, MIN_RENDER_COLUMN_WIDTH);

      return {
        ...column,
        onHeaderCell: (column: ColumnType<RecordType>) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
        width: getColumnWidthByDataIndex(dataIndex) ?? columnWidth,
      };
    });
    setColumns(mergedColumns ?? []);
  };

  return {
    columns,
    setColumns: setColumnsFromProps,
    tableRef,
  };
}

export default useTableResize;
