import { Input } from "antd";
import React, { useState } from "react";

type EditQueryProps = {
  query: string;
  onUpdateQuery: (query: string) => void;
};

export default function EditQuery({ query, onUpdateQuery }: EditQueryProps) {
  const [jsonQuery, setJsonQuery] = useState(query);
  const onQueryEdited: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    onUpdateQuery(e.target.value);
    setJsonQuery(e.target.value);
  };
  return <Input.TextArea autoSize onChange={onQueryEdited} value={jsonQuery} />;
}
