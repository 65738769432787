import { useState } from "react";
import ImpersonateForm from "./ImpersonateForm";
import SuChooseModeForm from "./SuChooseModeForm";

const SuModeWrapper = (): JSX.Element => {
  const [isImpersontaionFormOpen, setIsImpersontaionFormOpen] = useState(false);

  if (isImpersontaionFormOpen) {
    return <ImpersonateForm setIsImpersontaionFormOpen={setIsImpersontaionFormOpen} />;
  }

  return <SuChooseModeForm setIsImpersontaionFormOpen={setIsImpersontaionFormOpen} />;
};

export default SuModeWrapper;
