import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Empty, SpinProps, TableColumnType } from "antd";
import isArray from "lodash/isArray";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../../context/pageContext";
import { filterSelector, idsFilterSelector, orderSelector } from "../../../selectors";
import { updateOrder } from "../../../store/slices/filter";
import { RootState, useAppDispatch } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage, ListViewTable } from "../../../types/page";
import { useDeepCompareEffect } from "../../../utils/hooks/useDeepCompareEffect";
import useRouter from "../../../utils/hooks/useRouter";
import { ListViewVTableState, getScrollXPercent } from "../../ListViewVTable/ListViewVTable";
import VirtualListAntd from "./VirtualListAntd";

type TProps = {
  tableRef: React.RefObject<HTMLDivElement>;
  isTableLoading: boolean;
  columns: TableColumnType<Record<string, unknown>>[];
  filteredData: Record<string, unknown>[];
  state: ListViewVTableState;
  setNewState: React.Dispatch<React.SetStateAction<ListViewVTableState>>;
  table: ListViewTable<Record<string, unknown>[]>;
  onRowClick: (record: Record<string, unknown>) => void;
  rowSelection?: {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Record<string, unknown>[]) => void;
    columnWidth: number;
    selectedRowKeys: React.Key[];
  };
  pageId: string;
};

interface LocationState {
  referrer?: string;
}

export const handleFetchPaginatedData = (
  pageConfig: CategoryPage<BaseEntityType>,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
) => {
  if (pageConfig.listView?.table.fetchPaginatedData && pageConfig.listView?.table.resetCurrentPage) {
    dispatch(pageConfig.listView.table.resetCurrentPage());
    dispatch(pageConfig.listView.table.fetchPaginatedData({ page: 1, forceUpdate: true }));
  }
};

const PaginatedTable = ({
  tableRef,
  isTableLoading,
  columns,
  filteredData,
  state,
  setNewState,
  onRowClick,
  table,
  rowSelection,
}: TProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const filters = useSelector(filterSelector(pageConfig.id, pageConfig.id));
  const idsFilters = useSelector(idsFilterSelector(pageConfig.id, pageConfig.id));
  const orders = useSelector(orderSelector(pageConfig.id));
  const { pathname, location } = useRouter();
  const onTableChange = useCallback(
    (_, __, sorter, extra) => {
      setNewState({ ...state, sortedData: extra.currentDataSource });
      if (sorter) {
        if (isArray(sorter)) {
          sorter.map(sorterItem =>
            dispatch(
              updateOrder({
                pageId: pageConfig.id,
                entityKey: sorterItem.field as string,
                sortOrder: sorterItem.order!,
              })
            )
          );
        } else {
          dispatch(
            updateOrder({
              pageId: pageConfig.id,
              entityKey: sorter.field as string,
              sortOrder: sorter.order!,
            })
          );
        }
      }
    },
    [pageConfig]
  );

  useEffect(() => {
    const referrer = (location.state as LocationState)?.referrer;
    if (referrer?.includes(pageConfig.id) || idsFilters) {
      handleFetchPaginatedData(pageConfig, dispatch);
    }
  }, []);

  useDeepCompareEffect(() => {
    if (filters || idsFilters) {
      handleFetchPaginatedData(pageConfig, dispatch);
    }
  }, [filters, idsFilters, orders, pathname]);

  return (
    <VirtualListAntd
      tableRef={tableRef}
      key={`paged-${pageConfig.id}`}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NoData")} />,
        triggerDesc: t("SortDesc"),
        triggerAsc: t("SortAsc"),
        cancelSort: t("SortCancel"),
      }}
      loading={{ spinning: isTableLoading, style: { top: "20rem" } } as SpinProps}
      columns={columns}
      className="list-view-table"
      dataSource={filteredData}
      pagination={false}
      scroll={{
        x: `${getScrollXPercent(columns)}%`,
        y: "auto",
        scrollToFirstRowOnChange: false,
      }}
      rowKey={"id"}
      rowSelection={table.rowSelection ? { ...rowSelection } : undefined}
      onChange={onTableChange}
      onRow={record => {
        return {
          onClick: () => onRowClick(record),
        };
      }}
    />
  );
};

export default PaginatedTable;
