import "./CardLabel.less";

import { Typography } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { StringNumberNull } from "../../../../../types/common";

type TProps = {
  label: StringNumberNull;
  className?: string;
  labelTranslationKey?: string;
};

const CardLabel: React.FC<TProps> = ({ label, className, labelTranslationKey }) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  if (!label) return null;

  return (
    <Text className={clsx("card-label", className)} ellipsis>
      {!!labelTranslationKey && `${t(labelTranslationKey)} - `}
      {label}
    </Text>
  );
};

export default CardLabel;
