import Dashboard from "../../../components/Dashboard/Dashboard";
import { ReactComponent as DashboardLogo } from "../../../media/sidebar/dashboard-icon.svg";
import { dashboardSelectors, pageSelectors } from "../../../selectors";
import { addNewChart } from "../../../store/slices/dashboard";
import { DASHBOARD_FULL_SIZE_BREAKPOINT } from "../../../types/constants";
import { DashboardPage } from "../../../types/dashboard";
import { CustomPage } from "../../../types/page";
import { UserRole } from "../../../types/user";
import { getWindowDimensions } from "../../../utils/hooks/useWindowDimensions";

export const EditDashboard = {
  id: "editDashboard",
  title: "EditDashboardButton",
  isDataOperation: false,
};

export const ScheduleReport = {
  id: "scheduleReport",
  title: "ScheduleReportButton",
  isDataOperation: false,
};

export const ResetDashboardLayout = {
  id: "resetDashboardLayout",
  title: "ResetDashboardLayoutButton",
  isDataOperation: false,
};

export const ExportAllCharts = {
  id: "exportAllCharts",
  title: "ExportAllChartsButton",
  isDataOperation: false,
};

function getDashboardActions() {
  const { width } = getWindowDimensions();
  if (width < DASHBOARD_FULL_SIZE_BREAKPOINT) {
    return [ResetDashboardLayout, ExportAllCharts, ScheduleReport];
  }

  return [EditDashboard, ResetDashboardLayout, ExportAllCharts, ScheduleReport];
}

const dashboardConfigPage = (): CustomPage<DashboardPage> => ({
  id: "dashboard",
  title: "SmartDashboard",
  PageIcon: DashboardLogo,
  lastUpdatedSelector: dashboardSelectors.dashboardLastUpdatedText,
  addButton: {
    label: "AddChart",
    action: addNewChart,
    enablingFor(userState) {
      return userState.settings.role === UserRole.SU;
    },
  },
  defaultView: "custom",
  isLoading: pageSelectors.isDashboardPageLoading,
  actions: getDashboardActions(),
  singleViewActions: getDashboardActions(),
  CustomView: Dashboard,
  primarySingleEntitySelector: dashboardSelectors.single,
});

export default dashboardConfigPage;
