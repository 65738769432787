import "../MobileCardTemplate.less";

import {
  trainingAccountableIsAssignedToOUBranchField,
  trainingAccountableRoleField,
} from "../../../../../pages/pageConfig/category/training/trainingConfigPageData";
import { MobileCardTemplateTProps } from "../../../../../types/page";
import { PrivilegeData } from "../../../../../types/utility";
import AccountableMobileCardTemplateWithSelection from "./AccountableMobileCardTemplateWithSelection";

const TrainingAccountableMobileCardTemplate: React.FC<MobileCardTemplateTProps<PrivilegeData>> = ({ entity }) => {
  const roleSelectConfig = trainingAccountableRoleField;
  const isAssignedToOUBranchSelectConfig = trainingAccountableIsAssignedToOUBranchField;

  return (
    <AccountableMobileCardTemplateWithSelection
      entity={entity}
      roleSelectConfig={roleSelectConfig}
      isAssignedToOUBranchSelectConfig={isAssignedToOUBranchSelectConfig}
    />
  );
};

export default TrainingAccountableMobileCardTemplate;
