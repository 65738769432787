export type Project = Record<string, unknown> & {
  id: number;
  name: string;
  language: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  activeAndValid: boolean;
};

export interface ProjectState {
  data: Project[];
  isLoading: boolean;
  singleData: Project | null;
  error: string | null;
  lastUpdated: string;
  paginationInfo: {
    currentPage: number;
    count: number;
  };
}

export type ProjectsPaginatedData = {
  data: Project[];
  count: number;
};

export const ProjectsEndpoint = "/Project";
