import { ReactComponent as PageIcon } from "../../../../media/employee-page-icon.svg";
import { orgUnitSelectors } from "../../../../selectors";
import { OrgUnit } from "../../../../types/orgUnit";
import { BaseField, InputTypes } from "../../../../types/page";
import { options } from "../common";
import { constructTableTree, fetchOrgUnitById, localizeText, renderOptionIcon } from "../utilities";

export const nameField: BaseField<OrgUnit> = {
  id: "name",
  title: "FieldName",
  value: value => value.name,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const externalIdField: BaseField<OrgUnit> = {
  id: "externalId",
  title: "ExternalID",
  value: value => value.externalId,
  type: InputTypes.INPUT,
  placeholder: "Enter",
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const statusField: BaseField<OrgUnit> = {
  id: "status",
  title: "FieldStatus",
  value: value => value.status,
  valueText: value => localizeText(value.status),
  Icon: ({ value }) => renderOptionIcon(value.status),
  type: InputTypes.DROPDOWN,
  options: options,
  optionsSelector: () => null,
};

export const parentOUField: BaseField<OrgUnit> = {
  id: "parentId",
  title: "ParentOU",
  Icon: PageIcon,
  value: value => value.parentId,
  valueText: value => fetchOrgUnitById(value.parentId),
  type: InputTypes.TREESELECT,
  optionsSelector: () => null,
  treeData: {
    treeDataSelector: orgUnitSelectors.all,
    treeDataSet: (dataSet, currentEntityId) =>
      constructTableTree(
        {
          data: dataSet,
          itemValueKey: "id",
          itemLabelKey: "name",
          currentEntityValue: currentEntityId,
        },
        true
      ),
  },
  placeholder: "Root",
  placeholderMobileFirst: "Root",
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "orgUnit",
  },
  enableMobileScanners: true,
};

export const managerUserField: BaseField<OrgUnit> = {
  id: "managerUserId",
  title: "ManagerUser",
  value: value => value.managerUserId || null,
  type: InputTypes.AUTOCOMPLETE,
  placeholder: "Choose",
  optionsSelector: state => state.employee.basicData?.map(e => ({ id: e.id, label: e.displayName })) || [],
  enableMobileScanners: true,
};

export const primaryFields: BaseField<OrgUnit>[] = [nameField, externalIdField, statusField];

export const secondaryFields: BaseField<OrgUnit>[] = [parentOUField, managerUserField];
