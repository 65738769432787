import "./AddFileSection.less";

import { RcFile } from "antd/lib/upload/interface";
import { useTranslation } from "react-i18next";

import { MobileAddEntitySectionTProps } from "../../../../../types/page";
import { hasSpecialChars } from "../../../../HBComponents/UploadModal/HBUploadModal";
import MobileUpload from "../../../../HBComponents/UploadModal/MobileUpload";
import { useMobileSingleViewContext } from "../../../context/useMobileSingleViewContext";

const AddFileSection: React.FC<MobileAddEntitySectionTProps> = ({ entity, onFiledValueChange }) => {
  const { t } = useTranslation();
  const { setErrors } = useMobileSingleViewContext();

  const onFileSelected = (file: RcFile): boolean | Promise<void | File | Blob> => {
    if (hasSpecialChars(file.name)) {
      setErrors && setErrors({ title: [t("Error")], message: [t("ErrorSpecialCharactersInFileName")] });
      return false;
    }
    onFiledValueChange({ file });

    return false;
  };

  if (!entity) return null;

  return <MobileUpload onFileSelected={onFileSelected} />;
};

export default AddFileSection;
