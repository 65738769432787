import { Space } from "antd";
import { isEmpty } from "lodash";

import {
  certificateIdModalField,
  expirationDateModalField,
  trainingDateTabModalField,
} from "../../../../pages/pageConfig/category/certification/fields";
import { MobileAddEntitySectionTProps } from "../../../../types/page";
import EditableInputMobileFirst from "../../../EditableInput/EditableInputMobileFirst";

const AddCertificateSection: React.FC<MobileAddEntitySectionTProps> = ({ entity, onChange }) => {
  if (!entity) return null;

  const buttons = [certificateIdModalField, trainingDateTabModalField, expirationDateModalField];
  return (
    <Space direction="vertical" size={9} className="buttons-space">
      {buttons.map(field => {
        return (
          <EditableInputMobileFirst
            key={field.id}
            isEditMode={true}
            title={field.title}
            id={field.id}
            type={field.type}
            optionSelector={field.optionsSelector}
            fieldValue={isEmpty(entity) ? null : (field.value(entity) as string | null)}
            globalEdit={true}
            onChange={onChange(field.id)}
            prefixIcon={field.cellPrefixIcon}
            placeholder={field.placeholder}
            label={field.placeholder}
          />
        );
      })}
    </Space>
  );
};

export default AddCertificateSection;
