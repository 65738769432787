import { Input, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import "./inputModal.less";

export const InputModal = ({
  open,
  handleOpen,
  value,
  onChange,
}: {
  open: boolean;
  handleOpen: () => void;
  value: string | null | number;
  onChange: (v: string | null | boolean | number) => void;
}): JSX.Element => {
  const [inputValue, setInputValue] = useState(value);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textAreaRef = useRef<any>(null);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement> | number | string | null) => {
    if (e && typeof e === "object" && e.hasOwnProperty("target")) {
      const updatedText = e.target.value;
      setInputValue(updatedText);
      setCursorPosition(e.target.selectionStart);
      onChange(updatedText);
    } else if (e === null || typeof e === "number" || typeof e === "string") {
      setInputValue(e);
      onChange(e);
    }
  };

  const handleTextAreaClick = () => {
    setCursorPosition(textAreaRef.current.selectionStart);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCursorPosition = (position: any) => {
    textAreaRef.current.selectionStart = position;
    textAreaRef.current.selectionEnd = position;
    textAreaRef.current.focus();
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  return (
    <Modal className="multiline-input" mask={false} footer={null} onCancel={handleOpen} open={open}>
      <Input.TextArea
        ref={textAreaRef}
        onClick={handleTextAreaClick}
        onChange={handleChange}
        value={inputValue || ""}
        rows={5}
        className="modal"
      />
    </Modal>
  );
};
