import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import { Location } from "history";
import { useEffect } from "react";
import { getUserTokenByKey, removeAuthToken } from "../../store/slices/user";
import { useAppDispatch } from "../../store/store";
import { ParamsKeys } from "../../types/utility";
import useRouter from "../../utils/hooks/useRouter";

const STORE_CONDITION_ERROR = "ConditionError";

export default (): JSX.Element => {
  const { location } = useRouter<{ referer: Location }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const page = urlSearchParams.get(ParamsKeys.PAGE) ?? "actions";
  const key = urlSearchParams.get(ParamsKeys.KEY);
  const isMobile = urlSearchParams.get(ParamsKeys.PAGE)?.includes("mobile") ? true : false;
  const isLandingPage = urlSearchParams.get(ParamsKeys.PAGE)?.includes("landing") ? true : false;
  const lang = urlSearchParams.get(ParamsKeys.LANGUAGE);
  const { history } = useRouter();

  useEffect(() => {
    const route = isLandingPage
      ? `${page}?${ParamsKeys.NOREDIRECT}=true`
      : `/login?${page ? `${ParamsKeys.RETURN_URL}=${page}` : ""}`;
    if (key) {
      dispatch(removeAuthToken());
      dispatch(getUserTokenByKey({ key, mobile: isMobile }))
        .then(res => unwrapResult(res))
        .then(data =>
          data.token ? history.push(isLandingPage ? page : `/${page}?${ParamsKeys.LANG}=${lang}`) : history.push(route)
        )
        .catch(e => {
          console.error(e);
          if (e.name !== STORE_CONDITION_ERROR) {
            history.push(route);
          }
        });
    } else {
      history.push(route);
    }
  }, []);

  return (
    <div className="full-login-container" style={{ height: "100vh" }}>
      <Spin />
    </div>
  );
};
