export type HBEvent = {
  eventName: HBEventName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any; // support anything that we want, do not change
};

export enum HBEventName {
  Login = "Login",
  AppLoad = "App load",
  DateChange = "Date Change",
  UserRedirected = "User Redirected",
  ToggleTodoList = "Toggle Todo list",
  ScreenView = "Screen / Module View",
  LoginScreenPresented = "Login Screen Presented",
  UserLoggedIn = "User Logged In",
  UserEnteredUserame = "User Entered Username",
  UserEnteredPassword = "User Entered Password",
  UserEnteredPhoneOTP = "User Entered Phone (OTP)",
  LoginSuccessful = "Login Successful",
  LoginFail = "Login Fail",
  Required2FA = "2FA Required",
  Passed2FA = "2FA Passed",
  Failed2FA = "2FA Failed",
  ChangeLanguage = "Language Changed",
  SSOInititated = "SSO Initiated",
  ScreenModuleView = "Screen / Module view",
  SceenLoadingDone = "Screen Loading Done",
  CreateNew = "Create New",
  FilterEntityList = "Filter Entity List",
  ExportEntityList = "Export Entity List",
  ImportEntityList = "Import Entity List",
  ClickSingleEntity = "Click Single Entity",
  EnterEditMode = "Enter Edit Mode",
  SaveEntityChanges = "Save Entity Changes",
  TodoListStatusFiltered = "Todo List Filtered By Status",
  TodoListViewFiltered = "Todo List Filtered By View",
  DashboardSelected = "Dashboard Selected",
  DashboardScheduledReportClicked = "Dashboard Scheduled Report Clicked",
  DashboardResetDashboardLayout = "Dashboard Layout Reset To Default",
  ChartFiltered = "Chart Filter applied",
}
