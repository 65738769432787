import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, Button, Row } from "antd";
import { debounce } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { login } from "../../../store/slices/user";
import { RootState, useAppDispatch } from "../../../store/store";
import { HBEventName } from "../../../types/analyticsTypes/HBEvent";
import useInitTrackEvents from "../../../utils/hooks/useInitTrackEvents";
import { useLoginContext } from "../context/useLoginContext";

const LoginForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { track } = useInitTrackEvents();
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const { settings } = useSelector((state: RootState) => state.user);

  const {
    darkBackground,
    setCustomTitle,
    username,
    setUsername,
    password,
    setPassword,
    setIsForgotPassword,
    errorMessage,
    setErrorMessage,
    setTfaInfo,
    setResetPasswordInfo,
  } = useLoginContext();

  useEffect(() => {
    const debouncedTrack = debounce(() => {
      track({ eventName: HBEventName.UserEnteredUserame });
      track({ eventName: HBEventName.UserEnteredPassword });
    }, 1000);

    if (username && password) {
      debouncedTrack();
    }

    return () => {
      debouncedTrack.cancel();
    };
  }, [username, password]);

  const handleLogin = (values: { username: string; password: string }) => {
    if (!jwt) {
      dispatch(login({ userName: values.username, password: values.password, rememberMe: true }))
        .then(unwrapResult)
        .then(originalPromiseResult => {
          if (originalPromiseResult.data.token && originalPromiseResult.data.status === "VerificationCodeSent") {
            track({ eventName: HBEventName.Required2FA });
            setTfaInfo({ temporaryJwt: originalPromiseResult.data.token, tfaCode: "" });
            setErrorMessage("");
          } else {
            track({ eventName: HBEventName.LoginSuccessful });
          }
        })
        .catch(({ userError, data }) => {
          track({ eventName: HBEventName.LoginFail });
          if (data?.status === "UserMustChangePassword") {
            setResetPasswordInfo({ key: data.resetPasswordKey });
            setCustomTitle && setCustomTitle("SetPasswordTitle");
          } else if (userError) {
            setErrorMessage(userError);
          }
        });
    }
  };

  const handleInputChange = (setInput: React.Dispatch<React.SetStateAction<string>>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setInput(e.target.value);

  return (
    <Form
      className="login-wrapper"
      name="basic"
      initialValues={{ username: username || "", remember: true }}
      onFinish={handleLogin}
    >
      <Form.Item name="username" className="login-input" data-testid="usernameInput">
        <Input
          placeholder={t("AuthTypeUsername")}
          value={username || ""}
          onChange={handleInputChange(setUsername)}
          data-testid="login-username-input"
        />
      </Form.Item>

      <Form.Item className="password-input" data-testid="passwordInput" name="password">
        <Input.Password
          placeholder={t("FieldPassword")}
          value={password}
          onChange={handleInputChange(setPassword)}
          data-testid="login-password-input"
        />
      </Form.Item>
      <div
        className={`forgot-password forgot-password-${settings.direction} ${darkBackground ? "login-text-white" : ""}`}
        data-testid="forgot-password-link"
      >
        <span
          onClick={() => {
            setErrorMessage("");
            setCustomTitle("ForgotPasswordTitle");
            setIsForgotPassword(true);
          }}
        >
          {t("ForgotPasswordTitle")}
        </span>
      </div>
      <div className="login-error-message" data-testid="login-error-message">
        {errorMessage.includes(" ") ? errorMessage : t(errorMessage)}
      </div>
      <Row justify="center">
        <Button
          type="primary"
          disabled={!username || !password}
          className="button-submit"
          htmlType="submit"
          data-testid="login-submit-button"
        >
          {t("LoginTitle")}
        </Button>
        {errorMessage && (
          <div className={`tech-message-info ${darkBackground && "login-text-white"}`}>{t("ContactTechMessage")}</div>
        )}
      </Row>
    </Form>
  );
};

export default LoginForm;
