import { TFunction } from "react-i18next";
import { HandleTaskModal } from "../../components/HBComponents/HandleTaskModal/HandleTaskModal";
import { ReactComponent as ClockIcon } from "../../media/clock.svg";
import { TodoListTask } from "../../types/todolist";
import { TodoListCardStatus } from "../../types/utility";
import { renderCardStatus } from "../pageConfig/category/utilities";

interface TaskCardProps {
  task: TodoListTask;
  t: TFunction<"translation">;
}

const TaskCard = ({ task, t }: TaskCardProps) => {
  const {
    id,
    title,
    createdByName,
    priority,
    completedDate,
    startDate,
    reviewIds,
    dueDate,
    canBeCompleted,
    cardStatus,
  } = task;
  return (
    <HandleTaskModal
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      status={cardStatus as any}
      isTodoList={true}
      taskId={id}
      title={title}
      createdByName={createdByName}
      priority={priority}
      isOpen={completedDate ? false : true}
    >
      <div className="todo-cart todo-cart-pointer">
        <div className="cart-content">
          <div className="cart-title">{title}</div>
          <div> {canBeCompleted ? "" : reviewIds && reviewIds.length > 0 ? t("FollowUp") : ""}</div>
          <div className="recurrency">{t("FieldTask")}</div>
          <div className="date-wrapper">
            <ClockIcon className="clock-icon" />
            <span className="date">
              {startDate && startDate + " - "} {dueDate}
            </span>
          </div>
        </div>
        {cardStatus && (
          <div className="status">
            {cardStatus !== TodoListCardStatus.Open && t(cardStatus)}
            {renderCardStatus(cardStatus)}
          </div>
        )}
      </div>
    </HandleTaskModal>
  );
};

export default TaskCard;
