import "../MobileCardTemplate.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { Button, Row } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  inspectionCheckpointActionField,
  inspectionCheckpointStatusField,
} from "../../../../../pages/pageConfig/category/inspection/inspectionConfigPageData";
import { useAppDispatch } from "../../../../../store/store";
import { InspectionCheckpoint, InspectionRecurrencyType } from "../../../../../types/inspection";
import { FieldType } from "../../../../../types/page";
import { UploadModalType } from "../../../../../types/utility";
import EditableInputMobileFirst from "../../../../EditableInput/EditableInputMobileFirst";
import { SHORT_DATE_FORMAT_WITHOUT_TIME } from "../../../../HBComponents/DatePicker/HBDatePicker";
import { useMobileSingleViewContext } from "../../../context/useMobileSingleViewContext";
import { useMobileSingleViewTabContext } from "../../../context/useMobileSingleViewTabContext";
import CardLabel from "../../core/CardLabel/CardLabel";
import CardWithSelect from "../../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../../core/ContainerWithDivider/ContainerWithDivider";
import LabelWithDate from "../../core/LabelWithDate/LabelWithDate";
import RowContainer from "../../core/RowContainer/RowContainer";
import StatusMark from "../../core/StatusMark/StatusMark";
import InspectionCheckpointModal from "./InspectionCheckpointModal";

type TProps = {
  entity: InspectionCheckpoint;
};

const InspectionCheckpointMobileCardTemplate: React.FC<TProps> = ({ entity }) => {
  const { t } = useTranslation();
  const { setIsTableDataLoading } = useMobileSingleViewTabContext();
  const { setErrors } = useMobileSingleViewContext();
  const dispatch = useAppDispatch();
  const dateFormat = SHORT_DATE_FORMAT_WITHOUT_TIME;

  const [uploadModalType, setUploadModalType] = useState(UploadModalType.FileUpload);
  const [isUploadModalVisible, setUploadModalVisibility] = useState(false);

  const handleColumnDropdownOnChange = async (newValue: string | number | boolean | null) => {
    try {
      if (inspectionCheckpointStatusField.cellDropdownOnChange) {
        setIsTableDataLoading(true);
        const actionResponse = await dispatch(inspectionCheckpointStatusField.cellDropdownOnChange(entity, newValue));
        await unwrapResult(actionResponse);
        setIsTableDataLoading(false);
      }
    } catch (customError) {
      setErrors(customError as Record<string, string[]>);
      setIsTableDataLoading(false);
    }
  };

  const actionField = inspectionCheckpointActionField;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatchAction = async (dispatchFunc: any) => {
    if (dispatchFunc) {
      await dispatch(dispatchFunc)
        // TODO: check in PAS-10564
        .then(unwrapResult)
        .catch((customError: Record<string, string[]>) => {
          setErrors(customError);
        });
    }
  };

  const onCancel = async () => {
    const getDispatchFunc = () => {
      if (actionField) {
        return actionField.stagingActions?.[1]?.action(entity);
      }
    };

    const dispatchFunc = getDispatchFunc();

    if (dispatchFunc) {
      await dispatchAction(dispatchFunc);
    }
  };

  const onSave = async () => {
    const getDispatchFunc = () => {
      if (actionField) {
        return actionField.stagingActions?.[0]?.action(entity);
      }
    };

    const dispatchFunc = getDispatchFunc();

    if (dispatchFunc) {
      await dispatchAction(dispatchFunc);
    } else {
      setUploadModalType(
        entity.recurrencyType === InspectionRecurrencyType.Fixed
          ? UploadModalType.MannualyUpdateFixedInspection
          : UploadModalType.InspectionStatus
      );
      setUploadModalVisibility(!isUploadModalVisible);
    }
  };

  const closeModal = () => {
    setUploadModalVisibility(!isUploadModalVisible);
  };

  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {!!entity.id && <CardLabel className="primary-color" label={entity.id} />}
        {entity.status && <StatusMark statusLabel={entity.status || ""} />}
      </ContainerWithDivider>
      <ContainerWithDivider>
        {entity.startPeriod && (
          <LabelWithDate labelTranslationKey="FieldStart" date={entity.startPeriod} dateFormat={dateFormat} />
        )}
        {entity.endPeriod && (
          <LabelWithDate labelTranslationKey="FieldEnd" date={entity.endPeriod} dateFormat={dateFormat} />
        )}
        {entity.completedAt && (
          <LabelWithDate
            // add translation key
            labelTranslationKey="Actual"
            date={entity.completedAt}
            dateFormat={dateFormat}
            className="bold"
          />
        )}
      </ContainerWithDivider>
      <RowContainer>{entity.comment && <CardLabel className="small-font-size" label={entity.comment} />}</RowContainer>
      {!dayjs(entity.endPeriod).isBefore(dayjs(), "date") && !!entity.id && (
        <EditableInputMobileFirst
          className="action-select"
          key={String(inspectionCheckpointStatusField.id)}
          isEditMode={true}
          id={String(inspectionCheckpointStatusField.id)}
          type={inspectionCheckpointStatusField.cellType?.(entity) as FieldType}
          optionSelector={inspectionCheckpointStatusField.optionsSelector}
          globalEdit={true}
          onChange={handleColumnDropdownOnChange}
          fieldValue={entity.status}
          options={inspectionCheckpointStatusField.cellDropdownOptions?.(entity) || []}
          selector={inspectionCheckpointStatusField.cellSearchSelector!}
          prefixIcon={inspectionCheckpointStatusField.cellPrefixIcon}
        />
      )}
      {entity.staging && (
        <>
          <Row className="mobile-control-buttons margin-top-15">
            <Button className="cancel" onClick={onCancel}>
              {t("Cancel")}
            </Button>
            <Button className="save" type="primary" onClick={onSave}>
              {t("Save")}
            </Button>
          </Row>
          <InspectionCheckpointModal
            uploadModalType={uploadModalType}
            isVisible={isUploadModalVisible}
            entity={entity}
            closeModal={closeModal}
          />
        </>
      )}
    </CardWithSelect>
  );
};

export default InspectionCheckpointMobileCardTemplate;
