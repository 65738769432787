import { MoreOutlined } from "@ant-design/icons";

import { Button, Popover } from "antd";
import { useSelector } from "react-redux";

import { userSelectors } from "../../../selectors";
import { DotsMenuAction, Tab } from "../../../types/page";
import DotsMenu from "./DotsMenu";

type TProps = {
  selectedEntities: Record<string, unknown>[];
  handleDotsMenuClick: (action: DotsMenuAction<Record<string, unknown>>, skipConfirmation?: boolean) => Promise<void>;
  tabConfig: Tab<Record<string, unknown>, Record<string, unknown>>;
  isExportEnabled?: boolean;
};
const DotsPopover: React.FC<TProps> = ({
  selectedEntities,
  handleDotsMenuClick,
  tabConfig,
  isExportEnabled = true,
}) => {
  const user = useSelector(userSelectors.getCurrentUser);

  const dropdownButtonActions = tabConfig.dropdownButtonActions?.filter(mi => !mi.privilege || mi.privilege(user));

  if (!dropdownButtonActions?.length && !isExportEnabled) return null;

  return (
    <Popover
      trigger={["click"]}
      content={
        <DotsMenu
          dropdownButtonActions={dropdownButtonActions}
          selectedEntities={selectedEntities}
          handleDotsMenuClick={handleDotsMenuClick}
          isExportEnabled={isExportEnabled}
        />
      }
    >
      <Button shape="round" type="text" className="dropdown-button">
        <MoreOutlined className="dropdown-button__icon" />
      </Button>
    </Popover>
  );
};

export default DotsPopover;
