import React from "react";
import { ConfirmationModal } from "../../ConfirmationModal/ConfirmationModal";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";

const SingleViewCardMobileDialogs: React.FC = () => {
  const {
    handleClearErrors,
    errorMessages,
    cardValidationErrors,
    setCardValidationErrors,
    resetEditMode,
    navigationModalVisibility,
    modalInfo,
    handleModalClose,
  } = useMobileSingleViewContext();

  return (
    <>
      <ConfirmationModal
        visible={navigationModalVisibility}
        confirmationType="navigateAway"
        confirmOkClick={resetEditMode}
        cancelClick={handleModalClose}
      />
      <ConfirmationModal
        visible={errorMessages.length > 0}
        confirmationType="mobileFirstCardValidationErrors"
        confirmOkClick={handleClearErrors}
        messages={errorMessages}
      />
      <ConfirmationModal
        visible={cardValidationErrors.length > 0}
        confirmationType="mobileFirstCardValidationErrors"
        confirmOkClick={() => setCardValidationErrors([])}
        messages={cardValidationErrors}
      />
      <ConfirmationModal
        visible={modalInfo !== null}
        message={modalInfo?.body || ""}
        okText={modalInfo?.okText || ""}
        cancelText={modalInfo?.cancelText || ""}
        confirmOkClick={modalInfo?.onConfirm || (() => undefined)}
        cancelClick={modalInfo?.onCancel || (() => undefined)}
      />
    </>
  );
};

export default SingleViewCardMobileDialogs;
