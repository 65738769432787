import { Col, Dropdown } from "antd";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../../context/pageContext";
import { RootState } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage } from "../../../types/page";
import usePrevious from "../../../utils/hooks/usePrevious";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";

const ICON_TYPE_KEY = "iconType";

const formTypeIcons = Array.from({ length: 56 }, (_, index) => ({
  name: `${(index + 1) * 10}.png`,
  alt: `${(index + 1) * 10}`,
}));

const IconComponent: React.FC = () => {
  const {
    currentData,
    isGlobalEditForbidden,
    canEditCard,
    setIsEdited,
    summaryCardIcon,
    setSummaryCardIcon,
  } = useMobileSingleViewContext();

  const { summaryCard } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const [isIconDropdownVisible, setIsIconDropdownVisible] = useState(false);
  const prevSummaryCardIcon = usePrevious(summaryCardIcon);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const [svgContent, setSvgContent] = useState<string>("");
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (
      prevSummaryCardIcon &&
      summaryCardIcon &&
      !summaryCardIcon.init &&
      (prevSummaryCardIcon.name !== summaryCardIcon.name || prevSummaryCardIcon.alt !== summaryCardIcon.alt)
    ) {
      setIsEdited(true);
    }
  }, [summaryCardIcon, prevSummaryCardIcon]);

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await fetch(`IconReviewTypeSvg/${summaryCardIcon?.name.replace(".png", ".svg") || 10}`);
        const svgText = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgText, "image/svg+xml");
        const svg = doc.querySelector("svg");

        if (svg) {
          const serializer = new XMLSerializer();
          const newSvgContent = serializer.serializeToString(svg);
          setSvgContent(newSvgContent);
        }
      } catch (error) {
        console.error("Failed to fetch SVG:", error);
      }
    };

    fetchSvg();
  }, [summaryCardIcon, primaryColor]);

  useEffect(() => {
    if (currentData?.iconType)
      setSummaryCardIcon({ name: `${currentData?.iconType}.png`, alt: `${currentData?.iconType}`, init: true });
  }, [currentData]);

  const handleIconClick = useCallback(() => {
    if (!isGlobalEditForbidden && canEditCard) {
      setIsIconDropdownVisible(true);
    }
  }, [isGlobalEditForbidden, canEditCard]);

  if (!(summaryCard.CardIcon || currentData?.hasOwnProperty(ICON_TYPE_KEY))) {
    return null;
  }

  if (isIconDropdownVisible && currentData?.hasOwnProperty(ICON_TYPE_KEY)) {
    return (
      <Col>
        <Dropdown.Button
          className="icon-type-menu profile-image"
          placement="bottom"
          trigger={["click"]}
          menu={{
            items: formTypeIcons.map(({ name, alt }) => ({
              key: alt,
              icon: (
                <img className="form-type-menu-icon" src={`IconReviewType/${name}`} alt={alt} width="25" height="25" />
              ),
              onClick: () => {
                setSummaryCardIcon({ name, alt });
                setIsIconDropdownVisible(false);
              },
            })),
          }}
          icon={
            <img
              className="icon-type-menu-icon"
              src={`IconReviewType/${summaryCardIcon?.name}`}
              alt={summaryCardIcon?.alt}
              width="28"
              height="28"
            />
          }
        />
      </Col>
    );
  }

  if (summaryCard.CardIcon) {
    return (
      <div className="icon-type-menu-icon-non-editable">
        <summaryCard.CardIcon fill={primaryColor} />
      </div>
    );
  }

  return (
    <div onClick={handleIconClick} className="icon-type-menu-icon" dangerouslySetInnerHTML={{ __html: svgContent }} />
  );
};

export default IconComponent;
