import useBodyScroll from "../../hooks/useBodyScroll";
import useIsMobile from "../../hooks/useIsMobile";
import { PullToRefreshContext } from "./PullToRefreshContext";

export const PullToRefreshContextProvider: React.FC = ({ children }) => {
  const isMobile = useIsMobile();
  const isBodyScrolled = useBodyScroll();
  return (
    <PullToRefreshContext.Provider value={{ disabled: !isMobile || isBodyScrolled }}>
      {children}
    </PullToRefreshContext.Provider>
  );
};
