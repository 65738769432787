import {
  ExportAction,
  ColumnManagementAction,
  GetFiltersUrl,
  ResetColumnsWidthAction,
} from "../../../actions/menuActions";
import { ReactComponent as Logo } from "../../../media/location-page-icon.svg";
import { locationTypeSelectors, pageSelectors } from "../../../selectors";
import {
  addLocationType,
  clearLocationTypeError,
  createLocationTypeTemplate,
  createNewLocTypeInspTypeRelationEntryTemplate,
  fetchLocationTypes,
  fetchSingleLocationType,
  updateLocationType,
} from "../../../store/slices/locationType";
import { LocationTypePage, LocationType } from "../../../types/locationType";
import { CategoryPage, InputTypes } from "../../../types/page";
import { UserRole } from "../../../types/user";
import { TableAddButtonType } from "../../../types/utility";
import { locTypeInspTypeRelationTableColumns, locationTypeTableColumns } from "./locationTypeConfigPageData";
import { prepareDynamicColumns, removeColumnFilters } from "./utilities";

const locationTypeConfigPage = (): CategoryPage<LocationTypePage> => ({
  id: "locationType",
  title: "PluginTypeName",
  entityEndpoint: "/LocationType",
  PageIcon: Logo,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: locationTypeSelectors.error,
  clearError: clearLocationTypeError,
  createNewEntityTemplate: createLocationTypeTemplate,
  createNewEntity: addLocationType,
  updateEntity: updateLocationType,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  fetchSingle: fetchSingleLocationType,
  primarySingleEntitySelector: locationTypeSelectors.single,
  intializeDataActions: [fetchLocationTypes],
  customPropertiesSelector: locationTypeSelectors.locationTypeCustomProperties,
  isLoading: pageSelectors.isLocationTypePageLoading,
  lastUpdatedSelector: locationTypeSelectors.locationTypeLastUpdatedText,
  addButton: {
    label: "AddType",
    // eslint-disable-next-line no-console
    action: () => console.log("Create new Location Type"),
  },
  listView: {
    table: {
      tableSelector: locationTypeSelectors.all,
      fetchListViewData: fetchLocationTypes,
      columns: locationTypeTableColumns,
      rowSelection: true,
      defaultColumnKeys: ["id", "name"],
      type: "table",
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<LocationType>(props, data),
  },
  summaryCard: {
    primaryFields: [
      {
        id: "name",
        title: "FieldName",
        value: e => e.name,
        type: InputTypes.INPUT,
        required: true,
        placeholder: "Enter",
        optionsSelector: () => null,
      },
    ],
    secondaryFields: [],
  },
  tabsPanel: [
    {
      key: "inspectionType",
      label: "PluginInspectionName",
      tabSelector: locationTypeSelectors.locTypeInspTypeRelationSelector,
      columns: removeColumnFilters(locTypeInspTypeRelationTableColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewLocTypeInspTypeRelationEntryTemplate,
        label: "AddLocTypeInspType",
        type: TableAddButtonType.AddInline,
      },
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        //NOTE: in future it will be added again, for now it removed per Siso's request
        // {
        //   label: "Delete",
        //   action: entities => deleteInspectionTypeLocationTypeRelation(entities as LocTypeInspTypeRelation[]),
        //   privilege: adminOrSuUserOnly,
        //   type: "bulk",
        //   changesConfirmation: {
        //     body: "ConfirmEntitiesRemoval",
        //     okText: "Remove",
        //     cancelText: "Keep",
        //     addValueToBody: false,
        //     addValueToOkText: false,
        //   },
        // },
      ],
    },
  ],
  listViewActions: [ExportAction, ColumnManagementAction, ResetColumnsWidthAction, GetFiltersUrl],
  allowedUserRoles: [UserRole.SU, UserRole.Admin, UserRole.TenantAdmin],
});

export default locationTypeConfigPage;
