import { Button, Popover } from "antd";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../../../../types/user";

export type ChartMenuItemProperties = {
  key: string;
  label: string;
  icon: ReactNode;
  initialClickedState?: boolean;
  disabled?: boolean;
  action?: () => void;
  toggle?: boolean;
  customComponent?: ReactNode;
  allowedUserRoles?: UserRole[];
  className?: string;
};
export default function ChartMenuItem({
  label,
  icon,
  initialClickedState,
  disabled,
  toggle,
  action,
  customComponent,
  className,
}: ChartMenuItemProperties) {
  const [clicked, setClicked] = useState(initialClickedState ? initialClickedState : false);
  const { t } = useTranslation();

  if (customComponent) {
    return (
      <Popover content={customComponent} arrowPointAtCenter trigger="click" placement="right">
        <Button className="chartMenuItem" type="text" shape="round" disabled={disabled}>
          <div className="chartMenuItemContainer">
            <div className="chartMenuItemLabel">{t(label)}</div>
            <div className={`${clicked ? "selected-vizual-menu-icon" : ""}`}>{icon}</div>
          </div>
        </Button>
      </Popover>
    );
  }

  return (
    <Button
      type="text"
      shape="round"
      onClick={() => {
        setClicked(!clicked);
        if (action) action();
      }}
      disabled={disabled}
      className="chartMenuItem"
    >
      <div className="chartMenuItemContainer">
        <div className={`chartMenuItemLabel${className ? ` ${className}` : ""}`}>{t(label)}</div>
        <div className={`${clicked && toggle ? "selected-vizual-menu-icon" : ""}`}>{icon}</div>
      </div>
    </Button>
  );
}
