import "./confirmationModal.less";

import { Modal, Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";

import { modalTexts } from "./config";
import { ConfirmationModalProps } from "./types";

export const ConfirmationModal = ({
  visible,
  confirmationType,
  okText,
  cancelText,
  confirmOkClick,
  cancelClick,
  messages,
  message,
}: ConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation();
  // debugger;
  const texts = confirmationType
    ? messages
      ? modalTexts[confirmationType](messages)
      : modalTexts[confirmationType]()
    : null;

  return (
    <>
      <Modal
        open={visible}
        wrapClassName="modalWrapper"
        onOk={confirmOkClick}
        onCancel={cancelClick ? cancelClick : confirmOkClick}
        okButtonProps={{ style: { minHeight: "4rem" } }}
        cancelButtonProps={
          !cancelClick ? { style: { display: "none", minHeight: "4rem" } } : { style: { minHeight: "4rem" } }
        }
        cancelText={(texts?.cancelText && t(texts.cancelText)) || (cancelText && t(cancelText))}
        okText={(texts?.okText && t(texts.okText)) || (okText && t(okText))}
        centered
        className="message-modal"
      >
        {texts?.Image && <texts.Image className="message-modal__body-image" />}
        <Typography.Paragraph className="message-modal__body-title">
          {texts?.title && <Trans i18nKey={texts?.title} />}
        </Typography.Paragraph>
        <Typography.Paragraph className="message-modal__body-text">
          {typeof texts?.body === "string" ? (
            <Trans i18nKey={texts.body} />
          ) : (
            texts?.body || <Trans i18nKey={message ?? ""} />
          )}
        </Typography.Paragraph>
      </Modal>
    </>
  );
};
