import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { MessageType, useLoginContext } from "../context/useLoginContext";

const BackToLoginScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    backToLoginMessage,
    setIsForgotPassword,
    setBackToLoginMessage,
    setResetPasswordInfo,
    setCustomTitle,
  } = useLoginContext();

  const onBack = () => {
    setIsForgotPassword(false);
    setCustomTitle("LoginHeader");
    setBackToLoginMessage(null);
    setResetPasswordInfo(null);
  };

  return (
    <div className="login-wrapper">
      <div
        className={`login-${backToLoginMessage?.type || MessageType.Error}-message`}
        data-testid="reset-password-error-message"
      >
        {t(backToLoginMessage?.message || "")}
      </div>

      <Button type="primary" onClick={onBack} className="button-submit" data-testid="back-to-login-button">
        {t("LoginTitle")}
      </Button>
    </div>
  );
};

export default BackToLoginScreen;
