import { PivotConfig, Query, TimeDimensionRanged } from "@cubejs-client/core";

import dayjs from "dayjs";
import i18next from "i18next";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { isCompareDateRangeQuery } from "../../../utils/dashboard/dashboards";
import { CustomResultSet } from "../../../utils/dashboard/resultSet/resultSetFactory";
import { DATE_FORMAT_WITHOUT_TIME } from "../../HBComponents/DatePicker/HBDatePicker";
import { ChartContext } from "../Chart/HBChart";
import { appointmentStatus } from "./Appointment";

export type LocalizedDimension = {
  dimension: string;
  values: { [key: string]: string };
};

export const CUBE_NO_VALUE = "∅";

export const localizedDimensions: LocalizedDimension[] = [appointmentStatus];

export const formatDateLabel: (date: string, granularity: string) => string = (date, granularity) => {
  switch (granularity) {
    case "week": {
      return `${dayjs(date).format("YYYY")}-${dayjs(date).format("w")}`;
    }
    case "month": {
      return dayjs(date).format("MMMM");
    }
    case "quarter": {
      return `${dayjs(date).format("YY")}Q${dayjs(date).format("Q")}`;
    }
    case "year": {
      return dayjs(date).format("YYYY");
    }
    default: {
      return dayjs(date).format(DATE_FORMAT_WITHOUT_TIME);
    }
  }
};

const getResultsQuery = (resultSet?: CustomResultSet) => {
  const queryType = resultSet?.queryType;
  return isCompareDateRangeQuery(queryType) ? resultSet?.decompose()[1]?.query() : resultSet?.query();
};

const getTimeDimension = (resultsQuery?: Query) => {
  const timeDimensions = resultsQuery?.timeDimensions;
  return timeDimensions?.length ? timeDimensions[0] : null;
};

const getTimeKey = (timeDimension: TimeDimensionRanged | null) => {
  return `${timeDimension?.dimension}.${timeDimension?.granularity}`;
};

const getLocalizedDimension = (xLabel: string, pivotConfig: PivotConfig) => {
  const localizedDim = localizedDimensions.find(d => pivotConfig.x![0] === d.dimension)?.values[xLabel] || xLabel;
  return i18next.t(localizedDim, xLabel);
};

export const formatCategoryLabel = (resultSet?: CustomResultSet, xLabel = "", pivotConfig?: PivotConfig): string => {
  if (!pivotConfig) return xLabel;
  const resultsQuery = getResultsQuery(resultSet);
  const timeDimension = getTimeDimension(resultsQuery);

  if (!timeDimension) return xLabel;

  const timeKey = getTimeKey(timeDimension);

  if (pivotConfig?.x?.includes(timeKey)) {
    return formatDateLabel(xLabel, timeDimension.granularity!);
  }

  if (pivotConfig.x?.length) {
    return getLocalizedDimension(xLabel, pivotConfig);
  }

  return xLabel;
};

export default function useLocalizeDimensions() {
  const { chart } = useContext(ChartContext);
  const { t } = useTranslation();

  const localizeDimension = (dimension: string, value: string) => {
    const localizedDim = localizedDimensions.find(x => x.dimension === dimension)?.values[value] || value;
    return t(localizedDim, value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatSeriesLabel: (resultSet: CustomResultSet, seriesKey: string, seriesLabel: string) => string = (
    resultSet,
    seriesKey,
    seriesLabel
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryType = resultSet?.queryType;
    const resultsQuery = isCompareDateRangeQuery(queryType) ? resultSet.decompose()[1].query() : resultSet.query();
    if (resultsQuery.timeDimensions && resultsQuery.timeDimensions!.length > 0) {
      if (isCompareDateRangeQuery(queryType)) {
        const splittedLabel = seriesLabel.split(",");
        return splittedLabel
          .map((part, index) => {
            if (index === 0) {
              return part
                .split(" - ")
                .map(date => formatDateLabel(date, "day"))
                .join(" - ");
            }
            return part;
          })
          .join(",");
      }
    }
    const labelSplitted = seriesLabel.split(",");
    let label = labelSplitted[0];

    if (chart?.pivotConfig && chart?.pivotConfig.y!.length > 1) {
      if (labelSplitted.length > 1) {
        const localizedDim = localizedDimensions.find(dimension => chart!.pivotConfig!.y![0] === dimension.dimension);
        if (localizedDim) {
          return t(localizedDim.values[labelSplitted[0]], labelSplitted[0]);
        }
      } else {
        label = CUBE_NO_VALUE;
      }
    } else {
      label = t(seriesKey, seriesLabel);
    }
    return label;
  };

  return { localizeDimension, formatSeriesLabel };
}
