import "./MobileCardTemplate.less";

import { TaskFile } from "../../../../types/files";
import { MobileCardTemplateTProps } from "../../../../types/page";
import FilesMobileCardTemplate from "./FilesMobileCardTemplate";

const TaskFilesMobileCardTemplate: React.FC<MobileCardTemplateTProps<TaskFile>> = ({ entity }) => {
  return <FilesMobileCardTemplate entity={{ ...entity, expirationDate: "", isVirusScanningPassed: false }} />;
};

export default TaskFilesMobileCardTemplate;
