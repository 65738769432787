import { InputRef } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

type TProps = {
  value?: string | null | number;
  ref?: React.RefObject<InputRef>;
  defaultValue?: string | null | number;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
};

const HBTextArea = ({
  value,
  ref,
  defaultValue,
  placeholder,
  autoFocus,
  disabled,
  onChange,
  onBlur,
}: TProps): JSX.Element => {
  return (
    <TextArea
      placeholder={placeholder}
      onBlur={onBlur}
      ref={ref}
      className="HB-input"
      defaultValue={defaultValue || ""}
      onChange={onChange}
      value={value ? value : ""}
      autoFocus={autoFocus}
      autoSize={{ minRows: 3, maxRows: 5 }}
      disabled={disabled}
    />
  );
};

export default HBTextArea;
