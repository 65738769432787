import { Badge } from "antd";
import dayjs from "dayjs";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import NewTabComponent from "../../../../components/HBComponents/NewTabIcon/NewTabComponent";
import { ReactComponent as SearchIcon } from "../../../../media/search-icon.svg";
import { ReactComponent as UsersIcon } from "../../../../media/users-icon.svg";
import { orgUnitSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntry,
} from "../../../../store/slices/orgUnit";
import {
  addEntityPrivilege,
  updateEntityPrivilegeOULvl,
  updateEntityPrivilegeRole,
} from "../../../../store/slices/privileges";
import { IS_ASSIGNED_TO_OU_FIELD } from "../../../../types/constants";
import { Employee } from "../../../../types/employee";
import { OrgUnit } from "../../../../types/orgUnit";
import { CellTypes, ColumnType, ColumnWithDifferentCells, InputTypes } from "../../../../types/page";
import { PrivilegeData, PrivilegedEntityType, Role, Status } from "../../../../types/utility";
import { AccountabilityOptions, boolOptions, getCellSearchResultColumns, options } from "../common";
import {
  fetchEmployeeById,
  fetchOrgUnitById,
  localizeText,
  newRenderBoolStatus,
  renderBoolStatus,
  renderTableStatus,
} from "../utilities";

export const orgUnitsTableColumns: ColumnType<OrgUnit>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: value => value.id.toString(),
    sortable: true,
    optionsSelector: () => null,
    // hidden: true,
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: orgUnitSelectors.uniqueNames,
    sortable: true,
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: orgUnitSelectors.uniqueExternalIds,
    sortable: true,
  },
  {
    id: "parentId",
    label: "ParentOU",
    renderValue: value => fetchOrgUnitById(value.parentId) || "Root",
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: orgUnitSelectors.uniqueParentOrgUnits,
    sortable: true,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null, // label is rendered together with icon in renderTableStatus()
    valueIcon: (value: OrgUnit): React.ReactNode | null => {
      return renderTableStatus(value.status);
    },
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: () =>
      options.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    width: 100,
  },
  {
    id: "managerUserId",
    label: "ManagerUser",
    renderValue: value => fetchEmployeeById(value.managerUserId) || null,
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: orgUnitSelectors.uniqueManagerUserIds,
    sortable: true,
  },
];

export const employeesTabColumns: ColumnWithDifferentCells<Employee>[] = [
  {
    id: "displayName",
    label: "FieldLanguageDisplayName",
    renderValue: () => null,
    valueIcon: value => <NewTabComponent value={value.displayName} href={`/employee/${value.id}`} />,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "externalId",
    label: "ExternalID",
    renderValue: value => value.externalId || "",
    sortable: false,
    optionsSelector: () => null,
  },
  {
    id: "expirationDate",
    label: "FieldExpirationDate",
    optionsSelector: () => null,
    renderValue: value => (value.expirationDate ? dayjs(value.expirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null),
    valueIcon: (value: Employee) => {
      if (value.expirationDate) {
        const expDate = dayjs(value.expirationDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
      }
    },
    sortable: false,
    filterType: InputTypes.DATE_RANGE,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: value => (value.status ? localizeText(Status[value.status]) : ""),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
];

export const orgUnitAccountableNameField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "name",
  label: "FieldName",
  renderValue: entity => entity.name || "",
  sortable: true,
  filterType: InputTypes.MULTI_SELECT,
  cellType: entity => (entity.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: orgUnitSelectors.allOrgUnitRelations,
  cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
  cellSearchKeys: ["name"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: () => null,
  width: 100,
  primaryColumn: entity => ({
    navigationTarget: entity.userId ? `/employee/${entity.userId}` : `/orgUnit/${entity.orgUnitId}`,
  }),
  cellPrefixIcon: <UsersIcon className="user-icon" />,
  placeholder: "Name",
};

export const orgUnitAccountableExternalIdField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "externalId",
  label: "ExternalID",
  renderValue: entity => entity.externalId || "",
  sortable: true,
  filterType: InputTypes.MULTI_SELECT,
  cellType: entity => (entity.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: orgUnitSelectors.allOrgUnitRelations,
  cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
  cellSearchKeys: ["externalId"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: orgUnitSelectors.allOrgUnitExternalIDs,
  width: 100,
  cellPrefixIcon: <SearchIcon className="search-icon" />,
  placeholder: "ExternalID",
};

export const orgUnitAccountableStatusField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "status",
  label: "FieldStatus",
  renderValue: entity => (entity.status ? localizeText(entity.status.toString()) : ""),
  sortable: true,
  optionsSelector: () => null,
  width: 100,
};

export const orgUnitAccountableIsExternalField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "isExternal",
  label: "External",
  renderValue: entity => (entity.isExternal !== null ? renderBoolStatus(entity.isExternal) : ""),
  sortable: true,
  optionsSelector: () => null,
  width: 100,
};

export const orgUnitAccountableRoleField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "role",
  label: "Accountability",
  renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
  cellType: () => CellTypes.DROPDOWN,
  cellDropdownOptions: () =>
    AccountabilityOptions.map(ao => ({
      id: ao.id,
      label: localizeText(ao.label),
    })),
  cellDropdownOnChange: (entity, newValue) =>
    entity.staging
      ? updateNewAccountableEntry({ entity: entity, newValue: newValue, property: "role" })
      : updateEntityPrivilegeRole({
          entity: entity,
          role: newValue as Role,
          entityType: PrivilegedEntityType.OrgUnit,
          id: null,
        }),
  sortable: true,
  optionsSelector: () => null,
  width: 100,
  cellPrefixIcon: <UsersIcon />,
};

export const orgUnitAccountableIsAssignedToOUBranchField: ColumnWithDifferentCells<PrivilegeData> = {
  id: IS_ASSIGNED_TO_OU_FIELD,
  label: "FieldIsAssignedToOUBranch",
  renderValue: value => {
    return value.orgUnitId && value.role === Role.Recipient ? newRenderBoolStatus(value.isAssignedToOUBranch) : null;
  },
  cellType: e => (e.orgUnitId && e.role === Role.Recipient ? "dropdown" : "text"),
  sortable: true,
  cellDropdownOptions: () =>
    boolOptions.map(b => ({
      id: b.id,
      label: localizeText(b.label),
    })),
  cellDropdownOnChange: (entity, newValue) =>
    entity.staging
      ? updateNewAccountableEntry({ entity: entity, newValue: newValue, property: IS_ASSIGNED_TO_OU_FIELD })
      : updateEntityPrivilegeOULvl({
          entity: entity,
          isAssignedToOUBranch: newValue as boolean,
          entityType: PrivilegedEntityType.OrgUnit,
          id: null,
        }),
  optionsSelector: () => null,
  width: 100,
};

export const orgUnitAccountableActionField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "action" as keyof PrivilegeData,
  label: "FieldAction",
  renderValue: () => null,
  sortable: true,
  optionsSelector: () => null,
  cellType: () => CellTypes.BUTTON_ACTIONS,
  width: 100,
  stagingActions: [
    {
      label: "Add",
      className: "hb-primary-button",
      action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.OrgUnit, id: null }),
    },
    {
      label: "Cancel",
      className: "hb-tertiary-button",
      action: entity => deleteNewAccountableEntryTemplate(entity),
    },
  ],
};

export const orgUnitAccountableTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  orgUnitAccountableNameField,
  orgUnitAccountableExternalIdField,
  orgUnitAccountableStatusField,
  orgUnitAccountableIsExternalField,
  orgUnitAccountableRoleField,
  orgUnitAccountableIsAssignedToOUBranchField,
  orgUnitAccountableActionField,
];
