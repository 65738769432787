import "./ImpersonateScreen.less";

import { Button, Modal, Row } from "antd";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../media/close-icon.svg";
import { logout } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { SuMode } from "../../types/user";

const ImpersonateScreen: React.FC = () => {
  const { t } = useTranslation();
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const dispatch = useAppDispatch();
  const suMode = useSelector((state: RootState) => state.user.settings.suMode);

  const onLogoutClick = () => {
    setIsConfirmModalOpened(false);
    dispatch(logout());
  };

  const openConfirmModal = () => {
    setIsConfirmModalOpened(true);
  };

  const onCancel = () => {
    setIsConfirmModalOpened(false);
  };

  if (suMode !== SuMode.Impersonate) return null;

  return (
    <div className="impersonate-screen">
      <div className="button-wrapper">
        <CloseIcon onClick={openConfirmModal} />
      </div>
      <Modal
        className={"impersonate-confirm-modal"}
        open={isConfirmModalOpened}
        closable
        onCancel={onCancel}
        footer={null}
      >
        <div className="confirmation-title">{t("ConfirmDefaultText")}</div>
        <div className="confirmation-description">{t("ImpersonateUserLogoutConfirmDescroption")}</div>

        <Row justify="center" gutter={[0, 24]}>
          <Button
            type="primary"
            className="button-submit"
            htmlType="submit"
            data-testid="impersonate-logout-button"
            onClick={onLogoutClick}
          >
            {t("ImpersonateUserLogoutConfirmButton")}
          </Button>
          <Button
            type="default"
            className="button-submit"
            htmlType="submit"
            data-testid="impersonate-cancel-button"
            onClick={onCancel}
          >
            {t("No")}
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default memo(ImpersonateScreen);
