import "./MobileCardTemplate.less";

import { Employee } from "../../../../types/employee";
import { SHORT_DATE_FORMAT_WITHOUT_TIME } from "../../../HBComponents/DatePicker/HBDatePicker";
import CardLabelWithIcon, { ICON_NAMES } from "../core/CardLabelWithIcon/CardLabelWithIcon";
import CardTitle from "../core/CardTitle/CardTitle";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import LabelWithDate from "../core/LabelWithDate/LabelWithDate";
import RowContainer from "../core/RowContainer/RowContainer";
import StatusMark from "../core/StatusMark/StatusMark";

type TProps = {
  entity: Employee;
};

const TeamMembersMobileCardTemplate: React.FC<TProps> = ({ entity }) => {
  const dateFormat = SHORT_DATE_FORMAT_WITHOUT_TIME;

  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.externalId && <CardTitle title={entity.externalId} />}
        {entity.displayName && (
          <CardLabelWithIcon
            label={entity.displayName}
            iconName={ICON_NAMES.USER}
            category="employee"
            id={entity.id!}
          />
        )}
      </ContainerWithDivider>
      <RowContainer>
        {entity.status && <StatusMark statusLabel={entity.status || ""} />}
        {entity.expirationDate && (
          <LabelWithDate labelTranslationKey="FieldExpiration" date={entity.expirationDate} dateFormat={dateFormat} />
        )}
      </RowContainer>
    </CardWithSelect>
  );
};

export default TeamMembersMobileCardTemplate;
