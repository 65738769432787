import { Modal } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PageConfigurationContext from "../../../context/pageContext";
import { RootState } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage } from "../../../types/page";
import { isMultilineTextTruncated } from "../../../utils/functions";
import HBTextArea from "../../HBComponents/Input/HBTextArea";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";
import LabelWithDate from "./core/LabelWithDate/LabelWithDate";
import StatusMark from "./core/StatusMark/StatusMark";
import DetailsRowSection from "./DetailsRowSection";

enum InputTypes {
  TITLE = "title",
  ID = "id",
  STATUS = "status",
  MAIN_CONTEXT_RELATION = "mainContextRelation",
  ADDITIONAL_MAIN_CONTEXT_RELATION = "additionalContextRelation",
  DATE = "date",
  DATE_TIME = "dateTime",
}

export const ICON_TYPE_KEY = "iconType";

export interface Input {
  type: string;
  id: string;
  className?: string;
  isOptional?: boolean;
}

const inputs: Record<string, Input> = {
  title: { type: InputTypes.TITLE, id: InputTypes.TITLE },
  id: { type: InputTypes.ID, id: InputTypes.ID },
  status: { type: InputTypes.STATUS, id: InputTypes.STATUS },
  context: { type: InputTypes.MAIN_CONTEXT_RELATION, id: "context", className: "role", isOptional: true },
  additionalContext: {
    type: InputTypes.ADDITIONAL_MAIN_CONTEXT_RELATION,
    id: "additionalContext",
    className: "role",
    isOptional: true,
  },
  expiry: { type: InputTypes.DATE, id: "expiry", isOptional: true },
};

const groupInputs: Input[] = [inputs.status, inputs.expiry, inputs.context, inputs.additionalContext];

const DetailsComponent: React.FC<{ isNewEntity?: boolean }> = () => {
  const { t } = useTranslation();
  const { summaryCard } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const direction = useSelector((state: RootState) => state.user.settings.direction);

  const { isNewEntity, renderValue } = useMobileSingleViewContext();

  const openTextInModal = (value?: string) => {
    if (!value) return;
    Modal.info({
      icon: null,
      content: <HBTextArea value={value} disabled />,
      maskClosable: true,
    });
  };

  const entityIdNameSection = () => {
    const idField = summaryCard.mobileSingleView?.summary[InputTypes.ID];
    const dataName = summaryCard.mobileSingleView?.summary[InputTypes.TITLE];
    return (
      <div className="id-name">
        {dataName?.value && (
          <p
            className="name"
            onClick={event =>
              isMultilineTextTruncated(event.currentTarget) &&
              openTextInModal(dataName?.value && (renderValue(dataName.value) as string))
            }
          >
            <span dir={direction} className="id">
              {idField?.value && renderValue(idField.value)}
            </span>
            {dataName?.value && renderValue(dataName.value)}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="details">
      {isNewEntity ? (
        <h1 className="create-title">{t("CreateNew")}</h1>
      ) : (
        <>
          {entityIdNameSection()}
          <div className="status-role">
            {groupInputs.map(({ type, id, isOptional }) => {
              const inputConf = summaryCard.mobileSingleView?.summary[type];
              if (!inputConf) return null;

              if (id === InputTypes.STATUS) {
                return <StatusMark key={id} statusLabel={renderValue(inputConf?.value) as string} />;
              }
              if (inputConf.type === InputTypes.DATE || inputConf.type === InputTypes.DATE_TIME) {
                return (
                  <LabelWithDate
                    key={id}
                    date={renderValue(inputConf?.value) as string}
                    labelTranslationKey={inputConf.title}
                  />
                );
              }

              return <DetailsRowSection key={id} input={inputConf} showTitle={isOptional} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsComponent;
