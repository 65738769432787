import {
  ExportAction,
  ColumnManagementAction,
  GetFiltersUrl,
  ResetColumnsWidthAction,
} from "../../../../actions/menuActions";
import { ReactComponent as PageIcon } from "../../../../media/sidebar/tasks.svg";
import { pageSelectors, recurrentActionsSelectors } from "../../../../selectors";
import { fetchIssueTypes } from "../../../../store/slices/issueType";
import {
  addRecurrentAction,
  clearReccurentActionsError,
  createRecurrentActionTemplate,
  fetchReccurentActions,
  fetchSingleRecurrentAction,
  updateRecurrentAction,
} from "../../../../store/slices/recurrentActions";
import { fetchTasksAdditionalData } from "../../../../store/slices/tasks";
import { CategoryPage, SectionType } from "../../../../types/page";
import { RecurrentAction, RecurrentActionPage } from "../../../../types/recurrentAction";
import { prepareDynamicColumns, pickSpecificColumns } from "../utilities";
import {
  locationIdField,
  primaryFields,
  responsibleUserIdField,
  secondaryFields,
  startDateField,
  titleField,
} from "./fields";
import { recurrentActionTableColumns } from "./recurringActionConfigPageData";

const recurrentActionConfigPage = (): CategoryPage<RecurrentActionPage> => ({
  id: "recurrentActions",
  title: "TitleRecurringPlanning",
  entityEndpoint: "/recurrentActions",
  fetchSingle: fetchSingleRecurrentAction,
  PageIcon: PageIcon,
  hasGroupView: false,
  hasListView: true,
  intializeDataActions: [fetchTasksAdditionalData, fetchIssueTypes],
  primaryErrorSelector: recurrentActionsSelectors.error,
  isLoading: pageSelectors.isRecurrentActionsPageLoading,
  clearError: clearReccurentActionsError,
  lastUpdatedSelector: recurrentActionsSelectors.recurrentActionsLastUpdatedText,
  primarySingleEntitySelector: recurrentActionsSelectors.single,
  customPropertiesSelector: recurrentActionsSelectors.recurrentActionsCustomProperties,
  listViewActions: [ColumnManagementAction, ResetColumnsWidthAction, ExportAction, GetFiltersUrl],
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.title };
  },
  updateEntity: updateRecurrentAction,
  createNewEntityTemplate: createRecurrentActionTemplate,
  createNewEntity: addRecurrentAction,
  addButton: {
    label: "AddRecurrentAction",
    // eslint-disable-next-line no-console
    action: () => console.log("Create new recurrent action"),
  },
  summaryCard: {
    canEdit: () => true,
    CardIcon: PageIcon,
    mobileSingleView: {
      summary: {
        icon: PageIcon,
        title: titleField,
        mainContextRelation: responsibleUserIdField,
      },
      sections: [
        {
          type: SectionType.ContextSection,
          fields: [locationIdField],
        },
        {
          type: SectionType.DatesSection,
          fields: [startDateField],
        },
      ],
    },
    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
    RRuleComponent: {
      fieldName: "recurrenceRule",
    },
  },

  listView: {
    table: {
      type: "table",
      tableSelector: recurrentActionsSelectors.all,
      fetchListViewData: fetchReccurentActions,
      columns: recurrentActionTableColumns,
      rowSelection: true,
      defaultColumnKeys: [
        "id",
        "title",
        "responsibleUserName",
        "projectName",
        "startDate",
        "recurrencyRule",
        "recurrencyRuleEndDate",
        "isActive",
      ],
      disableNavigation: false,
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<RecurrentAction>(props, data),
  },
  tabsPanel: [],
  listSearch: {
    columns: pickSpecificColumns<RecurrentAction>(recurrentActionTableColumns, "id", "title"),
    keys: ["id", "title"],
  },
  defaultFilters: [],
});

export default recurrentActionConfigPage;
