import "./webhookContextCondition.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { addEntityPrivilege } from "../../store/slices/privileges";
import { RootState, useAppDispatch } from "../../store/store";
import { PrivilegeData, PrivilegedEntityType, Role } from "../../types/utility";
import { AddCustomComponentProps } from "../SingleViewVTable/SingleViewVTable";
import { SelectListModal } from "./SelectListModal";

export const ProjectsSelectModal: React.FC<AddCustomComponentProps> = ({
  modalVisible,
  setModalVisible,
  setErrors,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: projects } = useSelector((state: RootState) => state.projects);
  const { employeeProjects } = useSelector((state: RootState) => state.employee.subData);
  const singleData = useSelector((state: RootState) => state.employee.singleData);

  if (!singleData) return null;

  const allReviews = projects
    .filter(item => !employeeProjects.some(i => i.privilegedEntityId === item.id))
    .map(x => ({
      id: x.id,
      label: x.name,
      projectId: x.id,
    }));

  const onChange = async (selectedProjects: number[]) => {
    const promises = selectedProjects.map(projectId => {
      const entity = {
        isAssignedToOUBranch: false,
        orgUnitId: null,
        privilegedEntityId: projectId,
        role: Role.Recipient,
        userId: singleData.id,
      };
      return dispatch(
        addEntityPrivilege({ entity: entity as PrivilegeData, entityType: PrivilegedEntityType.Project, id: projectId })
      )
        .then(unwrapResult)
        .catch(error => ({ projectId, error }));
    });

    const results = await Promise.all(promises);
    const failedProjects = results.filter(result => result.error).map(result => result.projectId);

    if (failedProjects.length > 0) {
      const failedProjectNames = failedProjects.map(projectId => {
        const project = projects.find(p => p.id === projectId);
        return `Project #${projectId}${project ? ` (${project.name})` : ""}`;
      });
      setErrors({
        message: [`${t("AssignUserToProjectsError")}: ${failedProjectNames.join(", ")}`],
      });
    }
  };

  return (
    <SelectListModal
      onChange={selectedProjects => {
        onChange(selectedProjects);
      }}
      data={allReviews}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title="AssignUserToProjects"
    />
  );
};
