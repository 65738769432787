import { BaseEntityType } from "./entityBase";
import { ExplicitAdditionalProps } from "./utility";

export type CustomProperty = Record<string, unknown> & {
  id: number;
  name: string;
  sortOrder: number;
  type: CustomPropertyType;
  defaultValue?: string | null;
  actAsIdentifier: boolean;
  dictionaryId?: number | null;
};

export type CustomPropertySingleView = {
  id: number;
  name: string;
  sortOrder: number;
  type: CustomPropertyType;
  defaultValue?: string | null;
  actAsIdentifier: boolean;
  dictionaryId?: number | null;
  dictionaryName?: string | null;
};

export interface CustomPropertyState {
  data: CustomProperty[];
  isLoading: boolean;
  singleData: CustomPropertySingleView | null;
  subData: Record<string, never>;
  companyDictionaries: CompanyDictionary[];
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
}

export type CustomPropertyPage = BaseEntityType & {
  entityData: {
    primaryData: CustomPropertySingleView;
  };
  subData: Record<string, never>;
  listViewData: CustomProperty;
};

export const CustomPropertyType = {
  BOOL: "Bool",
  DICTIONARY: "Dictionary",
  NUMERIC: "Numeric",
  STRING: "String",
};

export type CustomPropertyType = typeof CustomPropertyType[keyof typeof CustomPropertyType];

export type CompanyDictionary = {
  id: number;
  organizationId: number;
  companyId: number;
  name: string;
  isActive: boolean;
  dictionaryValues: DictionaryValues[] | null;
};

export type DictionaryValues = {
  id: number;
  value: string;
  isActive: boolean;
};

export type CustomPropInfo = {
  propId: number;
  dictionaryId?: number;
  dictionaryValues?: DictionaryValues[] | null;
};
