import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchEntityColSelSettings, saveEntityColSelSettings } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { UserSettingsConstants } from "../../types/constants";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage, ColumnType } from "../../types/page";
import { ParamsKeys } from "../../types/utility";

type ReturnType = {
  selectedColumns: ColumnType<Record<string, unknown>>[];
  allColumns: ColumnType<Record<string, unknown>>[];
  isTableLoading: boolean;
  handleColumnSelection: (columns: ColumnType<Record<string, unknown>>[]) => Promise<void>;
};

const useColumn = (
  pageConfig: CategoryPage<BaseEntityType>,
  filteredData: Record<string, unknown>[],
  handleColumnSelectionVisibility?: (visible: boolean) => void
): ReturnType => {
  const dispatch = useAppDispatch();
  const jwt = useSelector((state: RootState) => state.user.jwt);

  // TODO: Maybe use the config file with defined key constants instead of concatenating the strings out of id+suffix here.
  const userSavedColumnKeys = useSelector((state: RootState) => state.user.settings.columns).find(
    entity => entity.key === pageConfig.id + UserSettingsConstants.COL_KEYS_SUFFIX
  );
  const [queryColumns, setQueryColumns] = useState("");

  const defaultCustomProperties = useSelector(pageConfig.customPropertiesSelector);
  const { table, generateDynamicColumns } = pageConfig.listView!;

  const [selectedColumns, setSelectedColumns] = useState<typeof table.columns>([]);
  const [allColumns, setAllColumns] = useState<typeof table.columns>([]);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const getColumnsFromQuery = () => {
    const urlSearchParams = new URLSearchParams(location.search);
    const columnsQuery = urlSearchParams.get(ParamsKeys.COLUMNS_SEARCH_PREFIX);

    if (columnsQuery) {
      const decodedColumns: string = jwtDecode(columnsQuery);
      setQueryColumns(decodedColumns);
    } else {
      setQueryColumns("");
    }
  };

  useEffect(() => {
    getColumnsFromQuery();
  }, [pageConfig.id]);

  useEffect(() => {
    if (!userSavedColumnKeys && jwt) {
      dispatch(fetchEntityColSelSettings({ keys: [pageConfig.id + UserSettingsConstants.COL_KEYS_SUFFIX] }));
    }
  }, [pageConfig.id, jwt]);

  useEffect(() => {
    const orderColumns = (columnKeys: string[] | string) => {
      return allColumns
        .filter(column => columnKeys.includes(column.id))
        .sort((a, b) => columnKeys.indexOf(a.id) - columnKeys.indexOf(b.id));
    };

    if (queryColumns) {
      return setSelectedColumns(orderColumns(queryColumns));
    }

    if (userSavedColumnKeys?.value) {
      return setSelectedColumns(orderColumns(userSavedColumnKeys.value));
    }

    return setSelectedColumns(allColumns.filter(c => table.defaultColumnKeys?.includes(c.id)));
  }, [userSavedColumnKeys, queryColumns, allColumns]);

  useEffect(() => {
    setAllColumns([...table.columns, ...generateDynamicColumns(defaultCustomProperties || [], filteredData)]);
  }, [table.columns, generateDynamicColumns, defaultCustomProperties]);

  const handleColumnSelection = async (columns: typeof table.columns) => {
    setIsTableLoading(true);
    handleColumnSelectionVisibility && handleColumnSelectionVisibility(false);

    const columnKeys = columns.map(c => c.id);

    await dispatch(
      saveEntityColSelSettings({
        method: userSavedColumnKeys ? "put" : "post",
        newValues: { key: pageConfig.id + UserSettingsConstants.COL_KEYS_SUFFIX, value: columnKeys },
      })
    );

    setQueryColumns("");
    setIsTableLoading(false);
  };

  return { selectedColumns, allColumns, isTableLoading, handleColumnSelection };
};

export default useColumn;
