import "./TableChart.less";

import { TableColumn } from "@cubejs-client/core";

import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import uniq from "lodash/uniq";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VList } from "virtual-table-ant-design";

import { CustomResultSet } from "../../../../../utils/dashboard/resultSet/resultSetFactory";
import useLocalizeDimensions, { formatDateLabel } from "../../../Localization/useLocalizeDimensions";
import { ChartContext } from "../../HBChart";

type TableProps = {
  data: CustomResultSet | undefined;
  drillDownMode?: boolean;
};

export default function TableChart({ data, drillDownMode }: TableProps) {
  const { t } = useTranslation();
  const { chart } = useContext(ChartContext);
  const { localizeDimension } = useLocalizeDimensions();
  const [dataSource, setDataSource] = useState(data?.tablePivot());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const manipulateColumns: (columns?: TableColumn[]) => ColumnsType<any> | undefined = columns => {
    return columns?.map(c => {
      const dimension = c.type === "time" ? `${c.key.split(".")[0]}.${c.key.split(".")[1]}` : c.key;
      const granularity = c.key.split(".")[2];
      return {
        ...c,
        title: () => (
          <Tooltip placement="topLeft" title={t(dimension, c.title)}>
            {t(dimension, c.title)}
          </Tooltip>
        ),
        ellipsis: true,
        filters: uniq(data?.tablePivot().map(v => v[c.key])).map(value => {
          const formattedLabel = c.type === "time" ? formatDateLabel(value as string, granularity) : value;
          return { text: `${formattedLabel}`, value: `${formattedLabel}` };
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFilter: (value: string | number | boolean, record: any) => {
          const formattedCell =
            c.type === "time" ? formatDateLabel(record[c.key] as string, granularity) : `${record[c.key]}`;
          return formattedCell.toLowerCase().includes((value as string).toLowerCase());
        },
        sorter: (a, b) => {
          if (c.type === "number") {
            if (b[c.key] === undefined) return 1;
            if (a[c.key] === undefined) return -1;
            return a[c.key] - b[c.key];
          } else if (c.type === "time") {
            const dateA = dayjs(a[c.key]);
            const dateB = dayjs(b[c.key]);
            if (dateA.isBefore(dateB)) return -1;
            else if (dateA.isAfter(dateB)) return 1;
            else return 0;
          } else {
            const cellA = `${a[c.key]}`;
            const cellB = `${b[c.key]}`;
            if (cellA < cellB) return -1;
            else if (cellA > cellB) return 1;
            else return 0;
          }
        },
        width: `${columns.length + 5 / 100}%`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (value: any) => {
          const formattedLabel =
            c.type === "time" ? formatDateLabel(value, granularity) : localizeDimension(c.key, value);
          return (
            <Tooltip placement="topLeft" title={formattedLabel}>
              {formattedLabel}
            </Tooltip>
          );
        },
      };
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [columns, setColumns] = useState<ColumnsType<any> | undefined>(manipulateColumns(data?.tableColumns()));

  useEffect(() => {
    setDataSource(data?.tablePivot());
  }, [data]);

  useEffect(() => {
    if (data) {
      const updatedColumns = drillDownMode
        ? data
            .tableColumns()
            .filter(tc => chart?.tableColumns?.find(ctc => ctc.key === tc.key && ctc.isChecked))
            .sort((a, b) => {
              const aIndex = chart?.tableColumns?.findIndex(tc => tc.key === a.key) || -100;
              const bIndex = chart?.tableColumns?.findIndex(tc => tc.key === b.key) || -100;
              return aIndex - bIndex;
            })
        : data.tableColumns();
      setColumns(manipulateColumns(updatedColumns));
    }
  }, [data]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Table<any>
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      tableLayout="fixed"
      components={VList({ height: "auto" })}
      scroll={{
        x: "max-content",
        y: "max-content",
      }}
    />
  );
}
