import { exportReviewResults } from "../store/slices/tasks";
import { DropDownAction } from "../types/page";
import { Survey } from "../types/survey";

export const ExportSelectedSurveysAction: DropDownAction<Survey> = {
  id: "exportSurvey",
  title: "AdvanceFormFillingReport",
  isDataOperation: true,
  action: (_, __, selectedEntities, t, dispatch) => {
    const surveyIds: number[] = selectedEntities?.map(s => s.id) || [];
    if (surveyIds.length == 0) {
      return null;
    }

    if (dispatch) {
      dispatch(exportReviewResults());

      return (t && (t("ReceiveEmailWithResults") as string)) ?? "";
    } else {
      return (t && (t("SomethingWentWrong") as string)) ?? "";
    }
  },
};
