import "./MobileCardTemplate.less";

import { Space } from "antd";

import { fetchEquipmentTypeById, fetchLocationById } from "../../../../pages/pageConfig/category/utilities";
import { Equipment } from "../../../../types/equipment";
import { SHORT_DATE_FORMAT_WITHOUT_TIME } from "../../../HBComponents/DatePicker/HBDatePicker";
import CardLabel from "../core/CardLabel/CardLabel";
import CardLabelWithIcon, { ICON_NAMES } from "../core/CardLabelWithIcon/CardLabelWithIcon";
import CardTitle from "../core/CardTitle/CardTitle";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import LabelWithDate from "../core/LabelWithDate/LabelWithDate";
import RowContainer from "../core/RowContainer/RowContainer";
import StatusMark from "../core/StatusMark/StatusMark";

type TProps = {
  entity: Equipment;
};

const EquipmentMobileCardTemplate: React.FC<TProps> = ({ entity }) => {
  const dateFormat = SHORT_DATE_FORMAT_WITHOUT_TIME;
  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.id && <CardTitle title={entity.id} category="equipment" id={entity.id} />}
        <Space direction="horizontal" size={10} wrap={true}>
          {entity.name && <CardLabel label={entity.name} />}
          {entity.status && <StatusMark statusLabel={entity.status} />}
        </Space>
      </ContainerWithDivider>
      <ContainerWithDivider>
        {entity.expiration && (
          <LabelWithDate labelTranslationKey="FieldExpiration" date={entity.expiration} dateFormat={dateFormat} />
        )}
        {entity.typeId && <CardLabel label={fetchEquipmentTypeById(entity.typeId)} />}
      </ContainerWithDivider>
      <RowContainer>
        {entity.locationId && (
          <CardLabelWithIcon label={fetchLocationById(entity.locationId)} iconName={ICON_NAMES.LOCATION} />
        )}
      </RowContainer>
    </CardWithSelect>
  );
};

export default EquipmentMobileCardTemplate;
