import "./ProfileMenu.less";

import { nanoid } from "@reduxjs/toolkit";

import { Button, Popover, Space } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as SettingsIcon } from "../../media/settings.svg";
import { ReactComponent as WebhookIcon } from "../../media/webhooks-icon.svg";
import { RootState } from "../../store/store";
import { UserRole } from "../../types/user";
import useRouter from "../../utils/hooks/useRouter";
import { getAlignment, handleRedirectToLegacy, MenuItemType, ProfileMenuItem } from "./utility";

export default function CompanySettings() {
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const role = useSelector((state: RootState) => state.user.settings.role);
  const { t } = useTranslation();
  const router = useRouter();

  const companySettingsMenuItems: ProfileMenuItem[] = [
    {
      title: "MenuCompanySettings",
      icon: <SettingsIcon />,
      type: MenuItemType.Action,
      action: () =>
        handleRedirectToLegacy({
          returnUrl: "companysettings",
          triggerLoader: false,
          newTab: true,
        }),
      allowedUserRoles: [UserRole.Admin, UserRole.SU],
    },
    {
      title: "Webhooks",
      icon: <WebhookIcon />,
      type: MenuItemType.Action,
      action: () => {
        router.push("/webhooks");
      },
      allowedUserRoles: [UserRole.SU, UserRole.Admin],
    },
  ];

  const getMenuItem: (item: ProfileMenuItem) => ReactNode = item => {
    return (
      <Button
        onClick={item.action ? item.action : null}
        disabled={item.disabled}
        type="text"
        className="menu-button"
        key={nanoid()}
      >
        <div id={`icon-container-${item.title}`}>{item.icon}</div>
        {t(item.title)}
      </Button>
    );
  };

  return (
    <Popover
      style={{ cursor: "pointer" }}
      // align={getAlignment(settings.direction)}
      placement={direction === "ltr" ? "rightBottom" : "leftTop"}
      content={
        <Space direction="vertical" className="profile-menu-item" wrap={true}>
          {companySettingsMenuItems
            .filter(item => !item.disabled && item.allowedUserRoles?.includes(role))
            .map(item => {
              if (item.type === MenuItemType.Menu) {
                return (
                  <Popover
                    align={getAlignment(direction)}
                    placement={direction === "ltr" ? "rightBottom" : "leftTop"}
                    key={item.title}
                    trigger="hover"
                    content={item.content}
                  >
                    {getMenuItem(item)}
                  </Popover>
                );
              } else {
                return getMenuItem(item);
              }
            })}
        </Space>
      }
    >
      <SettingsIcon />
    </Popover>
  );
}
