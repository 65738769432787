import { LeftOutlined } from "@ant-design/icons";

import { Button, Row } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useMobileSingleViewContext } from "../../../../components/SingleViewCardMobileFirst/context/useMobileSingleViewContext";
import SingleViewCardMobileFirst from "../../../../components/SingleViewCardMobileFirst/SingleViewCardMobileFirst";
import PageConfigurationContext from "../../../../context/pageContext";
import { BaseEntityType } from "../../../../types/entityBase";
import { CategoryPage } from "../../../../types/page";
import NotAllowedPage from "../../../ErrorPages/NotAllowedPage";
import NotFoundPage from "../../../ErrorPages/NotFoundPage";
import { Visibility } from "./CategorySingleMobileFirst";

const CategorySingleMobileFirstPageWrapper = ({ visibility }: { visibility: Visibility }): JSX.Element | null => {
  const { t } = useTranslation();
  const { summaryCard } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;

  const {
    currentData,
    isNewEntity,
    isEdited,
    todoListInParams,
    handleBack,
    handleBackToPageBeforeDetailPage,
  } = useMobileSingleViewContext();

  return (
    <>
      {!isEdited && (
        <div className="upper-section">
          <div className="back">
            <LeftOutlined onClick={handleBack} />
            <Button className="back-to-search-button" onClick={handleBackToPageBeforeDetailPage}>
              {t(`${todoListInParams ? "MobileBackToAgenda" : "MobileBackToSearch"}`)}
            </Button>
          </div>
          {(!isEdited && summaryCard.actionComponent && !isNewEntity && currentData && (
            <Row>{summaryCard.actionComponent(currentData)}</Row>
          )) ||
            null}
        </div>
      )}
      {(() => {
        switch (visibility) {
          case Visibility.LOADING:
          case Visibility.VISIBLE:
            return <SingleViewCardMobileFirst />;
          case Visibility.NOT_FOUND:
            return <NotFoundPage />;
          case Visibility.NOT_ALLOWED:
            return <NotAllowedPage />;
          default:
            return null;
        }
      })()}
    </>
  );
};

export default CategorySingleMobileFirstPageWrapper;
