import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

import { availableLanguages } from "../pages/ProfileMenu/LanguagePicker/LanguagePicker";
import { setAppointmentToOpen } from "../store/slices/common";
import { RootState } from "../store/store";
import { UserRole } from "../types/user";
import { checkIsMobile } from "./hooks/useIsMobile";

/**
 * @param functions - multiple (fun1, fun2, ...) data manipulation functions and applies them to the data provided;
 * @returns manipulated data based on all of the functions provided
 * */
const pipe = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>): ((a: R) => R) =>
  fns.reduce((prevFn, nextFn) => value => nextFn(prevFn(value)), fn1);

const findAndReplaceOrAdd = <TType>(arr: TType[], item: TType, key: keyof TType): TType[] => [
  ...arr.filter(a => !(a[key] === item[key])),
  item,
];

const initials = (value: string) => {
  const splitted = value.split(" ");
  return splitted.map(word => word[0]).join("");
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function spliceIntoChunks(arr: any[], chunkSize: number) {
  const res = [];
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk.join(" "));
  }
  return res;
}

function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return !isNaN(Number(str));
}

const numericOptions = () =>
  Array.from({ length: 101 }, (_, i) => ({
    id: i.toString(),
    label: i.toString(),
  }));

function isRoleAdminLevel(role: UserRole): boolean {
  const roles = [UserRole.SU, UserRole.TenantAdmin, UserRole.Admin];
  return roles.includes(role);
}

function isRoleReporter(role: UserRole): boolean {
  const roles = [UserRole.Reporter];
  return roles.includes(role);
}

const getAppointmentUrl = (baseUrl: string, appointmentId: number | string, isMobile?: boolean, actionId?: number) => {
  return `${baseUrl}/WebApplication/Index/${appointmentId}${isMobile ? `?actionId=${actionId}` : "?newpas=true"}`;
};

export const extractAndProcessAppointmentUrl = (
  url: string | undefined,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  isPasProEnabled?: boolean
) => {
  const isMobile = checkIsMobile();
  const numericIdMatch = url?.match(/\d+/)?.[0];

  if (!isMobile && isPasProEnabled && numericIdMatch !== undefined) {
    dispatch(setAppointmentToOpen({ appointmentId: Number(numericIdMatch) }));
  } else if (url) {
    window.location.href = url;
  }
};

// Constants for base paths and query parameters
export const BASE_ACTION_PATH = "/actions";
export const MOBILE_PATH = "/mobile";
export const NEW_MOBILE_PATH = "/new_mobile";
export const TODO_LIST_QUERY_PARAM = "todolist=true";

export const constructTaskUrl = ({
  taskId,
  isMobile,
  todoListInParams,
}: {
  taskId?: number | null;
  isMobile: boolean;
  todoListInParams?: boolean | null;
}) => {
  const basePath = `${BASE_ACTION_PATH}/${taskId}`;
  if (isMobile) {
    const todoListQueryParam = todoListInParams ? TODO_LIST_QUERY_PARAM : "";
    return `${basePath}${MOBILE_PATH}?${todoListQueryParam}`;
  }
  return basePath;
};

const getDeviceLang = () => {
  const matchingLanguages = availableLanguages.filter(lang =>
    lang.code.toLowerCase().includes((window.navigator.language || "").toLowerCase())
  );

  return matchingLanguages.length > 0 ? matchingLanguages[0].code : availableLanguages[0].code;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notSupportedFunc = (): any => {
  console.warn("This context is only available from the login page.");
};

function isTextTruncated(element: HTMLElement) {
  return element.scrollWidth > element.clientWidth;
}

function isMultilineTextTruncated(element: HTMLElement) {
  return element.scrollHeight > element.clientHeight;
}

export {
  pipe,
  findAndReplaceOrAdd,
  initials,
  capitalizeFirstLetter,
  spliceIntoChunks,
  isNumeric,
  numericOptions,
  isRoleAdminLevel,
  getAppointmentUrl,
  isRoleReporter,
  getDeviceLang,
  notSupportedFunc,
  isTextTruncated,
  isMultilineTextTruncated,
};
