import { ParamsKeys } from "../../types/utility";
import useRouter from "./useRouter";
import useWindowDimensions from "./useWindowDimensions";

const MOBILE_BREAKPOINT = 767;

export function isFlutterWebView() {
  return navigator.isFlutterWebView;
}

// We need a way to determine if the user is on a mobile device within a utility function.
// In this utility, we don't need to subscribe to resolution changes or respond to screen resizing.
export function checkIsMobile() {
  const { location, innerWidth: width } = window;
  const urlSearchParams = new URLSearchParams(location.search);
  const isMobile =
    width < MOBILE_BREAKPOINT ||
    Boolean(urlSearchParams.get(ParamsKeys.MOBILE)) ||
    location.pathname.includes(ParamsKeys.MOBILE);

  return isMobile;
}

const useIsMobile = (): boolean => {
  const { location } = useRouter();
  const { width } = useWindowDimensions();
  const urlSearchParams = new URLSearchParams(location.search);
  const isMobile =
    width < MOBILE_BREAKPOINT ||
    Boolean(urlSearchParams.get(ParamsKeys.MOBILE)) ||
    location.pathname.includes(ParamsKeys.MOBILE);

  return isMobile;
};

export default useIsMobile;
