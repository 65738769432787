import { PivotConfig, PivotRow } from "@cubejs-client/core";

import { CUBE_NO_VALUE, formatCategoryLabel } from "../../../components/Dashboard/Localization/useLocalizeDimensions";
import DefaultResultSetWrapper from "./DefaultResultSet";
import { CustomResultSet } from "./resultSetFactory";

class HeatmapResultSetWrapper extends DefaultResultSetWrapper implements CustomResultSet {
  pivot(pivotConfig?: PivotConfig): PivotRow[] {
    return this.tablePivot(pivotConfig).map(row => {
      return {
        xValues: [String(row.x), String(row.y)],
        yValuesArray: [],
      };
    });
  }

  tablePivot(pivotConfig?: PivotConfig): Array<{ [key: string]: string | number | boolean }> {
    return this.resultSet
      .tablePivot(pivotConfig)
      .map(row => this.extractRowWithValues(row, pivotConfig))
      .sort(this.sortByYAxis);
  }

  private extractRowWithValues(row: { [key: string]: string | number | boolean }, pivotConfig?: PivotConfig) {
    const [xColumn, yColumn, valueColumn] = this.tableColumns();
    const valueColumnKey = valueColumn?.key;
    return {
      v: row[valueColumnKey],
      x: formatCategoryLabel(this, row[xColumn.key] as string, pivotConfig),
      y: row[yColumn.key] || CUBE_NO_VALUE,
      ...row,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sortByYAxis(a: Record<string, any>, b: Record<string, any>): number {
    // do sort for y axis in case of y axis is numeric
    if (!isNaN(a.y) && !isNaN(b.y)) {
      return Number(a.y) - Number(b.y);
    }
    return String(a.y).localeCompare(String(b.y));
  }
}

export default HeatmapResultSetWrapper;
