import "./impersonate.less";

import { Button, Row } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { changeSuMode } from "../../../store/slices/user";
import { useAppDispatch } from "../../../store/store";
import { SuMode } from "../../../types/user";
import { useLoginContext } from "../context/useLoginContext";

interface TProps {
  setIsImpersontaionFormOpen: (value: boolean) => void;
}

const SuChooseModeForm: React.FunctionComponent<TProps> = ({ setIsImpersontaionFormOpen }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { setCustomTitle } = useLoginContext();

  useEffect(() => {
    setCustomTitle("ImpersonateUserChooseHowProceed");
  }, []);

  const onLoginAsSuClick = () => {
    dispatch(changeSuMode(SuMode.SU));
  };

  const onImpersonateUserClick = () => {
    setIsImpersontaionFormOpen(true);
  };

  return (
    <Row justify="center" gutter={[0, 24]}>
      <Button
        type="primary"
        className="button-submit"
        htmlType="submit"
        data-testid="impersonate-button"
        onClick={onImpersonateUserClick}
      >
        {t("ImpersonateUser")}
      </Button>
      <Button
        type="default"
        className="button-submit"
        htmlType="submit"
        data-testid="continue-as-su-button"
        onClick={onLoginAsSuClick}
      >
        {t("ImpersonateUserContinueAsSu")}
      </Button>
    </Row>
  );
};

export default SuChooseModeForm;
