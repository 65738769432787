import { Badge, Tag, Typography } from "antd";
import i18next from "i18next";
import uniqBy from "lodash/uniqBy";
import React from "react";

import { AccumulatorType } from "../../../components/SingleViewTabs/types";
import { ReactComponent as HighPriorityIcon } from "../../../media/priorityIcons/high.svg";
import { ReactComponent as HighestPriorityIcon } from "../../../media/priorityIcons/highest.svg";
import { ReactComponent as LowPriorityIcon } from "../../../media/priorityIcons/low.svg";
import { ReactComponent as LowestPriorityIcon } from "../../../media/priorityIcons/lowest.svg";
import { ReactComponent as MediumPriorityIcon } from "../../../media/priorityIcons/medium.svg";
import store from "../../../store/store";
import { GroupViewCertification } from "../../../types/certification";
import { CustomPropertyType } from "../../../types/customProperty";
import { BasicEmployee } from "../../../types/employee";
import { InspectionType } from "../../../types/inspectionType";
import { CellTypes, ColumnType, InputTypes, TOption } from "../../../types/page";
import { Project } from "../../../types/projects";
import { AuthType, UserRole } from "../../../types/user";
import {
  AdditionalProps,
  AppointmentStatus,
  CustomProperties,
  ExplicitAdditionalProps,
  OpenClosedStatus,
  Role,
  Status,
  TaskPriority,
  TasksPageStatus,
  TodoListCardStatus,
  TwoFactorType,
} from "../../../types/utility";
import { authTypeOptions, boolOptions, twoFactorTypeOptions } from "./common";

// Note: We use this utility function to get only specific columns out. Useful when defining the columns for the entity's searchbox as they usually are a subset of the entity's table.
export function pickSpecificColumns<T>(allColumns: ColumnType<T>[], ...args: string[]): ColumnType<T>[] {
  return allColumns.filter(column => {
    if (args.includes(column.id as string)) {
      return true;
    }
    return false;
  });
}

// Note: We use this utility function to remove the filter out of all the columns. Useful when a tab and a list view use the same data structure but only one of them needs filters (e.g. in the Locations entity)
export function removeColumnFilters<T>(allColumns: ColumnType<T>[]): ColumnType<T>[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return allColumns.map(({ filterType, ...rest }) => ({ ...rest, optionsSelector: () => null }));
}

// Note: We use this utility function to prepare the needed dynamic columns based on the customPropertyValues of each entity
export function prepareDynamicColumns<T>(
  customProps: ExplicitAdditionalProps[],
  data: T & { customPropertyValues?: AdditionalProps[] }[]
): ColumnType<T & { customPropertyValues?: AdditionalProps[] }>[] {
  if (!data) {
    return [];
  }

  return customProps.map(prop => {
    const filteredData = data.filter(record =>
      record.customPropertyValues?.filter(customProp => customProp.name === prop.name)
    );

    return {
      id: prop.name as keyof T,
      label: prop.name,
      renderValue: value => {
        const matchedCustomProperty = value.customPropertyValues?.find(customProp => customProp.propertyId === prop.id);
        return matchedCustomProperty?.type === CustomPropertyType.DICTIONARY
          ? fetchDictionaryValueByDictionaryIdAndValueId(
              matchedCustomProperty?.dictionaryId,
              matchedCustomProperty?.value
            ) || null
          : matchedCustomProperty?.value || null;
      },
      filterType: InputTypes.MULTI_SELECT,
      optionsSelector: () => {
        const allCustomPropValues = filteredData.map(record => {
          const matchingRecord = record.customPropertyValues?.find(customProp => customProp.propertyId === prop.id);
          return {
            id: matchingRecord?.value || "",
            label:
              matchingRecord?.type === CustomPropertyType.DICTIONARY
                ? fetchDictionaryValueByDictionaryIdAndValueId(matchingRecord?.dictionaryId, matchingRecord?.value) ||
                  ""
                : matchingRecord?.value || "",
          };
        });
        return uniqBy(allCustomPropValues, "label");
      },
      sortable: false,
      width: 100,
    };
  });
}
//TODO: remove after merging all paginated screens
export function prepareDynamicColumnsPaginated<T>(
  customProps: ExplicitAdditionalProps[],
  data: T & { customProperties?: CustomProperties[] }[]
): ColumnType<T & { customProperties?: CustomProperties[] }>[] {
  if (!data) {
    return [];
  }

  return customProps.map(prop => {
    return {
      id: prop.name as keyof T,
      label: prop.name,
      renderValue: value => {
        const customProperty = value.customProperties?.find(customProp => customProp.propertyId === prop.id);
        const matchedCustomProperty = customProps?.find(x => x.id === customProperty?.propertyId);

        return matchedCustomProperty?.type === CustomPropertyType.DICTIONARY
          ? fetchDictionaryValueByDictionaryIdAndValueId(
              matchedCustomProperty?.dictionaryId,
              customProperty?.propertyValue
            ) || null
          : customProperty?.propertyValue || null;
      },
      optionsSelector: () => null,
      sortable: false,
      width: 100,
      filterType: prop.type,
      cutomPropInfo: {
        propId: prop.id,
        dictionaryId: prop.dictionaryId,
      },
      asyncFetchFilterOptionsEntityType: "CustomPropertyValue",
    };
  });
}

export const renderOptionIcon = (status: Status): React.ReactElement | null => {
  switch (status) {
    case Status.Active:
      return <Badge color="#02D924" />;

    case Status.InActive:
      return <Badge color="#B7B7B7" />;

    case Status.Broken:
      return <Badge color="#D9042B" />;

    default:
      return null;
  }
};

// Note: We use this utility function to render the needed status + icon in a table column
export const renderTableStatus = (status: Status): React.ReactNode | null => {
  switch (status) {
    case Status.Active:
      return (
        <>
          <Badge color="#02D924" />
          <Typography.Text>{localizeText(Status[status]) || localizeText(Status.Broken)}</Typography.Text>
        </>
      );

    case Status.InActive:
    case Status.Inactive:
      return (
        <>
          <Badge color="#B7B7B7" />
          <Typography.Text>{localizeText(Status.InActive) || localizeText(Status.Broken)}</Typography.Text>
        </>
      );

    case Status.Broken:
      return (
        <Tag
          style={{
            backgroundColor: "transparent",
            border: "1px solid #D9042B",
            borderRadius: "50px",
            padding: "0.2rem 1rem",
            display: "flex",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Badge color="#D9042B" />
          <Typography.Text style={{ color: "#D9042B" }}>
            {localizeText(Status[status]) || localizeText(Status.Broken)}
          </Typography.Text>
        </Tag>
      );

    default:
      return null;
  }
};

export const renderEmployeeStatus = (status: Status): React.ReactNode | null => {
  switch (status) {
    case Status.Active:
      return (
        <>
          <Badge color="#02D924" />
          <Typography.Text>{localizeText(Status.Active)}</Typography.Text>
        </>
      );

    case Status.Inactive:
      return (
        <>
          <Badge color="#B7B7B7" />
          <Typography.Text>{localizeText(Status.InActive)}</Typography.Text>
        </>
      );

    default:
      return null;
  }
};
export const renderAppointmentStatus = (status: AppointmentStatus): React.ReactNode | null => {
  switch (status) {
    case AppointmentStatus.Completed:
      return (
        <>
          <Badge color="#02D924" />
          <Typography.Text>{localizeText(`AppointmentStatus_${AppointmentStatus[status]}`)}</Typography.Text>
        </>
      );

    case AppointmentStatus.Draft:
      return (
        <>
          <Badge color="#B7B7B7" />
          <Typography.Text>{localizeText(`AppointmentStatus_${AppointmentStatus[status]}`)}</Typography.Text>
        </>
      );

    case AppointmentStatus.Sent:
      return (
        <>
          <Badge color="#FFFF00" />
          <Typography.Text>{localizeText(`AppointmentStatus_${AppointmentStatus[status]}`)}</Typography.Text>
        </>
      );

    case AppointmentStatus.Proccessing:
      return (
        <>
          <Badge color="#00ecec" />
          <Typography.Text>{localizeText(`AppointmentStatus_${AppointmentStatus[status]}`)}</Typography.Text>
        </>
      );
    case AppointmentStatus.Canceled:
      return (
        <>
          <Badge color="#E54841" />
          <Typography.Text>{localizeText(`AppointmentStatus_${AppointmentStatus[status]}`)}</Typography.Text>
        </>
      );

    case AppointmentStatus.PartiallyCompleted:
      return (
        <>
          <Badge color="#90EE90" />
          <Typography.Text>{localizeText(`AppointmentStatus_${AppointmentStatus[status]}`)}</Typography.Text>
        </>
      );
    default:
      return null;
  }
};

export const renderOpenCloseStatus = (status: OpenClosedStatus): React.ReactNode | null => {
  const taskStatusLocazationKey = `TaskStatus_${OpenClosedStatus[status]}`;

  switch (status) {
    case OpenClosedStatus.Opened:
      return (
        <>
          <Badge color="#02D924" />
          <Typography.Text>{localizeText(taskStatusLocazationKey)}</Typography.Text>
        </>
      );
    case OpenClosedStatus.Closed:
      return (
        <>
          <Badge color="#B7B7B7" />
          <Typography.Text>{localizeText(taskStatusLocazationKey)}</Typography.Text>
        </>
      );

    case OpenClosedStatus.Canceled:
      return (
        <>
          <Badge color="#E54841" />
          <Typography.Text>{localizeText(taskStatusLocazationKey)}</Typography.Text>
        </>
      );

    default:
      return null;
  }
};

type HttpStatusInfo = {
  message: string;
  color: string;
};

//map of http status codes and their corresponding messages and colors
const httpStatusInfo: Record<number, HttpStatusInfo> = {
  200: { message: "OK", color: "#02D924" },
  201: { message: "Created", color: "#02D924" },
  204: { message: "No Content", color: "#02D924" },
  400: { message: "Bad Request", color: "#E54841" },
  401: { message: "Unauthorized", color: "#E54841" },
  403: { message: "Forbidden", color: "#E54841" },
  404: { message: "Not Found", color: "#E54841" },
  500: { message: "Internal Server Error", color: "#E54841" },
  502: { message: "Bad Gateway", color: "#E54841" },
  503: { message: "Service Unavailable", color: "#E54841" },
};

export const renderHttpStatus = (status: number): React.ReactNode | null => {
  const statusInfo = httpStatusInfo[status];

  if (!statusInfo) {
    return null;
  }

  return (
    <>
      <Badge style={{ marginLeft: "1rem", marginRight: "1rem" }} color={statusInfo.color} />
      <Typography.Text>{`${status} ${statusInfo.message}`}</Typography.Text>
    </>
  );
};

export const renderTasksPageStatus = (status: TasksPageStatus): React.ReactNode | null => {
  switch (status) {
    case TasksPageStatus.Assigned:
      return (
        <>
          <Badge color="#02D924" />
          <Typography.Text>{localizeText(TasksPageStatus[status])}</Typography.Text>
        </>
      );

    case TasksPageStatus.Unassigned:
      return (
        <>
          <Badge color="#B7B7B7" />
          <Typography.Text>{localizeText(TasksPageStatus.Unassigned)}</Typography.Text>
        </>
      );

    case TasksPageStatus.PastDueDate:
      return (
        <>
          <Badge color="#D9042B" />
          <Typography.Text>{localizeText(TasksPageStatus.PastDueDate)}</Typography.Text>
        </>
      );

    default:
      return null;
  }
};
export const getTaskPriorityByNumber = (priority: number): TaskPriority | null => {
  switch (priority) {
    case 40:
      return TaskPriority.Highest;
    case 30:
      return TaskPriority.High;
    case 20:
      return TaskPriority.Medium;
    case 10:
      return TaskPriority.Medium;
    case 0:
      return TaskPriority.Lowest;
    default:
      return null;
  }
};

export const renderTaskPriority = (priority: TaskPriority): React.ReactElement | null => {
  switch (priority) {
    case TaskPriority.Highest:
      return <HighestPriorityIcon />;
    case TaskPriority.High:
      return <HighPriorityIcon />;
    case TaskPriority.Medium:
      return <MediumPriorityIcon />;
    case TaskPriority.Low:
      return <LowPriorityIcon />;
    case TaskPriority.Lowest:
      return <LowestPriorityIcon />;

    default:
      return null;
  }
};

export const renderActionStatusIcon = (status: OpenClosedStatus): React.ReactNode | null => {
  switch (status) {
    case OpenClosedStatus.Opened:
      return <Badge color="#02D924" />;

    case OpenClosedStatus.Closed:
      return <Badge color="#B7B7B7" />;

    case OpenClosedStatus.Canceled:
      return <Badge color="#E54841" />;

    default:
      return null;
  }
};

export const renderCardStatus = (status: TodoListCardStatus): React.ReactNode | null => {
  switch (status) {
    case TodoListCardStatus.Close:
      return <Badge color="#02D924" />;

    case TodoListCardStatus.Late:
      return <Badge color="#D9042B" />;

    case TodoListCardStatus.AwaitForApproval:
      return <Badge color="#F5731B" />;

    case TodoListCardStatus.SentForApproval:
      return <Badge color="#F5731B" />;
    case TodoListCardStatus.Proccessing:
      return <Badge color="#F5731B" />;
    default:
      return null;
  }
};

export const fetchAuthTypes = (): TOption[] => {
  return store.getState().user.companySettings.authType == AuthType.SSO
    ? authTypeOptions
    : authTypeOptions.filter(a => a.id != AuthType.SSO);
};

export const fetchTwoFactorTypes = (): TOption[] => {
  return store.getState().user.companySettings.twoFactorType == TwoFactorType.None
    ? twoFactorTypeOptions
    : twoFactorTypeOptions.filter(a => a.id != TwoFactorType.None);
};

export const renderBoolStatus = (bool: boolean) => {
  const intBool = (!!bool as unknown) as number;
  return boolOptions.find(o => o.id == intBool)
    ? localizeText(boolOptions.filter(o => o.id == intBool)[0].label)
    : null;
};

export const newRenderBoolStatus = (bool: boolean) => {
  const intBool = bool ? 1 : 0;
  return boolOptions.find(o => o.id == intBool)
    ? localizeText(boolOptions.filter(o => o.id == intBool)[0].label)
    : null;
};

export const fetchCompanyAuthType = (): AuthType | null => {
  return store.getState().user.companySettings.authType;
};

export const fetchCompanyTwoFactorType = (): TwoFactorType | null => {
  return store.getState().user.companySettings.twoFactorType;
};

export const fetchOrgUnitById = (id: number | null | undefined): string | null => {
  return (
    (store.getState().orgUnit.data || []).find(ou => ou.id === id)?.name ||
    (store.getState().orgUnit.basicData || []).find(ou => ou.id === id)?.name ||
    null
  );
};

export const fetchEqTypeById = (id: number | null): string | null => {
  return (store.getState().equipmentType.data || []).find(eqType => eqType.id === id)?.name || null;
};

export const fetchCertificateById = (id: number): GroupViewCertification | null => {
  return (
    store.getState().certification.groupViewData?.find(cert => cert.id === id) ||
    (store.getState().certification.basicData?.find(cert => cert.id === id) as GroupViewCertification) ||
    null
  );
};

export const fetchSupplierByCertificateId = (id: number): string | null => {
  return (store.getState().certification.groupViewData || []).find(cert => cert.id === id)?.supplier || null;
};

export const fetchLocationById = (id: number | null): string | null => {
  if (!id) return store.getState().location.data.find(loc => !loc.parentId)?.name || null;
  return (
    store.getState().location.data.find(loc => loc.id === id)?.name ||
    store.getState().location.basicData.find(loc => loc.id === id)?.name ||
    null
  );
};

export const fetchLocationTypeById = (id?: number | null): string | null => {
  if (!id) return null;
  return store.getState().locationType.data.find(loc => loc.id === id)?.name || null;
};

export const fetchCurrentLocationId = (): number | null => {
  return store.getState().equipment.singleData?.locationId || null;
};

export const fetchCurrentTrainingHasParticipants = (): boolean => {
  return !!store.getState().training.subData.participants.length;
};

export const fetchEmployeeById = (id: number | null | undefined): string | null => {
  if (!id) return null;

  return (
    store.getState().employee.data?.find(e => e.id === id)?.displayName ||
    store.getState().employee.basicData?.find(e => e.id === id)?.displayName ||
    null
  );
};

export const fetchOUById = (id: number | null | undefined): string | null => {
  if (!id) return null;

  return (
    store.getState().orgUnit.data?.find(e => e.id === id)?.name ||
    store.getState().orgUnit.basicData?.find(e => e.id === id)?.name ||
    null
  );
};

export const fetchInspectionTypeById = (id: number | null | undefined): InspectionType | null | undefined => {
  if (!id) return null;

  return store.getState().inspectionType.data?.find(e => e.id === id);
};

export const fetchEquipmentTypeById = (id: number | null | undefined): string | null => {
  if (!id) return null;

  return store.getState().equipmentType.data?.find(e => e.id === id)?.name || null;
};

export const fetchDictionaryValueByDictionaryIdAndValueId = (
  dictionaryId?: number | null | undefined,
  valueId?: number | null | undefined | string
): string | null => {
  if (dictionaryId && valueId) {
    const dictionaryValue = store
      .getState()
      .customProperty.companyDictionaries?.find(x => x.id === dictionaryId)
      ?.dictionaryValues?.find(x => x.id === Number(valueId))?.value;
    return dictionaryValue || null;
  }
  return null;
};

export const companyHasCertificates = (): boolean => {
  if (store.getState().certification.isLoading) {
    return true;
  }
  return store.getState().certification.basicData.length > 0;
};
export class CustomError extends Error {
  constructor(message = "Something went wrong. Please refresh and try again.") {
    super(message);
    this.message = message;
  }
}

export const isDevEnvironment = (): boolean => {
  return process.env.REACT_APP_ENVIRONMENT === "development" ? true : false;
};

export const addEmptyValueForFiltering = (arr: TOption[]): TOption[] | null => {
  return [{ id: getEmptyValue().key, label: getEmptyValue().localizedValue }, ...arr];
};

export const fetchWebhookEventTypeById = (id: number | null | undefined): string | null => {
  if (!id) return null;

  return localizeText(store.getState().webhooks.eventTypes?.find(e => e.eventTypeId === id)?.eventTypeName || "");
};

export const fetchWebhookEventSubTypeById = (
  eventTypeId: number | null | undefined,
  eventSubTypeId: number | null | undefined
): string | null => {
  if (!eventTypeId || !eventSubTypeId) return null;

  return localizeText(
    store
      .getState()
      .webhooks.eventTypes?.find(e => e.eventTypeId === eventTypeId)
      ?.eventSubTypes.find(x => x.eventSubTypeId === eventSubTypeId)?.eventSubTypeName || ""
  );
};

export type TreeData = {
  key: string;
  value: string | number;
  label: string;
  parentId: number | null;
  children: TreeData[];
  disabled?: boolean;
};

export type Localization = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue: any;
  localizedValue: string;
};

type TConstructTableTreeProps = {
  data: Record<string, unknown>[];
  itemValueKey: string;
  itemLabelKey: string;
  currentEntityValue?: string;
  isNullableField?: boolean;
  disableInactiveItems?: boolean;
};

export const constructTableTree = (
  {
    data,
    itemValueKey,
    itemLabelKey,
    currentEntityValue,
    isNullableField,
    disableInactiveItems,
  }: TConstructTableTreeProps,
  disableChildren?: boolean
): TreeData[] => {
  let treeData: TreeData[] = [];
  //We create a new array because we need the additional properties from the DataNode type (key, title, icon, etc.)
  if (data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const locationTreeData: TreeData[] = data.map((item: any) => {
      return {
        key: item[itemValueKey].toString(),
        value: item[itemValueKey],
        label: item[itemLabelKey],
        children: [],
        parentId: item.parentId && data.find(f => f.id === item.parentId) ? item.parentId : null,
        disabled:
          (item[itemValueKey].toString() === currentEntityValue && disableChildren) ||
          (disableInactiveItems && (item.status === Status.InActive || item.status === Status.Inactive))
            ? true
            : false,
      };
    });

    const idMapping = data.reduce<AccumulatorType>((acc, location, i) => {
      const id = location.id as number;
      acc[id] = i;
      return acc;
    }, {});

    locationTreeData.forEach(leaf => {
      if (leaf.parentId === null) {
        treeData = [...treeData, leaf];
        return;
      }

      const parentEl = locationTreeData[idMapping[leaf.parentId]];

      if (parentEl) {
        parentEl.children = [...(parentEl.children || []), leaf];
        // Note: We need to disable the children of the current node only when we have a parentId (e.g. Changing 'Parent Location' under the 'location' entity)
        if (parentEl.disabled && disableChildren) {
          if (parentEl.children.length) {
            parentEl.children.forEach(el => (el.disabled = true));
          }
        }
      }
    });
  }
  if (isNullableField) {
    treeData.unshift({
      key: getEmptyValue().key,
      value: getEmptyValue().key,
      label: getEmptyValue().localizedValue,
      children: [],
      parentId: null,
      disabled: false,
    });
  }

  return treeData;
};

export const userIsCetificateOwner = (): boolean => {
  const userCertificates = store.getState().certification.subData.userCertifications?.length;

  return !!userCertificates;
};
export const isAdmin = (): boolean => {
  const userSettings = store.getState().user.settings;

  return !!(
    userSettings.isSysAdmin ||
    userSettings.role === UserRole.SU ||
    userSettings.role === UserRole.Admin ||
    userSettings.role === UserRole.TenantAdmin
  );
};

export const fetchActiveUsers = (): BasicEmployee[] => {
  const users = store.getState().employee.basicData?.filter(e => e.isUser && e.status === Status.Active);
  return users || [];
};

export const getCustomPropertiesWithValues = (
  customProperties?: ExplicitAdditionalProps[],
  customPropertyValues?: AdditionalProps[]
): AdditionalProps[] => {
  if (!customProperties) {
    return [];
  }
  const inputProps = [...customProperties];

  return inputProps
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map(cp => ({
      name: cp.name,
      type: cp.type,
      propertyId: cp.id,
      value: customPropertyValues?.find(v => v.propertyId === cp.id)?.value,
      dictionaryId: cp.dictionaryId,
      actAsIdentifier: cp.actAsIdentifier,
    }));
};

export const localizeText = (initialValue: string) => {
  const t = i18next.t.bind(i18next);
  return t(initialValue, initialValue);
};

export const getEmptyValue = () => {
  const t = i18next.t.bind(i18next);
  return {
    key: "[{No%%%Value}]",
    localizedValue: t("NoValue"),
  };
};
export const getAccoutableColumnCellType = (role: Role, orgUnitId?: string | number | null): CellTypes => {
  return orgUnitId && role === Role.Recipient ? "dropdown" : "text";
};

export const fetchProjectById = (id: number | null | undefined): Project | null => {
  if (!id) return null;
  return store.getState().projects.data.find(project => project.id === id) || null;
};
