import "./CardWithSelect.less";

import { Card, Checkbox, Space } from "antd";
import clsx from "clsx";

import { useMobileSingleViewTabContext } from "../../../context/useMobileSingleViewTabContext";

type TProps = {
  entity: Record<string, unknown>;
};

const CardWithSelect: React.FC<TProps> = ({ children, entity }) => {
  const { onEntitySelect, isSelected } = useMobileSingleViewTabContext();

  return (
    <Card className={clsx("mobile-card-body", { checked: isSelected(entity) })} hoverable>
      <Space direction="horizontal" size={10} align={"start"} className="mobile-card-space">
        <Checkbox onChange={() => onEntitySelect(entity)}></Checkbox>
        <Space className="main-content" direction="vertical" size={6}>
          {children}
        </Space>
      </Space>
    </Card>
  );
};

export default CardWithSelect;
