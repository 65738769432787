import { createAsyncThunk, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import dayjs from "dayjs";

import {
  EqLocTypeInspTypeRelationStatus,
  EqTypeInspTypeRelation,
  EquipmentType,
  EquipmentTypeSingleView,
  EquipmentTypeState,
} from "../../types/equipmentType";
import { InspectionType } from "../../types/inspectionType";
import { ExplicitAdditionalProps } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { shouldUpdate } from "./common";

export const initialState: EquipmentTypeState = {
  data: [],
  defaultCustomProperties: [],
  subData: {
    eqTypeInspTypeRelation: [],
  },
  isLoading: false,
  singleData: null,
  error: null,
  lastUpdated: dayjs().toISOString(),
};

const newEquipmentType: EquipmentType = {
  id: 0,
  name: "",
  createTaskOnBrokenStatus: false,
  createTaskOnInactiveStatus: false,
  iconType: "10",
};

export const fetchEquipmentTypes = createAsyncThunk<
  { primaryData: EquipmentType[]; defaultCustomProperties: ExplicitAdditionalProps[] },
  boolean | undefined,
  { state: RootState }
>(
  "@@EQUIPMENT_TYPE/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const equipmentTypesResponse = await hbApi.get<EquipmentType[]>("/EquipmentType", hbApiOptions(user.jwt));

    return {
      primaryData: equipmentTypesResponse.data,
      defaultCustomProperties: [],
    };
  },
  {
    condition: (forceUpdate, { getState }) => {
      const { equipmentType } = getState();
      return shouldUpdate(equipmentType.lastUpdated, equipmentType.isLoading, forceUpdate);
    },
  }
);

export const fetchSingleEquipmentType = createAsyncThunk<
  {
    singleData: EquipmentTypeSingleView;
    subData: { eqTypeInspTypeRelation: EqTypeInspTypeRelation[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>(
  "@@EQUIPMENT_TYPE/FETCH_SINGLE",
  async (id, { getState }) => {
    const { user } = getState();

    const equipmentType = await hbApi.get<EquipmentTypeSingleView>(`/EquipmentType/${id}`, hbApiOptions(user.jwt));
    const eqTypeInspTypeRelations = await hbApi.get<EqTypeInspTypeRelation[]>(
      `/EquipmentTypeInspectionType/${id}`,
      hbApiOptions(user.jwt)
    );

    return {
      singleData: equipmentType.data,
      subData: {
        eqTypeInspTypeRelation: eqTypeInspTypeRelations.data,
      },
      defaultCustomProperties: [],
    };
  },
  {
    condition: (_, { getState }) => {
      const { equipmentType, user } = getState();
      return !equipmentType.isLoading && !!user.jwt;
    },
  }
);

export const addEquipmentType = createAsyncThunk<EquipmentType, { entity: EquipmentType }, { state: RootState }>(
  "@@EQUIPMENT_TYPE/ADD",
  async ({ entity }, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const response = await hbApi.post<EquipmentType>("/EquipmentType", entity, hbApiOptions(user.jwt));
      return response.data;
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { equipmentType, user } = getState();
      return !equipmentType.isLoading && !!user.jwt;
    },
  }
);

export const updateEquipmentType = createAsyncThunk<EquipmentType, EquipmentType, { state: RootState }>(
  "@@EQUIPMENT_TYPE/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();
      const response = await hbApi.put<EquipmentType>(`/EquipmentType/${entity.id}`, entity, hbApiOptions(user.jwt));
      return response.data;
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { equipmentType, user } = getState();
      return !equipmentType.isLoading && !!user.jwt;
    },
  }
);

export const addInspectionTypeEquipmentTypeRelation = createAsyncThunk<
  { res: EqTypeInspTypeRelation; initialId: number | string },
  EqTypeInspTypeRelation,
  { state: RootState }
>("@@EQUIPMENT_TYPE/ADD_INT_EQT_RELATION", async (req, { getState }) => {
  const { user } = getState();
  const body = {
    equipmentTypeId: req.equipmentTypeId,
    inspectionTypeId: req.inspectionTypeId,
  };

  const result = await hbApi.post<EqTypeInspTypeRelation>("/EquipmentTypeInspectionType", body, hbApiOptions(user.jwt));
  return { res: result.data, initialId: req.id };
});

export const deleteInspectionTypeEquipmentTypeRelation = createAsyncThunk<
  EqTypeInspTypeRelation[],
  EqTypeInspTypeRelation[],
  { state: RootState }
>("@@EQUIPMENT_TYPE/DELETE_INT_EQT_RELATION", async (req, { getState }) => {
  const { user } = getState();

  for (const rel of req) {
    await hbApi.delete("/EquipmentTypeInspectionType", hbApiOptions(user.jwt, rel));
  }
  return req;
});

export const updateEqTypeInspTypeRelationStatus = createAsyncThunk<
  EqTypeInspTypeRelation,
  EqTypeInspTypeRelation,
  { state: RootState }
>("@@EQUIPMENT_TYPE/UPDATE_INS_EQT_RELATION_STATUS", async (req, { getState }) => {
  const { user } = getState();

  await hbApi.put<EqTypeInspTypeRelation>(`/EquipmentTypeInspectionType/${req.id}`, req, hbApiOptions(user.jwt));

  return req;
});

const slice = createSlice({
  name: "equipmentType",
  initialState,
  reducers: {
    createEquipmentTypeTemplate: state => {
      state.singleData = newEquipmentType;
    },
    clearEquipmentTypeError: state => {
      state.error = null;
    },
    createNewEqTypeInspTypeRelationEntryTemplate: state => {
      state.subData.eqTypeInspTypeRelation.unshift({
        id: nanoid(),
        equipmentTypeId: null,
        inspectionTypeId: null,
        staging: true,
        status: EqLocTypeInspTypeRelationStatus.Active,
      });
    },
    fillNewEqTypeInspTypeRelationEntryTemplate: {
      prepare: (payload: { row: EqTypeInspTypeRelation; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: (
        state,
        action: PayloadAction<{ row: EqTypeInspTypeRelation; targetEntity: Record<string, unknown> }>
      ) => {
        const stagedRecordIndex = state.subData.eqTypeInspTypeRelation.findIndex(r => r.id === action.payload.row.id);
        const castTargetEntity = action.payload.targetEntity as InspectionType;

        state.subData.eqTypeInspTypeRelation[stagedRecordIndex] = {
          ...state.subData.eqTypeInspTypeRelation[stagedRecordIndex],
          equipmentTypeId: state.singleData?.id ?? 0,
          inspectionTypeId: castTargetEntity.id,
        };
      },
    },
    deleteNewEqTypeInspTypeRelationEntryTemplate: {
      prepare: payload => ({ payload }),
      reducer: (state, action: PayloadAction<EqTypeInspTypeRelation>) => {
        if (typeof action.payload.id === "string") {
          state.subData.eqTypeInspTypeRelation = state.subData.eqTypeInspTypeRelation.filter(
            r => r.id !== action.payload.id
          );
        }
      },
    },
    changeLocalStatus: {
      prepare: (payload: { entity: EqTypeInspTypeRelation; status: EqLocTypeInspTypeRelationStatus }) => ({ payload }),
      reducer: (
        state,
        action: PayloadAction<{ entity: EqTypeInspTypeRelation; status: EqLocTypeInspTypeRelationStatus }>
      ) => {
        if (state.subData && state.subData.eqTypeInspTypeRelation) {
          const index = state.subData.eqTypeInspTypeRelation.findIndex(x => x.id === action.payload.entity.id);

          if (index >= 0) {
            state.subData.eqTypeInspTypeRelation[index].status = action.payload.status;
            state.subData.eqTypeInspTypeRelation[index].staging = true;
          }
        }
      },
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEquipmentTypes.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleEquipmentType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addEquipmentType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateEquipmentType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addInspectionTypeEquipmentTypeRelation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateEqTypeInspTypeRelationStatus.pending, state => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(fetchEquipmentTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleEquipmentType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addEquipmentType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateEqTypeInspTypeRelationStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateEquipmentType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addInspectionTypeEquipmentTypeRelation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addInspectionTypeEquipmentTypeRelation.fulfilled, (state, action) => {
        state.subData.eqTypeInspTypeRelation = state.subData.eqTypeInspTypeRelation.filter(
          r => r.id !== action.payload.initialId
        );
        state.subData.eqTypeInspTypeRelation.unshift({
          ...action.payload.res,
          staging: false,
        });
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEqTypeInspTypeRelationStatus.fulfilled, (state, action) => {
        state.subData.eqTypeInspTypeRelation = state.subData.eqTypeInspTypeRelation.map(item =>
          item.id === action.payload.id ? { ...action.payload, staging: false } : item
        );
        state.error = null;
        state.isLoading = false;
      })
      .addCase(deleteInspectionTypeEquipmentTypeRelation.fulfilled, (state, action) => {
        const relationsToRemove = action.payload.map(r => r.id);
        state.subData.eqTypeInspTypeRelation = state.subData.eqTypeInspTypeRelation.filter(
          r => !relationsToRemove.includes(r.id)
        );
        state.error = null;
      })
      .addCase(fetchEquipmentTypes.fulfilled, (state, action) => {
        state.data = action.payload.primaryData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSingleEquipmentType.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData = action.payload.subData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addEquipmentType.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEquipmentType.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(et => et.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const {
  createEquipmentTypeTemplate,
  clearEquipmentTypeError,
  createNewEqTypeInspTypeRelationEntryTemplate,
  fillNewEqTypeInspTypeRelationEntryTemplate,
  deleteNewEqTypeInspTypeRelationEntryTemplate,
  changeLocalStatus,
} = slice.actions;
export default slice.reducer;
