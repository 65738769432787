import "./MobileCardTemplate.less";

import { Row } from "antd";
import { useTranslation } from "react-i18next";

import { contextActionsColumnWithActions } from "../../../../pages/pageConfig/category/common";
import { MobileCardTemplateTProps } from "../../../../types/page";
import { ContextActions } from "../../../../types/tasks";
import CardLabel from "../core/CardLabel/CardLabel";
import CardLabelWithIcon, { ICON_NAMES } from "../core/CardLabelWithIcon/CardLabelWithIcon";
import CardTitle from "../core/CardTitle/CardTitle";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import RowContainer from "../core/RowContainer/RowContainer";
import StatusMark from "../core/StatusMark/StatusMark";

const ContextActionsMobileCardTemplate: React.FC<MobileCardTemplateTProps<ContextActions>> = ({ entity }) => {
  const { t } = useTranslation();
  const actionsConfig = contextActionsColumnWithActions;
  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.id && <CardTitle title={entity.id} category="actions" id={entity.id} />}
        {entity.title && <CardLabel label={entity.title} />}
      </ContainerWithDivider>
      <RowContainer>
        {entity.responsibleUserName && (
          <CardLabelWithIcon label={entity.responsibleUserName} iconName={ICON_NAMES.USER} />
        )}
        {entity.status && <StatusMark statusLabel={entity.status} />}
      </RowContainer>
      <ContainerWithDivider>
        {entity.reviewName && <CardLabel label={entity.reviewName} />}
        {!!entity.subTasksTotal && (
          <CardLabel label={`${t("CompletedSubTasks")}: ${entity.subTasksCompleted}/${entity.subTasksTotal}`} />
        )}
      </ContainerWithDivider>
      <Row className="mobile-card-row actions-buttons" align="middle">
        {actionsConfig.actions?.map(action => {
          if (action.component) {
            return action.component(entity);
          }
          return null;
        })}
      </Row>
    </CardWithSelect>
  );
};

export default ContextActionsMobileCardTemplate;
