import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Modal } from "antd";
import { RcFile } from "antd/lib/upload";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";
import { nanoid } from "nanoid";

import { CustomError, getEmptyValue, localizeText } from "../../pages/pageConfig/category/utilities";
import { TaskFile } from "../../types/files";
import {
  ActionsPaginatedData,
  ActionSummary,
  IdName,
  LinkedAction,
  Task,
  TaskCloseModel,
  TaskInfo,
  TaskReopenModel,
  TasksState,
  UsersQuickCreateData,
} from "../../types/tasks";
import {
  ExplicitAdditionalProps,
  HistoryLog,
  HistoryLogOperations,
  OpenClosedStatus,
  ParamsKeys,
  PrivilegeData,
  PrivilegedEntityType,
  Role,
  TaskComment,
  TaskPriority,
} from "../../types/utility";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../../utils/gridifyQueryHelper";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import {
  addEntityPrivilege,
  createNewPrivilegeTemplate,
  deleteEntityPrivilege,
  deleteMultipleEntityPrivileges,
  deleteNewPrivilegeEntryTemplate,
  fillNewPrivilegeEntryTemplate,
  getEntityPrivileges,
  updateAccountableEntity,
  updateEntityPrivilegeOULvl,
  updateNewPrivilegeEntry,
} from "./privileges";
import { updateClosedTask } from "./todolist";

export const initialState: TasksState = {
  data: [],
  tags: [],
  usersQuickCreateData: [],
  isLoading: false,
  singleData: null,
  subData: {
    tags: [],
    accountable: [],
    accountableRole: Role.Viewer,
    historyLog: [],
    files: [],
    linkedActions: [],
  },
  searchResults: [],
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
  paginationInfo: {
    count: 0,
    currentPage: 0,
    lastPage: false,
  },
};

export const newTask: Task = {
  responsibleUserId: null,
  id: 0,
  title: "",
  createDate: dayjs().toISOString(),
  createdDate: dayjs().toISOString(),
  startDate: dayjs().toISOString(),
  closeDate: "",
  projectId: null,
  dueDate: dayjs().add(1, "day").toISOString(),
  status: OpenClosedStatus.Opened,
  openClosed: OpenClosedStatus.Opened,
  responsible: "",
  createdByUserName: "",
  createdBy: "",
  priority: TaskPriority.Medium,
  projectName: "",
  project: "",
  followUpReviewName: "",
  review: "",
  reviewId: -1,
  tags: "",
  description: "",
  parentTaskId: null,
  issueTypeName: "",
  issueTypeId: null,
  locationId: null,
  equipmentId: null,
  equipmentTypeId: null,
  employeeId: null,
  employeeName: "",
  equipmentName: "",
  equipmentTypeName: "",
  locationName: "",
  followUpReviewId: null,
  isFormBased: false,
  responsibleUserName: "",
  issueType: "",
  subTasksCompleted: 0,
  subTasksTotal: 0,
};

//TODO make this thunk generic and use it for fetching list view and paginated data (maybe soem conditional boolean for cases, but it should be covered through whole system)
export const fetchPaginatedActions = createAsyncThunk<
  {
    primaryData: Task[];
    defaultCustomProperties: ExplicitAdditionalProps[];
    possibleResults: number;
    defaultPageSize?: number;
  },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@TASKS/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, actions, filter } = getState();

    const tasksFilters = filter.filters.actions?.actions?.activeFilters || [];
    const idsFilter = filter.filters.actions?.actions?.idsFilter || [];

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps([...tasksFilters, ...idsFilter]);

    const orders = filter.filterValues.actions?.order
      ? generateGridifySorterQuery(filter.filterValues.actions?.order)
      : undefined;

    const response = await hbApi.post<ActionsPaginatedData>(
      "/Task/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || actions.paginationInfo.currentPage + 1,
          PageSize: user.companySettings.defaultPageSize ?? params?.pageSize,
          Filter: filters,
          OrderBy: orders,
        },
        withTotal: false,
        emptyPropIds: emptyPropIds,
      },
      hbApiOptions(user.jwt)
    );

    return {
      primaryData: response.data.data.map((item: Task) => {
        return {
          ...item,
          openClosed: item.closeDate ? OpenClosedStatus.Closed : OpenClosedStatus.Opened,
        };
      }),
      defaultCustomProperties: [],
      possibleResults: response.data.count,
      defaultPageSize: user.companySettings.defaultPageSize,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { actions } = getState();

      return (
        arg?.forceUpdate ||
        (dayjs(actions.lastUpdated).isBefore(dayjs()) &&
          !actions.isLoading &&
          actions.data.length !== actions.paginationInfo.count)
      );
    },
  }
);

//TODO make this thunk generic and use it for fetching list view and paginated data (maybe soem conditional boolean for cases, but it should be covered through whole system)
export const searchTasks = createAsyncThunk<
  Task[],
  { filters?: string; page?: number } | undefined,
  { state: RootState }
>(
  "@@TASKS/SEARCH_PAGINATED",
  async (params, { getState }) => {
    const { user } = getState();
    const response = await hbApi.post<ActionsPaginatedData>(
      "/Task/pagedList",
      {
        gridifyQuery: {
          Page: params?.page || 1,
          PageSize: 100,
          Filter: params?.filters,
        },
      },
      hbApiOptions(user.jwt)
    );

    return response.data.data.map((item: Task) => {
      return {
        ...item,
        openClosed: item.closeDate ? OpenClosedStatus.Closed : OpenClosedStatus.Opened,
      };
    });
  },
  {
    condition: (_, { getState }) => {
      const { actions } = getState();
      return !actions.isLoading;
    },
  }
);

export const fetchSingleTask = createAsyncThunk<
  {
    singleData: Task;
    subData: { files: TaskFile[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>(
  "@@SINGLE_TASK/FETCH",
  async (id, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const cardDataResponse = await hbApi.get<Task>(`/Task/${id}`, hbApiOptions(user.jwt));

      return {
        singleData: {
          ...cardDataResponse.data,
          isFormBased: !!cardDataResponse.data.followUpReviewId,
        },
        subData: {
          files: [],
        },
        defaultCustomProperties: [],
      };
    } catch (e) {
      if (e.status === 400 || e.status === 404) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();
      return !!user.jwt;
    },
  }
);

export const getTaskCloseInfo = createAsyncThunk<TaskInfo, number, { state: RootState }>(
  "@@SINGLE_TASK/CLOSE_INFO",
  async (taskId, { getState, rejectWithValue }) => {
    const { user } = getState();
    try {
      const cardDataResponse = await hbApi.get<TaskInfo>(`/Task/closeinfo/${taskId}`, hbApiOptions(user.jwt));

      return cardDataResponse.data;
    } catch (e) {
      if (e.status === 400 || e.status === 404) {
        return rejectWithValue(e.data);
      }
      throw new CustomError();
    }
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();
      return !!user.jwt;
    },
  }
);

export const fetchTasksAdditionalData = createAsyncThunk<
  {
    additionalData?: { tags?: IdName[]; usersQuickCreateData: UsersQuickCreateData[] };
  },
  boolean | undefined,
  { state: RootState }
>("@@SINGLE_TASK/FETCH_ADDITIONAL_DATA", async (_, { getState, rejectWithValue }) => {
  const { user } = getState();
  try {
    const userReviewData = await hbApi.get<UsersQuickCreateData[]>("/Task/userReviewData", hbApiOptions(user.jwt));
    const tagsResponse = await hbApi.get<IdName[]>("Tag", hbApiOptions(user.jwt));

    return {
      additionalData: {
        usersQuickCreateData: userReviewData.data,
        tags: tagsResponse.data,
      },
    };
  } catch (e) {
    if (e.status === 400 || e.status === 404) {
      return rejectWithValue(e.data);
    }
    throw new CustomError();
  }
});

export const openTaskFollowUp = createAsyncThunk<
  number | undefined,
  {
    ProjectId: number;
    ReviewInfoId: number;
    TaskId: number | null;
    IsUsedProjectReviewEntity: boolean;
    IsTodoList?: boolean;
    Edit?: boolean;
  },
  { state: RootState }
>("@@TASK/TASK_FOLLOW_UP", async (model, { getState }) => {
  const { user } = getState();

  const baseUrl = "/task/QuicklyCreateFollowUp";

  const appointmentId = await hbApi.post<string>(baseUrl, model, hbApiOptions(user.jwt));

  return parseInt(appointmentId.data);
});

export const quicklyCreateAppointment = createAsyncThunk<
  void,
  { ProjectId: number; ReviewInfoId: number; IsUsedProjectReviewEntity: boolean },
  { state: RootState }
>("@@TASK/QUiCKLY_CREATE_APPOINTMENT", async (model, { getState }) => {
  const { user } = getState();

  const baseUrl = "/Appointment/QuicklyCreate";

  const appointmentId = await hbApi.post<string>(baseUrl, model, hbApiOptions(user.jwt));

  if (appointmentId.data)
    window.location.href = `${process.env.REACT_APP_OLD_PAS_URL}/${user.settings.lang}/WebApplication/Index/${appointmentId.data}?newpas=true`;

  return;
});

export const addTask = createAsyncThunk<Task, { entity: Task; additionTrigger?: boolean }, { state: RootState }>(
  "@@TASK/CREATE",
  async ({ entity, additionTrigger }, { getState, rejectWithValue }) => {
    const { user } = getState();
    const urlParams = new URLSearchParams(window.location.search);
    const parentId = urlParams.get(ParamsKeys.PARENT_ID);
    try {
      const response = await hbApi.post<Task>(
        `/Task${additionTrigger ? "?instantClose=true" : ""}`,
        {
          ...entity,
          startDate: dayjs(entity.startDate).toISOString(),
          dueDate: dayjs(entity.dueDate).toISOString(),
          tags: entity.tags ? entity.tags.split(",") : [],
          issueTypeId: entity.issueTypeId === getEmptyValue().key ? null : entity.issueTypeId,
          followUpReviewId: entity.isFormBased ? entity.followUpReviewId : null,
          parentTaskId: parentId,
        },
        hbApiOptions(user.jwt)
      );

      return response.data;
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions, user } = getState();
      return !actions.isLoading && !!user.jwt;
    },
  }
);

export const updateTask = createAsyncThunk<Task, Task, { state: RootState }>(
  "@@TASK/UPDATE",
  async (entity, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const response = await hbApi.put<Task>(
        `/Task/${entity.id}`,
        {
          ...entity,
          startDate: dayjs(entity.startDate).toISOString(),
          dueDate: dayjs(entity.dueDate).toISOString(),
          tags: entity.tags ? entity.tags.split(",") : [],
          followUpReviewId: entity.isFormBased ? entity.followUpReviewId : null,
          issueTypeId: entity.issueTypeId === getEmptyValue().key ? null : entity.issueTypeId,
        },
        hbApiOptions(user.jwt)
      );

      return response.data;
    } catch (e) {
      return rejectWithValue(e.errors);
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions, user } = getState();
      return !actions.isLoading && !!user.jwt;
    },
  }
);

export const closeTask = createAsyncThunk<
  { task: TaskCloseModel; files?: TaskFile[] | null },
  { task: TaskCloseModel; file?: RcFile },
  { state: RootState }
>(
  "@@TASK/CLOSE_TASK",

  async ({ task, file }, { getState, dispatch }) => {
    const { user } = getState();

    let files: TaskFile[] | null = null;

    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      files = (await hbApi.post<TaskFile[]>(`Task/uploadTaskFile/${task.TaskId}`, formData, hbApiOptions(user.jwt)))
        .data;
    }
    await hbApi.post<Task>("/Task/closetask", task, hbApiOptions(user.jwt));

    await dispatch(updateClosedTask(task.TaskId));

    return { task, files: files && files };
  },
  {
    condition: (_, { getState }) => {
      const { actions, user } = getState();
      return !actions.isLoading && !!user.jwt;
    },
  }
);

export const closeTaskFromMobile = createAsyncThunk<number, number, { state: RootState }>(
  "@@TASK/CLOSE_TASK_FROM_MOBILE",
  async taskId => {
    return taskId;
  }
);

export const reopenTask = createAsyncThunk<TaskReopenModel, TaskReopenModel, { rejectValue: string; state: RootState }>(
  "@@TASK/REOPEN_TASK",
  async (model, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      await hbApi.post<Task>(
        "/Task/reopentask",
        model,
        hbApiOptions(user.jwt, null, { LANGUAGE_KEY: user.settings.lang })
      );
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.message);
      }
      throw new CustomError();
    }
    return model;
  },
  {
    condition: (_, { getState }) => {
      const { actions, user } = getState();
      return !actions.isLoading && !!user.jwt;
    },
  }
);

export const fetchTaskHistoryLog = createAsyncThunk<HistoryLog[], { taskId: string }, { state: RootState }>(
  "@@FETCH_TASK_HISTORY_LOG",
  async ({ taskId }, { getState }) => {
    const { user } = getState();

    const events = await hbApi.get<HistoryLog[]>(`/Task/historyLog/${taskId}`, hbApiOptions(user.jwt));

    const historyLog = events.data
      .map((x: HistoryLog) => {
        return { ...x, date: x.timeStamp };
      })
      .sort((x, y) => (new Date(x.date) > new Date(y.date) ? -1 : new Date(x.date) < new Date(y.date) ? 1 : 0));

    return historyLog as HistoryLog[];
  }
);

export const fetchLinkedRelatedActions = createAsyncThunk<LinkedAction[], { taskId: string }, { state: RootState }>(
  "@@FETCH_LINKED_ACTIONS",
  async ({ taskId }, { getState }) => {
    const { user } = getState();

    const response = await hbApi.get<LinkedAction[]>(`task/linked-list/${taskId}`, hbApiOptions(user.jwt));

    return response.data;
  }
);

export const fetchActionFiles = createAsyncThunk<TaskFile[], { taskId: string }, { state: RootState }>(
  "@@FETCH_ACTIONS_",
  async ({ taskId }, { getState }) => {
    const { user } = getState();

    const filesResponse = await hbApi.get<TaskFile[]>(`/Task/getFiles/${taskId}`, hbApiOptions(user.jwt));

    return filesResponse.data.map(x => {
      return {
        ...x,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        id: x.id === 0 ? (nanoid() as any) : x.id,
        createdByUser: x.createdByUser ?? { name: "" },
      };
    });
  }
);

export const addTaskComment = createAsyncThunk<HistoryLog[], { comment: string }, { state: RootState }>(
  "@@TASK/ADD_COMMENT",
  async ({ comment }, { getState }) => {
    const { user, actions } = getState();

    const comments = await hbApi.post<TaskComment[]>(
      "/Task/comments/create",
      {
        taskId: actions.singleData?.id,
        comment: comment,
      },
      hbApiOptions(user.jwt)
    );

    const convertedComments = comments.data.map((x: TaskComment) => {
      return {
        entityId: x.taskId,
        userId: x.createdByUserId,
        userName: x.userName,
        operation: HistoryLogOperations.Created,
        timeStamp: x.createDate,
        fieldName: "comment",
        oldValue: "",
        newValue: x.comment,
        date: x.createDate,
      };
    });

    return convertedComments;
  }
);

export const uploadTaskFile = createAsyncThunk<TaskFile[], { file: RcFile; expiration: string }, { state: RootState }>(
  "@@TASK/UPLOAD_TASK_FILE",
  async (model, { getState }) => {
    const { user, actions } = getState();

    const formData = new FormData();

    formData.append("file", model.file);

    const res = await hbApi.post<TaskFile[]>(
      `Task/uploadTaskFile/${actions.singleData?.id}`,
      formData,
      hbApiOptions(user.jwt)
    );

    return res.status === 200
      ? res.data.map(x => {
          return {
            ...x,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            id: x.id === 0 ? (nanoid() as any) : x.id,
            createdByUser: x.createdByUser ?? { name: "" },
          };
        })
      : [];
  }
);

export const removeTaskFiles = createAsyncThunk<number[], number[], { state: RootState }>(
  "@@TASK/REMOVE_TASK_FILES",
  async (ids, { getState }) => {
    const { user } = getState();

    const preparedIds = ids.filter(x => typeof x !== "string");

    if (preparedIds.length !== ids.length) {
      Modal.info({
        content: localizeText("FormImagesNotDeleted"),
        closable: true,
        maskClosable: true,
      });
    }
    await hbApi.delete<File[]>("/Task/deleteFiles", hbApiOptions(user.jwt, preparedIds));

    return preparedIds;
  }
);

export const fetchActionSummary = createAsyncThunk<ActionSummary, number, { state: RootState }>(
  "@@TASK/FETCH_ACTION_SUMMARY",
  async (taskId, { getState }) => {
    const { user } = getState();
    const res = await hbApi.get<ActionSummary>(`/Task/summary/${taskId}`, hbApiOptions(user.jwt));

    return res.data;
  }
);

export const exportReviewResults = createAsyncThunk<boolean, null | undefined, { state: RootState }>(
  "@@TASK/EXPORT_REVIEW_RESULTS",
  async (_, { getState }) => {
    const { user, filter } = getState();

    const tasksFilters = filter.filters.actions?.actions?.activeFilters || [];

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps(tasksFilters);

    const res = await hbApi.get<boolean>(
      "/Task/export-review-results",
      hbApiOptions(user.jwt, null, null, {
        Filter: filters,
        emptyPropIds: emptyPropIds,
      })
    );

    return res.data;
  }
);

export const setActionsStatus = createAsyncThunk<number[] | null, number[], { rejectValue: string; state: RootState }>(
  "@@SET_ACTION_STATUS",
  async (ids, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();
      const response = await hbApi.post<boolean>(
        "/Task/bulkcancel",
        { ids },
        hbApiOptions(user.jwt, null, { LANGUAGE_KEY: user.settings.lang })
      );
      if (response.data) {
        return ids;
      }
    } catch (e) {
      return rejectWithValue(e.message);
    }

    return null;
  }
);

export const cloneAction = createAsyncThunk<number | null, number, { state: RootState }>(
  "@@CLONE_ACTION",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();
      const response = await hbApi.post<number>("/Task/clone", { taskId: id }, hbApiOptions(user.jwt));
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      return rejectWithValue(e.message);
    }

    return null;
  }
);

export const slice = createSlice({
  name: "actions",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    createTasksTemplate: state => {
      state.singleData = newTask;
    },
    resetCurrentPage: state => {
      state.paginationInfo.currentPage = 0;
    },
    clearTasksError: state => {
      state.error = null;
    },
    createNewAccountableEntryTemplate: createNewPrivilegeTemplate,
    fillNewAccountableEntryTemplate: {
      prepare: (payload: { row: PrivilegeData; targetEntity: Record<string, unknown> }) => ({ payload }),
      reducer: fillNewPrivilegeEntryTemplate,
    },
    updateNewAccountableEntry: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prepare: (payload: { entity: PrivilegeData; newValue: any; property: keyof PrivilegeData }) => ({ payload }),
      reducer: updateNewPrivilegeEntry,
    },
    deleteNewAccountableEntryTemplate: {
      prepare: (payload: PrivilegeData) => ({ payload }),
      reducer: deleteNewPrivilegeEntryTemplate,
    },
    resetCloseInfo: state => {
      state.closeInfo = undefined;
    },
    resetSearchResults: state => {
      state.searchResults = [];
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchPaginatedActions.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchTasks.pending, state => {
        state.error = null;
      })
      .addCase(fetchSingleTask.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTaskHistoryLog.pending, state => {
        state.error = null;
      })
      .addCase(fetchLinkedRelatedActions.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addTaskComment.pending, state => {
        state.error = null;
      })
      .addCase(uploadTaskFile.pending, state => {
        state.error = null;
      })
      .addCase(removeTaskFiles.pending, state => {
        state.error = null;
      })
      .addCase(fetchTasksAdditionalData.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTaskCloseInfo.pending, state => {
        state.error = null;
      })
      // Update
      .addCase(updateTask.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addTask.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(openTaskFollowUp.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(quicklyCreateAppointment.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(closeTask.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(reopenTask.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setActionsStatus.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchActionFiles.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEntityPrivileges.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Note - Rejected:
      .addCase(fetchPaginatedActions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(searchTasks.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(closeTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(reopenTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || null;
      })
      .addCase(addTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(getTaskCloseInfo.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(fetchTaskHistoryLog.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addTaskComment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchLinkedRelatedActions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(uploadTaskFile.rejected, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeTaskFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchTasksAdditionalData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(getEntityPrivileges.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Update
      .addCase(updateTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleTask.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
        state.singleData = undefined;
      })
      .addCase(openTaskFollowUp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(quicklyCreateAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(setActionsStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message || null;
      })
      .addCase(fetchActionFiles.rejected, state => {
        state.isLoading = false;
        state.error = "ErrorOnLoadingFiles"; //error on any rejection
      })
      // Note - Fulfilled:

      .addCase(setActionsStatus.fulfilled, (state, action) => {
        const toggleStatus = (status: OpenClosedStatus) =>
          status === OpenClosedStatus.Opened ? OpenClosedStatus.Canceled : OpenClosedStatus.Opened;

        if (action.payload) {
          state.data = state.data.map(x =>
            action.payload?.includes(x.id)
              ? {
                  ...x,
                  status: toggleStatus(x.status),
                }
              : x
          );
          if (state.singleData) {
            state.singleData = {
              ...state.singleData,
              status: toggleStatus(state.singleData.status),
            } as Task;
          }
        }
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchPaginatedActions.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.data = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.data = unionBy(state.data, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = state.data.length + action.payload.primaryData.length;
        state.paginationInfo.lastPage =
          action.payload.primaryData.length === 0 ||
          (action.payload.defaultPageSize && action.payload.primaryData.length < action.payload.defaultPageSize) ||
          false;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      })
      .addCase(searchTasks.fulfilled, (state, action) => {
        state.searchResults = [...action.payload];
        state.error = null;
      })
      .addCase(addTask.fulfilled, (state, action) => {
        state.singleData = { ...action.payload, isFormBased: !!action.payload.followUpReviewId };
        state.data.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSingleTask.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData.files = action.payload.subData.files;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchActionFiles.fulfilled, (state, action) => {
        state.subData.files = action.payload;
        state.error = null;
        state.isLoading = false;
      })

      .addCase(fetchTasksAdditionalData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersQuickCreateData = action.payload.additionalData?.usersQuickCreateData || [];
        state.tags = action.payload.additionalData?.tags || [];
        state.error = null;
      })
      .addCase(getEntityPrivileges.fulfilled, (state, action) => {
        state.subData.accountable = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addEntityPrivilege.fulfilled, (state, action) => {
        const entityType = action.payload.entityType;
        if (entityType === PrivilegedEntityType.HbTask) {
          state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.entity.id);
          state.subData.accountable.unshift({
            ...action.payload.entity,
            id: action.payload.entity.id!,
            staging: false,
          });
        }

        state.error = null;
      })
      .addCase(deleteEntityPrivilege.fulfilled, (state, action) => {
        const entityType = action.payload.entityType;

        if (entityType === PrivilegedEntityType.HbTask) {
          state.subData.accountable = state.subData.accountable.filter(r => r.id !== action.payload.entity.id);
        }
        state.error = null;
      })
      .addCase(getTaskCloseInfo.fulfilled, (state, action) => {
        state.closeInfo = {
          info: action.payload,
          taskId: action.meta.arg,
        };
        state.error = null;
      })
      .addCase(deleteMultipleEntityPrivileges.fulfilled, (state, action) => {
        const privilegesToRemove = action.payload.map(r => r.id);
        state.subData.accountable = state.subData.accountable.filter(r => !privilegesToRemove.includes(r.id));
        // state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchTaskHistoryLog.fulfilled, (state, action) => {
        state.subData.historyLog = action.payload ? action.payload.map(r => ({ ...r, id: nanoid() })) : [];
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchLinkedRelatedActions.fulfilled, (state, action) => {
        state.subData.linkedActions = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(addTaskComment.fulfilled, (state, action) => {
        state.subData.historyLog = action.payload
          ? [
              ...state.subData.historyLog.filter(x => x.fieldName !== "comment"),
              ...action.payload.map(r => ({ ...r, id: nanoid() })),
            ].sort((x, y) =>
              new Date(x.date as string) > new Date(y.date as string)
                ? -1
                : new Date(x.date as string) < new Date(y.date as string)
                ? 1
                : 0
            )
          : [];
        state.error = null;
        state.isLoading = false;
      })
      .addCase(uploadTaskFile.fulfilled, (state, action) => {
        state.subData.files = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(removeTaskFiles.fulfilled, (state, action) => {
        state.subData.files = state.subData.files.filter(x => !action.payload.includes(x.id));
        state.isLoading = false;
        state.error = null;
      })
      // Update
      .addCase(updateTask.fulfilled, (state, action) => {
        state.singleData = { ...action.payload, isFormBased: !!action.payload.followUpReviewId };
        const updatedEntityIndex = state.data.findIndex(task => task.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(openTaskFollowUp.fulfilled, state => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(quicklyCreateAppointment.fulfilled, state => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(closeTask.fulfilled, (state, action) => {
        const updatedEntityIndex = state.data.findIndex(task => task.id === action.payload.task.TaskId);
        const updatedData = action.payload.task.IsHandled
          ? { closeDate: dayjs().toString(), openClosed: OpenClosedStatus.Closed, status: OpenClosedStatus.Closed }
          : {
              responsible: action.payload.task.ResponsibleChange?.NewResponsibleDisplayName as string,
              responsibleUserId: action.payload.task.ResponsibleChange?.NewResponsibleId as number,
            };

        if (updatedEntityIndex) {
          state.data[updatedEntityIndex] = {
            ...state.data[updatedEntityIndex],
            ...updatedData,
          };
        }
        if (state.singleData) {
          state.singleData = {
            ...state.singleData,
            ...updatedData,
          };
        }
        if (action.payload.files) {
          state.subData.files = action.payload.files;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(reopenTask.fulfilled, (state, action) => {
        const updatedEntityIndex = state.data.findIndex(task => task.id === action.payload.TaskId);

        const updatedData = {
          closeDate: "",
          status: OpenClosedStatus.Opened,
        };
        state.data[updatedEntityIndex] = {
          ...state.data[updatedEntityIndex],
          ...updatedData,
        };
        if (state.singleData) {
          state.singleData = {
            ...state.singleData,
            ...updatedData,
          };
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEntityPrivilegeOULvl.fulfilled, (state, action) => {
        updateAccountableEntity(state, action);
      })
      .addCase(closeTaskFromMobile.fulfilled, (state, action) => {
        const updatedEntityIndex = state.data.findIndex(task => task.id === action.payload);
        if (updatedEntityIndex !== -1) {
          state.data[updatedEntityIndex].status = OpenClosedStatus.Closed;
        }
      });
  },
});

export const {
  resetCurrentPage,
  createTasksTemplate,
  clearTasksError,
  resetCloseInfo,
  createNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  deleteNewAccountableEntryTemplate,
  resetSearchResults,
  updateNewAccountableEntry,
} = slice.actions;
export default slice.reducer;
