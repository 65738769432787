import "./singleViewCardMobileFirst.less";

import { Col, Form, Row } from "antd";
import clsx from "clsx";
import React from "react";
import { Prompt } from "react-router-dom";

import { ReactComponent as EditIcon } from "../../media/edit-icon.svg";
import { TodoListRefreshEvent } from "../../pages/TodoList/TodoList";
import { closeTaskFromMobile } from "../../store/slices/tasks";
import { useAppDispatch } from "../../store/store";
import useMobilePullToRefreshData from "../../utils/hooks/useMobilePullToRefreshData";
import SingleViewHeader from "./components/HeaderComponent";
import SingleViewCardMobileDialogs from "./components/SingleViewCardMobileDialogs";
import SingleViewMobileTabs from "./components/SingleViewMobileTabs";
import { ABOUT_TAB_KEY, useMobileSingleViewContext } from "./context/useMobileSingleViewContext";

const SingleViewCardMobileFirst: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    singleViewRef,
    canEditCard,
    isEdited,
    isGlobalEditForbidden,
    setIsEdited,
    form,
    onSubmitChanges,
    onFinishFailed,
    handleDependentFieldsValues,
    handleBlockedNavigation,
    activeTab,
    handleDataFetch,
  } = useMobileSingleViewContext();

  const handleRefreshData = async () => {
    handleDataFetch();
    endRefreshData();
  };

  const handleRefreshDataFromMobile = (event: Event) => {
    const actionId = (event as TodoListRefreshEvent).detail.actionId;
    if (actionId) {
      dispatch(closeTaskFromMobile(parseInt(actionId)));
    } else {
      handleDataFetch();
    }
    endRefreshData();
  };

  const { endRefreshData, PullToRefreshOnWeb, PullToRefreshContextProvider } = useMobilePullToRefreshData({
    refreshHandler: handleRefreshDataFromMobile,
  });

  return (
    <PullToRefreshContextProvider>
      <PullToRefreshOnWeb onRefresh={handleRefreshData} disabled={isEdited}>
        <div ref={singleViewRef} className={clsx("single-mobile-first", isEdited && "edit-mode")}>
          <Prompt when={isEdited} message={handleBlockedNavigation}></Prompt>
          <SingleViewCardMobileDialogs />
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmitChanges}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
            validateTrigger={["onChange"]}
            onValuesChange={handleDependentFieldsValues}
          >
            <Row>
              <Row className="full-width">
                <Col className="fields-section full-width">
                  {!isEdited && (
                    <SingleViewHeader
                      editIcon={
                        activeTab == ABOUT_TAB_KEY && !isEdited && !isGlobalEditForbidden && canEditCard ? (
                          <EditIcon className="edit-icon" onClick={() => setIsEdited(true)} />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}

                  <SingleViewMobileTabs />
                </Col>
              </Row>
            </Row>
          </Form>
        </div>
      </PullToRefreshOnWeb>
    </PullToRefreshContextProvider>
  );
};

export default SingleViewCardMobileFirst;
