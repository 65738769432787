import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import { useEffect } from "react";
import { getRedirectToLegacy } from "../../store/slices/user";
import { useAppDispatch } from "../../store/store";
import { ParamsKeys } from "../../types/utility";
import useRouter from "../../utils/hooks/useRouter";

export default (): JSX.Element => {
  const { location } = useRouter<{ referer: Location }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const returnUrl = urlSearchParams.get(ParamsKeys.RETURN_URL);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRedirectToLegacy({ returnUrl: returnUrl, loginForm: true }))
      .then(res => unwrapResult(res))
      .then(data => (window.location.href = data.returnUrl))
      .catch(e => {
        console.error(e);
      });
  });
  return (
    <div className="full-login-container" style={{ height: "100vh" }}>
      <Spin />
    </div>
  );
};
