import { createSelector, OutputSelector } from "@reduxjs/toolkit";
import { addEmptyValueForFiltering, fetchEmployeeById, fetchOrgUnitById } from "./pages/pageConfig/category/utilities";
import { RootState } from "./store/store";
import { CategoryId, TOption } from "./types/page";
import { TrainingTrainee } from "./types/training";
import { PrivilegeData, Role } from "./types/utility";

// TODO: REMOVE DRY CODE !! Multiple similiar functions that differentiate by type only/etc.

export type AppSelector<ReturnType, SliceState> = OutputSelector<
  RootState,
  ReturnType,
  (res: SliceState) => ReturnType
>;

const stateSelector = (state: RootState): RootState => state;
// Note: Pages start

const stateEquipmentPage = (state: RootState): boolean[] => [state.equipment.isLoading, state.equipmentType.isLoading];
const isEquipmentPageLoading = createSelector(stateEquipmentPage, state => state.some(Boolean));

const stateInspectionPage = (state: RootState): boolean[] => [
  state.inspection.isLoading,
  state.inspectionType.isLoading,
];
const isInspectionPageLoading = createSelector(stateInspectionPage, state => state.some(Boolean));

const stateEmployeePage = (state: RootState): boolean[] => [state.employee.isLoading];
const isEmployeePageLoading = createSelector(stateEmployeePage, state => state.some(Boolean));

const stateTrainingPage = (state: RootState): boolean[] => [state.training.isLoading];
const isTrainingPageLoading = createSelector(stateTrainingPage, state => state.some(Boolean));
const stateCertificationPage = (state: RootState): boolean[] => [state.certification.isLoading];
const isCertificationPageLoading = createSelector(stateCertificationPage, state => state.some(Boolean));

const stateDashboardPage = (state: RootState): boolean[] => [state.dashboard.isLoading];
const isDashboardPageLoading = createSelector(stateDashboardPage, state => state.some(Boolean));

const stateLocationPage = (state: RootState): boolean[] => [state.location.isLoading];
const isLocationPageLoading = createSelector(stateLocationPage, state => state.some(Boolean));

const stateOrgUnitPage = (state: RootState): boolean[] => [state.orgUnit.isLoading];
const isOrgUnitPageLoading = createSelector(stateOrgUnitPage, state => state.some(Boolean));

const stateSurveyPage = (state: RootState): boolean[] => [state.survey.isLoading];
const isSurveyPageLoading = createSelector(stateSurveyPage, state => state.some(Boolean));

const stateTaskPage = (state: RootState): boolean[] => [state.actions.isLoading];
const isTasksPageLoading = createSelector(stateTaskPage, state => state.some(Boolean));

const stateEquipmentTypePage = (state: RootState): boolean[] => [state.equipmentType.isLoading];

const isEquipmentTypePageLoading = createSelector(stateEquipmentTypePage, state => state.some(Boolean));
const stateInspectionTypePage = (state: RootState): boolean[] => [state.inspectionType.isLoading];
const isInspectionTypePageLoading = createSelector(stateInspectionTypePage, state => state.some(Boolean));
const stateLocationTypePage = (state: RootState): boolean[] => [state.locationType.isLoading];
const isLocationTypePageLoading = createSelector(stateLocationTypePage, state => state.some(Boolean));
const stateCustomPropertyPage = (state: RootState): boolean[] => [state.customProperty.isLoading];
const isCustomPropertyPageLoading = createSelector(stateCustomPropertyPage, state => state.some(Boolean));
const stateIssueTypePage = (state: RootState): boolean[] => [state.issueType.isLoading];
const isIssueTypePageLoading = createSelector(stateIssueTypePage, state => state.some(Boolean));
const stateRecurrentActionsTypePage = (state: RootState): boolean[] => [state.recurrentActions.isLoading];
const isRecurrentActionsPageLoading = createSelector(stateRecurrentActionsTypePage, state => state.some(Boolean));
const stateWebhooksPage = (state: RootState): boolean[] => [state.webhooks.isLoading];
const isWebhooksLoading = createSelector(stateWebhooksPage, state => state.some(Boolean));

// Note: Used when we dont have a need for a selector but the generic type demands a selector. // TODO: This should be removed and type for defaultCustomPropertiesSelector on the tab - fixed
const nullableCustomPropertiesSelector = createSelector(stateSelector, () => null);

const pageSelectors = {
  isEquipmentPageLoading,
  isEmployeePageLoading,
  isTrainingPageLoading,
  isCertificationPageLoading,
  isDashboardPageLoading,
  isLocationPageLoading,
  isInspectionPageLoading,
  isOrgUnitPageLoading,
  isSurveyPageLoading,
  isTasksPageLoading,
  nullableCustomPropertiesSelector,
  isEquipmentTypePageLoading,
  isInspectionTypePageLoading,
  isLocationTypePageLoading,
  isCustomPropertyPageLoading,
  isIssueTypePageLoading,
  isRecurrentActionsPageLoading,
  isWebhooksLoading,
  stateSelector,
};

//Note: Equipment Start
// const stateEquipment = (state: RootState): CategoryState<Equipment> => state;

const equipmentCustomProperties = createSelector(stateSelector, state => state.equipment.defaultCustomProperties);
const allEquipment = createSelector(stateSelector, state => state.equipment.data || []);
const singleEquipment = createSelector(stateSelector, state => state.equipment.singleData);
const equipmentError = createSelector(stateSelector, state => state.equipment.error);
const equipmentIsLoading = createSelector(stateSelector, state => state.equipment.isLoading);
const inspectionsSelector = createSelector(stateSelector, state => state.equipment.subData.inspections || []);
const historyLogSelector = createSelector(stateSelector, state => state.equipment.subData.historyLog || []);
const equipmentFilesSelector = createSelector(stateSelector, state => state.equipment.subData.files || []);
const optionsTypeSelector = createSelector(stateSelector, state => state.equipmentType.data || []);
const equipmentLastUpdatedText = createSelector(stateSelector, state => state.equipment.lastUpdated);
const equipmentAccountableRelations = createSelector(stateSelector, state => state.equipment.subData.accountable);
const allEquipmentCards = createSelector(stateSelector, state => state.equipment.groupViewData);
const equipmentHistoryLog = createSelector(stateSelector, state => state.equipment.subData.historyLog);
const equipmentActions = createSelector(stateSelector, state => state.equipment.subData.actions);
const equipmentSearchResults = createSelector(stateSelector, state => state.equipment.searchResults);
const equipmentPossibleResults = createSelector(stateSelector, state => state.equipment.paginationInfo.count);

const allEquipmentRelations = createSelector(stateSelector, state => {
  const employees = state.employee.basicData.map(emp => ({
    name: emp.displayName,
    externalId: emp.externalId,
    userId: emp.id,
    orgUnitId: null,
    status: emp.status,
    isEmployee: true,
    isExternal: emp.isExternal,
    role: Role.Recipient,
  })) as PrivilegeData[];
  const orgUnits = state.orgUnit.basicData.map(org => ({
    externalId: org.externalId,
    orgUnitId: org.id,
    userId: null,
    name: org.name,
    status: org.status,
    isOrgUnit: true,
    isExternal: false,
    role: Role.Recipient,
  })) as PrivilegeData[];
  return [...employees, ...orgUnits];
});

const equipmentSelectors = {
  all: allEquipment,
  single: singleEquipment,
  error: equipmentError,
  isLoading: equipmentIsLoading,
  inspections: inspectionsSelector,
  files: equipmentFilesSelector,
  historyLog: historyLogSelector,
  typeOptions: optionsTypeSelector,
  actions: equipmentActions,
  equipmentLastUpdatedText,
  equipmentCustomProperties,
  equipmentAccountableRelations,
  allEquipmentCards,
  allEquipmentRelations,
  equipmentHistoryLog,
  equipmentSearchResults,
  equipmentPossibleResults,
};

//Note: Inspection Start

const allInspections = createSelector(stateSelector, state => state.inspection.data);
const singleInspection = createSelector(stateSelector, state => state.inspection.singleData);
const inspectionError = createSelector(stateSelector, state => state.inspection.error);
const inspectionCustomProperties = createSelector(stateSelector, () => []); //state.inspection.defaultCustomProperties
const inspectionIsLoading = createSelector(stateSelector, state => state.inspection.isLoading);
const inspectionLastUpdatedText = createSelector(stateSelector, state => state.inspection.lastUpdated);
const inspectionCheckpoints = createSelector(stateSelector, state => state.inspection.singleData?.checkpoints || []);
const inspectionActions = createSelector(stateSelector, state => state.inspection.subData?.actions || []);
const inspectionHistoryLog = createSelector(stateSelector, state => state.inspection.subData?.historyLog || []);
const inspectionSearchResults = createSelector(stateSelector, state => state.inspection.searchResults);
const inspectionPossibleResults = createSelector(stateSelector, state => state.inspection.paginationInfo.count);

const inspectionSelectors = {
  all: allInspections,
  error: inspectionError,
  single: singleInspection,
  actions: inspectionActions,
  inspectionCustomProperties,
  inspectionIsLoading,
  inspectionLastUpdatedText,
  inspectionCheckpoints,
  inspectionSearchResults,
  inspectionPossibleResults,
  inspectionHistoryLog,
};

//Note: Employee Start

const employeeCustomProperties = createSelector(stateSelector, state => state.employee.defaultCustomProperties);
const allEmployees = createSelector(stateSelector, state => state.employee.data);
const basicEmployees = createSelector(stateSelector, state => state.employee.basicData);
const employeeSearchResults = createSelector(stateSelector, state => state.employee.searchResults);
const employeePossibleResults = createSelector(stateSelector, state => state.employee.paginationInfo.count);
const singleEmployee = createSelector(stateSelector, state => state.employee.singleData);
const employeeIsLoading = createSelector(stateSelector, state => state.employee.isLoading);
const employeesError = createSelector(stateSelector, state => state.employee.error);
const empInspectionsSelector = createSelector(stateSelector, state => state.employee.subData.inspections);
const empHistoryLogSelector = createSelector(stateSelector, state => state.employee.subData.historyLog);
const employeeLastUpdatedText = createSelector(stateSelector, state => state.employee.lastUpdated);
const employeeAccountableRelations = createSelector(stateSelector, state => state.employee.subData.accountable);
const employeeProjects = createSelector(stateSelector, state => state.employee.subData.employeeProjects);
const employeeCertifications = createSelector(stateSelector, state => state.employee.subData.certifications);
const employeeFilesSelector = createSelector(stateSelector, state => state.employee.subData.files || []);
const employeeActionsSelector = createSelector(stateSelector, state => state.employee.subData.actions || []);
const employeeHistoryLog = createSelector(stateSelector, state => state.employee.subData.historyLog);
// TODO: We have many identical implementations of this function except the type -> make it generic
const allEmployeeRelations = createSelector(stateSelector, state => {
  const employees = state.employee.basicData.map(emp => ({
    name: emp.displayName,
    externalId: emp.externalId,
    userId: emp.id,
    status: emp.status,
    orgUnitId: null,
    isExternal: emp.isExternal,
  })) as PrivilegeData[];

  // .filter(emp => emp.id !== state.employee.singleData?.id)
  const orgUnits = state.orgUnit.basicData.map(org => ({
    externalId: org.externalId,
    orgUnitId: org.id,
    userId: null,
    name: org.name,
    status: org.status,
  })) as PrivilegeData[];
  return [...employees, ...orgUnits];
});

const allLocationRelations = createSelector(stateSelector, state => {
  const employees = state.employee.basicData.map(emp => ({
    name: emp.displayName,
    externalId: emp.externalId,
    userId: emp.id,
    status: emp.status,
    isExternal: emp.isExternal,
  })) as PrivilegeData[];

  const orgUnits = state.orgUnit.basicData.map(org => ({
    externalId: org.externalId,
    orgUnitId: org.id,
    name: org.name,
    status: org.status,
  })) as PrivilegeData[];
  return [...employees, ...orgUnits];
});

const uniquePositions = (state: RootState): TOption[] | null =>
  Array.from(new Set(state.employee.data.filter(l => l.position !== null).map(l => l.position))).map(position => ({
    id: position || "",
    label: position.toString() || "",
  }));

const allEmployeeExternalIDs = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    state.employee.data
      .filter(l => l.externalId)
      .map(emp => ({
        id: emp.externalId || "",
        label: emp.externalId || "",
      }))
  );

const basicEmployeeExternalIDs = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    state.employee.basicData
      .filter(l => l.externalId)
      .map(emp => ({
        id: emp.externalId || "",
        label: emp.externalId || "",
      }))
  );

const employeeSelectors = {
  all: allEmployees,
  basic: basicEmployees,
  error: employeesError,
  single: singleEmployee,
  isLoading: employeeIsLoading,
  inspections: empInspectionsSelector,
  historyLog: empHistoryLogSelector,
  employeeSearchResults,
  employeePossibleResults,
  employeeCustomProperties,
  employeeLastUpdatedText,
  uniquePositions,
  allEmployeeExternalIDs,
  basicEmployeeExternalIDs,
  allLocationRelations,
  employeeAccountableRelations,
  employeeProjects,
  allEmployeeRelations,
  employeeCertifications,
  files: employeeFilesSelector,
  actions: employeeActionsSelector,
  employeeHistoryLog,
};

//Note: Training Start

const allTrainings = createSelector(stateSelector, state => state.training.data);
const trainingCustomProperties = createSelector(stateSelector, state => state.training.defaultCustomProperties);
const singleTraining = createSelector(stateSelector, state => state.training.singleData);
const trainingIsLoading = createSelector(stateSelector, state => state.training.isLoading);
const trainingError = createSelector(stateSelector, state => state.training.error);
const trainingInspectionsSelector = createSelector(stateSelector, state => state.training.subData.inspections);
const trainingHistoryLogSelector = createSelector(stateSelector, state => state.training.subData.historyLog);
const trainingfilesSelector = createSelector(stateSelector, state => state.training.subData.files || []);
const trainingLastUpdatedText = createSelector(stateSelector, state => state.training.lastUpdated);
const allTrainingParticipants = createSelector(stateSelector, state => state.training.subData.participants);
const allTrainingAccountable = createSelector(stateSelector, state => state.training.subData.accountable);
const trainingHistoryLog = createSelector(stateSelector, state => state.training.subData.historyLog);
const allTrainingEmployees = createSelector(stateSelector, state => {
  return state.employee.basicData.map(emp => ({
    name: emp.displayName,
    externalId: emp.externalId,
    id: emp.id,
    isEmployee: true,
    isExternal: emp.isExternal,
    // phone: emp.phone,
  })) as TrainingTrainee[];
});
const allTrainingEmployeesAsAccountable = createSelector(stateSelector, state => {
  const employees = state.employee.basicData.map(emp => ({
    name: emp.displayName,
    externalId: emp.externalId,
    userId: emp.id,
    status: emp.status,
    orgUnitId: null,
    isExternal: emp.isExternal,
  })) as PrivilegeData[];

  const orgUnits = state.orgUnit.basicData.map(org => ({
    externalId: org.externalId,
    orgUnitId: org.id,
    userId: null,
    name: org.name,
    status: org.status,
  })) as PrivilegeData[];
  return [...employees, ...orgUnits];
});

const trainingSearchResults = createSelector(stateSelector, state => state.training.searchResults);
const trainingPossibleResults = createSelector(stateSelector, state => state.training.paginationInfo.count);

const trainingSelectors = {
  all: allTrainings,
  single: singleTraining,
  isLoading: trainingIsLoading,
  error: trainingError,
  inspections: trainingInspectionsSelector,
  historyLog: trainingHistoryLogSelector,
  files: trainingfilesSelector,
  trainingCustomProperties,
  trainingLastUpdatedText,
  allTrainingParticipants,
  allTrainingEmployees,
  allTrainingAccountable,
  allTrainingEmployeesAsAccountable,
  trainingHistoryLog,
  trainingSearchResults,
  trainingPossibleResults,
};

//Note: Certification Start

const allCertifications = createSelector(stateSelector, state => state.certification.listViewData);
const basicCertifications = createSelector(stateSelector, state => state.certification.basicData);
const certificationsError = createSelector(stateSelector, state => state.certification.error);
const allEmployeeCertificates = createSelector(stateSelector, state => state.certification.listViewData);
const certificationCustomProperties = createSelector(
  stateSelector,
  state => state.certification.defaultCustomProperties
);
const singleCertification = createSelector(stateSelector, state => state.certification.singleData);
const certificationIsLoading = createSelector(stateSelector, state => state.certification.isLoading);
const certificationInspectionsSelector = createSelector(
  stateSelector,
  state => state.certification.subData.inspections
);
const certificationHistoryLogSelector = createSelector(stateSelector, state => state.certification.subData.historyLog);
const allCertificateCards = createSelector(stateSelector, state => state.certification.groupViewData);
const manualCertificateCards = createSelector(stateSelector, state =>
  state.certification.groupViewData.filter(r => r.isManual === true)
);
const certificationLastUpdatedText = createSelector(stateSelector, state => state.certification.lastUpdated);
const certificateAccountableRelations = createSelector(stateSelector, state => state.certification.subData.accountable);
const certificationfilesSelector = createSelector(stateSelector, state => state.certification.subData.files || []);
const certificationHistoryLog = createSelector(stateSelector, state => state.certification.subData.historyLog);
const certificationTrainings = createSelector(stateSelector, state => state.certification.subData.trainings);
const allCertificateRelations = createSelector(stateSelector, state => {
  const employees = state.employee.basicData.map(emp => ({
    name: emp.displayName,
    externalId: emp.externalId,
    userId: emp.id,
    status: emp.status,
    orgUnitId: null,
    isExternal: emp.isExternal,
  })) as PrivilegeData[];

  const orgUnits = state.orgUnit.basicData.map(org => ({
    externalId: org.externalId,
    orgUnitId: org.id,
    userId: null,
    name: org.name,
    status: org.status,
  })) as PrivilegeData[];
  return [...employees, ...orgUnits];
});

const certificationSearchResults = createSelector(stateSelector, state => state.certification.searchResults);
const certificationPossibleResults = createSelector(stateSelector, state => state.certification.paginationInfo.count);

const certificationSelectors = {
  all: allCertifications,
  basic: basicCertifications,
  error: certificationsError,
  allEmployeeCertificates,
  allCertificateCards,
  manualCertificateCards,
  single: singleCertification,
  isLoading: certificationIsLoading,
  inspections: certificationInspectionsSelector,
  historyLog: certificationHistoryLogSelector,
  certificationCustomProperties,
  certificationLastUpdatedText,
  certificateAccountableRelations,
  allCertificateRelations,
  files: certificationfilesSelector,
  certificationHistoryLog,
  certificationTrainings,
  certificationSearchResults,
  certificationPossibleResults,
};

// Note: Dashboard start
const allDashboards = createSelector(stateSelector, state => state.dashboard.dashboards);
const singleDashboard = createSelector(stateSelector, state => state.dashboard.single);
const dashboardLastUpdatedText = createSelector(stateSelector, state => state.dashboard.lastUpdated);

const dashboardSelectors = {
  all: allDashboards,
  single: singleDashboard,
  dashboardLastUpdatedText,
};
// Note: Location start
// const stateLocation = (state: RootState): CategoryState<Location> => state.location;

const allLocationEntities = createSelector(stateSelector, state => state.location.data || []);
const basicLocationEntities = createSelector(stateSelector, state => state.location.basicData || []);
const singleLocationEntity = createSelector(stateSelector, state => state.location.singleData);
const locationEntityIsLoading = createSelector(stateSelector, state => state.location.isLoading);
const locationCustomProperties = createSelector(stateSelector, state => state.location.defaultCustomProperties);
const locationEquipmentCustomProperties = createSelector(
  stateSelector,
  state => state.location.subData.equipmentCustomProps || []
);
const locationLastUpdatedText = createSelector(stateSelector, state => state.location.lastUpdated);
const locationError = createSelector(stateSelector, state => state.location.error);
const locationEquipments = createSelector(stateSelector, state => state.location.subData.equipments || []);
const locationEmployees = createSelector(stateSelector, state => state.location.subData.accountable);
const locationHistoryLog = createSelector(stateSelector, state => state.location.subData.historyLog);
const locationActions = createSelector(stateSelector, state => state.location.subData.actions);
const locationInspectionsSelector = createSelector(stateSelector, state => state.location.subData.inspections || []);
const locationSearchResults = createSelector(stateSelector, state => state.location.searchResults);
const locationPossibleResults = createSelector(stateSelector, state => state.location.paginationInfo.count);

const locationEntitySelectors = {
  all: allLocationEntities,
  basic: basicLocationEntities,
  single: singleLocationEntity,
  isLoading: locationEntityIsLoading,
  locationCustomProperties,
  locationEquipmentCustomProperties,
  error: locationError,
  locationLastUpdatedText,
  locationEquipments,
  locationEmployees,
  locationHistoryLog,
  locationInspectionsSelector,
  locationSearchResults,
  locationPossibleResults,
  actions: locationActions,
};

// Note: Organizational Unit Start
const allOrgUnits = createSelector(stateSelector, state => state.orgUnit.data);
const basicOrgUnits = createSelector(stateSelector, state => state.orgUnit.basicData);
const orgUnitCustomProperties = createSelector(stateSelector, state => state.orgUnit.defaultCustomProperties);
const singleOrgUnit = createSelector(stateSelector, state => state.orgUnit.singleData);
const orgUnitError = createSelector(stateSelector, state => state.orgUnit.error);
const orgUnitLastUpdatedText = createSelector(stateSelector, state => state.orgUnit.lastUpdated);
const orgUnitEmployees = createSelector(stateSelector, state => state.orgUnit.subData.employees);
const orgUnitFilesSelector = createSelector(stateSelector, state => state.orgUnit.subData.files || []);
const orgUnitHistoryLog = createSelector(stateSelector, state => state.orgUnit.subData.historyLog);
const orgUnitAccountableRelations = createSelector(stateSelector, state => state.orgUnit.subData.accountable);
const allOrgUnitRelations = createSelector(stateSelector, state => {
  const employees = state.employee.basicData.map(e => ({
    name: e.displayName,
    externalId: e.externalId,
    status: e.status,
    userId: e.id,
    isExternal: e.isExternal,
  })) as PrivilegeData[];

  // Why was this filtered !
  // .filter(e => e.userId !== state.employee.singleData?.id) ;
  const orgUnits = state.orgUnit.basicData.map(ou => ({
    externalId: ou.externalId,
    name: ou.name,
    status: ou.status,
    orgUnitId: ou.id,
  })) as PrivilegeData[];
  return [...employees, ...orgUnits];
});

const allOrgUnitExternalIDs = (state: RootState): TOption[] | null =>
  state.orgUnit.data
    .filter(ou => ou.externalId)
    .map(ou => ({
      id: ou.externalId || "",
      label: ou.externalId || "",
    }));

const uniqueNames = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.orgUnit.data.filter(ou => ou.name).map(ou => ou.name))).map(name => ({
      id: name || "",
      label: name || "",
    }))
  );

const uniqueParentOrgUnits = (state: RootState): TOption[] | null =>
  Array.from(new Set(state.orgUnit.data.filter(ou => ou.parentId).map(ou => ou.parentId))).map(id => ({
    id: id || "",
    label: state.orgUnit.data.find(ou => ou.id === id)?.name || "",
  }));

const uniqueExternalIds = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.orgUnit.data.filter(l => l.externalId).map(l => l.externalId))).map(externalId => ({
      id: externalId || "",
      label: externalId || "",
    }))
  );

const uniqueManagerUserIds = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.orgUnit.data.filter(l => l.managerUserId).map(l => l.managerUserId))).map(
      managerUserId => ({
        id: managerUserId || "",
        label: fetchEmployeeById(managerUserId) || "",
      })
    )
  );

const orgUnitSelectors = {
  all: allOrgUnits,
  basic: basicOrgUnits,
  single: singleOrgUnit,
  error: orgUnitError,
  files: orgUnitFilesSelector,
  orgUnitCustomProperties,
  orgUnitLastUpdatedText,
  orgUnitEmployees,
  orgUnitHistoryLog,
  orgUnitAccountableRelations,
  allOrgUnitRelations,
  allOrgUnitExternalIDs,
  uniqueManagerUserIds,
  uniqueNames,
  uniqueParentOrgUnits,
  uniqueExternalIds,
};

// Note:  Survey Start

// Note: Tasks start

const allTasks = createSelector(stateSelector, state => state.actions.data);
const searchResults = createSelector(stateSelector, state => state.actions.searchResults);
const possibleResults = createSelector(stateSelector, state => state.actions.paginationInfo.count);
const tasksCustomProperties = createSelector(stateSelector, state => state.actions.defaultCustomProperties);
const singleTask = createSelector(stateSelector, state => state.actions.singleData);
const tasksError = createSelector(stateSelector, state => state.actions.error);
const taskFilesSelector = createSelector(stateSelector, state => state.actions.subData.files || []);
const tasksLastUpdatedText = createSelector(stateSelector, state => state.actions.lastUpdated);
const taskAccountableRelations = createSelector(stateSelector, state => state.actions.subData.accountable);
const allTaskRelations = createSelector(
  stateSelector,
  state =>
    state.employee.basicData.map(emp => ({
      name: emp.displayName,
      externalId: emp.externalId,
      userId: emp.id,
      orgUnitId: null,
      status: emp.status,
      isEmployee: true,
      isExternal: emp.isExternal,
      role: Role.Recipient,
    })) as PrivilegeData[]
);
const taskHistoryLog = createSelector(stateSelector, state => state.actions.subData.historyLog);
const taskLinkedActions = createSelector(stateSelector, state => state.actions.subData.linkedActions);

const uniqueTags = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(
      new Set(
        state.actions.tags.map(tags => ({
          id: tags.name ?? "",
          label: tags.name ?? "",
        }))
      )
    )
  );

const uniqueIds = (state: RootState): TOption[] | null =>
  Array.from(new Set(state.actions.data.filter(l => l.id).map(l => l.id))).map(id => ({
    id: id ?? "",
    label: id.toString() ?? "",
  }));

const tagsOptions = (state: RootState): TOption[] | null => {
  return state.actions.tags.map(e => ({
    id: e.id ?? "",
    label: e.name ?? "",
  }));
};

const tasksSelectors = {
  all: allTasks,
  searchResults: searchResults,
  possibleResults: possibleResults,
  single: singleTask,
  error: tasksError,
  tasksCustomProperties,
  tasksLastUpdatedText,
  taskAccountableRelations,
  allTaskRelations,
  taskHistoryLog,
  taskLinkedActions,
  taskFilesSelector,
  uniqueTags,
  tagsOptions,
  uniqueIds,
};

// Note: Recurrent actions start

const allRecurrentActions = createSelector(stateSelector, state => state.recurrentActions.data);
const recurrentActionsCustomProperties = createSelector(
  stateSelector,
  state => state.recurrentActions.defaultCustomProperties
);
const singleRecurrentActions = createSelector(stateSelector, state => state.recurrentActions.singleData);
const recurrentActionsLastUpdatedText = createSelector(stateSelector, state => state.recurrentActions.lastUpdated);
const recurrentActionsError = createSelector(stateSelector, state => state.recurrentActions.error);

const uniqueRecurrentActionIds = (state: RootState): TOption[] | null =>
  Array.from(new Set(state.recurrentActions.data.filter(l => l.id).map(l => l.id))).map(id => ({
    id: id ?? "",
    label: id.toString() ?? "",
  }));

const uniqueRecurrentActionTitle = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.recurrentActions.data.filter(l => l.title).map(l => l.title))).map(title => ({
      id: title ?? "",
      label: title ?? "",
    }))
  );

const uniqueRecurrentActionResponsible = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(
      new Set(state.recurrentActions.data.filter(l => l.responsibleUserName).map(l => l.responsibleUserName))
    ).map(responsible => ({
      id: responsible ?? "",
      label: responsible ?? "",
    }))
  );
const uniqueRecurrentActionProject = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.recurrentActions.data.filter(l => l.projectName).map(l => l.projectName))).map(
      project => ({
        id: project ?? "",
        label: project ?? "",
      })
    )
  );

const uniqueRecurrentActionRecurrencyRule = (state: RootState): TOption[] | null =>
  Array.from(new Set(state.recurrentActions.data.filter(l => l.recurrencyRule).map(l => l.recurrencyRule))).map(
    recurrencyRule => ({
      id: recurrencyRule ?? "",
      label: recurrencyRule ?? "",
    })
  );

const uniqueRecurrentActionOccurrencesCount = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(
      new Set(
        state.recurrentActions.data.filter(l => l.recurrencyRuleOccurrences).map(l => l.recurrencyRuleOccurrences)
      )
    ).map(recurrencyRuleOccurrences => ({
      id: recurrencyRuleOccurrences ?? "",
      label: recurrencyRuleOccurrences ? recurrencyRuleOccurrences.toString() : "",
    }))
  );

const recurrentActionsSelectors = {
  all: allRecurrentActions,
  single: singleRecurrentActions,
  error: recurrentActionsError,
  recurrentActionsCustomProperties,
  recurrentActionsLastUpdatedText,
  uniqueRecurrentActionIds,
  uniqueRecurrentActionTitle,
  uniqueRecurrentActionResponsible,
  uniqueRecurrentActionProject,
  uniqueRecurrentActionRecurrencyRule,
  uniqueRecurrentActionOccurrencesCount,
};

//Note: Equipment Type Start

const allEquipmentTypes = createSelector(stateSelector, state => state.equipmentType.data);

const equipmentTypesError = createSelector(stateSelector, state => state.equipmentType.error);

const singleEquipmentType = createSelector(stateSelector, state => state.equipmentType.singleData);

const equipmentTypeCustomProperties = createSelector(stateSelector, () => []);

const equipmentTypeIsLoading = createSelector(stateSelector, state => state.equipmentType.isLoading);

const equipmentTypeLastUpdatedText = createSelector(stateSelector, state => state.equipmentType.lastUpdated);

const eqTypeInspTypeRelationSelector = createSelector(
  stateSelector,
  state => state.equipmentType.subData.eqTypeInspTypeRelation || []
);

const equipmentTypeSelectors = {
  all: allEquipmentTypes,

  error: equipmentTypesError,

  single: singleEquipmentType,

  equipmentTypeCustomProperties,

  equipmentTypeIsLoading,

  equipmentTypeLastUpdatedText,

  eqTypeInspTypeRelationSelector,
};

//Note: Inspection Type Start

const allInspectionTypes = createSelector(stateSelector, state => state.inspectionType.data);

const singleInspectionType = createSelector(stateSelector, state => state.inspectionType.singleData);

const inspectionTypeError = createSelector(stateSelector, state => state.inspectionType.error);

const inspectionTypeCustomProperties = createSelector(stateSelector, () => []);

const inspectionTypeIsLoading = createSelector(stateSelector, state => state.inspectionType.isLoading);

const inspectionTypeLastUpdatedText = createSelector(stateSelector, state => state.inspectionType.lastUpdated);

const uniqueUserIds = (state: RootState): TOption[] | null =>
  Array.from(new Set(state.inspectionType.data.filter(it => it.userId).map(it => it.userId))).map(userId => ({
    id: userId || "",
    label: fetchEmployeeById(userId) || "",
  }));

const uniqueOrgUnitIds = (state: RootState): TOption[] | null =>
  Array.from(new Set(state.orgUnit.data.filter(ou => ou.id).map(ou => ou.id))).map(id => ({
    id: id || "",

    label: fetchOrgUnitById(id) || "",
  }));

const inspectionTypeSelectors = {
  all: allInspectionTypes,

  single: singleInspectionType,

  error: inspectionTypeError,

  inspectionTypeCustomProperties,

  inspectionTypeIsLoading,

  inspectionTypeLastUpdatedText,

  uniqueUserIds,

  uniqueOrgUnitIds,
};

//Note: Location Type Start

const allLocationTypes = createSelector(stateSelector, state => state.locationType.data);

const singleLocationType = createSelector(stateSelector, state => state.locationType.singleData);

const locationTypeError = createSelector(stateSelector, state => state.locationType.error);

const locationTypeCustomProperties = createSelector(stateSelector, () => []);

const locationTypeIsLoading = createSelector(stateSelector, state => state.locationType.isLoading);

const locationTypeLastUpdatedText = createSelector(stateSelector, state => state.locationType.lastUpdated);

const locTypeInspTypeRelationSelector = createSelector(
  stateSelector,
  state => state.locationType.subData.locTypeInspTypeRelations || []
);

const locationTypeSelectors = {
  all: allLocationTypes,

  single: singleLocationType,

  error: locationTypeError,

  locationTypeCustomProperties,

  locationTypeIsLoading,

  locationTypeLastUpdatedText,

  locTypeInspTypeRelationSelector,
};

//Note: Custom Property Start

const allCustomProperties = createSelector(stateSelector, state => state.customProperty.data);
const customPropertiesError = createSelector(stateSelector, state => state.customProperty.error);

const singleCustomProperty = createSelector(stateSelector, state => state.customProperty.singleData);

// TODO: Refactor. Yes, custom properties can't have their own custom properties, but it's required. I'm sorry for this nonsense

const customPropertyCustomProperties = createSelector(stateSelector, () => []);

const customPropertyIsLoading = createSelector(stateSelector, state => state.customProperty.isLoading);

const customPropertyLastUpdatedText = createSelector(stateSelector, state => state.customProperty.lastUpdated);

const customPropertySelectors = {
  all: allCustomProperties,
  error: customPropertiesError,
  single: singleCustomProperty,
  customPropertyCustomProperties,
  customPropertyIsLoading,
  customPropertyLastUpdatedText,
};

// Note: Filter
const filterSelector = (pageId: CategoryId, entityKey: string) =>
  createSelector(stateSelector, state => {
    if (state.filter.filters[pageId]) {
      return state.filter.filters[pageId][entityKey]?.activeFilters;
    }
    return [];
  });

const idsFilterSelector = (pageId: CategoryId, entityKey: string) =>
  createSelector(stateSelector, state => {
    if (state.filter.filters[pageId]) {
      return state.filter.filters[pageId][entityKey]?.idsFilter;
    }
    return [];
  });

const orderSelector = (pageId: CategoryId) =>
  createSelector(stateSelector, state => {
    if (state.filter.filterValues[pageId]) {
      return state.filter.filterValues[pageId]?.order;
    }
    return undefined;
  });

const getCurrentUser = createSelector(stateSelector, state => state.user);
const userSelectors = {
  getCurrentUser,
};

//Note: Issue Type Start
const allIssueTypes = createSelector(stateSelector, state => state.issueType.data);
const singleIssueType = createSelector(stateSelector, state => state.issueType.singleData);
const issueTypeCustomProperties = createSelector(stateSelector, () => []);
const issueTypeLastUpdatedText = createSelector(stateSelector, state => state.issueType.lastUpdated);
const issueTypeActions = createSelector(stateSelector, state => state.issueType.subData.actions);
const issueTypeError = createSelector(stateSelector, state => state.issueType.error);

const uniqueIssueTypeId = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.issueType.data.filter(l => l.id).map(l => l.id))).map(id => ({
      id: id ?? "",
      label: id.toString() ?? "",
    }))
  );

const uniqueIssueTypeName = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.issueType.data.filter(l => l.name).map(l => l.name))).map(name => ({
      id: name ?? "",
      label: name ?? "",
    }))
  );

const uniqueIssueTypeExternalId = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.issueType.data.filter(l => l.externalId).map(l => l.externalId))).map(externalId => ({
      id: externalId ?? "",
      label: externalId ?? "",
    }))
  );

const uniqueIssueTypeParentId = (state: RootState): TOption[] | null =>
  addEmptyValueForFiltering(
    Array.from(new Set(state.issueType.data.filter(l => l.parentId).map(l => l.parentId))).map(parentId => ({
      id: parentId ?? "",
      label: parentId?.toString() ?? "",
    }))
  );

const issueTypeSelectors = {
  all: allIssueTypes,
  single: singleIssueType,
  issueTypeCustomProperties,
  error: issueTypeError,
  actions: issueTypeActions,
  issueTypeLastUpdatedText,
  uniqueIssueTypeId,
  uniqueIssueTypeName,
  uniqueIssueTypeExternalId,
  uniqueIssueTypeParentId,
};

const allWeebhooks = createSelector(stateSelector, state => state.webhooks.data);
const singleWebhook = createSelector(stateSelector, state => state.webhooks.singleData);
const webhookError = createSelector(stateSelector, state => state.webhooks.error);
const webhooksIsLoading = createSelector(stateSelector, state => state.webhooks.isLoading);
const webhooksLastUpdatedText = createSelector(stateSelector, state => state.locationType.lastUpdated);
const webhooksCustomProperties = createSelector(stateSelector, () => []);
const webhookPossibleResults = createSelector(stateSelector, state => state.webhooks.paginationInfo.count);
const webhookLogs = createSelector(stateSelector, state => state.webhooks.subData.webhookLogs);
const webhookEvents = createSelector(stateSelector, state => state.webhooks.subData.webhookEvents);

const webhooksSelectors = {
  all: allWeebhooks,
  single: singleWebhook,
  error: webhookError,
  webhookEvents,
  webhooksIsLoading,
  webhooksLastUpdatedText,
  webhooksCustomProperties,
  webhookPossibleResults,
  webhookLogs,
};

export {
  pageSelectors,
  equipmentSelectors,
  equipmentTypeSelectors,
  inspectionSelectors,
  inspectionTypeSelectors,
  employeeSelectors,
  // locationSelectors,
  filterSelector,
  idsFilterSelector,
  orderSelector,
  trainingSelectors,
  certificationSelectors,
  dashboardSelectors,
  locationEntitySelectors,
  locationTypeSelectors,
  orgUnitSelectors,
  tasksSelectors,
  customPropertySelectors,
  userSelectors,
  issueTypeSelectors,
  recurrentActionsSelectors,
  webhooksSelectors,
};
