import "./ErrorPageTemplate.less";

import { Space } from "antd";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as Arrow } from "../../media/message-arrow.svg";
import { RootState } from "../../store/store";
import Layout from "../Layout/Layout";

type ErrorPageTemplateProps = {
  titleTranslationKey: string;
};

const ErrorPageTemplate: React.FC<ErrorPageTemplateProps> = ({ titleTranslationKey }) => {
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  return (
    <Layout isSideBarOnly={true} entityKey="notAllowed">
      <Space className="not-allowed-container" direction="vertical" align="center">
        <Space size={1} direction="horizontal" align="baseline">
          <Arrow className="not-allowed-page-arrow" fill={primaryColor} />
          <div className="not-allowed-title">
            <Trans i18nKey={titleTranslationKey ?? ""} />
          </div>
        </Space>
      </Space>
    </Layout>
  );
};

export default ErrorPageTemplate;
