import { useMobileSingleViewContext } from "../../components/SingleViewCardMobileFirst/context/useMobileSingleViewContext";
import { CategoryId } from "../../types/page";
import { MOBILE_PATH } from "../functions";

const useCategoryRedirect = (category?: CategoryId, id?: string | number): (() => void) | undefined => {
  const { openNextPage } = useMobileSingleViewContext();

  if (!category || !id) return undefined;

  const handleRedirect = () => {
    openNextPage(`/${category}/${id}${MOBILE_PATH}`);
  };

  return handleRedirect;
};

export default useCategoryRedirect;
