import "./layout.less";

import { Layout as AntdLayout, Spin } from "antd";
import React, { Suspense, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { HeaderBar } from "../../components/CategoryHeader/HeaderBar";
import { TableSearchBar } from "../../components/CategoryHeader/TableSearchBar";
import PageConfigurationContext from "../../context/pageContext";
import { ReactComponent as Agenda } from "../../media/agenda.svg";
import { ReactComponent as SideBarArrow } from "../../media/arrow-side-bar.svg";
import { RootState, useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage } from "../../types/page";
import { ParamsKeys } from "../../types/utility";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import { isFlutterWebView } from "../../utils/hooks/useIsMobile";
import useMobilePullToRefreshData from "../../utils/hooks/useMobilePullToRefreshData";
import useRouter from "../../utils/hooks/useRouter";
// import CategorySingleMobileFirst from "../Categories/CategorySingle/CategorySingleMobileFirst";
import SideBar from "./SideBar";

const TodoList = React.lazy(() => import("../TodoList/TodoList"));

const { Content, Header, Sider } = AntdLayout;

type LayoutProps = {
  contentStyles?: React.CSSProperties;
  data?: Record<string, unknown>[];
  hasTableSearchBar?: boolean;
  isSideBarOnly?: boolean;
  entityKey: string;
  handleColumnSelectionVisibility?: (visible: boolean) => void;
  selectedRows?: Record<string, unknown>[];
  isCustomPage?: boolean;
  customRefreshAction?: () => void;
  // searchResultColumns?: Partial<ColumnType<Record<string, unknown>>>[];
  // searchKeys?: string[];
};

const Layout: React.FC<LayoutProps> = ({
  children,
  contentStyles,
  data,
  hasTableSearchBar = true,
  isSideBarOnly = false,
  entityKey,
  handleColumnSelectionVisibility,
  selectedRows,
  isCustomPage,
  customRefreshAction,
  // searchResultColumns,
  // searchKeys,
}) => {
  const { id, groupView, listView, hasListView, hasGroupView } = useContext(
    PageConfigurationContext
  ) as CategoryPage<BaseEntityType>;

  const [isSiderVisible, setSiderVisibleStatus] = useState<boolean>(false);
  const settings = useSelector((state: RootState) => state.user.settings);
  const defaultPageSize = useSelector((state: RootState) => state.user.companySettings?.defaultPageSize);
  const { track } = useInitTrackEvents();
  const dispatch = useAppDispatch();
  const { location } = useRouter<{ referer: Location }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const isMobile = Boolean(urlSearchParams.get(ParamsKeys.MOBILE)) || isFlutterWebView();

  const onSiderTriggerClick = () => {
    track({ eventName: HBEventName.ToggleTodoList, data: { state: isSiderVisible ? "on" : "off" } });
    setSiderVisibleStatus(!isSiderVisible);
  };

  const getSiderClassName = () => {
    const direction = settings?.direction || "ltr";
    if (isSiderVisible) {
      return `sider-trigger-button sider-trigger-button-shape-${direction} sider-trigger-button-opened-${direction} arrow-closed-${direction}`;
    } else {
      return `sider-trigger-button sider-trigger-button-shape-${direction} sider-trigger-button-location-${direction} arrow-opened-${direction}`;
    }
  };
  const rootLocation = `/${id}`;

  const handleDataRefetch = () => {
    if (customRefreshAction) {
      customRefreshAction();
      endRefreshData();
    } else if (location.pathname === rootLocation) {
      if (hasListView && listView?.table.type === "paginatedTable" && listView?.table.fetchPaginatedData) {
        dispatch(
          listView?.table.fetchPaginatedData({ forceUpdate: true, page: 1, pageSize: defaultPageSize })
        ).then(() => endRefreshData());
      } else if (hasGroupView && hasListView && listView?.table.fetchListViewData) {
        dispatch(listView?.table.fetchListViewData(true)).then(() => endRefreshData());
      }
      if (hasGroupView && groupView) {
        dispatch(groupView?.fetchCardThunk(true)).then(() => endRefreshData());
      }
    }
  };

  const { endRefreshData, PullToRefreshOnWeb, PullToRefreshContextProvider } = useMobilePullToRefreshData({
    refreshHandler: handleDataRefetch,
  });

  const handlePullToRefreshFromWeb = async () => handleDataRefetch();

  return (
    <PullToRefreshContextProvider>
      <PullToRefreshOnWeb onRefresh={handlePullToRefreshFromWeb}>
        <AntdLayout hasSider={true}>
          {!isMobile && <SideBar />}
          {/* {!isMobile && (
            <Sider
              trigger={null}
              collapsible
              collapsed={showSingleViewSider}
              className={`todo-list-sider ${showSingleViewSider ? "" : "hidden-sider"}`}
            >
              <CategorySingleMobileFirst isNewEntity={false} />
            </Sider>
          )} */}
          <AntdLayout
            className={isSideBarOnly || isMobile ? "layout-no-padding" : "layout"}
            data-testid="categorySingle__layout"
          >
            {!isSideBarOnly && !isMobile && (
              <Header className="layout__header">
                <HeaderBar
                  addButtonPathAction={!isCustomPage}
                  handleColumnSelectionVisibility={handleColumnSelectionVisibility}
                  selectedRows={selectedRows}
                  customRefreshAction={customRefreshAction}
                />
                {hasTableSearchBar && (
                  <>
                    {/* <Divider style={{ margin: 0 }} /> */}
                    <TableSearchBar data={data!} entityKey={entityKey} />
                  </>
                )}
              </Header>
            )}
            <Content className="layout__content" style={contentStyles}>
              {children}
            </Content>
            {!isMobile && (
              <div className={getSiderClassName()} onClick={onSiderTriggerClick}>
                <SideBarArrow id="arrow" />
                <Agenda />
              </div>
            )}
          </AntdLayout>
          {!isMobile && (
            <Sider
              trigger={null}
              collapsible
              collapsed={isSiderVisible}
              className={`todo-list-sider ${isSiderVisible ? "" : "hidden-sider"}`}
            >
              <Suspense fallback={<Spin size="large" spinning />}>{isSiderVisible && <TodoList />}</Suspense>
            </Sider>
          )}
        </AntdLayout>
      </PullToRefreshOnWeb>
    </PullToRefreshContextProvider>
  );
};

export default Layout;
