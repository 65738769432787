import { Query, CubejsApi } from "@cubejs-client/core";
import { useCubeQuery, UseCubeQueryResult } from "@cubejs-client/react";
import { useEffect, useState } from "react";

type UseCubeQueryOptions = {
  /**
   * A `CubejsApi` instance to use. Taken from the context if the param is not passed
   */
  cubejsApi?: CubejsApi;
  /**
   * Query execution will be skipped when `skip` is set to `true`. You can use this flag to avoid sending incomplete queries.
   */
  skip?: boolean;
  /**
   * Use continuous fetch behavior. See [Real-Time Data Fetch](real-time-data-fetch)
   */
  subscribe?: boolean;
  /**
   * When `true` the resultSet will be reset to `null` first
   */
  resetResultSetOnChange?: boolean;
};

type UseRenewCubeQueryProps = {
  query: Query | Query[];
  options?: UseCubeQueryOptions;
};

const MAX_COUNT_OF_ERRORS = 10;

const useRenewCubeQuery = <T>({ query, options }: UseRenewCubeQueryProps): UseCubeQueryResult<T> => {
  const { resultSet, isLoading: queryLoadingState, progress, refetch, error } = useCubeQuery<T>(query, options);
  const [isLoading, setIsLoading] = useState(false);
  const [countOfErrors, setCountOfErrors] = useState(0);

  useEffect(() => {
    setIsLoading(queryLoadingState);
  }, [queryLoadingState]);

  useEffect(() => {
    if (error && countOfErrors < MAX_COUNT_OF_ERRORS) {
      setCountOfErrors(countOfErrors + 1);
      refetch();
      setIsLoading(true);
    }
  }, [error]);

  return { resultSet, isLoading, progress, refetch, error };
};

export default useRenewCubeQuery;
