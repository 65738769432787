import "./MobileCardTemplate.less";

import { useTranslation } from "react-i18next";

import { traineeStatusField } from "../../../../pages/pageConfig/category/training/trainingConfigPageData";
import { TrainingTrainee } from "../../../../types/training";
import CardLabel from "../core/CardLabel/CardLabel";
import CardLabelWithIcon, { ICON_NAMES } from "../core/CardLabelWithIcon/CardLabelWithIcon";
import CardTitle from "../core/CardTitle/CardTitle";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import RowContainer from "../core/RowContainer/RowContainer";
import StatusMark from "../core/StatusMark/StatusMark";
import EditableInputMobileFirstWithConfig from "./EditableInputMobileFirstWithConfig";

type TProps = {
  entity: TrainingTrainee;
};

const TraineesMobileCardTemplate: React.FC<TProps> = ({ entity }) => {
  const { t } = useTranslation();
  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.externalId && <CardTitle title={entity.externalId} />}
        {entity.name && (
          <CardLabelWithIcon label={entity.name} iconName={ICON_NAMES.USER} category="employee" id={entity.id!} />
        )}
      </ContainerWithDivider>
      <RowContainer>
        {entity.status && <StatusMark statusLabel={entity.status || ""} />}
        <CardLabel label={`${t("External")} - ${entity.isExternal ? t("Yes") : t("No")}`} />
      </RowContainer>
      <EditableInputMobileFirstWithConfig entity={entity} config={traineeStatusField} />
    </CardWithSelect>
  );
};

export default TraineesMobileCardTemplate;
