import { CaretDownOutlined, InboxOutlined } from "@ant-design/icons";

import { Space } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { RcFile } from "antd/lib/upload/interface";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as FileIcon } from "../../../media/file.svg";
import { isFlutterWebView } from "../../../utils/hooks/useIsMobile";
import useFormManagmentEvents, { MobileEvent } from "../../EmbeddedModal/useFormManagmentEvents";
import CardLabelWithIcon, {
  ICON_NAMES,
} from "../../SingleViewCardMobileFirst/components/core/CardLabelWithIcon/CardLabelWithIcon";

type TProps = {
  onFileSelected: (file: RcFile) => boolean | Promise<void | File | Blob>;
};

const FILE_INPUT_ID = "fileInput";

const MobileUpload: React.FC<TProps> = ({ onFileSelected }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const draggerRef = useRef<any>(null);

  const { t } = useTranslation();
  const { fireMobileEvent } = useFormManagmentEvents();

  const onUploadButtonClick = () => {
    if (isFlutterWebView()) {
      fireMobileEvent(MobileEvent.UploadFileClicked, { inputSelector: `#${FILE_INPUT_ID}` });
    } else {
      draggerRef.current?.upload?.uploader?.onClick();
    }
  };

  return (
    <Space direction="vertical" size={9} className="buttons-space">
      <div onClick={onUploadButtonClick} className="button-upload-file">
        <CardLabelWithIcon className="without-background" label={t("UploadDocument")} iconName={ICON_NAMES.FILE} />
        <CaretDownOutlined />
      </div>

      <Dragger
        id={FILE_INPUT_ID}
        ref={draggerRef}
        style={{ display: "none" }}
        maxCount={1}
        beforeUpload={onFileSelected}
        itemRender={(node, file) => (
          <div className="uploaded-file-section">
            <FileIcon className="file-icon" />
            {file?.name}
          </div>
        )}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t("ClickOrDropFile")}</p>
      </Dragger>
    </Space>
  );
};

export default MobileUpload;
