import { DragOutlined } from "@ant-design/icons";
import { Annotation, QueryOrder } from "@cubejs-client/core";

import { List } from "antd";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { updateChart } from "../../../../../../store/slices/dashboard";
import { useAppDispatch } from "../../../../../../store/store";
import { DimensionOrder, UserOrder } from "../../../../../../types/dashboard";
import { isCompareDateRangeQuery } from "../../../../../../utils/dashboard/dashboards";
import { ChartContext } from "../../../HBChart";
import OrderDimensionItem from "./OrderDimensionItem";

export type OrdersOptions = {
  dimension?: string;
  title?: string;
  order?: QueryOrder | "none";
};

export default function OrderDimensions() {
  const { chart } = useContext(ChartContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const calculateOrderFor: (
    queryPart: string[] | undefined,
    annotationPart: Record<string, Annotation> | undefined
  ) => OrdersOptions[] | undefined = (queryPart, annotationPart) => {
    return queryPart?.reduce<OrdersOptions[]>((acc, d) => {
      const label = annotationPart && annotationPart[d]?.title;
      const userOrder = chart?.userOrders?.find(od => od.label === d);
      let order: QueryOrder | "none" = "none";
      if (userOrder) {
        order = chart?.orders?.find(o => o.sort === userOrder.order)?.value || "none";
      }
      if (label) {
        acc.push({
          title: label,
          dimension: d,
          order: order,
        });
      }
      return acc;
    }, []);
  };

  const calculateOrders = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryType = chart?.resultSet?.queryType;
    const results = isCompareDateRangeQuery(queryType) ? chart?.resultSet?.decompose()[0] : chart?.resultSet;
    const dimensions = results?.query().dimensions;
    const timeDimensions = results?.query().timeDimensions;
    const measures = results?.query().measures;

    const dimensionOrders = calculateOrderFor(dimensions, results?.annotation().dimensions);
    const measuresOrders = calculateOrderFor(measures, results?.annotation().measures);
    const timeDimensionsOrders = timeDimensions?.reduce<OrdersOptions[]>((acc, td) => {
      if (td.granularity) {
        const label = results?.annotation().timeDimensions[`${td.dimension}.${td.granularity}`]?.title;
        const userOrder = chart?.userOrders?.find(od => od.label === td.dimension);
        let order: QueryOrder | "none" = "none";
        if (userOrder) {
          order = chart?.orders?.find(o => o.sort === userOrder.order)?.value || "none";
        }
        if (label) {
          acc.push({
            title: label,
            dimension: `${td.dimension}`,
            order: order,
          });
        }
      }
      return acc;
    }, []);

    let tmpOrders: OrdersOptions[] = [];
    if (dimensionOrders) {
      tmpOrders = tmpOrders.concat(dimensionOrders);
    }
    if (timeDimensionsOrders) {
      tmpOrders = tmpOrders.concat(timeDimensionsOrders);
    }
    if (measuresOrders) {
      tmpOrders = tmpOrders.concat(measuresOrders);
    }
    return tmpOrders;
  };

  const [orders, setOrders] = useState<OrdersOptions[]>();

  const [updateOrders, setUpdateOrders] = useState<boolean>(false);

  useEffect(() => {
    const o = calculateOrders().sort((a, b) => {
      if (orders) {
        return (
          orders?.findIndex(f => f.dimension === a.dimension) - orders?.findIndex(f => f.dimension === b.dimension)
        );
      }
      return 0;
    });
    setOrders(o);
  }, [chart?.resultSet]);

  const onOrderChanged = () => {
    setUpdateOrders(true);
  };

  useEffect(() => {
    if (chart && updateOrders) {
      const updatedOrders = orders?.reduce<DimensionOrder[]>((acc, o) => {
        if (o.order) {
          acc.push({ sort: o.title || "", value: o.order });
        }
        return acc;
      }, []);
      const updatedUserOrders = orders?.reduce<UserOrder[]>((acc, o) => {
        if (o.order) {
          acc.push({
            label: o.dimension || "",
            order: o.title || "",
          });
        }
        return acc;
      }, []);
      dispatch(updateChart({ ...chart, orders: updatedOrders, userOrders: updatedUserOrders }));
      setUpdateOrders(false);
    }
  }, [updateOrders]);

  return (
    <div className="orderDimensions">
      <div>{t("OrderBy")}</div>
      <DragDropContext
        onDragEnd={result => {
          if (result.destination && orders) {
            const items = Array.from(orders);
            const [reorderedItems] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItems);
            setOrders(items);
            setUpdateOrders(true);
          }
        }}
      >
        <Droppable droppableId="ordersList">
          {providedParent => (
            <div {...providedParent.droppableProps} ref={providedParent.innerRef} className="orderDimensionsContainer">
              <List
                size="small"
                className="ordersList"
                dataSource={orders}
                renderItem={(item, index) => (
                  <Draggable draggableId={item.dimension || `dimension-${index}`} index={index} key={item.dimension}>
                    {provided => (
                      <List.Item
                        key={item.dimension}
                        className="orders"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <div className="dimensionOrderItem">
                          <div className="dimensionOrderItemDragButton" {...provided.dragHandleProps}>
                            <DragOutlined />
                          </div>
                          <OrderDimensionItem onOrderChanged={onOrderChanged} item={item} />
                        </div>
                      </List.Item>
                    )}
                  </Draggable>
                )}
                itemLayout="horizontal"
              />
              {providedParent.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
