import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { useSelector } from "react-redux";

import { AppSelector } from "../../selectors";
import { RootState, useAppDispatch } from "../../store/store";
import { ColumnType } from "../../types/page";
import { FuseSearch } from "./FuseSearch";

type TProps = {
  resultColumns?: Partial<ColumnType<Record<string, unknown>>>[] | null;
  searchKeys?: string[] | null;
  currentRow?: Record<string, unknown>;
  dataSelector: AppSelector<Record<string, unknown>[], RootState>;
  resultClickAction: ActionCreatorWithPayload<{ row: Record<string, unknown>; targetEntity: Record<string, unknown> }>;
  defaultValue?: string;
  placeholder?: string; // Placeholder for the input;
  prefixIcon?: React.ReactNode;
};

export const EditableCellSearch = ({
  resultColumns,
  searchKeys,
  dataSelector,
  currentRow,
  resultClickAction,
  defaultValue,
  placeholder,
  prefixIcon,
}: TProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const data = useSelector(dataSelector);
  const handleOnResultClick = (record: Record<string, unknown>) => {
    dispatch(resultClickAction({ row: currentRow!, targetEntity: record }));
  };
  return (
    <FuseSearch
      data={data}
      resultColumns={resultColumns}
      searchKeys={searchKeys}
      onResultClick={handleOnResultClick}
      defaultValue={defaultValue}
      prefixIcon={prefixIcon}
      placeholder={placeholder}
    />
  );
};
