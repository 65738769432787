import { RcFile } from "antd/lib/upload";
import { useEffect, useState } from "react";

import { PartialBaseField } from "../../types/page";

type TProps = {
  fields?: {
    primaryFields: PartialBaseField[];
    secondaryFields?: PartialBaseField[];
  };
};

const useNewEntityRecord = ({ fields }: TProps) => {
  const [entity, setEntity] = useState<{ [index: string]: string | boolean | number | RcFile | null }>({});

  useEffect(() => {
    if (fields) {
      const allFields = fields.secondaryFields?.length
        ? [...fields.primaryFields, ...fields.secondaryFields]
        : [...fields.primaryFields];
      const initialState: { [index: string]: string | boolean | number | null } = {};
      allFields.forEach(f => {
        if (!f.placeholder) {
          initialState[f.id] = f.defaultValue || f.value(entity);
        }
      });
      setEntity(initialState);
    }
  }, [fields]);

  const onChange = (id: string) => (value: string | number | boolean | null) => {
    setEntity(prevEntity => ({ ...prevEntity, [id]: value }));
  };

  const onFiledValueChange = (field: Record<string, string | number | RcFile | boolean | null>) => {
    setEntity(prevEntity => ({ ...prevEntity, ...field }));
  };

  return {
    entity,
    setEntity,
    onChange,
    onFiledValueChange,
  };
};

export default useNewEntityRecord;
