import "./ScheduleReportModal.less";

import { Button, DatePicker, Form, Input, Modal, Space, TimePicker } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { deleteScheduledReport, updateScheduledReport } from "../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../store/store";
import { RecipientEntityType, ScheduledReport } from "../../../types/dashboard";
import UsersOrgUnitsSelector from "../DashboardSettings/UsersOrgUnitsSelector";
import RRuleComponent from "./SheduleComponent";

type ScheduledReportModalProps = {
  open: boolean;
  onClose: () => void;
};

export default function ScheduledReportModal({ open, onClose }: ScheduledReportModalProps) {
  const scheduleReport = useSelector((state: RootState) => state.dashboard.single.scheduledReport.scheduleReport);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ScheduledReport>();
  const employeeBasicData = useSelector((state: RootState) => state.employee.basicData);
  const orgUnitBasicData = useSelector((state: RootState) => state.orgUnit.basicData);
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>(
    scheduleReport ? scheduleReport.selectedEmployeeRecipients.map(er => er.id) : []
  );
  const [selectedOrgUnits, setSelectedOrgUnits] = useState<number[]>(
    scheduleReport ? scheduleReport.selectedOrgUnitRecipients.map(er => er.id) : []
  );

  useEffect(() => {
    if (scheduleReport) {
      setSelectedEmployees(scheduleReport.selectedEmployeeRecipients.map(er => er.id));
      setSelectedOrgUnits(scheduleReport.selectedOrgUnitRecipients.map(er => er.id));
    }
  }, [scheduleReport]);

  const onReportSubmitted: (values: ScheduledReport) => void = values => {
    const updatedValues = { ...values };
    updatedValues.selectedEmployeeRecipients = employeeBasicData
      .filter(e => selectedEmployees.includes(e.id))
      .map(e => ({ entityType: RecipientEntityType.Employee, id: e.id, name: e.displayName, dependsOn: [] }));
    updatedValues.selectedOrgUnitRecipients = orgUnitBasicData
      .filter(e => selectedOrgUnits.includes(e.id))
      .map(e => ({ entityType: RecipientEntityType.OrgUnit, id: e.id, name: e.name, dependsOn: [] }));
    dispatch(updateScheduledReport({ ...scheduleReport, ...updatedValues }));
    onClose();
  };

  const onModalClosed: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void = () => {
    onClose();
  };

  const onEmployeeSelected = (value: number[]) => {
    setSelectedEmployees(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onOrgUnitSelected = (value: any[]) => {
    setSelectedOrgUnits(value.map(v => v.value));
  };

  if (!scheduleReport) return null;
  return (
    <Modal
      open={open}
      title={t("ScheduleReportButton")}
      maskClosable={false}
      className="schedule-report-modal"
      closable
      onCancel={onModalClosed}
      footer={
        <Space>
          <Button type="primary" onClick={form.submit}>
            {t("Save")}
          </Button>
          <Button type="default" onClick={onModalClosed}>
            {t("Cancel")}
          </Button>
          <Button
            type="default"
            onClick={() => {
              if (scheduleReport.id !== 0) dispatch(deleteScheduledReport(scheduleReport.id));
              onClose();
            }}
          >
            {t("Delete")}
          </Button>
        </Space>
      }
    >
      <Form form={form} name="scheduleReport" initialValues={scheduleReport} onFinish={onReportSubmitted}>
        <Form.Item label={t("ScheduleReportRecipients")}>
          <UsersOrgUnitsSelector
            employeeValues={selectedEmployees}
            orgUnitValues={selectedOrgUnits}
            onEmployeeSelected={onEmployeeSelected}
            onOrgUnitSelected={onOrgUnitSelected}
            selectOU={true}
            selectUsers={true}
          />
        </Form.Item>
        <Form.Item name="subject" label={t("ScheduleReportSubject")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="date" label={t("ScheduleReportDate")} rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item name="startTime" label={t("ScheduleReportStart")} rules={[{ required: true }]}>
          <TimePicker format="HH:mm" />
        </Form.Item>
        <RRuleComponent form={form} fieldName="recurrenceRule" />
      </Form>
    </Modal>
  );
}
