import { Menu } from "antd";
import { useTranslation } from "react-i18next";

import { DotsMenuAction } from "../../../types/page";

type TProps = {
  dropdownButtonActions: DotsMenuAction<Record<string, unknown>>[] | undefined;
  selectedEntities: Record<string, unknown>[];
  handleDotsMenuClick: (action: DotsMenuAction<Record<string, unknown>>, skipConfirmation?: boolean) => Promise<void>;
  isExportEnabled?: boolean;
};

const DotsMenu: React.FC<TProps> = ({
  dropdownButtonActions,
  selectedEntities,
  handleDotsMenuClick,
  isExportEnabled = true,
}) => {
  const { t } = useTranslation();

  return (
    <Menu selectable={false}>
      {dropdownButtonActions?.map(option => (
        <Menu.Item
          key={option.label}
          disabled={option.type !== "inline" ? !selectedEntities.length : false} // TODO: This assumes that this menu will be used only for bulk actions that require selection, otherwise - this logic has to be extended
          onClick={() => handleDotsMenuClick(option)} // TODO: This assumes that this menu will be used only for bulk actions that require selection, otherwise - this logic has to be extended
        >
          {t(option.label)}
        </Menu.Item>
      ))}
      {isExportEnabled && (
        <Menu.Item
          key="export"
          onClick={() => handleDotsMenuClick({ action: () => "export", label: "export", type: "inline" })}
        >
          {t("ExportToExcelButton")}
        </Menu.Item>
      )}
    </Menu>
  );
};

export default DotsMenu;
