import "./webhookContextCondition.less";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import { CustomComponentProps } from "../TableCellRenderer/TableCellRenderer";
import { SelectListModal } from "./SelectListModal";

export const WebhookContextConditionModal: React.FC<CustomComponentProps> = ({ onChange, managedValue }) => {
  const { usersQuickCreateData } = useSelector((state: RootState) => state.actions);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const allReviews = usersQuickCreateData.map(x => ({
    id: x.reviewId,
    label: x.reviewName,
    projectId: x.projectId,
  }));

  return (
    <>
      <span className="pointer" onClick={() => setModalVisible(true)}>
        {t("SelectReviews")}
      </span>
      <SelectListModal
        onChange={selectedReviews => {
          onChange(selectedReviews?.map(id => `ReviewId=${id}`).join("|"));
        }}
        managedValue={managedValue}
        data={allReviews}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title="SelectReviews"
      />
    </>
  );
};
