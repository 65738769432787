import { unwrapResult } from "@reduxjs/toolkit";

import { Modal } from "antd";
import fileDownload from "js-file-download";

import { AddButtonActions, SingleViewActions, SystemActions } from "../consts/listViewActions";
import { isAdmin } from "../pages/pageConfig/category/utilities";
import FormSettingsMenu from "../pages/ProfileMenu/FormSettingsMenu/FormSettingsMenu";
import { hbApi } from "../store/api";
import { cancelTraining } from "../store/slices/training";
import { forgotPassword } from "../store/slices/user";
import store, { AppDispatch } from "../store/store";
import { EmployeeCertificateListView } from "../types/certification";
import { Employee } from "../types/employee";
import { CategoryId, DropDownAction } from "../types/page";
import { TrainingStatus } from "../types/training";
import { UserState } from "../types/user";
import { ExportMapping } from "../types/utility";
import { isRoleAdminLevel } from "../utils/functions";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../utils/gridifyQueryHelper";

export const ColumnManagementAction = {
  id: SystemActions.ColumnManagement,
  title: "ManageColumns",
  showColumnManagement: (): boolean => true,
  isDataOperation: false,
};

export const ResetColumnsWidthAction = {
  id: SystemActions.ResetColumnsWidth,
  title: "ResetColumnsWidth",
  isDataOperation: false,
};

export const QuickCreateAppointment = {
  id: AddButtonActions.QuickCreateAppointment,
  title: "FillSurvey",
  isDataOperation: false,
};

export const AddNewAction = {
  id: SystemActions.AddNew,
  title: "CreateOrReportAnAction",
  isDataOperation: false,
};

export const ImportAction = {
  id: SystemActions.Import,
  title: "ImportButton",
  link: () => ({
    path: "import",
  }),
  canAccess: (): boolean => isAdmin(),
  isDataOperation: false,
};

export const CreateTrainingWithEmployeesAction = {
  id: SystemActions.CreateTrainingWithEmployees,
  title: "CreateTraining",
  link: () => ({ path: "/training/new" }),
};

export const SetActionStatus = {
  id: SystemActions.SetActionStatus,
  title: "CancelAction",
  isDataOperation: true,
};

export const ExportAction = {
  id: SystemActions.Export,
  title: "ExportToExcelButton",
  isDataOperation: true,
};

export const GetFiltersUrl = {
  id: SystemActions.GetFilterUrl,
  title: "GetFiltersUrl",
  isDataOperation: false,
};

export const SetFiltersToDefault = {
  id: SystemActions.SetFiltersToDefault,
  title: "SetFiltersToDefault",
  isDataOperation: false,
};

export const FormSettings = {
  id: SystemActions.FormSettings,
  title: "FormSettings",
  content: <FormSettingsMenu />,
  isDataOperation: false,
  stateBaseAccess: (user: UserState) => isRoleAdminLevel(user.settings.role) || user.personalSettings.hasForms,
};

export const ResetPassword = {
  id: SingleViewActions.ResetPassword,
  title: "ResetPasswordTitle",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (_: any, __: string, ___?: any[], t?: any, dispatch?: AppDispatch) => {
    dispatch &&
      dispatch(forgotPassword())
        .then(response => unwrapResult(response))
        .then(
          response =>
            response &&
            Modal.success({
              content: t("ResetPasswordEmployeeSingleView"),
            })
        )
        .catch(e => {
          Modal.error({
            content: t(e.message),
          });
        });
  },
  isDataOperation: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stateBaseAccess: (user: UserState, singleViewEntity?: any) =>
    isRoleAdminLevel(user.settings.role) && singleViewEntity?.isUser,
};

export const CancelTraining = {
  id: SingleViewActions.CancelTraining,
  title: "CancelTraining",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (_: any, __: string, ___?: any[], t?: any, dispatch?: AppDispatch) => {
    dispatch &&
      dispatch(cancelTraining()).catch(e => {
        Modal.error({
          content: t(e.message),
        });
      });
  },
  isDataOperation: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stateBaseAccess: (user: UserState, singleViewEntity?: any) =>
    isRoleAdminLevel(user.settings.role) && singleViewEntity?.status === TrainingStatus.Passed,
};

export const SetSertificationAction = {
  id: "setCertification",
  title: "SetCertification",
  link: () => ({
    path: "/certification",
  }),
  isDataOperation: false,
};

export const MannualyUpdateInspection = {
  id: "updateInspectionDetails",
  title: "UpdateInspectionDetails",
  isDataOperation: false,
};

export const CreateTrainingWithEmployees: DropDownAction<Employee> = {
  id: SystemActions.CreateTrainingWithEmployees,
  title: "CreateTraining",
  link: (selectedEntities: Employee[]) => {
    const employeeIds: number[] = [];
    selectedEntities.forEach(e => employeeIds.push(e.id));
    return { path: "/training/new", prefillData: { employeeIds } };
  },
  isDataOperation: false,
};

export const CreateTrainingFromEmpCertificates: DropDownAction<EmployeeCertificateListView> = {
  id: SystemActions.CreateTrainingFromEmpCertificates,
  title: "CreateTraining",
  link: (selectedEntities: EmployeeCertificateListView[]) => {
    const employeeIds: number[] = [];
    const certificateIds: number[] = [];
    const certificateId = selectedEntities.length && selectedEntities[0].certificateId;
    selectedEntities.forEach(e => {
      !employeeIds.includes(e.employeeId) && employeeIds.push(e.employeeId);
      !certificateIds.includes(e.certificateId) && certificateIds.push(e.certificateId);
    });
    return {
      path: "/training/new",
      prefillData: { employeeIds, certificateId },
      warningMessage:
        certificateIds.length > 1
          ? {
              body: "CreateTrainingWarning", //"'Create Training' is possible only for a single certification selection",
              okText: "FormsOk",
            }
          : undefined,
    };
  },
  isDataOperation: false,
};

export function isPagedListExportSupported(entity: CategoryId) {
  const entitySupportedPagedList: CategoryId[] = [
    "actions",
    "certification",
    "employee",
    "equipment",
    "inspectionEquipment",
    "inspectionLocation",
    "location",
    "training",
  ];
  return entitySupportedPagedList.includes(entity);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ExportData(tableData: any, exportTemplate: any, fileName: string): Promise<any> {
  const { user } = store.getState();

  const request = new FormData();
  request.append("data", JSON.stringify(tableData));
  request.append("mapping", JSON.stringify(exportTemplate));
  return hbApi
    .post("/Export/xlsx", request, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.jwt}`,
      },
      responseType: "blob",
    })
    .then(res => {
      fileDownload(res.data, fileName);
      return Promise.resolve();
    });
}

function mapCategoryToExportEntity(category: CategoryId) {
  switch (category) {
    case "actions":
      return "action";
    case "certification":
      return "employeeCertificate";
    case "inspectionLocation":
    case "inspectionEquipment":
      return "inspection";

    default:
      return category;
  }
}

function getLinkedEntityType(category: CategoryId) {
  switch (category) {
    case "inspectionLocation":
      return "location";
    case "inspectionEquipment":
      return "equipment";

    default:
      return undefined;
  }
}

function transformMapping(mapping: ExportMapping[], entity: CategoryId) {
  switch (entity) {
    case "actions":
      const completedTasksMapObj = {
        property: "subTasksCompleted",
        type: "Header",
        value: "Completed Sub Tasks",
      };
      const totalTasksMapObj = {
        property: "subTasksTotal",
        type: "Header",
        value: "Total Sub Tasks",
      };
      return mapping.flatMap(item =>
        item.property === "completedSubTasks" ? [completedTasksMapObj, totalTasksMapObj] : item
      );
    default:
      return mapping;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ExportEntityData(entity: CategoryId, mapping: ExportMapping[]): Promise<any> {
  const { filter, user } = store.getState();

  const transformedMapping = transformMapping(mapping, entity);

  const activeFilters = filter.filters[entity]?.[entity]?.activeFilters;

  const orders = filter.filterValues[entity]?.order
    ? generateGridifySorterQuery(filter.filterValues[entity]!.order)
    : undefined;

  const { filters, emptyPropIds } = generateFiltersAndEmptyProps(activeFilters);

  const linkedEntityType = getLinkedEntityType(entity);

  return hbApi
    .post(
      `/Export/pagedList/${mapCategoryToExportEntity(entity)}/xlsx`,
      {
        gridifyQuery: {
          Filter: filters,
          OrderBy: orders,
        },
        mapping: transformedMapping,
        emptyPropIds,
        ...(linkedEntityType && { linkedEntityType }),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.jwt}`,
        },
        responseType: "blob",
      }
    )
    .then(res => {
      fileDownload(res.data, `${entity}.xlsx`);
      return Promise.resolve();
    });
}
