import { Button, Modal, Table, Tag, Typography, Form, Space, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createDimension, deleteDimension, updateDimension } from "../../../../store/slices/cubeDimensions";
import { RootState, useAppDispatch } from "../../../../store/store";
import { CubeDimension, DimensionJoin } from "../../../../types/cubeDimensions";
import AddDimensionsForm from "../DimensionForm/AddDimensionsForm";
import QuestionTagList from "./QuestionTagList";

const { Title } = Typography;

enum DimensionFormState {
  CREATE,
  UPDATE,
}

export default function DimensionsTable() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { dimensions } = useSelector((state: RootState) => state.cubeDimensions);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formState, setFormState] = useState<DimensionFormState>(DimensionFormState.CREATE);
  const [clickedDimension, setClickedDimension] = useState<CubeDimension | undefined>(undefined);

  const onEditClicked: (dimension: CubeDimension) => void = dimension => {
    setClickedDimension(dimension);
    setFormState(DimensionFormState.UPDATE);
    setShowModal(true);
  };

  const deleteDimensionHandler: (dimension: CubeDimension) => () => void = dimension => () => {
    dispatch(deleteDimension(dimension.id));
  };

  const onDeleteClicked: (dimension: CubeDimension) => void = dimension => {
    Modal.confirm({
      content: `Are you sure you want to delete ${dimension.name}?`,
      onOk: deleteDimensionHandler(dimension),
    });
  };

  const renderDimensionActions = (record: CubeDimension) => {
    return (
      <Space direction="horizontal">
        <Button key="edit" onClick={() => onEditClicked(record)}>
          Edit
        </Button>
        <Button key="delete" danger onClick={() => onDeleteClicked(record)}>
          Delete
        </Button>
      </Space>
    );
  };

  const getColumns = () => {
    return [
      {
        title: "Dimensions",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Questions",
        dataIndex: "questionIds",
        key: "questions",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (questions: any) => (questions ? <QuestionTagList dimensionQuestions={questions} /> : null),
      },
      {
        title: "Joins",
        dataIndex: "joins",
        key: "joins",
        render: (joins: DimensionJoin[]) =>
          joins ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexFlow: "wrap",
                alignContent: "space-around",
                width: "100%",
              }}
            >
              {joins.map(join => {
                const joinDimensionName = dimensions.find(d => d.id === join.joinedDimensionId)?.name;
                return (
                  <Tag key={join.joinedDimensionId} title={`${joinDimensionName}`} style={{ marginRight: "10px" }}>
                    {join.joinBy ? `${joinDimensionName} - joins by ${join.joinBy}` : `${joinDimensionName} -joins All`}
                  </Tag>
                );
              })}
            </div>
          ) : null,
      },
      {
        title: "Actions",
        key: "actions",
        render: (text: string, record: CubeDimension) => renderDimensionActions(record),
      },
    ];
  };

  const onAddDimensions = () => {
    setShowModal(true);
    setFormState(DimensionFormState.CREATE);
    setClickedDimension(undefined);
  };
  const onModalCancel = () => {
    setShowModal(false);
    setClickedDimension(undefined);
  };
  const onModalOk = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
        setShowModal(false);
      })
      .then(() => setClickedDimension(undefined))
      .catch(error => console.warn(error));
  };

  const onSubmitForm: (dimension: CubeDimension) => void = dimension => {
    if (formState === DimensionFormState.CREATE) {
      message.loading({ content: `Creating ${dimension.name}`, duration: 0, key: "createDimensionMessage" });
      dispatch(createDimension(dimension)).then(response => {
        message.destroy("createDimensionMessage");
        if (response.meta.requestStatus === "fulfilled") {
          message.success({
            content: `Finished creating ${dimension.name}`,
            duration: 2,
          });
        } else {
          message.error({
            content: `Erroe while creating ${dimension.name}`,
            duration: 2,
          });
        }
      });
    } else {
      if (clickedDimension) {
        dimension.id = clickedDimension.id;
        message.loading({ content: `Updating ${dimension.name}`, duration: 0, key: "updateDimensionMessage" });
        dispatch(updateDimension(dimension)).then(response => {
          message.destroy("updateDimensionMessage");
          if (response.meta.requestStatus === "fulfilled") {
            message.success({
              content: `Finished updating ${dimension.name}`,
              duration: 2,
            });
          } else {
            message.error({
              content: `Erroe while updating ${dimension.name}`,
              duration: 2,
            });
          }
        });
      }
    }
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Title style={{ alignSelf: "flex-start" }} level={3}>
          Dimension Builder
        </Title>
        <div>
          <Button onClick={onAddDimensions}>Add Dimensions</Button>
        </div>
      </div>
      {dimensions && <Table tableLayout="auto" columns={getColumns()} dataSource={dimensions} />}
      <Modal
        open={showModal}
        onCancel={onModalCancel}
        onOk={onModalOk}
        afterClose={() => form.resetFields()}
        maskClosable={false}
        destroyOnClose={true}
        title={clickedDimension ? `Edit Dimension: ${clickedDimension.name}` : "Add New Dimension"}
      >
        <AddDimensionsForm onFinish={onSubmitForm} formInstance={form} dimension={clickedDimension} />
      </Modal>
    </div>
  );
}
