import { createAsyncThunk, createSlice, PayloadAction, unwrapResult } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import { Layout } from "react-grid-layout";

import { calculatePivotConfig, dimensionsManipulation } from "../../components/Dashboard/Chart/Helpers/QueryHelpers";
import {
  Chart,
  ChartWithData,
  convertObjectToRuleString,
  convertRuleStringToObject,
  DashboardDetails,
  DashboardFilterOption,
  DashboardFilterType,
  DashboardOrgUnit,
  DashboardState,
  DashboardUser,
  FullDashboard,
  HBChartType,
  RecipientEntityType,
  RepeatOptions,
  ScheduledReport,
  ScheduledReportResponse,
} from "../../types/dashboard";
import {
  migrateCoordinates,
  needMegration as checkMegrationNeeded,
  shouldUpdateChartPosition,
} from "../../utils/dashboard/dashboards";
import { CustomResultSet } from "../../utils/dashboard/resultSet/resultSetFactory";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { fetchCubeToken } from "./user";

const emptySingleDashboard: FullDashboard = {
  id: 0,
  name: "",
  isDefault: false,
  isMyDefault: false,
  crossProjects: false,
  isPrivate: false,
  charts: [],
  dashboardOrgUnits: [],
  dashboardUsers: [],
  scheduledReport: { isLoading: false },
};

const emptySingleChart: ChartWithData = {
  id: 10000,
  name: "New Chart",
  apiId: undefined,
  barGrouping: "",
  chartType: HBChartType.number,
  companyId: 0,
  coordinates: {
    x: 0,
    y: 0,
    width: 1,
    height: 1,
  },
  customColors: [],
  filters: {},
  isAllowedDrillDown: false,
  query: {},
  selectedGrouping: null,
  selectedMeasure: null,
  showAllLabels: false,
  chartAxis: "x",
  showNumber: false,
  showPercent: false,
  uniqueApiId: null,
  useHierarchicalNavigation: false,
  useViewData: false,
  hierarchicalNavigationState: {},
};

export const initialState: DashboardState = {
  dashboards: [],
  isLoading: false,
  single: emptySingleDashboard,
  selectedDashboardId: undefined,
  stagedChart: undefined,
  filterOptions: {
    options: {},
    isLoading: false,
  },
  error: null,
  lastUpdated: dayjs().toISOString(),
};

const emptyScheduleReport: ScheduledReport = {
  dashboardId: 0,
  ownerId: 0,
  subject: "",
  date: dayjs(),
  startTime: dayjs(),
  recurrenceRule: {
    repeat: RepeatOptions.Daily,
    amount: "",
    weekdays: undefined,
    onDay: undefined,
    onSpecificTime: undefined,
    endAfter: undefined,
    until: undefined,
  },
  selectedEmployeeRecipients: [],
  selectedOrgUnitRecipients: [],
  id: 0,
  unifiedId: 0,
};

const convertScheduleReport = (scheduleReport: ScheduledReportResponse) => {
  return {
    ...scheduleReport,
    recurrenceRule: convertRuleStringToObject(scheduleReport.recurrenceRule),
    date: dayjs(scheduleReport.date),
    startTime: dayjs(scheduleReport.startTime),
    selectedEmployeeRecipients: scheduleReport.selectedRecipients.filter(
      r => r.entityType === RecipientEntityType.Employee
    ),
    selectedOrgUnitRecipients: scheduleReport.selectedRecipients.filter(
      r => r.entityType === RecipientEntityType.OrgUnit
    ),
  };
};

export const fetchDashboards = createAsyncThunk<{ primaryData: DashboardDetails[] }, void, { state: RootState }>(
  "@@DASHBOARD/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<DashboardDetails[]>("/SmartDashboard/dashboards", hbApiOptions(user.jwt));

    return {
      primaryData: response.status == 200 ? response.data : [],
    };
  }
);

export const fetchDashboardOptions = createAsyncThunk<
  { primaryData: DashboardFilterOption[]; filterType: DashboardFilterType },
  { type: DashboardFilterType; dependsOn?: number },
  { state: RootState }
>("@@DASHBOARD/FETCH_FILTER_OPTIONS", async ({ type, dependsOn }, { getState }) => {
  const { user, dashboard } = getState();
  const response = await hbApi.get<DashboardFilterOption[]>(
    `/SmartDashboard/GetData?type=${type}&${dependsOn ? `dependsOnId=${dependsOn}` : ""}&crossProjects=${
      dashboard.single.crossProjects
    }`,
    hbApiOptions(user.jwt)
  );
  return {
    primaryData: response.status == 200 ? response.data : [],
    filterType: type,
  };
});

export const fetchScheduledReport = createAsyncThunk<ScheduledReport, { dashboardId: number }, { state: RootState }>(
  "@@DASHBOARD/FETCH_SCHEDULE_REPORT",
  async ({ dashboardId }, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<ScheduledReportResponse>(
      `/SmartDashboard/Report/?id=${dashboardId}`,
      hbApiOptions(user.jwt)
    );
    return convertScheduleReport(response.data);
  }
);

export const resetDashboardLayoutToDefault = createAsyncThunk<
  boolean,
  { dashboardId: number | undefined },
  { state: RootState }
>("@@DASHBOARD/RESET_DASHBOARD_LAYOUT", async ({ dashboardId }, { getState, dispatch }) => {
  const { user } = getState();
  if (dashboardId) {
    const response = await hbApi.post<boolean>(
      `/SmartDashboard/ResetDashboardLayout?id=${dashboardId}`,
      undefined,
      hbApiOptions(user.jwt)
    );
    if (response.status === 200) {
      await dispatch(fetchDashboard(dashboardId));
      return true;
    }
  }
  return false;
});

export const updateScheduledReport = createAsyncThunk<ScheduledReport, ScheduledReport, { state: RootState }>(
  "@@DASHBOARD/UPDATE_SCHEDULE_REPORT",
  async (scheduledReport, { getState }) => {
    const { user, dashboard } = getState();
    const requestPayload: ScheduledReportResponse = {
      ...scheduledReport,
      recurrenceRule: convertObjectToRuleString(scheduledReport.recurrenceRule, user.companySettings.firstDayOfWeek),
      selectedRecipients: scheduledReport.selectedEmployeeRecipients.concat(scheduledReport.selectedOrgUnitRecipients),
      startTime: scheduledReport.startTime.format("HH:mmZ"),
      date: scheduledReport.date.format("YYYY-MM-DD"),
    };
    let response;
    if (
      dashboard.single.scheduledReport.scheduleReport === undefined ||
      dashboard.single.scheduledReport.scheduleReport.id === 0
    ) {
      response = await hbApi.post<ScheduledReportResponse>(
        "/SmartDashboard/Report",
        requestPayload,
        hbApiOptions(user.jwt)
      );
    } else {
      response = await hbApi.put<ScheduledReportResponse>(
        `/SmartDashboard/Report/${scheduledReport.id}`,
        requestPayload,
        hbApiOptions(user.jwt)
      );
    }
    return convertScheduleReport(response.data);
  }
);

export const deleteScheduledReport = createAsyncThunk<boolean, number, { state: RootState }>(
  "@@DASHBOARD/DELETE_SCHEDULE_REPORT",
  async (reportId, { getState }) => {
    const { user } = getState();
    const response = await hbApi.delete<boolean>(`/SmartDashboard/Report?id=${reportId}`, hbApiOptions(user.jwt));
    return response.data;
  }
);

export const fetchDashboard = createAsyncThunk<{ dashboard: FullDashboard }, number, { state: RootState }>(
  "@@DASHBOARD/FETCH_SINGLE",
  async (dashboardId, { getState, dispatch }) => {
    const { user } = getState();
    const dashboardResponse = await hbApi.get<FullDashboard>(
      `/SmartDashboard/dashboard/?dashboardId=${dashboardId}`,
      hbApiOptions(user.jwt)
    );

    if (dashboardResponse.data && dashboardResponse.data.crossProjects) {
      await dispatch(fetchCubeToken(dashboardResponse.data.crossProjects));
    }

    const isMigrationNeeded = checkMegrationNeeded(dashboardResponse.data.charts);
    let loadedChartsConfig = dashboardResponse.data.charts;

    if (isMigrationNeeded) {
      loadedChartsConfig = migrateCoordinates(loadedChartsConfig);
    }

    const charts = loadedChartsConfig.map((d: Chart) => {
      const query = { ...d.query };
      if (d?.dimensions) {
        dimensionsManipulation(query, d);
      }
      if (!d.pivotConfig) {
        d.pivotConfig = calculatePivotConfig(query);
      }

      return {
        ...d,
        filters: !d.filters ? {} : d.filters,
        query,
        coordinates: d.coordinates,
      };
    });

    return {
      dashboard: { ...dashboardResponse.data, charts, scheduledReport: { isLoading: false } },
    };
  }
);

export const selectDashboard = createAsyncThunk<number | undefined, number | undefined, { state: RootState }>(
  "@@DASHBOARD/SELECT_DASHBOARD",
  async (dashboardId, { dispatch }) => {
    if (dashboardId) {
      let selectedDashboardId: number | undefined = dashboardId;
      const result = await dispatch(fetchDashboard(dashboardId));
      if (result.meta.requestStatus === "rejected") {
        const defaultResult = await dispatch(selectDefaultDashboard());
        selectedDashboardId = defaultResult.payload as number | undefined;
      }
      return selectedDashboardId;
    }
  }
);

export const selectDefaultDashboard = createAsyncThunk<number | undefined, void, { state: RootState }>(
  "@@DASHBOARD/SELECT_DEFAULT_DASHBOARD",
  async (_, { getState, dispatch }) => {
    const { dashboard } = getState();
    let selectedDashboard = dashboard.dashboards.length > 0 ? dashboard.dashboards[0].id : undefined;
    if (dashboard.dashboards?.find(d => d.isMyDefault)?.id) {
      selectedDashboard = dashboard.dashboards?.find(d => d.isMyDefault)?.id;
    } else if (dashboard.dashboards?.find(d => d.isDefault)?.id) {
      selectedDashboard = dashboard.dashboards?.find(d => d.isDefault)?.id;
    }
    if (selectedDashboard) {
      dispatch(selectDashboard(selectedDashboard));
    }
    return selectedDashboard;
  }
);

export const addNewChart = createAsyncThunk<Chart, void, { state: RootState }>(
  "@@DASHBOARD/ADD_CHART",
  async (_, { getState }) => {
    const { user, dashboard } = getState();
    const createChartResponse = await hbApi.post<Chart>(
      "/SmartDashboard/chart",
      {
        ...emptySingleChart,
        dashboardId: dashboard.single.id,
        id: undefined,
      },
      hbApiOptions(user.jwt)
    );
    return createChartResponse.data;
  }
);

export const addNewDashboard = createAsyncThunk<FullDashboard, string, { state: RootState }>(
  "@@DASHBOARD/ADD_DASHBOARD",
  async (dashboardName, { getState }) => {
    const { user } = getState();
    const createDashboardResponse = await hbApi.post<FullDashboard>(
      `/SmartDashboard/dashboards?name=${dashboardName}`,
      undefined,
      hbApiOptions(user.jwt)
    );
    return createDashboardResponse.data;
  }
);

export const renameDashboard = createAsyncThunk<boolean, { dashboardId: number; title: string }, { state: RootState }>(
  "@@DASHBOARD/RENAME_DASHBOARD",
  async ({ dashboardId, title }, { getState }) => {
    const { user } = getState();
    const updateDashboardNameResponse = await hbApi.post<boolean>(
      `/SmartDashboard/RenameDashboard?id=${dashboardId}&title=${title}`,
      undefined,
      hbApiOptions(user.jwt)
    );
    return updateDashboardNameResponse.data;
  }
);

export const saveStagedChart = createAsyncThunk<Chart, void, { state: RootState }>(
  "@@DASHBOARD/SAVE_STAGED_CHART",
  async (_, { getState }) => {
    const { user, dashboard } = getState();
    const createChartResponse = await hbApi.put<Chart>(
      "/SmartDashboard/chart",
      {
        ...dashboard.stagedChart,
        dashboardId: dashboard.single.id,
      },
      hbApiOptions(user.jwt)
    );
    return { ...createChartResponse.data, ...dashboard.stagedChart };
  }
);

export const updateChart = createAsyncThunk<Chart, Chart, { state: RootState }>(
  "@@DASHBOARD/UPDATE_CHART",
  async (chart, { getState }) => {
    const { user, dashboard } = getState();
    const createChartResponse = await hbApi.put<Chart>(
      "/SmartDashboard/chart",
      {
        ...chart,
        dashboardId: dashboard.single.id,
      },
      hbApiOptions(user.jwt)
    );
    return createChartResponse.data;
  }
);

const updateChartPosition = createAsyncThunk<Chart, { chartId: number; layout: Layout }, { state: RootState }>(
  "@@DASHBOARD/UPDATE_CHART_POSITION",
  async ({ chartId, layout }, { getState }) => {
    const { user, dashboard } = getState();
    const chart = dashboard.single.charts.find(c => c.id === chartId);
    const createChartResponse = await hbApi.put<Chart>(
      "/SmartDashboard/chart",
      {
        ...chart,
        dashboardId: dashboard.single.id,
        coordinates: { x: layout.x, y: layout.y, width: layout.w, height: layout.h },
      },
      hbApiOptions(user.jwt)
    );
    return createChartResponse.data;
  }
);

export const updateMultipleChartPositions = createAsyncThunk<Chart[], Layout[], { state: RootState }>(
  "@@DASHBOARD/UPDATE_MULTIPLE_CHART_POSITIONS",
  async (charts: Layout[], { dispatch, getState }) => {
    const { dashboard } = getState();
    const promises = charts
      .map(newChart => {
        const chart = dashboard.single.charts.find(c => c.id === parseInt(newChart.i));
        if (shouldUpdateChartPosition(chart, newChart)) {
          return dispatch(updateChartPosition({ chartId: parseInt(newChart.i), layout: newChart })).then(unwrapResult);
        }
      })
      .filter(p => p !== undefined);

    const results = await Promise.all(promises);
    return results;
  }
);

export const deleteChart = createAsyncThunk<boolean, number, { state: RootState }>(
  "@@DASHBOARD/DELETE_CHART",
  async (chartId, { getState }) => {
    const { user } = getState();
    const createChartResponse = await hbApi.delete<boolean>(
      `/SmartDashboard/chart?id=${chartId}`,
      hbApiOptions(user.jwt)
    );
    return createChartResponse.data;
  }
);

export const togglePrivateDashboard = createAsyncThunk<boolean, void, { state: RootState }>(
  "@@DASHBOARD/TOGGLE_PRIVATE_DASHBOARD",
  async (_, { getState }) => {
    const { user, dashboard } = getState();
    const createChartResponse = await hbApi.post<boolean>(
      `/SmartDashboard/SetPrivateDashboard?id=${dashboard.single.id}`,
      undefined,
      hbApiOptions(user.jwt)
    );
    return createChartResponse.data;
  }
);

export const togglCrossProjectDashboard = createAsyncThunk<boolean, void, { state: RootState }>(
  "@@DASHBOARD/TOGGLE_CROSS_PROJECT_DASHBOARD",
  async (_, { getState }) => {
    const { user, dashboard } = getState();
    const createChartResponse = await hbApi.post<boolean>(
      `/SmartDashboard/SetCrossProjectsDashboard?id=${dashboard.single.id}`,
      undefined,
      hbApiOptions(user.jwt)
    );
    return createChartResponse.data;
  }
);

export const setCompanyDefaultDashboard = createAsyncThunk<boolean, void, { state: RootState }>(
  "@@DASHBOARD/SET_AS_COMPANY_DEFAULT_DASHBOARD",
  async (_, { getState }) => {
    const { user, dashboard } = getState();
    const createChartResponse = await hbApi.post<boolean>(
      `/SmartDashboard/SetDefaultDashboard?id=${dashboard.single.id}&isUserDefault=${false}`,
      undefined,
      hbApiOptions(user.jwt)
    );
    return createChartResponse.data;
  }
);

export const setMyDefaultDashboard = createAsyncThunk<void, number, { state: RootState }>(
  "@@DASHBOARD/SET_AS_MY_DEFAULT_DASHBOARD",
  async (dashboardId, { getState }) => {
    const { user } = getState();
    const createChartResponse = await hbApi.post<void>(
      `/SmartDashboard/SetDefaultDashboard?id=${dashboardId}&isUserDefault=${true}`,
      undefined,
      hbApiOptions(user.jwt)
    );
    return createChartResponse.data;
  }
);

export const updateDashboardUsers = createAsyncThunk<
  boolean,
  { users: DashboardUser[]; dashboardId: number },
  { state: RootState }
>("@@DASHBOARD/UPDATE_DASHBOARD_USERS", async ({ users, dashboardId }, { getState }) => {
  const { user } = getState();
  const updateUsers = await hbApi.post<boolean>(
    "/SmartDashboard/ChangeDashboardUsers",
    {
      users,
      dashboardId,
    },
    hbApiOptions(user.jwt)
  );
  return updateUsers.data;
});

export const updateDashboardOrgUnits = createAsyncThunk<
  boolean,
  { orgunits: DashboardOrgUnit[]; dashboardId: number },
  { state: RootState }
>("@@DASHBOARD/UPDATE_DASHBOARD_ORGUNITS", async ({ orgunits, dashboardId }, { getState }) => {
  const { user } = getState();
  const updateOrguints = await hbApi.post<boolean>(
    "/SmartDashboard/ChangeDashboardOrgUnits",
    {
      orgUnitIds: orgunits.map(orgu => orgu.orgUnitId),
      dashboardId,
    },
    hbApiOptions(user.jwt)
  );
  return updateOrguints.data;
});

const slice = createSlice({
  name: "Dashboard",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    stageChart: {
      prepare: (payload: Chart) => ({ payload }),
      reducer: (state, action: PayloadAction<Chart>) => {
        state.stagedChart = action.payload;
      },
    },
    updateStagedChart: {
      prepare: (payload: Chart) => ({ payload }),
      reducer: (state, action: PayloadAction<Chart>) => {
        state.stagedChart = { ...action.payload };
      },
    },
    updateChartResultSet: {
      prepare: (payload: { id: number; resultSet: CustomResultSet }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ id: number; resultSet: CustomResultSet }>) => {
        state.single.charts.forEach(chart => {
          if (chart.id === action.payload.id) {
            chart.resultSet = action.payload.resultSet;
          }
        });
      },
    },
    toggleDrillDownMode: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        state.single.charts.forEach(chart => {
          if (chart.id === action.payload) {
            chart.drillDownModeEnabled = !chart.drillDownModeEnabled;
          }
        });
      },
    },
    flipChartAxis: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        state.single.charts.forEach(chart => {
          if (chart.id === action.payload) {
            chart.chartAxis = chart.chartAxis === undefined || chart.chartAxis === "x" ? "y" : "x";
          }
        });
      },
    },
    updateChartSlicers: {
      prepare: (payload: { id: number; dimensions?: string[] }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ id: number; dimensions?: string[] }>) => {
        state.single.charts.forEach(chart => {
          if (chart.id === action.payload.id) {
            chart.dimensions = action.payload.dimensions;
            chart.pivotConfig = {
              x: action.payload.dimensions,
              y: ["measures"],
            };
          }
        });
      },
    },
    updateDateSlicer: {
      prepare: (payload: { id: number; timeDimension: string; granularity?: string }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ id: number; timeDimension: string; granularity?: string }>) => {
        state.single.charts.forEach(chart => {
          if (chart.id === action.payload.id) {
            if (chart.filters) {
              chart.filters[`${action.payload.timeDimension}-0`] = action.payload.granularity;
              const updatedPivotConfig = chart?.pivotConfig ? chart?.pivotConfig : undefined;
              if (updatedPivotConfig) {
                if (updatedPivotConfig.x) {
                  const dim = chart.dimensions || [];
                  updatedPivotConfig.x = [...dim, `${action.payload.timeDimension}.${action.payload.granularity}`];
                }
                chart.pivotConfig = updatedPivotConfig;
              } else {
                chart.pivotConfig = {
                  x: [`${action.payload.timeDimension}.${action.payload.granularity}`],
                  y: ["measures"],
                };
              }
            }
          }
        });
      },
    },
    resetSlicers: {
      prepare: (payload: { id: number }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ id: number }>) => {
        state.single.charts.forEach(chart => {
          if (chart.id === action.payload.id) {
            chart.dimensions = undefined;
            if (chart.filters) {
              const timeDimension =
                chart.query.timeDimensions && chart.query.timeDimensions.length > 0
                  ? chart.query.timeDimensions[0]
                  : undefined;
              if (timeDimension) {
                const updatedFilters = { ...chart.filters };
                updatedFilters[`${timeDimension.dimension}-0`] = undefined;
                chart.filters = updatedFilters;
              }
              chart.pivotConfig = calculatePivotConfig(chart.query);
            }
          }
        });
      },
    },
    updateShowAllLabels: {
      prepare: (payload: { id: number; showAllLabels: boolean }) => ({ payload }),
      reducer: (state, action: PayloadAction<{ id: number; showAllLabels: boolean }>) => {
        state.single.charts.forEach(chart => {
          if (chart.id === action.payload.id) {
            chart.showAllLabels = action.payload.showAllLabels;
          }
        });
      },
    },
    updateDashboardName: {
      prepare: (payload: string) => ({ payload }),
      reducer: (state, action: PayloadAction<string>) => {
        state.single.name = action.payload;
      },
    },
  },
  // // Note: User reducers only for asynchronous logic with AsyncThunk

  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchDashboards.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboard.pending, state => {
        state.error = null;
      })
      .addCase(selectDashboard.pending, state => {
        state.error = null;
      })
      .addCase(selectDefaultDashboard.pending, state => {
        state.error = null;
      })
      .addCase(fetchDashboardOptions.pending, state => {
        state.filterOptions.isLoading = true;
      })
      .addCase(fetchScheduledReport.pending, state => {
        state.single.scheduledReport.isLoading = true;
      })
      .addCase(updateScheduledReport.pending, state => {
        state.single.scheduledReport.isLoading = true;
      })
      .addCase(deleteScheduledReport.pending, state => {
        state.single.scheduledReport.isLoading = true;
      })
      .addCase(addNewChart.pending, state => {
        state.error = null;
      })
      .addCase(saveStagedChart.pending, state => {
        state.error = null;
      })
      .addCase(updateChart.pending, state => {
        state.error = null;
      })
      .addCase(updateMultipleChartPositions.pending, state => {
        state.error = null;
      })
      .addCase(deleteChart.pending, state => {
        state.error = null;
      })
      .addCase(togglePrivateDashboard.pending, state => {
        state.error = null;
      })
      .addCase(togglCrossProjectDashboard.pending, state => {
        state.error = null;
      })
      .addCase(setCompanyDefaultDashboard.pending, state => {
        state.error = null;
      })
      .addCase(setMyDefaultDashboard.pending, state => {
        state.error = null;
      })
      .addCase(updateDashboardUsers.pending, state => {
        state.error = null;
      })
      .addCase(updateDashboardOrgUnits.pending, state => {
        state.error = null;
      })
      .addCase(addNewDashboard.pending, state => {
        state.error = null;
      })
      .addCase(renameDashboard.pending, state => {
        state.error = null;
      })
      .addCase(resetDashboardLayoutToDefault.pending, state => {
        state.error = null;
      })
      .addCase(fetchDashboards.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(selectDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(selectDefaultDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(fetchDashboardOptions.rejected, state => {
        state.filterOptions.isLoading = false;
      })
      .addCase(fetchScheduledReport.rejected, (state, action) => {
        state.single.scheduledReport.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateScheduledReport.rejected, (state, action) => {
        state.single.scheduledReport.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(deleteScheduledReport.rejected, (state, action) => {
        state.single.scheduledReport.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addNewChart.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(saveStagedChart.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(updateChart.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(updateMultipleChartPositions.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(deleteChart.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(togglePrivateDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(togglCrossProjectDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(setCompanyDefaultDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(setMyDefaultDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(updateDashboardUsers.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(updateDashboardOrgUnits.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(addNewDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(renameDashboard.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(resetDashboardLayoutToDefault.rejected, (state, action) => {
        state.error = action.error.message || null;
      })
      .addCase(fetchDashboards.fulfilled, (state, action) => {
        state.dashboards = action.payload.primaryData;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.single = action.payload.dashboard;
        // state.selectedDashboardId = action.payload.dashboard.id;
        state.lastUpdated = dayjs().toISOString();

        state.error = null;
      })
      .addCase(selectDashboard.fulfilled, (state, action) => {
        state.selectedDashboardId = action.payload;

        state.error = null;
      })
      .addCase(selectDefaultDashboard.fulfilled, (state, action) => {
        state.selectedDashboardId = action.payload;

        state.error = null;
      })
      .addCase(fetchDashboardOptions.fulfilled, (state, action) => {
        const updatedOptions = { ...state.filterOptions.options };
        updatedOptions[action.payload.filterType] = action.payload.primaryData;
        state.filterOptions.options = updatedOptions;
        state.filterOptions.isLoading = false;
      })
      .addCase(fetchScheduledReport.fulfilled, (state, action) => {
        state.single.scheduledReport.scheduleReport = action.payload;
        state.single.scheduledReport.isLoading = false;
      })
      .addCase(updateScheduledReport.fulfilled, (state, action) => {
        state.single.scheduledReport.scheduleReport = action.payload;
        state.single.scheduledReport.isLoading = false;
      })
      .addCase(deleteScheduledReport.fulfilled, state => {
        state.single.scheduledReport.scheduleReport = emptyScheduleReport;
        state.single.scheduledReport.isLoading = false;
      })
      .addCase(addNewChart.fulfilled, (state, action) => {
        state.single.charts = state.single.charts.concat(action.payload);
        state.error = null;
      })
      .addCase(saveStagedChart.fulfilled, (state, action) => {
        state.single.charts = state.single.charts.map(item => {
          if (item.id !== action.payload.id) {
            return item;
          }
          return { ...item, ...action.payload };
        });
        state.error = null;
      })
      .addCase(updateChart.fulfilled, (state, action) => {
        state.single.charts = state.single.charts.map(item => {
          if (item.id !== action.payload.id) {
            return item;
          }
          return { ...item, ...action.payload };
        });
        state.error = null;
      })
      .addCase(updateMultipleChartPositions.fulfilled, (state, action: PayloadAction<Chart[]>) => {
        action.payload.forEach(updatedChart => {
          const chart = state.single.charts.find(chart => chart.id === updatedChart.id);
          if (chart) {
            chart.coordinates = updatedChart.coordinates;
          }
        });
        state.error = null;
      })
      .addCase(deleteChart.fulfilled, (state, action) => {
        if (action.payload === true) {
          state.single.charts = state.single.charts.filter(item => {
            if (item.id !== action.meta.arg) {
              return true;
            }
          });
        }
        state.error = null;
      })
      .addCase(togglePrivateDashboard.fulfilled, (state, action) => {
        if (action.payload === true) {
          state.single.isPrivate = !state.single.isPrivate;
        }
        state.error = null;
      })
      .addCase(togglCrossProjectDashboard.fulfilled, (state, action) => {
        if (action.payload === true) {
          state.single.crossProjects = !state.single.crossProjects;
        }
        state.error = null;
      })
      .addCase(setCompanyDefaultDashboard.fulfilled, state => {
        state.single.isDefault = true;
        state.dashboards = state.dashboards.map(dashboard => {
          if (dashboard.id === state.single.id) {
            return {
              ...dashboard,
              isDefault: true,
            };
          } else {
            return { ...dashboard, isDefault: false };
          }
        });
        state.error = null;
      })
      .addCase(setMyDefaultDashboard.fulfilled, (state, action) => {
        state.dashboards = state.dashboards.map(dashboard => {
          if (dashboard.id === action.meta.arg) {
            return {
              ...dashboard,
              isMyDefault: true,
            };
          } else {
            return { ...dashboard, isMyDefault: false };
          }
        });
        state.error = null;
      })
      .addCase(updateDashboardUsers.fulfilled, (state, action) => {
        state.single.dashboardUsers = action.meta.arg.users;
        state.error = null;
      })
      .addCase(updateDashboardOrgUnits.fulfilled, (state, action) => {
        state.single.dashboardOrgUnits = action.meta.arg.orgunits;
        state.error = null;
      })
      .addCase(addNewDashboard.fulfilled, (state, action) => {
        if (action.payload) {
          state.dashboards = state.dashboards.concat(action.payload);
          state.single = { ...action.payload, scheduledReport: { isLoading: false } };
        }
        state.error = null;
      })
      .addCase(renameDashboard.fulfilled, (state, action) => {
        if (action.payload) {
          state.single = { ...state.single, name: action.meta.arg.title };
        }
        state.error = null;
      })
      .addCase(resetDashboardLayoutToDefault.fulfilled, state => {
        state.error = null;
      });
  },
});

export const {
  updateDashboardName,
  updateStagedChart,
  updateChartResultSet,
  stageChart,
  updateChartSlicers,
  updateDateSlicer,
  resetSlicers,
  updateShowAllLabels,
  flipChartAxis,
  toggleDrillDownMode,
} = slice.actions;
export default slice.reducer;
