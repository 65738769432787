import {
  ExportAction,
  ColumnManagementAction,
  GetFiltersUrl,
  ResetColumnsWidthAction,
} from "../../../actions/menuActions";
import { ReactComponent as Logo } from "../../../media/eq-list-icon.svg";
import { customPropertySelectors, pageSelectors } from "../../../selectors";
import {
  addCustomProperty,
  clearCustomPropertyError,
  createCustomPropertyTemplate,
  fetchCustomProperties,
  fetchSingleCustomProperty,
  updateCustomProperty,
} from "../../../store/slices/customProperty";
import { CustomPropertyPage, CustomProperty, CompanyDictionary, DictionaryValues } from "../../../types/customProperty";
import { CategoryType, CategoryPage, InputTypes } from "../../../types/page";
import { UserRole } from "../../../types/user";
import { booleanOptions, boolOptions } from "./common";
import { customPropertyTableColumns, customPropertyTypeOptions } from "./customPropertyConfigPageData";
import { localizeText, prepareDynamicColumns, renderBoolStatus } from "./utilities";

const customPropertyConfigPage = (categoryType: CategoryType): CategoryPage<CustomPropertyPage> => ({
  id: `${categoryType}CustomProperty`,
  title: "PluginCustomPropName",
  entityEndpoint: `/${categoryType}`,
  PageIcon: Logo,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: customPropertySelectors.error,
  clearError: clearCustomPropertyError,
  createNewEntityTemplate: createCustomPropertyTemplate,
  createNewEntity: addCustomProperty,
  intializeDataActions: [fetchCustomProperties],
  updateEntity: updateCustomProperty,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  fetchSingle: fetchSingleCustomProperty,
  primarySingleEntitySelector: customPropertySelectors.single,
  customPropertiesSelector: customPropertySelectors.customPropertyCustomProperties,
  isLoading: pageSelectors.isCustomPropertyPageLoading,
  lastUpdatedSelector: customPropertySelectors.customPropertyLastUpdatedText,
  addButton: {
    label: "AddCustomProp",
    // eslint-disable-next-line no-console
    action: () => console.log("Create new Custom Property"),
  },
  listView: {
    table: {
      tableSelector: customPropertySelectors.all,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchListViewData: fetchCustomProperties as any,
      columns: customPropertyTableColumns,
      rowSelection: true,
      defaultColumnKeys: ["id", "name", "sortOrder", "defaultValue", "actAsIdentifier"],
      type: "table",
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<CustomProperty>(props, data),
  },
  summaryCard: {
    CardIcon: Logo,
    primaryFields: [
      {
        id: "name",
        title: "FieldName",
        value: entity => entity.name,
        type: InputTypes.INPUT,
        required: true,
        placeholder: "Enter",
        optionsSelector: () => null,
      },
    ],
    secondaryFields: [
      {
        id: "sortOrder",
        title: "FieldSortOrder",
        value: entity => entity.sortOrder?.toString(),
        type: InputTypes.NUMBER,
        placeholder: "Enter",
        optionsSelector: () => null,
      },
      {
        id: "actAsIdentifier",
        title: "FieldActAsIdentifier",
        value: entity => renderBoolStatus(entity.actAsIdentifier),
        type: InputTypes.BOOLEAN,
        placeholder: "Choose",
        options: boolOptions,
        optionsSelector: () =>
          boolOptions.map(l => ({
            id: l.id,
            label: localizeText(l.label),
          })),
      },
      {
        id: "type",
        title: "FieldType",
        value: entity => entity.type,
        localizedValue: entity => localizeText(entity.type),
        type: InputTypes.DROPDOWN,
        options: customPropertyTypeOptions,
        optionsSelector: () => null,
      },
      {
        id: "dictionaryId",
        title: "FieldDictionary",
        value: entity => entity.dictionaryId || "",
        type: InputTypes.AUTOCOMPLETE,
        optionsSelector: state =>
          (state.customProperty.companyDictionaries || []).map(x => ({
            id: x.id,
            label: x.name,
          })),
        isHidden: entity => !(entity?.type === "Dictionary" ?? false),
        placeholder: "Enter",
        searchById: true,
      },
      //default value for Dictionary
      {
        id: "defaultValue",
        title: "FieldDefaultValue",
        value: entity => (entity.defaultValue ? parseInt(entity.defaultValue) : ""),
        type: InputTypes.AUTOCOMPLETE,
        selector: state => state.customProperty.companyDictionaries || [],
        basedOnDependensiesOptions: (currentData: CustomProperty, selectedDependentData: CompanyDictionary[] | null) =>
          selectedDependentData
            ?.filter(x => x.id === currentData?.dictionaryId)![0]
            ?.dictionaryValues?.filter((x: DictionaryValues) => x.isActive)
            .map(y => ({
              id: y.id,
              label: y.value,
            })) || [],
        optionsSelector: () => null,
        isHidden: entity => !((entity?.type === "Dictionary" && entity.dictionaryId) ?? false),
      },
      {
        id: "defaultValue",
        title: "FieldDefaultValue",
        value: entity => entity.defaultValue || "",
        type: InputTypes.INPUT,
        placeholder: "Enter",
        optionsSelector: () => null,
        isHidden: entity => !(entity?.type === "String" ?? false),
      },
      //default value for Number
      {
        id: "defaultValue",
        title: "FieldDefaultValue",
        value: entity => entity.defaultValue || "",
        type: InputTypes.NUMBER,
        placeholder: "Enter",
        optionsSelector: () => null,
        isHidden: entity => !(entity?.type === "Numeric" ?? false),
      },
      //default value for Boolean
      {
        id: "defaultValue",
        title: "FieldDefaultValue",
        value: entity => entity.defaultValue || "",
        type: InputTypes.BOOLEAN,
        placeholder: "Enter",
        options: booleanOptions,
        optionsSelector: () =>
          booleanOptions.map(b => ({
            id: b.id,
            label: localizeText(b.label),
          })),
        isHidden: entity => !(entity?.type === "Bool" ?? false),
      },
    ],
  },
  tabsPanel: [],
  listViewActions: [ExportAction, ColumnManagementAction, ResetColumnsWidthAction, GetFiltersUrl],
  allowedUserRoles: [UserRole.SU, UserRole.Admin, UserRole.TenantAdmin],
});

export default customPropertyConfigPage;
