import "../MobileCardTemplate.less";

import {
  equipmentAccountableIsAssignedToOUBranchField,
  equipmentAccountableRoleField,
} from "../../../../../pages/pageConfig/category/equipment/equipmentConfigPageData";
import { MobileCardTemplateTProps } from "../../../../../types/page";
import { PrivilegeData } from "../../../../../types/utility";
import AccountableMobileCardTemplateWithSelection from "./AccountableMobileCardTemplateWithSelection";

const EquipmentAccountableMobileCardTemplate: React.FC<MobileCardTemplateTProps<PrivilegeData>> = ({ entity }) => {
  const roleSelectConfig = equipmentAccountableRoleField;
  const isAssignedToOUBranchSelectConfig = equipmentAccountableIsAssignedToOUBranchField;

  return (
    <AccountableMobileCardTemplateWithSelection
      entity={entity}
      roleSelectConfig={roleSelectConfig}
      isAssignedToOUBranchSelectConfig={isAssignedToOUBranchSelectConfig}
    />
  );
};

export default EquipmentAccountableMobileCardTemplate;
