import "./MobileCardTemplate.less";

import dayjs from "dayjs";

import { Training } from "../../../../types/training";
import { SHORT_DATE_FORMAT_WITHOUT_TIME } from "../../../HBComponents/DatePicker/HBDatePicker";
import CardLabel from "../core/CardLabel/CardLabel";
import CardTitle from "../core/CardTitle/CardTitle";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import StatusMark from "../core/StatusMark/StatusMark";

type TProps = {
  entity: Partial<Training>;
};

const TrainingsMobileCardTemplate: React.FC<TProps> = ({ entity }) => {
  const formattedPlannedDate = dayjs(entity.plannedDate).format(SHORT_DATE_FORMAT_WITHOUT_TIME);

  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.plannedDate && <CardTitle title={formattedPlannedDate} category="training" id={entity.id} />}
        {entity.status && <StatusMark statusLabel={entity.status} />}
      </ContainerWithDivider>
      <ContainerWithDivider>
        {entity.participentsCount !== undefined && (
          <CardLabel
            className="small-font-size"
            labelTranslationKey="Trainees"
            label={entity?.participentsCount?.toString() || ""}
          />
        )}
        {entity.attendedParticipentsCount !== undefined && (
          <CardLabel
            className="small-font-size"
            labelTranslationKey="FieldAttendees"
            label={entity?.attendedParticipentsCount?.toString() || ""}
          />
        )}
      </ContainerWithDivider>
    </CardWithSelect>
  );
};
export default TrainingsMobileCardTemplate;
