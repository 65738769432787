import { PivotConfig, Query, QueryOrder } from "@cubejs-client/core";

import dayjs, { Dayjs } from "dayjs";

import { CustomResultSet } from "../utils/dashboard/resultSet/resultSetFactory";
import { capitalizeFirstLetter } from "../utils/functions";
import { BaseEntityType } from "./entityBase";

export interface DashboardState {
  dashboards: DashboardDetails[];
  isLoading: boolean;
  single: FullDashboard;
  selectedDashboardId?: number;
  stagedChart?: ChartWithData;
  filterOptions: DashboardFilterOptionsState;
  error: string | null;
  lastUpdated: string;
  test?: boolean;
}

export enum HBChartType {
  line = "line",
  bar = "bar",
  table = "table",
  area = "area",
  number = "number",
  pie = "pie",
  heatmap = "heatmap",
}

export enum RecipientEntityType {
  Employee = "Employee",
  OrgUnit = "OrgUnit",
}

export type ScheduledReportRecipients = {
  entityType: RecipientEntityType;
  id: number;
  name: string;
  dependsOn?: number[];
};

export enum Weekdays {
  Sunday = "ScheduleReportSunday",
  Monday = "ScheduleReportMonday",
  Tuseday = "ScheduleReportTuseday",
  Wendsday = "ScheduleReportWendsday",
  Thurdsday = "ScheduleReportThurdsday",
  Friday = "ScheduleReportFriday",
  Saturday = "ScheduleReportSaturday",
}

export enum RepeatOptions {
  Daily,
  Weekly,
  Monthly,
}

export enum SpecificTimeRule {
  First = 1,
  Second = 2,
  Third = 3,
  Forth = 4,
  Last = -1,
}

export enum LocalizedSpecificTimeRule {
  First = "ScheduleReportFirst",
  Second = "ScheduleReportSecond",
  Third = "ScheduleReportThird",
  Forth = "ScheduleReportForth",
  Last = "ScheduleReportLast",
}

export enum SpecificTimeDay {
  Day = "ScheduleReportDay",
  Weekday = "ScheduleReportWeekday",
  WeekendDay = "ScheduleReportWeekendDay",
}

export type RecurrenceRule = {
  repeat: RepeatOptions;
  amount: string;
  weekdays?: Weekdays[];
  onDay?: string;
  onSpecificTime?: {
    rule: SpecificTimeRule;
    day: SpecificTimeDay | Weekdays;
  };
  endAfter?: string;
  until?: Dayjs;
};

export type ScheduledReportResponse = {
  dashboardId: number;
  ownerId: number;
  subject: string;
  date: string;
  startTime: string;
  recurrenceRule: string;
  selectedRecipients: ScheduledReportRecipients[];
  id: number;
  unifiedId: number;
};

export type ScheduledReport = {
  dashboardId: number;
  ownerId: number;
  subject: string;
  date: Dayjs;
  startTime: Dayjs;
  recurrenceRule: RecurrenceRule;
  selectedEmployeeRecipients: ScheduledReportRecipients[];
  selectedOrgUnitRecipients: ScheduledReportRecipients[];
  id: number;
  unifiedId: number;
};

export type ScheduledReportState = {
  scheduleReport?: ScheduledReport;
  isLoading: boolean;
};

export type DashboardFilterOption = {
  id: number;
  name: string;
  dependsOn: number[];
};

export type DashboardFilterType = "company" | "project" | "user" | "review" | "dictionary" | "dictionaryValue";

export type DashboardFilterOptions = {
  [key in DashboardFilterType]?: DashboardFilterOption[];
};

export type DashboardFilterOptionsState = {
  options: DashboardFilterOptions;
  isLoading: boolean;
};

export type DashboardCoordinates = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ChartBound = {
  value: number;
  label: string;
  color: string;
};

export type LabelColors = {
  label?: string;
  color?: string;
};

export type UserFilter = {
  id: number;
  type: number;
  title: string;
  entity: string;
  field: string;
  readonly: boolean;
  dependsOn?: number;
  isSelected: boolean;
  setupEntity?: string;
  selectedEntity?: number;
};

export type UserFilterValues = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type DimensionOrder = {
  sort: string;
  value: QueryOrder | "none";
};

export type UserOrder = {
  label: string;
  order: string;
};

export type userDimension = {
  label: string;
  dimension: string;
};
export type DrillDownColumn = {
  key: string;
  isChecked: boolean;
};

export type ChartAxis = "x" | "y";

export type HierarchicalState = {
  depth?: number;
  dimensionKey?: string;
  value?: string;
};

export type Chart = {
  id: number;
  name: string;
  apiId: string | undefined;
  barGrouping: string;
  chartAxis: ChartAxis;
  chartType: HBChartType;
  companyId: number;
  coordinates: DashboardCoordinates;
  customColors: LabelColors[];
  dimensions?: string[];
  filters?: UserFilterValues;
  isAllowedDrillDown: boolean;
  orders?: DimensionOrder[];
  pivotConfig?: PivotConfig;
  query: Query;
  selectedGrouping: string | null;
  selectedMeasure: string | null;
  showAllLabels: boolean;
  showNumber: boolean;
  showPercent: boolean;
  tableColumns?: DrillDownColumn[];
  uniqueApiId: string | null;
  userDimensions?: userDimension[];
  userFilters?: UserFilter[];
  userOrders?: UserOrder[];
  annotations?: ChartBound[];
  drillDownModeEnabled?: boolean;
  useHierarchicalNavigation: boolean;
  useViewData: boolean;
  hierarchicalNavigationState: HierarchicalState;
};

export type ChartWithData = Chart & {
  resultSet?: CustomResultSet;
};

export type DashboardDetails = {
  id: number;
  name: string;
  isDefault: boolean;
  isMyDefault: boolean;
  crossProjects: boolean;
  isPrivate: boolean;
};

export const emptyDashboardDetails: DashboardDetails = {
  id: 0,
  name: "New Dashboard",
  isDefault: false,
  isMyDefault: false,
  crossProjects: false,
  isPrivate: false,
};

export type BasicDashboard = {
  charts: ChartWithData[];
  dashboardOrgUnits: DashboardOrgUnit[];
  dashboardUsers: DashboardUser[];
  scheduledReport: ScheduledReportState;
};

export type FullDashboard = BasicDashboard & DashboardDetails;

export type DashboardUser = {
  id?: number;
  dashboardId?: number;
  userId: number;
  isOwner?: boolean;
  isUser?: boolean;
};

export type DashboardOrgUnit = {
  id?: number;
  dashboardId?: number;
  orgUnitId: number;
};

export type DashboardPage = BaseEntityType & {
  entityData: {
    primaryData: DashboardDetails;
  };
  subData: BasicDashboard;
  // groupViewData: GroupViewCertification;
  listViewData: BasicDashboard;

  // tabs: PrivilegeData | HistoryLog | File | Partial<Training>;
  // secondary: Inspection | HistoryLog | Location;
};

export type CreateChartResonse = {
  result: Chart;
  id: number;
  exception: string;
  status: string;
  isCanceled: boolean;
  isCompleted: boolean;
  isCompletedSuccessfully: boolean;
  creationOptions: string;
  asyncState: string;
  isFaulted: boolean;
};

const frequencyToRepeat = (frequency: string) => {
  const fixedFreq = capitalizeFirstLetter(frequency);
  return (Object.entries(RepeatOptions).find(e => e[0] === fixedFreq)?.[1] as RepeatOptions) || RepeatOptions.Daily;
};

const byDayToWeekday = (byday: string) => {
  return Object.entries(Weekdays).find(e => e[0].toUpperCase().startsWith(byday))?.[1] as Weekdays;
};

const weekdayToByDay = (weekday: string) => {
  const enumKey = Object.keys(Weekdays)[Object.values(Weekdays).indexOf(weekday as Weekdays)];
  return enumKey.substring(0, 2).toUpperCase();
};

const bySetopsToSpecificTimeRule = (bysetops: string) => {
  return parseInt(bysetops) as SpecificTimeRule;
};

export const convertObjectToRuleString: (rule: RecurrenceRule, startOfWeek: string) => string = (rule, startOfWeek) => {
  let stringRule = "RRULE:";
  const frequency = RepeatOptions[rule.repeat].toUpperCase();
  const interval = rule.amount;
  stringRule = `${stringRule}FREQ=${frequency};INTERVAL=${interval}`;

  switch (rule.repeat) {
    case RepeatOptions.Weekly: {
      const weekdays = rule.weekdays?.map(wd => weekdayToByDay(wd as string)).join(",");
      stringRule = `${stringRule};BYDAY=${weekdays}`;
      break;
    }
    case RepeatOptions.Monthly: {
      if (rule.onDay) {
        stringRule = `${stringRule};BYMONTHDAY=${rule.onDay}`;
      } else {
        if (rule.onSpecificTime?.day && Object.values(Weekdays).includes(rule.onSpecificTime?.day as Weekdays)) {
          stringRule = `${stringRule};BYSETPOS=${rule.onSpecificTime.rule};BYDAY=${weekdayToByDay(
            rule.onSpecificTime.day as string
          )}`;
        } else if (rule.onSpecificTime?.day === SpecificTimeDay.Day) {
          stringRule = `${stringRule};BYSETPOS=${rule.onSpecificTime.rule};BYDAY=${Object.entries(Weekdays).map(e =>
            weekdayToByDay(e[1] as string)
          )}`;
        } else if (rule.onSpecificTime?.day === SpecificTimeDay.Weekday) {
          if (startOfWeek === "Monday") {
            stringRule = `${stringRule};BYSETPOS=${rule.onSpecificTime.rule};BYDAY=MO,TU,WE,TH,FR;WKST=MO`;
          } else {
            stringRule = `${stringRule};BYSETPOS=${rule.onSpecificTime.rule};BYDAY=SU,MO,TU,WE,TH;WKST=SU`;
          }
        } else if (rule.onSpecificTime?.day === SpecificTimeDay.WeekendDay) {
          if (startOfWeek === "Monday") {
            stringRule = `${stringRule};BYSETPOS=${rule.onSpecificTime.rule};BYDAY=SA,SU`;
          } else {
            stringRule = `${stringRule};BYSETPOS=${rule.onSpecificTime.rule};BYDAY=FR,SA`;
          }
        }
      }
      break;
    }
  }
  if (rule.endAfter) {
    stringRule = `${stringRule};COUNT=${rule.endAfter}`;
  }
  if (rule.until) {
    stringRule = `${stringRule};UNTIL=${rule.until.utc().format("YYYYMMDDThhmmss")}Z`;
  }
  return stringRule;
};

export const convertRuleStringToObject: (rule: string) => RecurrenceRule = rule => {
  const recurrencyRule = rule.replace("RRULE", "");
  const splittedRecuurenc = recurrencyRule.split(";");
  const repeat = frequencyToRepeat(splittedRecuurenc[0].split("=")[1]);
  const amount = splittedRecuurenc[1].split("=")[1];
  const countDefinition = splittedRecuurenc.find(r => r.split("=")[0] === "COUNT");
  const untilDefinition = splittedRecuurenc.find(r => r.split("=")[0] === "UNTIL");
  let endAfter = undefined;
  let until = undefined;
  if (countDefinition) {
    endAfter = countDefinition?.split("=")[1];
  }
  if (untilDefinition) {
    until = dayjs(
      untilDefinition?.split("=")[1].replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/, "$1-$2-$3T$4:$5:$6Z")
    );
  }

  switch (repeat) {
    case RepeatOptions.Monthly: {
      const isOnDay = splittedRecuurenc[2].split("=")[0] === "BYMONTHDAY";
      if (isOnDay) {
        const onDay = splittedRecuurenc[2].split("=")[1];
        return { repeat, amount, endAfter, until, onDay };
      } else {
        const rule = bySetopsToSpecificTimeRule(splittedRecuurenc[2].split("=")[1]);
        const isOnDay = splittedRecuurenc.find(r => r.split("=")[0] === "BYDAY");
        const days = isOnDay?.split(",");
        if (days?.length === 1) {
          return {
            repeat,
            amount,
            endAfter,
            until,
            onSpecificTime: {
              rule,
              day: byDayToWeekday(splittedRecuurenc[3].split("=")[1]),
            },
          };
        } else if (days?.length === 7) {
          return {
            repeat,
            amount,
            endAfter,
            until,
            onSpecificTime: {
              rule,
              day: SpecificTimeDay.Day,
            },
          };
        } else if (days?.length === 5) {
          return {
            repeat,
            amount,
            endAfter,
            until,
            onSpecificTime: {
              rule,
              day: SpecificTimeDay.Weekday,
            },
          };
        } else {
          return {
            repeat,
            amount,
            endAfter,
            until,
            onSpecificTime: {
              rule,
              day: SpecificTimeDay.WeekendDay,
            },
          };
        }
      }
    }
    case RepeatOptions.Weekly: {
      const weekdays = splittedRecuurenc[2]
        .split("=")[1]
        .split(",")
        .map(wd => byDayToWeekday(wd));
      return { repeat, amount, endAfter, until, weekdays };
    }
  }

  return { repeat, amount, until, endAfter };
};

export enum LocalizedMonths {
  January = "DashboardDateJanuary",
  February = "DashboardDateFebruary",
  March = "DashboardDateMarch",
  April = "DashboardDateApril",
  May = "DashboardDateMay",
  June = "DashboardDateJune",
  July = "DashboardDateJuly",
  August = "DashboardDateAugust",
  September = "DashboardDateSeptember",
  October = "DashboardDateOctober",
  November = "DashboardDateNovember",
  December = "DashboardDateDecember",
}

export enum CountOptions {
  Never,
  After,
  OnDate,
}

export enum MonthlyRadioOptions {
  OnDay = "onDay",
  OnSpecificTime = "onSpecificTime",
}
