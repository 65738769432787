import { Badge } from "antd";
import dayjs from "dayjs";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { ReactComponent as SearchIcon } from "../../../../media/search-icon.svg";
import { ReactComponent as UsersIcon } from "../../../../media/users-icon.svg";
import { employeeSelectors, equipmentSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntry,
} from "../../../../store/slices/equipment";
import {
  addEntityPrivilege,
  updateEntityPrivilegeOULvl,
  updateEntityPrivilegeRole,
} from "../../../../store/slices/privileges";
import { IS_ASSIGNED_TO_OU_FIELD } from "../../../../types/constants";
import { Equipment, EquipmentStatus } from "../../../../types/equipment";
import { Inspection, InspectionStatus } from "../../../../types/inspection";
import { CellTypes, ColumnType, ColumnWithDifferentCells, InputTypes, TOption } from "../../../../types/page";
import { PrivilegeData, PrivilegedEntityType, Role } from "../../../../types/utility";
import { AccountabilityOptions, boolOptions, getCellSearchResultColumns } from "../common";
import {
  fetchLocationById,
  getAccoutableColumnCellType,
  localizeText,
  newRenderBoolStatus,
  renderBoolStatus,
  renderTableStatus,
} from "../utilities";

export const fetchAllStatuses = (): TOption[] => {
  return eqStatusOptions.map<TOption>(x => ({ id: x.id, label: localizeText(x.label) }));
};

export const eqStatusOptions: TOption[] = [
  {
    id: EquipmentStatus.Active,
    label: "Active",
    icon: <Badge color="#02D924" />,
  },
  {
    id: EquipmentStatus.InActive,
    label: "InActive",
    icon: <Badge color="#B7B7B7" />,
  },
  {
    id: EquipmentStatus.Broken,
    label: "Broken",
    icon: <Badge color="#D9042B" />,
  },
];

export const equipmentsTableColumns: ColumnType<Equipment>[] = [
  {
    id: "serialNumber",
    label: "FieldSerialNumber",
    renderValue: value => value.serialNumber,
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Equipment",
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Equipment",
  },
  {
    id: "locationId",
    exportPropertyId: "locationName",
    // FIXME: Find a better way
    renderValue: value => fetchLocationById(value.locationId!),
    label: "AppointmentLocationLink",
    sortable: true,
    width: 100,
    filterType: InputTypes.MULTI_SELECT,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    asyncFetchFilterOptionsUseKeyValue: true,
  },
  {
    id: "typeName",
    label: "FieldType",
    width: 100,
    // FIXME: DOES NOT WORK on initial load
    renderValue: value => value.typeName,
    sortable: true,

    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "EquipmentType",
    filterType: InputTypes.MULTI_SELECT,
  },
  {
    id: "expiration",
    width: 100,
    label: "FieldExpiration",
    optionsSelector: () => null,
    renderValue: value =>
      dayjs(value.expiration).isValid() ? dayjs(value.expiration).format(DATE_FORMAT_WITHOUT_TIME) : "",
    valueIcon: value => {
      const expDate = dayjs(value.expiration);
      const isValidDate = expDate.isValid();
      const dayDiff = expDate.diff(dayjs(), "days");
      return isValidDate ? <Badge color={dayDiff >= 30 ? "green" : "red"} /> : null;
    },
    sortable: true,
    filterType: InputTypes.DATE_RANGE,
  },
  {
    id: "status",
    label: "FieldStatus",
    filterType: InputTypes.MULTI_SELECT,
    width: 50,
    optionsSelector: fetchAllStatuses,
    // renderValue: value => EquipmentStatus[value.status] || EquipmentStatus.Broken,
    renderValue: () => null, // Note: We render the value together with the icon when we want to show a tag
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },
    sortable: true,
  },
];

export const equipmentAccountableNameField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "name",
  label: "FieldName",
  renderValue: value => value.name || "",
  sortable: false,
  filterType: InputTypes.MULTI_SELECT,
  cellType: e => (e.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: equipmentSelectors.allEquipmentRelations,
  cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
  cellSearchKeys: ["name"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: () => null,
  width: 100,
  primaryColumn: e => ({
    navigationTarget: e.userId ? `/employee/${e.userId}` : `/orgUnit/${e.orgUnitId}`,
  }),
  cellPrefixIcon: <UsersIcon className="user-icon" />,
  placeholder: "Name",
};

export const equipmentAccountableExternalIdField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "externalId",
  label: "ExternalID",
  renderValue: value => value.externalId || "",
  sortable: false,
  filterType: InputTypes.MULTI_SELECT,
  cellType: e => (e.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: equipmentSelectors.allEquipmentRelations,
  cellSearchResultColumns: getCellSearchResultColumns(["name", "externalId"]),
  cellSearchKeys: ["externalId"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: employeeSelectors.allEmployeeExternalIDs,
  width: 100,
  cellPrefixIcon: <SearchIcon className="search-icon" />,
  placeholder: "ExternalID",
};

export const equipmentAccountableStatusField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "status",
  label: "FieldStatus",
  renderValue: value => (value.status ? localizeText(value.status) : ""),
  sortable: false,
  optionsSelector: () => null,
  width: 100,
};

export const equipmentAccountableIsExternalField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "isExternal",
  label: "External",
  renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
  sortable: false,
  optionsSelector: () => null,
  width: 100,
};

export const equipmentAccountableRoleField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "role",
  label: "Accountability",
  renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
  cellType: () => CellTypes.DROPDOWN,
  cellDropdownOptions: () =>
    AccountabilityOptions.map(ao => ({
      id: ao.id,
      label: localizeText(ao.label),
    })),
  cellDropdownOnChange: (e, newValue) =>
    e.staging
      ? updateNewAccountableEntry({ entity: e, newValue: newValue, property: "role" })
      : updateEntityPrivilegeRole({
          entity: e,
          role: newValue as Role,
          id: null,
          entityType: PrivilegedEntityType.Equipment,
        }),
  sortable: false,
  optionsSelector: () => null,
  width: 100,
  cellPrefixIcon: <UsersIcon />,
};

export const equipmentAccountableIsAssignedToOUBranchField: ColumnWithDifferentCells<PrivilegeData> = {
  id: IS_ASSIGNED_TO_OU_FIELD,
  label: "FieldIsAssignedToOUBranch",
  renderValue: value => {
    return value.orgUnitId && value.role === Role.Recipient ? newRenderBoolStatus(value.isAssignedToOUBranch) : null;
  },
  cellType: e => getAccoutableColumnCellType(e.role, e.orgUnitId),
  sortable: true,
  cellDropdownOptions: () =>
    boolOptions.map(b => ({
      id: b.id,
      label: localizeText(b.label),
    })),
  cellDropdownOnChange: (entity, newValue) =>
    entity.staging
      ? updateNewAccountableEntry({ entity: entity, newValue: newValue, property: IS_ASSIGNED_TO_OU_FIELD })
      : updateEntityPrivilegeOULvl({
          entity: entity,
          isAssignedToOUBranch: newValue as boolean,
          entityType: PrivilegedEntityType.Equipment,
          id: null,
        }),
  optionsSelector: () => null,
  width: 100,
};

export const equipmentAccountableActionField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "action" as keyof PrivilegeData,
  label: "FieldAction",
  renderValue: () => null,
  sortable: false,
  optionsSelector: () => null,
  cellType: () => CellTypes.BUTTON_ACTIONS,
  width: 100,
  stagingActions: [
    {
      label: "Add",
      className: "hb-primary-button",
      action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.Equipment, id: null }),
    },
    {
      label: "Cancel",
      className: "hb-tertiary-button",
      action: entity => deleteNewAccountableEntryTemplate(entity),
    },
  ],
  // buttonWithDropdownActions: {
  //   primaryButton: {
  //     label: "Action Button",
  //     action: entity => console.log("clicked primary button"),
  //   },
  //   dropdownButtons: [
  //     {
  //       label: "Delete Relation",
  //       className: "hb-primary-button",
  //       action: entity => deleteEquipmentRelation(entity), // TODO: Implement a thunk for deleting a LocationOrgUnit relation which would be based on 'staging'
  //       changesConfirmation: {
  //         body: "Are you sure you wish to remove this entity?",
  //         okText: "Remove",
  //         cancelText: "Keep",
  //         addValueToBody: false,
  //         addValueToOkText: false,
  //       },
  //     },
  //   ],
  // },
};

export const equipmentAccountableTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  equipmentAccountableNameField,
  equipmentAccountableExternalIdField,
  equipmentAccountableStatusField,
  equipmentAccountableIsExternalField,
  equipmentAccountableRoleField,
  equipmentAccountableIsAssignedToOUBranchField,
  equipmentAccountableActionField,
];

export const inspectionTableColumns: ColumnWithDifferentCells<Inspection>[] = [
  {
    id: "inspectionTypeId",
    label: "FieldType",
    renderValue: value => value.inspectionTypeName,
    sortable: true,
    optionsSelector: () => null,
    primaryColumn: e => ({
      navigationTarget: `/inspectionEquipment/${e.id}`,
    }),
  },
  {
    id: "recurrencyType",
    label: "RecurrencyType",
    renderValue: value => localizeText(value.recurrencyType),
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "inspectionDate",
    label: "PluginPropertyGroupCheck_LastCheck",
    renderValue: value =>
      dayjs(value.lastCheck).isValid() ? dayjs(value.lastCheck).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "expirationDate",
    label: "FieldExpiration",
    renderValue: value =>
      dayjs(value.expirationDate).isValid() ? dayjs(value.expirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null,
    sortable: false,
    optionsSelector: () => null,
    valueIcon: value => {
      const expDate = dayjs(value.expirationDate);

      if (!expDate) return null;

      const isValidDate = expDate.isValid();
      const isExpired = expDate.isBefore(dayjs(), "day");

      return isValidDate ? <Badge color={!isExpired ? "green" : "red"} /> : null;
    },
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: entity =>
      entity.status === InspectionStatus.Missing ? localizeText(InspectionStatus.Expired) : localizeText(entity.status),
    sortable: false,
    optionsSelector: () => null,
  },
];
