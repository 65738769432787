import { DragOutlined } from "@ant-design/icons";

import { Checkbox, List } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { updateStagedChart } from "../../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { isCompareDateRangeQuery } from "../../../../../utils/dashboard/dashboards";
import { ChartContext } from "../../HBChart";
import DrillDownConfigItem from "./DrillDownConfigItem";

export type DrillDownOption = {
  title?: string;
  dimension: string;
  selected: boolean;
};

export default function DrillDownConfigurationPanel() {
  const { chart } = useContext(ChartContext);
  const stagedChart = useSelector((state: RootState) => state.dashboard.stagedChart);
  const dispatch = useAppDispatch();

  const calculateOrders = () => {
    const dimensions = chart?.query.dimensions;
    const measures = chart?.query.measures;
    const queryType = chart?.resultSet?.queryType;
    const results = isCompareDateRangeQuery(queryType) ? chart?.resultSet?.decompose()[0] : chart?.resultSet;

    const dimensionDrillDownOptions = dimensions?.map<DrillDownOption>(d => {
      return {
        title: results?.annotation().dimensions[d]?.title,
        dimension: d,
        selected: chart?.tableColumns?.find(c => c.key === d)?.isChecked || false,
      };
    });
    const measuresDrillDownOptions = measures?.map<DrillDownOption>(m => ({
      title: results?.annotation().measures[m]?.title,
      dimension: m,
      selected: true,
    }));
    let tmpOptions: DrillDownOption[] = [];
    if (dimensionDrillDownOptions) {
      tmpOptions = tmpOptions.concat(dimensionDrillDownOptions);
    }
    if (measuresDrillDownOptions) {
      tmpOptions = tmpOptions.concat(measuresDrillDownOptions);
    }
    return tmpOptions;
  };

  const [drillDownOptions, setDrillDownOptions] = useState<DrillDownOption[]>(calculateOrders());

  const onOrderChanged = () => {
    if (stagedChart) {
      dispatch(
        updateStagedChart({
          ...stagedChart,
          tableColumns: drillDownOptions.map(dOption => ({ key: dOption.dimension, isChecked: dOption.selected })),
        })
      );
    }
  };

  const onDrillDownEnabledChanged: (e: CheckboxChangeEvent) => void = () => {
    if (stagedChart) {
      dispatch(
        updateStagedChart({
          ...stagedChart,
          isAllowedDrillDown: !stagedChart.isAllowedDrillDown,
        })
      );
    }
  };

  return (
    <div id="drillDownConfig">
      <Checkbox defaultChecked={stagedChart?.isAllowedDrillDown} onChange={onDrillDownEnabledChanged}>
        Drill Down
      </Checkbox>
      {stagedChart?.isAllowedDrillDown && (
        <div id="drillDownDnDArea">
          <DragDropContext
            onDragEnd={result => {
              if (result.destination) {
                const items = Array.from(drillDownOptions);
                const [reorderedItems] = items.splice(result.source.index, 1);
                items.splice(result.destination.index, 0, reorderedItems);
                setDrillDownOptions(items);
                if (stagedChart) {
                  dispatch(
                    updateStagedChart({
                      ...stagedChart,
                      tableColumns: items.map(dOption => ({
                        key: dOption.dimension,
                        isChecked: dOption.selected,
                      })),
                    })
                  );
                }
              }
            }}
          >
            <Droppable droppableId="ordersList">
              {providedParent => (
                <div {...providedParent.droppableProps} ref={providedParent.innerRef} className="ordersContainer">
                  <List
                    size="small"
                    // header={<Typography.Text className="column__title">{t("InUse")}</Typography.Text>}
                    className="ordersList"
                    dataSource={drillDownOptions}
                    renderItem={(item, index) => (
                      <Draggable
                        draggableId={item.dimension || `dimension-${index}`}
                        index={index}
                        key={item.dimension}
                      >
                        {provided => (
                          <List.Item
                            key={item.dimension}
                            className="orders"
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <div className="orderContainer">
                              <div style={{ marginRight: "1rem" }} {...provided.dragHandleProps}>
                                <DragOutlined />
                              </div>
                              <DrillDownConfigItem onSelectedChanged={onOrderChanged} item={item} />
                            </div>
                          </List.Item>
                        )}
                      </Draggable>
                    )}
                    itemLayout="horizontal"
                  />
                  {providedParent.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
}
