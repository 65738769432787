import "./CardList.less";

import { Empty, Space } from "antd";
import { useTranslation } from "react-i18next";

import { useMobileSingleViewTabContext } from "../context/useMobileSingleViewTabContext";
import MobileCardAddEntitySection from "./MobileCardAddEntitySection";

const CardList: React.FC = () => {
  const { t } = useTranslation();
  const { tabConfig, tabData } = useMobileSingleViewTabContext();
  const isEmptyState = !tabData.length;

  return (
    <>
      <MobileCardAddEntitySection />
      <Space direction="vertical" size={10} className="mobile-card-list">
        {isEmptyState ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NoData")} />
        ) : (
          tabData.map((entity, index) => {
            if (tabConfig.mobileCardTemplate) {
              const Template = tabConfig.mobileCardTemplate;
              return <Template key={(entity?.id as string) || index} entity={entity} />;
            }

            return null;
          })
        )}
      </Space>
    </>
  );
};

export default CardList;
