import {
  ColumnManagementAction,
  ExportAction,
  GetFiltersUrl,
  ImportAction,
  ResetColumnsWidthAction,
} from "../../../../actions/menuActions";
import AddEmployeeSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddEmployeeSection";
import LocationAccountableMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/accountable/LocationAccountableMobileCardTemplate";
import ContextActionsMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/ContextActionsMobileCardTemplate";
import EquipmentMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/EquipmentMobileCardTemplate";
import HistoryLogMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/HistoryLogMobileCardTemplate";
import InspectionLocationMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/inspections/InspectionLocationMobileCardTemplate";
import { ReactComponent as PageIcon } from "../../../../media/location-page-icon.svg";
import { locationEntitySelectors, pageSelectors } from "../../../../selectors";
import { fetchHistoryLog } from "../../../../store/slices/commonThunks";
import { fetchEquipmentTypes } from "../../../../store/slices/equipmentType";
import {
  addLocation,
  clearLocationError,
  createLocationTemplate,
  createNewAccountableEntryTemplate,
  fetchLocationCustomProps,
  fetchPaginatedLocations,
  fetchSingleLocation,
  patchLocationParentId,
  resetCurrentPage,
  resetSearchResults,
  searchLocation,
  updateLocation,
  updateParentId,
} from "../../../../store/slices/location";
import { fetchLocationTypes } from "../../../../store/slices/locationType";
import { deleteMultipleEntityPrivileges, getEntityPrivileges } from "../../../../store/slices/privileges";
import store from "../../../../store/store";
import { Equipment } from "../../../../types/equipment";
import { InspectionSingleView } from "../../../../types/inspection";
import { Location, LocationPage } from "../../../../types/location";
import { CategoryPage, ColumnType, SectionType } from "../../../../types/page";
import {
  HistoryLogEntityType,
  PrivilegeData,
  PrivilegedEntityType,
  TableAddButtonType,
} from "../../../../types/utility";
import { contextActionsTableColumns, historyLogTableColumns, locationsTableColumns } from "../common";
import {
  fetchLocationById,
  localizeText,
  pickSpecificColumns,
  prepareDynamicColumns,
  prepareDynamicColumnsPaginated,
  removeColumnFilters,
  renderTableStatus,
} from "../utilities";
import {
  externalIdField,
  nameField,
  parentIdField,
  primaryFields,
  secondaryFields,
  statusField,
  typeIdField,
} from "./fields";
import {
  locationAccountableActionField,
  locationAccountableExternalIdField,
  locationAccountableIsAssignedToOUBranchField,
  locationAccountableNameField,
  locationAccountableRoleField,
  locationAccountableTabColumns,
  locationInspectionTableColumns,
} from "./locationConfigPageData";

const fetchEquipmentTypeById = (id: number): string | null => {
  return (store.getState().equipmentType.data || []).find(type => type.id === id)?.name || null;
};

const equipmentsTableColumns: ColumnType<Equipment>[] = [
  {
    id: "serialNumber",
    label: "FieldSerialNumber",
    renderValue: value => value.serialNumber,
    sortable: true,
    optionsSelector: () => null,
    width: 100,
    primaryColumn: e => ({
      navigationTarget: `/equipment/${e.id}`,
    }),
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "typeId",
    label: "FieldType",
    renderValue: value => (value.typeId ? fetchEquipmentTypeById(value.typeId) : null),
    optionsSelector: () => null,
    sortable: true,
    width: 100,
  },
  {
    id: "locationId",
    label: "AppointmentLocationLink",
    renderValue: value => (value.locationId ? fetchLocationById(value.locationId) : null),
    optionsSelector: () => null,
    sortable: true,
    width: 100,
  },
  {
    id: "expiration",
    label: "FieldExpirationDate",
    renderValue: value => value.expiration,
    optionsSelector: () => null,
    sortable: true,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null, // Note: We render the value together with the icon when we want to show a tag
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },
    optionsSelector: () => null,
    sortable: true,
    width: 100,
  },
];

// TODO: FIX STATIC CONFIG
const locationConfigPage = (): CategoryPage<LocationPage> => ({
  id: "location",
  title: "PluginLocationName",
  entityEndpoint: "/Location",
  PageIcon: PageIcon,
  hasGroupView: false,
  hasListView: true,
  intializeDataActions: [fetchLocationTypes, fetchEquipmentTypes],
  primaryErrorSelector: locationEntitySelectors.error,
  // fetchPrimaryData: fetchLocationCustomProps,
  fetchCustomProps: fetchLocationCustomProps,
  lastUpdatedSelector: locationEntitySelectors.locationLastUpdatedText,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  addButton: {
    label: "AddLocation",
    // eslint-disable-next-line no-console
    action: () => console.log("Create new location"),
  },
  summaryCard: {
    remotelyUpdatedFieldIDs: ["parentId"],
    cardInformationalMessasge: e => {
      if (e.parentId === null) {
        return localizeText("RootLocationWarning");
      }
      return "";
    },
    globalEditForbidden: e => e.parentId === null && true,
    mobileSingleView: {
      summary: {
        icon: PageIcon,
        title: nameField,
        id: externalIdField,
        status: statusField,
        mainContextRelation: parentIdField,
        date: typeIdField,
      },
      sections: [
        {
          type: SectionType.ContextSection,
          fields: [],
        },
        {
          type: SectionType.DatesSection,
          fields: [],
        },
      ],
    },

    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
  },
  tabsPanel: [
    {
      key: "actions",
      label: "FieldActions",
      tabSelector: locationEntitySelectors.actions,
      columns: removeColumnFilters(contextActionsTableColumns()),
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: ContextActionsMobileCardTemplate,
    },
    {
      key: "location",
      label: "ParentLocation",
      tabSelector: locationEntitySelectors.basic,
      columns: removeColumnFilters(locationsTableColumns) as [],
      rowSelection: true,
      type: "tree",
      // currentSelection: () => fetchCurrentLocationId(),
      changeNewEntityParent: updateParentId,
      changeExistingEntityParent: patchLocationParentId,
      selectedKeyId: "parentId",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<Location>(props, data)),
      customPropertiesSelector: locationEntitySelectors.locationCustomProperties,
    },
    {
      key: "inspections",
      label: "PluginInspectionName",
      tabSelector: locationEntitySelectors.locationInspectionsSelector,
      type: "table",
      rowSelection: true,
      columns: removeColumnFilters(locationInspectionTableColumns) as [],
      generateDynamicColumns: (props, data) =>
        removeColumnFilters(prepareDynamicColumns<InspectionSingleView>(props, data)),
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: InspectionLocationMobileCardTemplate,
    },
    {
      key: "accountable",
      label: "Accountable",
      tabSelector: locationEntitySelectors.locationEmployees,
      tabDataThunk: id => getEntityPrivileges({ id: +id, entityType: PrivilegedEntityType.Location }),
      columns: removeColumnFilters(locationAccountableTabColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewAccountableEntryTemplate,
        label: "AddEmployee",
        type: TableAddButtonType.AddInline,
        mobileAddButtonTemplate: AddEmployeeSection,
        actionField: locationAccountableActionField,
        addEntityFields: [
          locationAccountableNameField,
          locationAccountableExternalIdField,
          locationAccountableRoleField,
          locationAccountableIsAssignedToOUBranchField,
        ],
      },
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities =>
            deleteMultipleEntityPrivileges({
              entity: entities as PrivilegeData[],
              entityType: PrivilegedEntityType.Location,
              id: null,
            }),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
      mobileCardTemplate: LocationAccountableMobileCardTemplate,
    },
    {
      key: "equipment",
      label: "PluginEquipmentName",
      rowSelection: true,
      tabSelector: locationEntitySelectors.locationEquipments,
      columns: removeColumnFilters(equipmentsTableColumns) as [], // TODO: fix cast
      type: "table",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<Equipment>(props, data)),
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: EquipmentMobileCardTemplate,
    },
    {
      key: "historyLog",
      label: "HistoryLog",
      tabDataThunk: id => fetchHistoryLog({ entityId: +id, entityType: HistoryLogEntityType.Location }),
      tabSelector: locationEntitySelectors.locationHistoryLog,
      columns: removeColumnFilters(historyLogTableColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: HistoryLogMobileCardTemplate,
    },
  ],
  listView: {
    table: {
      fetchPaginatedData: fetchPaginatedLocations,
      searchResultsSelector: locationEntitySelectors.locationSearchResults,
      possibleResultsSelector: locationEntitySelectors.locationPossibleResults,
      tableSelector: locationEntitySelectors.all,
      columns: locationsTableColumns,
      rowSelection: true,
      defaultColumnKeys: ["externalId", "name", "parentId"],
      type: "paginatedTable",
      searchPaginatedData: searchLocation,
      resetSearchResult: resetSearchResults,
      resetCurrentPage: resetCurrentPage,
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumnsPaginated<Location>(props, data),
  },
  listSearch: {
    columns: pickSpecificColumns<Location>(locationsTableColumns, "name", "externalId"),
    keys: ["name", "id", "externalId"],
  },
  entityProperties: [
    { property: "externalId", title: "ID", required: true },
    { property: "name", title: "FieldName", creation: true },
    { property: "status", title: "FieldStatus" },
    { property: "locationTypeName", title: "FieldType" },
    { property: "parentIdentifier", title: "ParentId" },
    { property: "owners", title: "Owners" },
    { property: "recipients", title: "Recipients" },
  ],
  isLoading: pageSelectors.isLocationPageLoading,
  primarySingleEntitySelector: locationEntitySelectors.single,
  customPropertiesSelector: locationEntitySelectors.locationCustomProperties,
  fetchSingle: fetchSingleLocation,
  clearError: clearLocationError,
  createNewEntityTemplate: createLocationTemplate,
  createNewEntity: addLocation,
  updateEntity: updateLocation,
  listViewActions: [ImportAction, ColumnManagementAction, ResetColumnsWidthAction, ExportAction, GetFiltersUrl],
});

export default locationConfigPage;
