import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { dayjsLocales } from "../locales";
import { Language } from "../types/user";
import QuestionPositions from "./common/QuestionPositions";
import { shouldPresentQuestion } from "./common/RenderHelpers";
import {
  Category,
  I18N_FIELDS,
  I18N_SUFFIX,
  I18nTranslation,
  TApiResponse,
  TBranding,
  TInitialFormattedState,
} from "./types";

dayjs.extend(customParseFormat);

/**
 * Maps the review data to the state doing some data transformations in the process
 * @param data
 * @param branding
 */

export const RTL_DATE_FORMAT = "DD/MM/YYYY";
export const LTR_DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "HH:mm A";

export const mapDataToState = (
  isLivePdf: boolean,
  data: TApiResponse,
  branding: TBranding,
  dateFormat: string,
  timeFormat?: string,
  lang?: string
): TInitialFormattedState => {
  const state: TInitialFormattedState = {} as TInitialFormattedState;
  const detailsCategory = data.categories.find(c => c.type === "Details")?.id;

  state.appointmentId = data.appointmentId;
  state.actionId = data.actionId;
  state.location =
    data.appointmentInfo?.latX !== null && data.appointmentInfo?.longY !== null
      ? { latx: data.appointmentInfo?.latX, longY: data.appointmentInfo?.longY }
      : data.latX !== null && data.longY !== null
      ? { latx: data.latX, longY: data.longY }
      : undefined;
  state.reviewName =
    data.appointmentInfo?.reviewName ||
    data.questions.find(q => q.position === QuestionPositions.ReviewName && q.categoryId === detailsCategory)
      ?.selectedAnswers[0].text ||
    "Review Name";
  state.projectName =
    data.appointmentInfo?.projectName ||
    data.questions.find(q => q.position === QuestionPositions.ProjectName && q.categoryId === detailsCategory)
      ?.selectedAnswers[0].text ||
    "";
  state.personName = data.appointmentInfo?.personName
    ? [data.appointmentInfo?.personName]
    : data.questions
        .find(q => q.position === QuestionPositions.PersonName && q.categoryId === detailsCategory)
        ?.selectedAnswers.filter(a => a.text)
        .map(a => a.text as string) || [];

  const timeZone = isLivePdf || !branding.company.timeZone ? dayjs.tz.guess() : branding.company.timeZone;

  const formatDateWithTimezone = (date: string, format?: string) => {
    const offset = dayjs().tz(timeZone).utcOffset();

    return dayjs(date)
      .locale(dayjsLocales[lang ?? "en-US"] ?? dayjsLocales["en-US"])
      .utcOffset(offset)
      .format(format);
  };

  const completedDate = data.appointmentInfo?.completedDate;
  const submittedDate = data.appointmentInfo?.submittedDate;
  const createdAtDateQuestion = data.questions.find(
    q => q.position === QuestionPositions.CreatedAtDate && q.categoryId === detailsCategory
  )?.selectedAnswers[0].text;
  const createdAtTimeQuestion = data.questions.find(
    q => q.position === QuestionPositions.CreatedAtTime && q.categoryId === detailsCategory
  )?.selectedAnswers[0].text;

  let createdAtDate;
  let createdAtTime;
  if (submittedDate) {
    state.submittedDate = submittedDate;
  } else if (completedDate) {
    const utcDate = completedDate.charAt(completedDate.length - 1) === "Z" ? completedDate : completedDate + "Z";
    createdAtDate = formatDateWithTimezone(utcDate, dateFormat);
    createdAtTime = formatDateWithTimezone(utcDate, timeFormat);
  } else {
    //if the date is not present, we will use the answers for date and time
    if (createdAtDateQuestion) {
      const dateInCompanyTimezone = dayjs.tz(createdAtDateQuestion, dateFormat, timeZone);
      createdAtDate = formatDateWithTimezone(dateInCompanyTimezone.toISOString(), dateFormat);
    } else {
      createdAtDate = "";
    }

    if (createdAtTimeQuestion && createdAtDate) {
      const timeInCompanyTimezone = dayjs.tz(
        `${createdAtDate} ${createdAtTimeQuestion}`,
        `${dateFormat} ${timeFormat || TIME_FORMAT}`,
        timeZone
      );
      createdAtTime = timeInCompanyTimezone.format(timeFormat);
    } else {
      createdAtTime = "";
    }
  }

  state.createdAtDate = createdAtDate;
  state.createdAtTime = createdAtTime;

  const categories = data.categories
    .filter(c => {
      if (c.type === "Details") {
        return false;
      }
      return c.isPrintable;
    })
    .map(c => ({
      name: c.printText ?? c.name,
      questions: [],
      categoryId: c.id,
      image: c.image,
      questionGroups: data.reviewResultQuestionGroups?.filter(qg => qg.categoryId === c.id && qg.isPrintable) || [],
      position: c.position,
    })) as Category[];

  categories.forEach(c => {
    c.questions = data.questions
      .filter(q => q.categoryId === c.categoryId && q.isPrintable && shouldPresentQuestion(q, branding))
      .map(q => ({
        type: q.questionType,
        answers: q.selectedAnswers
          .filter(a => !a.parentAnswerId)
          .map(selectedAnswer => ({
            ...selectedAnswer,
            subAnswers: q.selectedAnswers.filter(
              a =>
                a.parentAnswerId &&
                a.parentAnswerId ===
                  (q.answersType === "Dictionary" || q.answersType === "ChildDictionary"
                    ? selectedAnswer.extraId
                    : selectedAnswer.answerId)
            ),
          })),
        title: q.printText ?? q.title,
        reviewResultQuestionGroupId: q.reviewResultQuestionGroupId,
        id: q.id,
        position: q.position,
        hideAnswer: q.hideAnswer,
      }))
      .sort((a, b) => a.position - b.position);
  });

  state.categories = categories.filter(c => c.questions.length > 0).sort((a, b) => a.position - b.position);
  return state;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformI18nField = (field: Record<string, any>, fieldName: string, lang: Language) => {
  if (field[fieldName + I18N_SUFFIX]?.value) {
    const i18nValue =
      field[fieldName + I18N_SUFFIX].i18n?.find((x: I18nTranslation) => x.locale === lang)?.translation ||
      field[fieldName + I18N_SUFFIX].value;
    field[fieldName] = i18nValue;
  }
};

export function handleI18nFields(data: TApiResponse, lang: Language) {
  if (!data?.appointmentInfo) {
    return data;
  }

  // Transform appointmentInfo fields
  transformI18nField(data.appointmentInfo, I18N_FIELDS.REVIEW_NAME, lang);
  transformI18nField(data.appointmentInfo, I18N_FIELDS.TITLE, lang);

  // Transform questions and categories
  data.questions.forEach(question => {
    question.selectedAnswers.forEach(answer => {
      transformI18nField(answer, I18N_FIELDS.TEXT, lang);
    });
    transformI18nField(question, I18N_FIELDS.TITLE, lang);
  });

  data.categories.forEach(category => {
    transformI18nField(category, I18N_FIELDS.NAME, lang);
  });
  data.reviewResultQuestionGroups?.forEach(category => {
    transformI18nField(category, I18N_FIELDS.TITLE, lang);
  });

  return data;
}
