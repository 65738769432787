import "../MobileCardTemplate.less";

import { useTranslation } from "react-i18next";

import { ColumnWithDifferentCells } from "../../../../../types/page";
import { PrivilegeData, Role } from "../../../../../types/utility";
import CardLabel from "../../core/CardLabel/CardLabel";
import EditableInputMobileFirstWithConfig from "../EditableInputMobileFirstWithConfig";
import AccountableMobileCardTemplate from "./AccountableMobileCardTemplate";

type TProps = {
  entity: PrivilegeData;
  roleSelectConfig: ColumnWithDifferentCells<PrivilegeData>;
  isAssignedToOUBranchSelectConfig: ColumnWithDifferentCells<PrivilegeData>;
};

const AccountableMobileCardTemplateWithSelection: React.FC<TProps> = ({
  entity,
  roleSelectConfig,
  isAssignedToOUBranchSelectConfig,
}) => {
  const { t } = useTranslation();

  return (
    <AccountableMobileCardTemplate entity={entity}>
      <EditableInputMobileFirstWithConfig entity={entity} config={roleSelectConfig} />
      {entity.orgUnitId && entity.role === Role.Recipient && (
        <>
          <CardLabel label={t(isAssignedToOUBranchSelectConfig.label) as string} />
          <EditableInputMobileFirstWithConfig entity={entity} config={isAssignedToOUBranchSelectConfig} />
        </>
      )}
    </AccountableMobileCardTemplate>
  );
};

export default AccountableMobileCardTemplateWithSelection;
