import { Form, FormInstance, Input } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { CubeDimension, DimensionJoin } from "../../../../types/cubeDimensions";
import SelectJoins from "./SelectJoins";
import SelectQuestions from "./SelectQuestions";

type AddDimensionsFormProps = {
  dimension?: CubeDimension;
  formInstance: FormInstance;
  onFinish: (values: CubeDimension) => void;
};

const initials = (value: string) => {
  const splitted = value.split(" ");
  return splitted.map(word => word[0]).join("");
};

export default function AddDimensionsForm({ dimension, formInstance, onFinish }: AddDimensionsFormProps) {
  const formLayout = {};
  const { dimensions } = useSelector((state: RootState) => state.cubeDimensions);
  const [selectedQuestions, setselectedQuestions] = useState<number[] | undefined>();
  const [selectedJoins, setSelectedJoins] = useState<DimensionJoin[] | undefined>();

  return (
    <Form form={formInstance} name="addDimension" {...formLayout} onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Name"
        hasFeedback
        initialValue={dimension?.name}
        validateTrigger="onChange"
        rules={[
          { required: true, message: "please input dimension name" },
          ({ getFieldValue }) => ({
            validator() {
              const dimensionsInitials = dimensions.filter(d => d.id !== dimension?.id).map(d => initials(d.name));
              if (!dimensionsInitials.includes(initials(getFieldValue("name")))) {
                return Promise.resolve();
              } else {
                return Promise.reject("name must have unique initials");
              }
            },
          }),
        ]}
      >
        <Input placeholder="Enter Dimension Name" />
      </Form.Item>
      <Form.Item
        name="questionIds"
        label="Questions"
        hasFeedback
        initialValue={dimension ? dimension.questionIds : []}
        validateTrigger="onChange"
        rules={[
          { required: true, type: "array", message: "please select questions" },
          ({ getFieldValue }) => ({
            validator() {
              if (getFieldValue("questionIds").length > 0) {
                return Promise.resolve();
              } else {
                return Promise.reject("please choose questions");
              }
            },
          }),
        ]}
      >
        <SelectQuestions value={selectedQuestions} onChange={selectedValues => setselectedQuestions(selectedValues)} />
      </Form.Item>
      <Form.Item
        name="joins"
        label="Joins"
        hasFeedback
        initialValue={dimension ? dimension.joins : []}
        rules={[
          ({ getFieldValue }) => ({
            validator() {
              const joins: DimensionJoin[] = getFieldValue("joins");
              let validationPassed = true;
              if (joins.length > 0) {
                joins.forEach(join => {
                  if (!join.joinBy || !join.joinedDimensionId) {
                    validationPassed = validationPassed && false;
                  }
                });
                if (validationPassed) return Promise.resolve();
                return Promise.reject("please chosse joins and dimensions");
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <SelectJoins
          currentDimensionId={dimension?.id}
          onChange={selection => setSelectedJoins(selection)}
          value={selectedJoins}
        />
      </Form.Item>
    </Form>
  );
}
