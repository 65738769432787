import "./landingPage.less";

import { Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";

import { setPrimaryColor } from "../../store/slices/common";
import { fetchLandingPageInfo, removeJwt } from "../../store/slices/landingPage";
import { RootState, useAppDispatch } from "../../store/store";
import { LandingPageAuthType, Mode } from "../../types/landingPage";
import { LocalStorageKeys, ParamsKeys } from "../../types/utility";
import { applyTheme } from "../../utils/hooks/useCompanyColors";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useRouter from "../../utils/hooks/useRouter";
import useFormManagmentEvents, { MobileEvent } from "../EmbeddedModal/useFormManagmentEvents";
import BackgroundWithoutImage from "./BackgroundWithoutImage";
import LandingPageBubble from "./LandingPageBubble";
import LandingPageFooter from "./LandingPageFooter";
import LandingPageFormFilling from "./LandingPageFormFilling";
import LandingPageLogin from "./LandingPageLogin";
import NotAllowedComponent from "./NotAllowedComponent";
import NotFoundComponent from "./NotFoundComponent";

const IMAGE_MODE = "with-image";
const NO_IMAGE_MODE = "without-image";
const LIGHT_BACKGROUND = "#FBFBFB";
const TITLE_DARK_COLOR = "#333435";

const LandingPage: React.FC = () => {
  const { pathname, history, location } = useRouter<{ referer: Location }>();
  const dispatch = useAppDispatch();
  const { landingPageInfo, landingPageJwt, mode, notFound, notAllowed } = useSelector(
    (state: RootState) => state.landingPage
  );
  const { addMobileEventListener } = useFormManagmentEvents();
  const { jwt } = useSelector((state: RootState) => state.user);
  const { settings } = useSelector((state: RootState) => state.user);
  const [reviewCompleted, setReviewCompleted] = useState<boolean>(false);
  const { t } = useTranslation();
  const urlSearchParams = new URLSearchParams(location.search);
  const landingPageKey = pathname.replace("/landingPage/", "");
  const noRedirect = Boolean(urlSearchParams.get(ParamsKeys.NOREDIRECT));
  const storedJwt = localStorage.getItem(LocalStorageKeys.JWT);
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const isMobile = useIsMobile();

  useEffect(() => {
    const setColors = async () => {
      if (landingPageInfo?.colors) {
        if (landingPageInfo.colors?.mainColor) {
          await dispatch(setPrimaryColor(landingPageInfo.colors?.mainColor));
          applyTheme("@primary-color", landingPageInfo.colors.mainColor);
        }
      }
    };
    setColors();
  }, [landingPageInfo?.colors]);

  useEffect(() => {
    if (landingPageInfo?.authType === LandingPageAuthType.FullLogin && !storedJwt && !noRedirect) {
      window.location.href = `${process.env.REACT_APP_OLD_PAS_URL}/ReactUI/index?page=/landingPage/${landingPageKey}`;
    }
    dispatch(
      fetchLandingPageInfo({
        key: landingPageKey,
        lang: localStorage.getItem(LocalStorageKeys.LANG) ?? lang,
        isMobile: false,
      })
    );
  }, [landingPageJwt, jwt, landingPageInfo?.authType]);

  useEffect(() => {
    if (reviewCompleted) {
      setReviewCompleted(false);
      if (landingPageInfo?.authType === LandingPageAuthType.FullLogin) {
        history.push("/employee");
      } else {
        dispatch(removeJwt());
        window.location.reload();
      }
    }
  }, [reviewCompleted]);

  const getBackgroundWithImage = () => {
    if (isMobile) {
      return (
        <div
          style={{
            backgroundColor: "white",
            zIndex: 0,
          }}
        >
          <div
            style={{ backgroundImage: `url(${landingPageInfo?.image})` }}
            className="landing-page-mobile-image"
          ></div>
          <LandingPageBubble color={primaryColor} radius="180vw" left="-40vw" top="75svh" />
          <LandingPageBubble
            color={tinycolor(primaryColor).complement().darken(5).toHexString()}
            radius="40vw"
            left={settings.direction === "rtl" ? "-20vw" : "80vw"}
            top="-5vh"
          />
          <LandingPageBubble
            color={tinycolor(primaryColor).setAlpha(0.2).toHex8String()}
            radius="40vw"
            left={settings.direction === "rtl" ? "60vw" : "0vw"}
            top="-13vh"
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor: "white",
            zIndex: 0,
          }}
        >
          <div
            style={{ backgroundImage: `url(${landingPageInfo?.image})` }}
            className={settings.direction === "ltr" ? "image-ltr" : "image-rtl"}
          ></div>
          <LandingPageBubble
            color={primaryColor}
            radius="90vw"
            left={settings.direction === "ltr" ? "55vw" : "-50vw"}
            top="-25vh"
          />
          <LandingPageBubble
            color={tinycolor(primaryColor).complement().darken(5).toHexString()}
            radius="10vw"
            left={settings.direction === "ltr" ? "95vw" : "-5vw"}
            top="50vh"
          />
          <LandingPageBubble
            color={tinycolor(primaryColor).setAlpha(0.2).toHex8String()}
            radius="10vw"
            left={settings.direction === "ltr" ? "-5vw" : "95vw"}
            top="65vh"
          />
        </div>
      );
    }
  };

  const getLandingPageBackground = () => {
    if (isImageMode()) {
      return getBackgroundWithImage();
    } else {
      return <BackgroundWithoutImage />;
    }
  };

  const shouldShowLogin = () => {
    return landingPageInfo?.needAuthentication && !landingPageInfo.jwtToken && !landingPageJwt;
  };

  const isImageMode = () => {
    return landingPageInfo?.image ? landingPageInfo.image !== undefined : false;
  };

  const getLandingPageImageModeClassname = () => {
    return `landing-page-content-container-${isImageMode() ? IMAGE_MODE : NO_IMAGE_MODE} ${
      !isMobile && isImageMode() ? `landing-page-content-image-mode-${settings.direction}` : ""
    }`;
  };

  const getLandingPageFlowContainerClassname = () => {
    return `landing-page-flow-container ${
      shouldShowLogin() ? `landing-page-show-login-${!isImageMode() ? NO_IMAGE_MODE : IMAGE_MODE}` : ""
    } ${isImageMode() ? `landing-page-flow-container-image-mode-${settings.direction}` : ""}`;
  };

  const getLandingPageHeaderContainerClassname = () => {
    return `landing-page-header-container ${
      shouldShowLogin() ? "landing-page-header-container-login-mode" : "landing-page-header-container-form-mode"
    } ${
      isImageMode()
        ? `landing-page-heade-container-margin landing-page-header-container-image-mode-${settings.direction}`
        : ""
    }`;
  };

  const getLandingPageCompanyLogoContainerClassname = () => {
    return `logo-container ${settings.direction} ${isImageMode() ? IMAGE_MODE : NO_IMAGE_MODE}`;
  };

  const formSubmittedListener = useCallback((event: CustomEvent) => {
    console.warn(`submitted status: ${event.detail}`);
    setReviewCompleted(true);
  }, []);

  const getLandingPageByFlow = () => {
    return (
      <div className={getLandingPageFlowContainerClassname()}>
        <div className={getLandingPageHeaderContainerClassname()}>
          {shouldShowLogin() && (
            <div
              style={{ backgroundImage: `url(${landingPageInfo?.logo})` }}
              className={getLandingPageCompanyLogoContainerClassname()}
            />
          )}
          {!landingPageInfo?.surveyUrl && (
            <div style={{ color: landingPageInfo?.image ? TITLE_DARK_COLOR : "white" }} className="landing-page-title">
              {mode === Mode.Code ? t("AuthTypeOTP") : landingPageInfo?.reviewName}
            </div>
          )}
        </div>
        {shouldShowLogin() ? (
          <LandingPageLogin />
        ) : (
          <LandingPageFormFilling
            imageMode={isImageMode()}
            onLoad={() => {
              addMobileEventListener(MobileEvent.FormAnsweringFinished, formSubmittedListener as EventListener);
            }}
          />
        )}
      </div>
    );
  };

  if (notFound) {
    return <NotFoundComponent />;
  }

  if (notAllowed) {
    return <NotAllowedComponent />;
  }

  return (
    <div
      className="landing-page-container"
      style={{
        backgroundColor: landingPageInfo?.image ? LIGHT_BACKGROUND : landingPageInfo?.colors.mainColor,
      }}
    >
      <Helmet>
        <meta name="theme-color" content={primaryColor} media="(prefers-color-scheme: light)" />
      </Helmet>
      {!landingPageInfo ? (
        <div className="landing-page-spinner">
          <Spin spinning />
        </div>
      ) : (
        landingPageInfo && (
          <>
            <div className={getLandingPageImageModeClassname()}>
              {getLandingPageByFlow()}
              <LandingPageFooter isDarkBackground={landingPageInfo?.image && !isMobile ? true : false} />
            </div>
            {getLandingPageBackground()}
          </>
        )
      )}
    </div>
  );
};

export default LandingPage;
