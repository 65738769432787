export enum UserSettingsConstants {
  // Note: Used as a suffix in combination with pageId (e.g. location/employee/etc = locationColKeys/employeeColKeys/etc)
  COL_KEYS_SUFFIX = "ColKeys",
  FILTER_KEYS_SUFFIX = "Filters",
}

export const ROW_SVG_SIZE = 24;

export const IS_ASSIGNED_TO_OU_FIELD = "isAssignedToOUBranch";

export const DASHBOARD_FULL_SIZE_BREAKPOINT = 1300;
