import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Space } from "antd";
import { Colorpicker } from "antd-colorpicker";
import { useState } from "react";
import { useSelector } from "react-redux";
import { updateStagedChart } from "../../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { ChartBound } from "../../../../../types/dashboard";

type BoundsForm = {
  bounds: ChartBound[];
};

export default function Annotations() {
  const [form] = Form.useForm<BoundsForm>();
  const dispatch = useAppDispatch();
  const stagedChart = useSelector((state: RootState) => state.dashboard.stagedChart);
  const [changeTimeout, setChangeTimeout] = useState<NodeJS.Timeout | undefined>();
  const onFinish = (values: BoundsForm) => {
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, annotations: values.bounds }));
    }
  };

  return (
    <Form
      form={form}
      name="bounds"
      onValuesChange={(_, allValues) => {
        if (changeTimeout) {
          clearTimeout(changeTimeout);
        }
        setChangeTimeout(setTimeout(() => onFinish(allValues), 300));
      }}
      autoComplete="off"
      initialValues={{
        bounds: stagedChart?.annotations,
      }}
    >
      <Space direction="vertical">
        <Form.List name="bounds">
          {(fields, { add, remove }) => (
            <>
              {" "}
              {fields.map((field, index) => (
                <Space key={`${field.key}-${index}`} align="baseline">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.series !== curValues.series || prevValues.color !== curValues.color
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        key={`${field.key}-label`}
                        label="label"
                        name={[field.name, "label"]}
                        rules={[{ required: true, message: "missing label" }]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={`${field.key}-value`}
                    label="value"
                    name={[field.name, "value"]}
                    rules={[{ required: true, message: "missing value" }]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="color"
                    key={`${field.key}-color`}
                    name={[field.name, "color"]}
                    rules={[{ required: true, message: "Missing color" }]}
                  >
                    <Colorpicker popup onColorResult={color => color.hex} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Bound
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Space>
    </Form>
  );
}
