import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { RootState } from "../../store/store";
import { HBEvent } from "../../types/analyticsTypes/HBEvent";
import mixpanelWrapper from "../trackers/mixpanel/mixpanelWrapper";

const PLATFORM_PROPERTY = "platform";
const COMPANY_ID_PROPERTY = "companyId";
const PLATFORM_VALUE = "Web";

const useInitTrackEvents = () => {
  const state = new mixpanelWrapper();
  const companyId = useSelector((state: RootState) => state.user.settings.companyId);
  const { dispatch, identify, setProperty, optOutTracking, optInTracking, hasOptedInTracking } = state;
  const trakingAbilities = useTracking<HBEvent>({}, { dispatch });

  return {
    track: (event: HBEvent) => {
      const updatedData = { ...event.data };
      updatedData[PLATFORM_PROPERTY] = PLATFORM_VALUE;
      updatedData[COMPANY_ID_PROPERTY] = companyId;
      trakingAbilities.trackEvent({
        ...event,
        data: updatedData,
      });
    },
    Track: trakingAbilities.Track,
    identify,
    setProperty,
    optOutTracking,
    optInTracking,
    hasOptedInTracking,
  };
};

export default useInitTrackEvents;
