import { Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface ViewDataSwitchProps {
  handleSetViewData: (enabled: boolean) => void;
  viewDataEnabled: boolean;
}

const ViewDataSwitch: React.FC<ViewDataSwitchProps> = ({ handleSetViewData, viewDataEnabled }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <Switch onChange={handleSetViewData} defaultChecked={viewDataEnabled} checkedChildren="" unCheckedChildren="" />
      <span style={{ padding: "1rem" }}>{t("ViewData")}</span>
    </div>
  );
};

export default ViewDataSwitch;
