import { unwrapResult } from "@reduxjs/toolkit";

import { Col, Form, Modal, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../store/store";
import { PartialBaseField } from "../../../types/page";
import useNewEntityRecord from "../../../utils/hooks/useNewEntityRecord";
import EditableInput from "../../EditableInput/EditableInput";

type TProps = {
  isVisible: boolean;
  closeModal: () => void;
  setErrors?: (value: Record<string, string[]>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave?: (entity: Record<string, unknown>) => any;
  fields?: {
    primaryFields: PartialBaseField[];
    secondaryFields?: PartialBaseField[];
  };
};

export const HBAddTableRecordModal = ({ isVisible, closeModal, fields, onSave, setErrors }: TProps): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { entity, onChange } = useNewEntityRecord({ fields });

  const renderValue = (value: (entity: Record<string, unknown>) => string | number | boolean | null) => {
    return value(entity) as string | number | null;
  };

  const onFormSubmit = async () => {
    form.submit();
    if (onSave) {
      try {
        setIsLoading(true);
        const actionResponse = await dispatch(onSave(entity));
        await unwrapResult(actionResponse);
        setIsLoading(false);
        closeModal();
      } catch (customError) {
        setIsLoading(false);
        closeModal();
        setErrors && setErrors(customError as Record<string, string[]>);
      }
    }
  };

  return (
    <Modal
      open={isVisible}
      centered
      onCancel={closeModal}
      confirmLoading={isLoading}
      okText={t("Add")}
      onOk={onFormSubmit}
    >
      <Form form={form} layout="vertical" requiredMark={false} validateTrigger={["onChange"]}>
        <Col>
          {fields &&
            fields.primaryFields.length &&
            fields.primaryFields.map(f => (
              <Row key={f.id} style={{ width: "20rem" }}>
                <Form.Item
                  name={f.id}
                  style={{ width: "100%" }}
                  rules={[{ required: f.required, message: t("ErrorFieldRequired") }]}
                  label={f.title && t(f.title)}
                  className={`${f.id} form-item ${f.type}`}
                >
                  <EditableInput
                    id={f.id}
                    type={f.type}
                    fieldValue={renderValue(f.value)}
                    onChange={onChange(f.id)}
                    optionSelector={f.optionsSelector}
                    globalEdit={true}
                    placeholder={`${t("Choose")}`}
                    isEditMode
                  />
                </Form.Item>
              </Row>
            ))}
        </Col>
        <Row>
          {fields &&
            fields.secondaryFields?.length &&
            fields.secondaryFields.map(f => (
              <Col span={12} key={f.id}>
                <Form.Item
                  name={f.id}
                  style={{ width: "100%" }}
                  rules={[{ required: f.required, message: t("ErrorFieldRequired") }]}
                  label={f.title && t(f.title)}
                  className={`${f.id} form-item ${f.type}`}
                >
                  <EditableInput
                    id={f.id}
                    type={f.type}
                    fieldValue={renderValue(f.value) as string | null}
                    onChange={onChange(f.id)}
                    optionSelector={f.optionsSelector}
                    globalEdit={true}
                    placeholder={`${t("Choose")}`}
                    disabledDate={f.disabledDate && f.disabledDate(entity)}
                    isEditMode
                  />
                </Form.Item>
              </Col>
            ))}
        </Row>
      </Form>
    </Modal>
  );
};
