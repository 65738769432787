import { Typography } from "antd";
import "./StatusMark.less";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type TProps = {
  statusLabel: string;
};

const StatusMark: React.FC<TProps> = ({ statusLabel }) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  if (!statusLabel) return null;

  return <Text className={clsx("card-status-mark", statusLabel.toLowerCase())}>{t(statusLabel)}</Text>;
};

export default StatusMark;
