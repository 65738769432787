import { Tag, Select, Form, FormInstance } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchOrgUnitsOfCompany } from "../../../store/slices/companyMigration";
import { RootState, useAppDispatch } from "../../../store/store";
import { CompanyMigrationSelection } from "../../../types/companyMigration";
import { Status } from "../../../types/utility";
import MigrationTools, { MigrationTool } from "./MigrationTools";

import "../ManagmentToolsPage.less";

const { Option } = Select;

type MigrationFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  onFinish: (values: CompanyMigrationSelection) => void;
};

export default function MigrationForm({ form, onFinish }: MigrationFormProps) {
  const dispatch = useAppDispatch();
  const { companies } = useSelector((state: RootState) => state.common);
  const { destinationCompanyOrgUnits } = useSelector((state: RootState) => state.companyMigration);
  const [formValues, setFormValues] = useState<CompanyMigrationSelection | undefined>();
  const [orUnitSelectionIsLoading, setOrUnitSelectionIsLoading] = useState<boolean>(false);

  const onFormUpdated: (values: CompanyMigrationSelection) => void = values => {
    if (values) {
      setFormValues(values);
    }
  };

  const onFormFinished = () => {
    if (formValues) {
      onFinish(formValues);
    }
  };

  const onDestinationCompanySelected = (value: number) => {
    setOrUnitSelectionIsLoading(true);
    dispatch(fetchOrgUnitsOfCompany(value)).then(() => setOrUnitSelectionIsLoading(false));
  };

  return (
    <div className="migration-form-container">
      <Form
        name="migrationForm"
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onValuesChange={(_, allFields) => onFormUpdated(allFields)}
        autoComplete="off"
        form={form}
        onFinish={onFormFinished}
      >
        <Form.Item
          label="Source Company"
          name="sourceCompanyId"
          rules={[{ required: true, message: "Please Select Source Comapny!" }]}
        >
          <Select
            className="company-selector"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {companies
              ?.filter(company => formValues?.destinationCompanyId !== company.id)
              .map(company => (
                <Option title={company.name} key={company.id} value={company.id} className="company-option">
                  <Tag color={company.isActive ? "default" : "#dbdbdb"}>{company.name}</Tag>
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Destination Company"
          name="destinationCompanyId"
          rules={[{ required: true, message: "Please Select Destination Comapny!" }]}
        >
          <Select
            className="company-selector"
            onChange={onDestinationCompanySelected}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {companies
              ?.filter(company => formValues?.sourceCompanyId !== company.id)
              .map(company => (
                <Option title={company.name} key={company.id} value={company.id} className="company-option">
                  <Tag color={!company.isActive ? "#dbdbdb" : "default"}>{company.name}</Tag>
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Destination Org Unit"
          name="destinationOrgUnitId"
          rules={[{ required: true, message: "Please Select Destination Org Unit!" }]}
        >
          <Select
            className="company-selector"
            loading={orUnitSelectionIsLoading}
            disabled={formValues?.destinationCompanyId === undefined}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {destinationCompanyOrgUnits.map(orgUnit => (
              <Option title={orgUnit.name} key={orgUnit.id} value={orgUnit.id} className="company-option">
                <Tag color={orgUnit.status !== Status.Active ? "#dbdbdb" : "default"}>{orgUnit.name}</Tag>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {formValues?.sourceCompanyId && formValues?.destinationCompanyId && (
        <MigrationTools
          companyMigrationSelection={formValues}
          allowedMigrationTools={[MigrationTool.DuplicateEntities, MigrationTool.CompanyUserInDestinationCompanyCheck]}
        />
      )}
    </div>
  );
}
