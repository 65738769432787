import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";
import { nanoid } from "nanoid";
import { CustomError } from "../../pages/pageConfig/category/utilities";
import { ExplicitAdditionalProps } from "../../types/utility";
import {
  EventType,
  Webhook,
  WebhookConfigEndpoint,
  WebhookEvent,
  WebhookLog,
  WebhookLogPaginatedData,
  WebhooksPaginatedData,
  WebhookState,
} from "../../types/webhooks";
import { generateFiltersAndEmptyProps, generateGridifySorterQuery } from "../../utils/gridifyQueryHelper";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: WebhookState = {
  data: [],
  defaultCustomProperties: [],
  subData: {
    webhookLogs: [],
    webhookEvents: [],
  },
  isLoading: false,
  singleData: null,
  error: null,
  lastUpdated: dayjs().toISOString(),
  paginationInfo: {
    count: 0,
    currentPage: 0,
  },
  eventTypes: [],
};

const newWebhook: Webhook = {
  id: 0,
  name: "",
  url: "",
  active: true,
};

const updateWebhookEvent = <T>(
  state: WebhookState,
  action: PayloadAction<T>,
  updateCallback: (entity: WebhookEvent, payload: T) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatedEntityIndex = state.subData.webhookEvents.findIndex(r => r.id === (action.payload as any).entity.id);
  if (updatedEntityIndex !== -1) {
    const foundRow = state.subData.webhookEvents[updatedEntityIndex];
    updateCallback(foundRow, action.payload);
  }
};

export const fetchPaginatedWebhooks = createAsyncThunk<
  {
    primaryData: Webhook[];
    defaultCustomProperties: ExplicitAdditionalProps[];
    possibleResults: number;
    defaultPageSize?: number;
  },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@WEBHOOKS/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, webhooks, filter } = getState();

    const webhooksFilters = filter.filters.webhooks?.webhooks?.activeFilters || [];
    const idsFilter = filter.filters.actions?.actions?.idsFilter || [];

    const { filters, emptyPropIds } = generateFiltersAndEmptyProps([...webhooksFilters, ...idsFilter]);

    const orders = filter.filterValues.webhooks?.order
      ? generateGridifySorterQuery(filter.filterValues.webhooks?.order)
      : undefined;

    const response = await hbApi.post<WebhooksPaginatedData>(
      `${WebhookConfigEndpoint}/paged-list`,
      {
        Page: params?.page || webhooks.paginationInfo.currentPage + 1,
        PageSize: user.companySettings.defaultPageSize ?? params?.pageSize,
        Filter: filters,
        OrderBy: orders,
      },
      hbApiOptions(user.jwt, null, null, {
        emptyPropIds: emptyPropIds,
      })
    );

    return {
      primaryData: response.data.data,
      defaultCustomProperties: [],
      possibleResults: response.data.count,
      defaultPageSize: user.companySettings.defaultPageSize,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { webhooks } = getState();

      return (
        arg?.forceUpdate ||
        (dayjs(webhooks.lastUpdated).isBefore(dayjs()) &&
          !webhooks.isLoading &&
          webhooks.data.length !== webhooks.paginationInfo.count)
      );
    },
  }
);

export const fetchPaginatedWebhookLogs = createAsyncThunk<
  {
    webhookLogs: WebhookLog[];
  },
  string,
  { state: RootState }
>(
  "@@WEBHOOKS/FETCH_LOGS",
  async (id, { getState }) => {
    const { user } = getState();

    const response = await hbApi.post<WebhookLogPaginatedData>(
      `${WebhookConfigEndpoint}/${id}/log/paged-list`,
      {
        Page: 1,
        PageSize: 100,
      },
      hbApiOptions(user.jwt)
    );

    return {
      webhookLogs: response.data.data,
    };
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();
      return !!user.jwt;
    },
  }
);

export const fetchEventTypes = createAsyncThunk<
  { webhookEventTypes: EventType[] },
  boolean | undefined,
  { state: RootState }
>("@@WEBHOOKS/FETCH", async (_, { getState }) => {
  const { user } = getState();
  const webhooksResponse = await hbApi.get<EventType[]>(`${WebhookConfigEndpoint}/event-types`, hbApiOptions(user.jwt));

  return {
    webhookEventTypes: webhooksResponse.data,
  };
});

export const fetchSingleWebhook = createAsyncThunk<
  {
    singleData: Webhook;
    subData: { webhookLogs: WebhookLog[]; webhookEvents: WebhookEvent[] };
    defaultCustomProperties: ExplicitAdditionalProps[];
  },
  string,
  { state: RootState }
>(
  "@@WEBHOOKS/FETCH_SINGLE",
  async (id, { getState }) => {
    const { user } = getState();

    const webhook = await hbApi.get<Webhook>(`${WebhookConfigEndpoint}/${id}`, hbApiOptions(user.jwt));
    const eventsResponse = await hbApi.get<WebhookEvent[]>(
      `${WebhookConfigEndpoint}/${id}/event`,
      hbApiOptions(user.jwt)
    );
    const logsResponse = await hbApi.post<WebhookLogPaginatedData>(
      `${WebhookConfigEndpoint}/${id}/log/paged-list`,
      {
        Page: 1,
        PageSize: 100,
      },
      hbApiOptions(user.jwt)
    );

    return {
      singleData: webhook.data,
      subData: {
        webhookEvents: eventsResponse.data.sort((a, b) => Number(b.id) - Number(a.id)),
        webhookLogs: logsResponse.data.data,
      },
      defaultCustomProperties: [],
    };
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();
      return !!user.jwt;
    },
  }
);

export const addWebhook = createAsyncThunk<Webhook, { entity: Webhook }, { state: RootState }>(
  "@@WEBHOOKS/ADD",
  async ({ entity }, { getState }) => {
    const { user } = getState();

    const response = await hbApi.post<Webhook>(WebhookConfigEndpoint, entity, hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { webhooks, user } = getState();
      return !webhooks.isLoading && !!user.jwt;
    },
  }
);

export const updateWebhook = createAsyncThunk<Webhook, Webhook, { state: RootState }>(
  "@@WEBHOOKS/UPDATE",
  async (entity, { getState }) => {
    const { user } = getState();
    const response = await hbApi.put<Webhook>(`${WebhookConfigEndpoint}/${entity.id}`, entity, hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { webhooks, user } = getState();
      return !webhooks.isLoading && !!user.jwt;
    },
  }
);

export const fetchWebhookEvents = createAsyncThunk<
  {
    webhookEvents: WebhookEvent[];
  },
  string,
  { state: RootState }
>(
  "@@WEBHOOKS/FETCH_EVENTS",
  async (id, { getState }) => {
    const { user } = getState();

    const response = await hbApi.get<WebhookEvent[]>(`${WebhookConfigEndpoint}/${id}/event`, hbApiOptions(user.jwt));

    return {
      webhookEvents: response.data.sort((a, b) => Number(b.id) - Number(a.id)),
    };
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();
      return !!user.jwt;
    },
  }
);

export const addNewEvent = createAsyncThunk<
  { event: WebhookEvent; newId: number | string },
  WebhookEvent,
  { state: RootState }
>("@@WEBHOOKS/ADD_EVENT", async (req, { getState }) => {
  const { user, webhooks } = getState();

  try {
    const res = await hbApi.post<WebhookEvent>(
      `${WebhookConfigEndpoint}/${webhooks.singleData?.id}/event`,
      req,
      hbApiOptions(user.jwt)
    );

    return { event: req, newId: res.data.id };
  } catch (e) {
    throw new CustomError(e.message || e.Message);
  }
});

export const updateEvent = createAsyncThunk<WebhookEvent, WebhookEvent, { state: RootState }>(
  "@@WEBHOOKS/UPDATE_EVENT",
  async (req, { getState }) => {
    const { user, webhooks } = getState();

    try {
      await hbApi.put<WebhookEvent>(
        `${WebhookConfigEndpoint}/${webhooks.singleData?.id}/event/${req.id}`,
        req,
        hbApiOptions(user.jwt)
      );

      return req;
    } catch (e) {
      throw new CustomError(e.message || e.Message);
    }
  }
);

export const deleteEventsBulk = createAsyncThunk<number[], number[], { state: RootState }>(
  "@@WEBHOOKS/DELETE_EVENT",
  async (eventsToDelete, { getState }) => {
    const { user, webhooks } = getState();

    try {
      eventsToDelete.forEach(async id => {
        await hbApi.delete<WebhookEvent>(
          `${WebhookConfigEndpoint}/${webhooks.singleData?.id}/event/${id}`,
          hbApiOptions(user.jwt)
        );
      });
      return eventsToDelete;
    } catch (e) {
      throw new CustomError(e.message || e.Message);
    }
  }
);

const slice = createSlice({
  name: "webhook",
  initialState,
  reducers: {
    createWebhookTemplate: state => {
      state.singleData = {
        ...newWebhook,
      };
    },
    clearWebhookError: state => {
      state.error = null;
    },
    resetCurrentPage: state => {
      state.paginationInfo.currentPage = 0;
    },
    createNewEventEntryTemplate: state => {
      state.subData.webhookEvents.unshift({
        id: nanoid(),
        eventType: state.eventTypes[0].eventTypeId,
        eventSubType: state.eventTypes[0].eventSubTypes[0].eventSubTypeId,
        contextCondition: "",
        metaData: "",
        active: true,
        description: "",
        staging: true,
        action: "",
      });
    },
    deleteNewEventTemplate: {
      prepare: (payload: WebhookEvent) => ({ payload }),
      reducer: (state: WebhookState, action: PayloadAction<WebhookEvent>) => {
        state.subData.webhookEvents = state.subData.webhookEvents.filter(r => r.id !== action.payload.id);
      },
    },

    updateNewEventType: {
      prepare: (payload: { entity: WebhookEvent; newValue: number }) => ({ payload }),
      reducer: (state: WebhookState, action: PayloadAction<{ entity: WebhookEvent; newValue: number }>) => {
        updateWebhookEvent(state, action, (entity, payload) => {
          if (entity.eventType !== payload.newValue) {
            entity.eventType = payload.newValue;
            entity.eventSubType = state.eventTypes.find(
              x => x.eventTypeId === payload.newValue
            )?.eventSubTypes[0].eventSubTypeId;
          }
        });
      },
    },
    updateNewEventSubType: {
      prepare: (payload: { entity: WebhookEvent; newValue: number }) => ({ payload }),
      reducer: (state: WebhookState, action: PayloadAction<{ entity: WebhookEvent; newValue: number }>) => {
        updateWebhookEvent(state, action, (entity, payload) => {
          entity.eventSubType = payload.newValue;
        });
      },
    },
    updateNewEventActive: {
      prepare: (payload: { entity: WebhookEvent; newValue: boolean }) => ({ payload }),
      reducer: (state: WebhookState, action: PayloadAction<{ entity: WebhookEvent; newValue: boolean }>) => {
        updateWebhookEvent(state, action, (entity, payload) => {
          entity.active = payload.newValue;
        });
      },
    },
    updateEventTextField: {
      prepare: (payload: { entity: WebhookEvent; value: string | number | boolean | null; fieldId: string }) => ({
        payload,
      }),
      reducer: (
        state: WebhookState,
        action: PayloadAction<{ entity: WebhookEvent; value: string | number | boolean | null; fieldId: string }>
      ) => {
        updateWebhookEvent(state, action, (entity, payload) => {
          (entity as Record<string, unknown>)[payload.fieldId] = payload.value;
        });
      },
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPaginatedWebhooks.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewEvent.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateEvent.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleWebhook.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addWebhook.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateWebhook.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedWebhookLogs.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWebhookEvents.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEventTypes.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteEventsBulk.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedWebhooks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedWebhookLogs.rejected, state => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteEventsBulk.rejected, state => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchEventTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleWebhook.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addWebhook.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateWebhook.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addNewEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchPaginatedWebhooks.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.data = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.data = unionBy(state.data, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = action.payload.possibleResults;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      })
      .addCase(fetchWebhookEvents.fulfilled, (state, action) => {
        state.subData.webhookEvents = action.payload.webhookEvents;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSingleWebhook.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData = action.payload.subData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addNewEvent.fulfilled, (state, action) => {
        state.subData.webhookEvents = state.subData.webhookEvents.map(x => {
          if (x.id === action.payload.event.id) return { ...x, staging: false, id: action.payload.newId };
          return x;
        });
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addWebhook.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateWebhook.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(et => et.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchPaginatedWebhookLogs.fulfilled, (state, action) => {
        state.subData.webhookLogs = action.payload.webhookLogs;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchEventTypes.fulfilled, (state, action) => {
        state.eventTypes = action.payload.webhookEventTypes;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteEventsBulk.fulfilled, (state, action) => {
        state.subData.webhookEvents = state.subData.webhookEvents.filter(e => !action.payload.includes(e.id as number));
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        const updatedEntityIndex = state.subData.webhookEvents.findIndex(et => et.id === action.payload.id);
        state.subData.webhookEvents[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const {
  createWebhookTemplate,
  clearWebhookError,
  resetCurrentPage,
  createNewEventEntryTemplate,
  updateNewEventType,
  updateNewEventActive,
  updateNewEventSubType,
  deleteNewEventTemplate,
  updateEventTextField,
} = slice.actions;
export default slice.reducer;
