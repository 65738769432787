import "../MobileCardTemplate.less";

import {
  employeeAccountableRoleField,
  employeeIsAssignedToOUBranchField,
} from "../../../../../pages/pageConfig/category/employee/employeeConfigPageData";
import { MobileCardTemplateTProps } from "../../../../../types/page";
import { PrivilegeData } from "../../../../../types/utility";
import AccountableMobileCardTemplateWithSelection from "./AccountableMobileCardTemplateWithSelection";

const EmployeeAccountableMobileCardTemplate: React.FC<MobileCardTemplateTProps<PrivilegeData>> = ({ entity }) => {
  const roleSelectConfig = employeeAccountableRoleField;
  const isAssignedToOUBranchSelectConfig = employeeIsAssignedToOUBranchField;

  return (
    <AccountableMobileCardTemplateWithSelection
      entity={entity}
      roleSelectConfig={roleSelectConfig}
      isAssignedToOUBranchSelectConfig={isAssignedToOUBranchSelectConfig}
    />
  );
};

export default EmployeeAccountableMobileCardTemplate;
