import { nanoid } from "nanoid";

import { TaskFile } from "../../types/files";
import { ContextActions, TaskCloseModel, TaskReopenModel } from "../../types/tasks";
import { HistoryLog, OpenClosedStatus } from "../../types/utility";

export const updateContextActionAfterClose = <T extends { subData: { actions: ContextActions[] } }>(
  state: T,
  payload: { task: TaskCloseModel; files?: TaskFile[] | null }
) => {
  const updatedEntityIndex = state.subData.actions.findIndex(task => task.id === payload.task.TaskId);
  const updatedData = payload.task.IsHandled
    ? { status: OpenClosedStatus.Closed }
    : {
        responsibleUserName: payload.task.ResponsibleChange?.NewResponsibleDisplayName as string,
        responsibleUserId: payload.task.ResponsibleChange?.NewResponsibleId as number,
      };

  if (updatedEntityIndex !== -1) {
    state.subData.actions[updatedEntityIndex] = {
      ...state.subData.actions[updatedEntityIndex],
      ...updatedData,
    };
  }
};

export const updateContextActionAfterFillFormOnMobile = <T extends { subData: { actions: ContextActions[] } }>(
  state: T,
  taskId: number
) => {
  const updatedEntityIndex = state.subData.actions.findIndex(task => task.id === taskId);
  if (updatedEntityIndex !== -1) {
    state.subData.actions[updatedEntityIndex].status = OpenClosedStatus.Closed;
  }
};

export const updateContextActionAfterReopen = <T extends { subData: { actions: ContextActions[] } }>(
  state: T,
  payload: TaskReopenModel
) => {
  const updatedEntityIndex = state.subData.actions.findIndex(task => task.id === payload.TaskId);

  const updatedData = {
    closeDate: "",
    status: OpenClosedStatus.Opened,
  };

  if (updatedEntityIndex !== -1) {
    state.subData.actions[updatedEntityIndex] = {
      ...state.subData.actions[updatedEntityIndex],
      ...updatedData,
    };
  }
};

export const saveHistoryLog = <T extends { error: string | null; subData: { historyLog: HistoryLog[] } }>(
  state: T,
  payload: HistoryLog[]
) => {
  state.subData.historyLog = payload
    ? payload.map(r => ({
        ...r,
        id: nanoid(),
      }))
    : [];
  state.error = null;
};
