import { Space } from "antd";
import React from "react";

const RowContainer: React.FC = ({ children }) => {
  return (
    <Space className="mobile-card-row" direction="horizontal" size={10}>
      {children}
    </Space>
  );
};

export default RowContainer;
