import { Image } from "antd";
import { useSelector } from "react-redux";

import LanguagePicker from "../../pages/ProfileMenu/LanguagePicker/LanguagePicker";
import { RootState } from "../../store/store";
import { Language } from "../../types/user";
import { LocalStorageKeys } from "../../types/utility";
import { getDeviceLang } from "../../utils/functions";

type LandingPageFooterProps = {
  isDarkBackground: boolean;
};

export default function LandingPageFooter({ isDarkBackground }: LandingPageFooterProps) {
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const { landingPageInfo } = useSelector((state: RootState) => state.landingPage);
  return (
    <div
      className="landing-page-footer-container"
      style={{
        direction: direction,
        left: landingPageInfo?.image && landingPageInfo?.surveyUrl ? "45%" : "",
      }}
    >
      {!landingPageInfo?.surveyUrl && (
        <LanguagePicker
          defaultLanguage={
            (localStorage.getItem(LocalStorageKeys.LANG) as Language) ||
            landingPageInfo?.defaultLanguage ||
            getDeviceLang()
          }
          isLandingPageBlack={isDarkBackground}
          isLandingPage={true}
        />
      )}
      <div className="hb-logo-container">
        <Image preview={false} src="media/vitre-logo.png" />
        <span className={`${!isDarkBackground && "text-white"}`}>Powered by</span>
        <a href={process.env.REACT_APP_VITRE_MARKETING_URL} className={`${!isDarkBackground && "text-white"} hb-href`}>
          Vitre
        </a>
      </div>
    </div>
  );
}
