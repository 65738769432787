import { Row } from "antd";
import { useEffect } from "react";

import BackToLoginScreen from "./components/BackToLoginScreen";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import LoginForm from "./components/LoginForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
import SuModeWrapper from "./components/SuModeWrapper";
import TfaForm from "./components/TfaForm";
import { useLoginContext } from "./context/useLoginContext";

interface LoginProps {
  notCentered?: boolean;
}

const Login = ({ notCentered }: LoginProps): JSX.Element => {
  const {
    setCustomTitle,
    backToLoginMessage,
    isForgotPassword,
    resetPasswordInfo,
    tfaInfo,
    isChooseSuMode,
  } = useLoginContext();

  useEffect(() => {
    setCustomTitle("LoginHeader");
  }, []);

  return (
    <Row className="login-wrapper" justify={notCentered ? "start" : "center"} align="middle">
      {!backToLoginMessage && !isForgotPassword && !resetPasswordInfo && !tfaInfo.temporaryJwt && !isChooseSuMode && (
        <LoginForm />
      )}
      {tfaInfo.temporaryJwt && !isChooseSuMode && <TfaForm />}
      {resetPasswordInfo && <ResetPasswordForm />}
      {isForgotPassword && <ForgotPasswordForm />}
      {backToLoginMessage && <BackToLoginScreen />}
      {isChooseSuMode && <SuModeWrapper />}
    </Row>
  );
};

export default Login;
