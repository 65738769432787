import {
  ExportAction,
  ColumnManagementAction,
  GetFiltersUrl,
  ResetColumnsWidthAction,
} from "../../../actions/menuActions";
import { ReactComponent as Logo } from "../../../media/inspection-icon.svg";
import { inspectionTypeSelectors, pageSelectors } from "../../../selectors";
import {
  addInspectionType,
  clearInspectionTypeError,
  createInspectionTypeTemplate,
  fetchInspectionTypes,
  fetchSingleInspectionType,
  updateInspectionType,
} from "../../../store/slices/inspectionType";
import { InspectionTypePage, InspectionType } from "../../../types/inspectionType";
import { CategoryPage, InputTypes } from "../../../types/page";
import { UserRole } from "../../../types/user";
import { boolOptions } from "./common";
import {
  inspectionTypeTableColumns,
  inspectionRecurrencyTypeOptions,
  inspectionOwnershipTypeOptions,
} from "./inspectionTypeConfigPageData";
import { fetchActiveUsers, localizeText, prepareDynamicColumns, renderBoolStatus } from "./utilities";

const inspectionTypeConfigPage = (
  pageId: "inspectionEquipmentType" | "inspectionLocationType"
): CategoryPage<InspectionTypePage> => ({
  id: pageId || "inspectionType",
  title: "PluginTypeName",
  entityEndpoint: "/InspectionType",
  PageIcon: Logo,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: inspectionTypeSelectors.error,
  clearError: clearInspectionTypeError,
  createNewEntityTemplate: createInspectionTypeTemplate,
  createNewEntity: addInspectionType,
  updateEntity: updateInspectionType,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  fetchSingle: fetchSingleInspectionType,
  intializeDataActions: [fetchInspectionTypes],
  primarySingleEntitySelector: inspectionTypeSelectors.single,
  customPropertiesSelector: inspectionTypeSelectors.inspectionTypeCustomProperties,
  isLoading: pageSelectors.isInspectionTypePageLoading,
  lastUpdatedSelector: inspectionTypeSelectors.inspectionTypeLastUpdatedText,
  addButton: {
    label: "AddType",
    // eslint-disable-next-line no-console
    action: () => console.log("Create new Inspection Type"),
  },
  listView: {
    table: {
      tableSelector: inspectionTypeSelectors.all,
      fetchListViewData: fetchInspectionTypes,
      columns: inspectionTypeTableColumns,
      rowSelection: true,
      defaultColumnKeys: ["id", "name", "recurrency", "recurrencyType", "isReviewRequired", "ownershipType"],
      type: "table",
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<InspectionType>(props, data),
  },
  summaryCard: {
    dependentFields: () => [
      {
        id: "reviewId",
        parentId: "isReviewRequired",
        defaultValue: null,
      },
    ],
    primaryFields: [
      {
        id: "name",
        title: "FieldName",
        value: entity => entity.name,
        type: InputTypes.INPUT,
        required: true,
        placeholder: "Enter",
        optionsSelector: () => null,
      },
    ],
    secondaryFields: [
      {
        id: "recurrencyType",
        title: "FieldRecurrencyType",
        value: entity => entity.recurrencyType,
        localizedValue: entity => localizeText(entity.recurrencyType),
        type: InputTypes.DROPDOWN,
        required: true,
        options: inspectionRecurrencyTypeOptions,
        placeholder: "Choose",
        optionsSelector: () => null,
      },
      {
        id: "recurrency",
        title: "FieldRecurrency",
        value: entity => entity.recurrency,
        type: InputTypes.NUMBER,
        required: true,
        placeholder: "Enter",
        optionsSelector: () => null,
      },
      {
        id: "isReviewRequired",
        title: "FieldIsReviewRequired",
        value: entity => entity.isReviewRequired,
        localizedValue: entity => renderBoolStatus(entity.isReviewRequired),
        type: InputTypes.BOOLEAN,
        required: true,
        options: boolOptions,
        placeholder: "Choose",
        optionsSelector: () =>
          boolOptions.map(l => ({
            id: l.id,
            label: localizeText(l.label),
          })),
        dependentFields: [
          {
            id: "reviewId",
            title: "FieldReviewId",
            value: entity => entity.reviewId || null,
            type: InputTypes.NUMBER,
            required: true,
            placeholder: "Enter",
            optionsSelector: () => null,
          },
        ],
      },
      {
        id: "orgUnitId",
        title: "FieldOrgUnit",
        value: entity => entity.orgUnitId || null,
        type: InputTypes.AUTOCOMPLETE,
        placeholder: "Choose",
        optionsSelector: state =>
          state.orgUnit.basicData?.map(ou => ({
            id: ou.id,
            label: ou.name,
          })),
        enableMobileScanners: true,
      },
      {
        id: "ownershipType",
        title: "FieldOwnershipType",
        value: entity => entity.ownershipType,
        localizedValue: entity => localizeText(entity.ownershipType),
        type: InputTypes.DROPDOWN,
        required: true,
        options: inspectionOwnershipTypeOptions,
        placeholder: "Choose",
        optionsSelector: () => null,
      },
      {
        id: "userId",
        title: "FieldUser",
        value: entity => entity.userId || null,
        type: InputTypes.AUTOCOMPLETE,
        placeholder: "Choose",
        optionsSelector: () => fetchActiveUsers().map(user => ({ id: user.id, label: user.displayName })),
        enableMobileScanners: true,
      },
    ],
  },
  tabsPanel: [],
  listViewActions: [ExportAction, ColumnManagementAction, ResetColumnsWidthAction, GetFiltersUrl],
  allowedUserRoles: [UserRole.SU, UserRole.Admin, UserRole.TenantAdmin],
});

export default inspectionTypeConfigPage;
