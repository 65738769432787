import "../categorySingle.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { Spin } from "antd";
import parseInt from "lodash/parseInt";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { MobileSingleViewProvider } from "../../../../components/SingleViewCardMobileFirst/context/useMobileSingleViewContext";
import PageConfigurationContext from "../../../../context/pageContext";
import { RootState, useAppDispatch } from "../../../../store/store";
import { HBEventName } from "../../../../types/analyticsTypes/HBEvent";
import { BaseEntityType } from "../../../../types/entityBase";
import { CategoryPage } from "../../../../types/page";
import { ParamsKeys } from "../../../../types/utility";
import useInitTrackEvents from "../../../../utils/hooks/useInitTrackEvents";
import useLoadInitalData from "../../../../utils/hooks/useLoadInitalData";
import useRouter from "../../../../utils/hooks/useRouter";
import CategorySingleMobileFirstPageWrapper from "./CategorySingleMobileFirstPageWrapper";

export enum Visibility {
  LOADING = "loading",
  VISIBLE = "visible",
  NOT_FOUND = "notFound",
  NOT_ALLOWED = "notAllowed",
}

const CategorySingleMobileFirst = ({ isNewEntity }: { isNewEntity?: boolean }): JSX.Element | null => {
  useLoadInitalData();
  const dispatch = useAppDispatch();
  const { location, params } = useRouter<{ id: string }>();
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const [visibility, setVisibility] = useState<Visibility>(Visibility.LOADING);
  const data = useSelector(pageConfig.primarySingleEntitySelector);
  const customProperties = useSelector(pageConfig.customPropertiesSelector);
  const isLoading = useSelector(pageConfig.isLoading) ?? true;
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const basicData = useSelector(
    (state: RootState) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((state as any)[pageConfig.id] !== undefined ? (state as any)[pageConfig.id]["basicData"] : []) as [
        { id: number }
      ]
  );
  const { track } = useInitTrackEvents();
  const enitityId = new URLSearchParams(location.search).get(ParamsKeys.ID) ?? params.id;

  useEffect(() => {
    if (isNewEntity && customProperties.length > 0 && pageConfig.createNewEntityTemplate) {
      dispatch(pageConfig.createNewEntityTemplate());
      return;
    }
  }, [customProperties]);

  const handleDataFetch = async () => {
    if (!isNewEntity && jwt && enitityId) {
      // TODO: Create better limitation when to refetch
      dispatch(pageConfig.fetchSingle(enitityId))
        .then(result => unwrapResult(result))
        .then(() => {
          track({ eventName: HBEventName.SaveEntityChanges, data: { entityId: data?.id } });
          setVisibility(Visibility.VISIBLE);
        })
        .catch(e => {
          console.error("categorySingle error: ", e);
          if (basicData?.find(x => x.id === parseInt(enitityId))) {
            setVisibility(Visibility.NOT_ALLOWED);
          } else {
            setVisibility(Visibility.NOT_FOUND);
          }
        });
    }
  };

  useEffect(() => {
    if (isNewEntity && pageConfig.createNewEntityTemplate) {
      pageConfig.fetchCustomProps && dispatch(pageConfig.fetchCustomProps());
      dispatch(pageConfig.createNewEntityTemplate());
      setVisibility(Visibility.VISIBLE);
      return;
    }
    handleDataFetch();
  }, [enitityId, jwt]);

  return (
    <Spin spinning={visibility === Visibility.LOADING || isLoading}>
      <MobileSingleViewProvider
        enitityId={enitityId}
        singleData={data}
        isNewEntity={isNewEntity}
        handleDataFetch={handleDataFetch}
      >
        <CategorySingleMobileFirstPageWrapper visibility={visibility} />
      </MobileSingleViewProvider>
    </Spin>
  );
};

export default CategorySingleMobileFirst;
