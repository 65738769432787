import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../store/slices/user";
import { useAppDispatch } from "../../../store/store";
import { useLoginContext } from "../context/useLoginContext";

const ResetPasswordForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { setCustomTitle, errorMessage, setErrorMessage, resetPasswordInfo, setResetPasswordInfo } = useLoginContext();

  const { t } = useTranslation();

  const handleResetPassword = (values: { password: string; repeatPassword: string }) => {
    if (values.password.length < 6 || values.repeatPassword.length < 6) {
      setErrorMessage("RequiredLength");
      return;
    }
    if (values.password !== values.repeatPassword) {
      setErrorMessage("ErrorPasswordNotMatch");
      return;
    }

    if (resetPasswordInfo && values.password === values.repeatPassword) {
      dispatch(resetPassword(resetPasswordInfo))
        .then(unwrapResult)
        .then(e => {
          if (e.userError) {
            setErrorMessage(e.userError);
          } else {
            setResetPasswordInfo(null);
            setCustomTitle("LoginHeader");
            setErrorMessage("");
          }
        })
        .catch(e => {
          if (e.userError) {
            setErrorMessage(e.userError);
          }
          console.error(e);
        });
    }
  };

  return (
    <Form className="login-wrapper" name="basic" initialValues={{ remember: true }} onFinish={handleResetPassword}>
      <Form.Item className="password-input" data-testid="passwordInput" name="password">
        <Input.Password
          placeholder={t("FieldPassword")}
          value={resetPasswordInfo?.password}
          onChange={e => setResetPasswordInfo({ ...resetPasswordInfo, password: e.target.value })}
          data-testid="reset-password-input"
        />
      </Form.Item>

      <Form.Item className="password-input" data-testid="passwordInput" name="repeatPassword">
        <Input.Password
          placeholder={t("FieldRepeatPassword")}
          value={resetPasswordInfo?.repeatPassword}
          onChange={e => setResetPasswordInfo({ ...resetPasswordInfo, repeatPassword: e.target.value })}
          data-testid="reset-password-repeat-input"
        />
      </Form.Item>
      <div className="login-error-message" data-testid="reset-password-error-message">
        {errorMessage.includes(" ") ? errorMessage : t(errorMessage)}
      </div>
      <Row justify="center">
        <Button
          type="primary"
          className="button-submit"
          htmlType="submit"
          data-testid="reset-password-submit-button"
          disabled={!resetPasswordInfo?.password || !resetPasswordInfo?.repeatPassword}
        >
          {t("Reset")}
        </Button>
      </Row>
    </Form>
  );
};

export default ResetPasswordForm;
