import dayjs from "dayjs";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { ReactComponent as DatePickerIcon } from "../../../../media/datePicker-icon.svg";
import { ReactComponent as LocationIcon } from "../../../../media/location-icon.svg";
import { ReactComponent as OUIcon } from "../../../../media/ou-icon.svg";
import { locationEntitySelectors, orgUnitSelectors } from "../../../../selectors";
import { Employee } from "../../../../types/employee";
import { BaseField, InputTypes } from "../../../../types/page";
import { boolOptions, employeeStatusOptions } from "../common";
import {
  constructTableTree,
  fetchAuthTypes,
  fetchCompanyAuthType,
  fetchCompanyTwoFactorType,
  fetchLocationById,
  fetchOrgUnitById,
  fetchTwoFactorTypes,
  localizeText,
  renderBoolStatus,
} from "../utilities";
import { tenantRoleOptions } from "./employeeConfigPageData";

// Primary Fields
export const displayNameField: BaseField<Employee> = {
  id: "displayName",
  title: "FieldLanguageDisplayName",
  value: e => e.displayName,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const emailField: BaseField<Employee> = {
  id: "email",
  title: "TestEmail",
  value: e => e.email,
  type: InputTypes.EMAIL,
  required: false,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const phoneField: BaseField<Employee> = {
  id: "phone",
  title: "FieldPhone",
  value: e => e.phone,
  type: InputTypes.PHONE_INPUT,
  required: false,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const orgUnitIdField: BaseField<Employee> = {
  id: "orgUnitId",
  title: "FieldTeam",
  Icon: OUIcon,
  value: entity => entity.orgUnitId,
  valueText: entity => fetchOrgUnitById(entity.orgUnitId),
  type: InputTypes.TREESELECT,
  treeData: {
    treeDataSelector: orgUnitSelectors.all,
    treeDataSet: dataSet =>
      constructTableTree({
        data: dataSet,
        itemValueKey: "id",
        itemLabelKey: "name",
      }),
  },
  placeholder: "Root",
  optionsSelector: () => null,
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "team",
  },
};

// Secondary Fields
export const externalIdField: BaseField<Employee> = {
  id: "externalId",
  title: "ExternalID",
  value: e => e.externalId,
  type: InputTypes.INPUT,
  placeholder: "Enter",
  required: false,
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const expirationDateField: BaseField<Employee> = {
  id: "expirationDate",
  Icon: DatePickerIcon,
  title: "FieldExpiration",
  value: e =>
    e.expirationDate && dayjs(e.expirationDate).isValid()
      ? dayjs(e.expirationDate).format(DATE_FORMAT_WITHOUT_TIME)
      : null,
  type: InputTypes.DATE,
  placeholder: "Undefined",
  optionsSelector: () => null,
};

export const locationIdField: BaseField<Employee> = {
  id: "locationId",
  title: "AppointmentLocationLink",
  Icon: LocationIcon,
  value: e => e.locationId,
  valueText: entity => fetchLocationById(entity.locationId),
  type: InputTypes.TREESELECT,
  treeData: {
    treeDataSelector: locationEntitySelectors.basic,
    treeDataSet: dataSet =>
      constructTableTree({
        data: dataSet,
        itemValueKey: "id",
        itemLabelKey: "name",
      }),
  },
  placeholder: "Root",
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "location",
  },
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const positionField: BaseField<Employee> = {
  id: "position",
  title: "FieldPosition",
  value: e => e.position,
  type: InputTypes.NUMBER,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const isExternalField: BaseField<Employee> = {
  id: "isExternal",
  title: "External",
  value: e => renderBoolStatus(e.isExternal),
  type: InputTypes.BOOLEAN,
  options: boolOptions,
  optionsSelector: () =>
    boolOptions.map(l => ({
      id: l.id,
      label: localizeText(l.label),
    })),
};

export const tenantRoleField: BaseField<Employee> = {
  id: "tenantRole",
  title: "TenantUserRole",
  value: e => e.tenantRole,
  localizedValue: e => localizeText(e.tenantRole),
  type: InputTypes.DROPDOWN,
  options: tenantRoleOptions,
  optionsSelector: () => null,
};

export const isUserField: BaseField<Employee> = {
  id: "isUser",
  title: "User",
  value: e => renderBoolStatus(e.isUser),
  type: InputTypes.BOOLEAN,
  options: boolOptions,
  optionsSelector: () =>
    boolOptions.map(l => ({
      id: l.id,
      label: localizeText(l.label),
    })),
  dependentFields: [
    {
      id: "username",
      title: "FieldUserName",
      value: e => e.username,
      type: InputTypes.INPUT,
      optionsSelector: () => null,
      placeholder: "Choose",
      required: false,
    },
  ],
};

export const statusField: BaseField<Employee> = {
  id: "status",
  title: "FieldStatus",
  value: e =>
    employeeStatusOptions.find(o => o.id === e.status)
      ? employeeStatusOptions.filter(o => o.id === e.status)[0].label
      : "",
  localizedValue: e =>
    employeeStatusOptions.find(o => o.id === e.status)
      ? localizeText(employeeStatusOptions.filter(o => o.id === e.status)[0].label)
      : "",
  type: InputTypes.TEXT,
  optionsSelector: () => null,
};

export const authTypeField: BaseField<Employee> = {
  id: "authType",
  title: "FieldAuthType",
  value: e => (e.authType ? e.authType : fetchCompanyAuthType()),
  type: InputTypes.DROPDOWN,
  optionsSelector: () =>
    fetchAuthTypes().map(l => ({
      id: l.id,
      label: localizeText(l.label),
    })),
  required: false,
};

export const twoFactorTypeField: BaseField<Employee> = {
  id: "twoFactorType",
  title: "FieldTwoFactorType",
  value: e => (e.twoFactorType ? e.twoFactorType : fetchCompanyTwoFactorType()),
  type: InputTypes.DROPDOWN,
  optionsSelector: () =>
    fetchTwoFactorTypes().map(l => ({
      id: l.id,
      label: localizeText(l.label),
    })),
  required: false,
};

export const primaryFields: BaseField<Employee>[] = [displayNameField, externalIdField, phoneField, orgUnitIdField];

export const secondaryFields: BaseField<Employee>[] = [
  emailField,
  expirationDateField,
  locationIdField,
  positionField,
  isExternalField,
  tenantRoleField,
  isUserField,
  statusField,
  authTypeField,
  twoFactorTypeField,
];
