import "./CardLabelWithIcon.less";

import { Typography } from "antd";
import clsx from "clsx";

import { ReactComponent as FileIcon } from "../../../../../media/file.svg";
import { ReactComponent as LocationIcon } from "../../../../../media/location-icon.svg";
import { ReactComponent as EquipmentIcon } from "../../../../../media/shapes-icon.svg";
import { ReactComponent as UserIcon } from "../../../../../media/user-icon.svg";
import { CategoryId } from "../../../../../types/page";
import useCategoryRedirect from "../../../../../utils/hooks/useCategoryRedirect";

export enum ICON_NAMES {
  USER = "user-icon",
  EQUIPMENT = "equipment-icon",
  LOCATION = "location-icon",
  FILE = "file-icon",
}

type TProps = {
  label: string | null;
  iconName: ICON_NAMES;
  category?: CategoryId;
  id?: string | number;
  className?: string;
};

const CardLabelWithIcon: React.FC<TProps> = ({ label, iconName, category, id, className }) => {
  const handleRedirect = useCategoryRedirect(category, id);
  const { Text } = Typography;

  if (!label) return null;

  const icon = () => {
    switch (iconName) {
      case ICON_NAMES.USER:
        return <UserIcon />;
      case ICON_NAMES.EQUIPMENT:
        return <EquipmentIcon />;
      case ICON_NAMES.LOCATION:
        return <LocationIcon className="location-icon" />;
      case ICON_NAMES.FILE:
        return <FileIcon className="file-icon" />;
      default:
        return null;
    }
  };

  return (
    <Text className={clsx("card-label-with-icon", className)} onClick={handleRedirect}>
      {icon()}
      {label}
    </Text>
  );
};

export default CardLabelWithIcon;
