import { Row, Typography } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import { BaseField } from "../../../types/page";
import { useLabelAndValueCheck } from "../../EditableInput/useLabel";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";

const DetailsRowSection: React.FC<{
  input: BaseField<Record<string, unknown>>;
  showTitle?: boolean;
}> = ({ input, showTitle }) => {
  const { t } = useTranslation();
  const direction = useSelector((state: RootState) => state.user.settings.direction);

  const { singleData, renderValue, currentData } = useMobileSingleViewContext();

  const fieldValue = renderValue(input?.value) as string | null;
  const type = input.type;
  const options = input.options;
  const optionSelector = input.optionsSelector;
  const treeData = input.treeData;
  const selector = input.selector;
  const basedOnDependensiesOptions = input.basedOnDependensiesOptions;
  const localizedValue = input.localizedValue && (renderValue(input.localizedValue) as string | null);
  const fieldText = input.valueText && (renderValue(input.valueText) as string | null);
  const placeholder = input.placeholderMobileFirst && t(input.placeholderMobileFirst);

  const { computedLabel } = useLabelAndValueCheck({
    fieldValue,
    type,
    options,
    optionSelector,
    treeData,
    currentData,
    selector,
    basedOnDependensiesOptions,
    localizedValue,
  });

  if (singleData && input.isHiddenOnMobile?.(singleData)) {
    return null;
  }

  const getRenderValue = () => {
    return fieldText || (computedLabel !== "0" && computedLabel) || placeholder || null;
  };

  return (
    <Row className="static-view">
      {showTitle && input.title && (
        <Typography.Text dir={direction} className="editableInput-wrapper__addl-info" ellipsis title={input.title}>
          {`${t(input.title)}:`}
        </Typography.Text>
      )}
      {getRenderValue() && (
        <Typography.Text
          className={clsx(type === "phoneInput" && "ltr-only-phone-input")}
          ellipsis
          title={computedLabel !== null ? computedLabel?.toString() : undefined}
        >
          {getRenderValue()}
        </Typography.Text>
      )}
    </Row>
  );
};

export default DetailsRowSection;
