import { Badge } from "antd";
import dayjs from "dayjs";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { ReactComponent as SearchIcon } from "../../../../media/search-icon.svg";
import { ReactComponent as UsersIcon } from "../../../../media/users-icon.svg";
import { certificationSelectors } from "../../../../selectors";
import {
  deleteNewAccountableEntryTemplate,
  fillNewAccountableEntryTemplate,
  updateNewAccountableEntry,
} from "../../../../store/slices/certification";
import {
  addEntityPrivilege,
  updateEntityPrivilegeOULvl,
  updateEntityPrivilegeRole,
} from "../../../../store/slices/privileges";
import {
  CertificateOrgUnitEmployee,
  Certification,
  CertificationStatus,
  EmployeeCertificateListView,
} from "../../../../types/certification";
import { IS_ASSIGNED_TO_OU_FIELD } from "../../../../types/constants";
import { CellTypes, ColumnType, ColumnWithDifferentCells, InputTypes, TOption } from "../../../../types/page";
import { Training } from "../../../../types/training";
import { CertificateTraineeStatus, PrivilegeData, PrivilegedEntityType, Role } from "../../../../types/utility";
import { AccountabilityOptions, boolOptions, getCellSearchResultColumns } from "../common";
import { renderTrainingStatusOptionIcon, statusOptions as trainingStatus } from "../training/trainingConfigPageData";
import {
  getAccoutableColumnCellType,
  localizeText,
  newRenderBoolStatus,
  renderBoolStatus,
  renderTableStatus,
} from "../utilities";

const statusOptions: TOption[] = [
  {
    id: CertificationStatus.Active,
    label: "Active",
    icon: <Badge color="#02D924" />,
  },
  {
    id: CertificationStatus.Inactive,
    label: "Inactive",
    icon: <Badge color="#B7B7B7" />,
  },
];

const certificateStatusOptions: TOption[] = [
  {
    id: CertificateTraineeStatus.Active,
    label: CertificateTraineeStatus.Active,
  },
  {
    id: CertificateTraineeStatus.Expired,
    label: CertificateTraineeStatus.Expired,
  },
  {
    id: CertificateTraineeStatus.Pending,
    label: CertificateTraineeStatus.Pending,
  },

  {
    id: CertificateTraineeStatus.IsNoLongerNeeded,
    label: CertificateTraineeStatus.IsNoLongerNeeded,
  },
];

export const certificationTableColumns: ColumnType<Certification>[] = [
  {
    id: "name",
    label: "FieldName",
    renderValue: value => value.name,
    sortable: true,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "externalId",
    label: "ExternalID",
    // TODO: Change once we know from where we can get the organizations/team
    renderValue: value => value.externalId,
    optionsSelector: () => null,
    // filterType: "autocomplete",
    sortable: true,
    width: 100,
  },
  {
    id: "supplier",
    label: "Supplier",
    renderValue: value => value.supplier,
    optionsSelector: () => null,
    sortable: true,
    width: 100,
  },
  {
    id: "duration",
    label: "FieldDuration",
    optionsSelector: () => null,
    renderValue: value => `${value.duration} days`,
    sortable: true,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    renderValue: () => null,
    optionsSelector: () =>
      statusOptions.map(l => ({
        id: l.id,
        label: l.label,
      })),
    width: 100,
    valueIcon: value => {
      // TODO: Check if other colors need to be added
      return renderTableStatus(value.status);
    },

    sortable: true,
    // width: 100,
  },
];

export const certificationAccountableNameField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "name",
  label: "FieldName",
  renderValue: value => value.name || "",
  sortable: false,
  filterType: InputTypes.MULTI_SELECT,
  cellType: e => (e.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: certificationSelectors.allCertificateRelations,
  cellSearchResultColumns: getCellSearchResultColumns<CertificateOrgUnitEmployee>(["name", "externalId"]),
  cellSearchKeys: ["name"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: () => null,
  width: 100,
  primaryColumn: e => ({
    navigationTarget: e.userId ? `/employee/${e.id}` : `/orgUnit/${e.orgUnitId}`,
  }),
  cellPrefixIcon: <UsersIcon className="user-icon" />,
  placeholder: "Name",
};

export const certificationAccountableExternalIdField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "externalId",
  label: "ExternalID",
  renderValue: value => value.externalId || "",
  sortable: false,
  filterType: InputTypes.MULTI_SELECT,
  cellType: e => (e.staging ? CellTypes.SEARCH : CellTypes.TEXT),
  cellSearchSelector: certificationSelectors.allCertificateRelations,
  cellSearchResultColumns: getCellSearchResultColumns<CertificateOrgUnitEmployee>(["name", "externalId"]),
  cellSearchKeys: ["externalId"],
  cellSearchRecordClick: fillNewAccountableEntryTemplate,
  optionsSelector: () => null,
  width: 100,
  cellPrefixIcon: <SearchIcon className="search-icon" />,
  placeholder: "ExternalID",
};

export const certificationAccountableStatusField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "status",
  label: "FieldStatus",
  renderValue: value => (value.status ? localizeText(value.status.toString()) : ""),
  sortable: false,
  optionsSelector: () => null,
  width: 100,
};

export const certificationAccountableIsExternalField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "isExternal",
  label: "External",
  renderValue: value => (value.isExternal !== null ? renderBoolStatus(value.isExternal) : ""),
  sortable: false,
  optionsSelector: () => null,
  width: 100,
};

export const certificationAccountableRoleField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "role",
  label: "Accountability",
  renderValue: e => (e.role ? localizeText(e.role.toString()) : ""),
  cellType: () => CellTypes.DROPDOWN,
  cellDropdownOptions: () =>
    AccountabilityOptions.map(ao => ({
      id: ao.id,
      label: localizeText(ao.label),
    })),
  cellDropdownOnChange: (e, newValue) =>
    e.staging
      ? updateNewAccountableEntry({ entity: e, newValue: newValue, property: "role" })
      : updateEntityPrivilegeRole({
          entity: e,
          role: newValue as Role,
          id: null,
          entityType: PrivilegedEntityType.Certificate,
        }),
  sortable: false,
  optionsSelector: () => null,
  width: 100,
  cellPrefixIcon: <UsersIcon />,
};

export const certificationAccountableIsAssignedToOUBranchField: ColumnWithDifferentCells<PrivilegeData> = {
  id: IS_ASSIGNED_TO_OU_FIELD,
  label: "FieldIsAssignedToOUBranch",
  renderValue: value => {
    return value.orgUnitId && value.role === Role.Recipient ? newRenderBoolStatus(value.isAssignedToOUBranch) : null;
  },
  cellType: e => getAccoutableColumnCellType(e.role, e.orgUnitId),
  sortable: true,
  cellDropdownOptions: () =>
    boolOptions.map(b => ({
      id: b.id,
      label: localizeText(b.label),
    })),
  cellDropdownOnChange: (entity, newValue) =>
    entity.staging
      ? updateNewAccountableEntry({ entity: entity, newValue: newValue, property: IS_ASSIGNED_TO_OU_FIELD })
      : updateEntityPrivilegeOULvl({
          entity: entity,
          isAssignedToOUBranch: newValue as boolean,
          entityType: PrivilegedEntityType.Certificate,
          id: null,
        }),
  optionsSelector: () => null,
  width: 100,
};

export const certificationAccountableActionField: ColumnWithDifferentCells<PrivilegeData> = {
  id: "action" as keyof PrivilegeData,
  label: "FieldAction",
  renderValue: () => null,
  sortable: false,
  optionsSelector: () => null,
  cellType: () => CellTypes.BUTTON_ACTIONS,
  width: 100,
  stagingActions: [
    {
      label: "Add",
      className: "hb-primary-button",
      action: entity => addEntityPrivilege({ entity: entity, entityType: PrivilegedEntityType.Certificate, id: null }),
    },
    {
      label: "Cancel",
      className: "hb-tertiary-button",
      action: entity => deleteNewAccountableEntryTemplate(entity),
    },
  ],
};

export const certificationAccountableTabColumns: ColumnWithDifferentCells<PrivilegeData>[] = [
  certificationAccountableNameField,
  certificationAccountableExternalIdField,
  certificationAccountableStatusField,
  certificationAccountableIsExternalField,
  certificationAccountableRoleField,
  certificationAccountableIsAssignedToOUBranchField,
  certificationAccountableActionField,
];

export const trainingsTabColumns: ColumnWithDifferentCells<Partial<Training>>[] = [
  {
    id: "plannedDate",
    label: "PlannedDate",
    renderValue: value => (value.plannedDate ? dayjs(value.plannedDate).format(DATE_FORMAT_WITHOUT_TIME) : null),
    sortable: false,
    optionsSelector: () => null,
    width: 100,
  },
  {
    id: "participentsCount",
    label: "Trainees",
    renderValue: value => value?.participentsCount?.toString() || "",
    // optionsSelector: trainingSelectors.uniqueTrainingParticipantsCount,
    optionsSelector: () => null,
    // filterType: InputTypes.MULTI_SELECT,
    sortable: true,
    width: 100,
  },
  {
    id: "attendedParticipentsCount",
    label: "FieldAttendees",
    renderValue: value => value?.attendedParticipentsCount?.toString() || "",
    // optionsSelector: trainingSelectors.uniqueTrainingAttendees,
    optionsSelector: () => null,
    // filterType: InputTypes.MULTI_SELECT,
    sortable: true,
    width: 100,
  },
  {
    id: "status",
    label: "FieldStatus",
    valueIcon: e => renderTrainingStatusOptionIcon(e.status!),
    renderValue: value =>
      trainingStatus.find(o => o.id === value.status)
        ? localizeText(trainingStatus.filter(o => o.id === value.status)[0].label)
        : null,
    // renderValue: value => statusOptions.find(o => o.id === value.status)?.label || null,
    sortable: true,
    // filterType: InputTypes.MULTI_SELECT,
    // optionsSelector: () =>
    //   statusOptions.map(l => ({
    //     id: l.id,
    //     label: l.label,
    //   })),
    optionsSelector: () => null,
    width: 100,
  },
];

export const employeeCertificateTableColumns: ColumnType<EmployeeCertificateListView>[] = [
  {
    id: "employeeName",
    label: "EmployeeName",
    renderValue: value => value.employeeName,
    sortable: true,
    filterType: InputTypes.MULTI_SELECT,
    propName: "displayName",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    primaryColumn: e => ({
      navigationTarget: `/employee/${e.employeeId}`,
    }),
  },
  {
    id: "employeeLocation",
    label: "AppointmentLocationLink",
    renderValue: value => value.employeeLocation,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Location",
    filterType: InputTypes.MULTI_SELECT,
    sortable: true,
  },
  {
    id: "employeeExternalId",
    label: "ExternalID",
    renderValue: value => value.employeeExternalId,
    propName: "externalId",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "User",
    filterType: InputTypes.MULTI_SELECT,
    sortable: true,
  },
  {
    id: "employeeOrgUnit",
    label: "FieldOrgUnit",
    renderValue: value => value.employeeOrgUnit,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "OrgUnit",
    filterType: InputTypes.MULTI_SELECT,
    sortable: true,
  },
  {
    id: "certificateName",
    label: "FieldCertificate",
    renderValue: value => value.certificateName,
    filterType: InputTypes.MULTI_SELECT,
    propName: "name",
    valueType: "string",
    optionsSelector: () => null,
    asyncFetchFilterOptionsEntityType: "Certificate",
    sortable: true,
  },
  {
    id: "certificateExpirationDate",
    label: "FieldExpirationDate",
    renderValue: value =>
      value.certificateExpirationDate ? dayjs(value.certificateExpirationDate).format(DATE_FORMAT_WITHOUT_TIME) : null,
    valueIcon: value => {
      if (value.certificateExpirationDate) {
        const expDate = dayjs(value.certificateExpirationDate);
        const dayDiff = expDate.diff(dayjs(), "days");
        return <Badge color={dayDiff >= 30 ? "green" : "red"} />;
      }
    },
    filterType: InputTypes.DATE_RANGE,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "nextTrainingSessionDate",
    label: "FieldNextTrainingSession",
    renderValue: value =>
      value.nextTrainingSessionDate
        ? dayjs(value.nextTrainingSessionDate).format(DATE_FORMAT_WITHOUT_TIME)
        : localizeText("Undefined"),
    filterType: InputTypes.DATE_RANGE,
    sortable: true,
    optionsSelector: () => null,
  },
  {
    id: "status",
    label: "FieldStatus",
    filterType: InputTypes.MULTI_SELECT,
    optionsSelector: () =>
      certificateStatusOptions.map(l => ({
        id: l.id,
        label: localizeText(l.label),
      })),
    // width: 100,
    renderValue: e => localizeText(e.status),
    sortable: true,
  },
];
