import "./CompanyMenu.less";

import { SearchOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";

import { Input, Modal } from "antd";
import { ChangeEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { updateUserContext } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { AvailableComapny } from "../../types/user";
import usePasProFlag from "../../utils/hooks/usePasProFlag";
import useRouter from "../../utils/hooks/useRouter";
import CompanyMenuItem from "./CompanyMenuItem";

export type CompanyMenuProps = {
  onChangeCompanyCallback?: () => void;
};

export default function CompanyMenu({ onChangeCompanyCallback }: CompanyMenuProps) {
  const { history } = useRouter();
  const dispatch = useAppDispatch();
  const companyId = useSelector((state: RootState) => state.user.settings.companyId);
  const availableCompaniesSettings = useSelector((state: RootState) => state.user.settings.availableComapnies);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(companyId);
  const [inputText, setInputText] = useState<string>();
  const [availableCompanies, setAvailableCompanies] = useState<JSX.Element[]>([]);
  const isPASProEnabled = usePasProFlag();
  const selectedCompanyRef = useRef<HTMLElement>(null);

  const changeCompany = useCallback(
    (companyId: number) => {
      if (onChangeCompanyCallback) onChangeCompanyCallback();
      dispatch(updateUserContext({ companyId: companyId, clearMobileData: isPASProEnabled }))
        .then(x => unwrapResult(x))
        .then(async () => {
          Modal.destroyAll();
          history.push("/actions");
        });
    },
    [isPASProEnabled]
  );

  const isActiveCompany: (company: AvailableComapny) => boolean = company => company.id === companyId;

  const onSearchCompany: ChangeEventHandler<HTMLInputElement> = e => {
    setInputText(e.target.value);
  };

  useEffect(() => {
    let companies = [...availableCompaniesSettings].sort((a, b) => {
      if (isActiveCompany(a)) return -100;
      if (isActiveCompany(b)) return 100;
      return a.name.localeCompare(b.name);
    });

    if (inputText)
      companies = companies.filter(company => company.name.toLowerCase().includes(inputText.toLowerCase()));

    setAvailableCompanies(
      companies.map(company => (
        <CompanyMenuItem
          key={company.id}
          company={company}
          onChangeCompany={changeCompany}
          ref={isActiveCompany(company) ? selectedCompanyRef : undefined}
        />
      ))
    );

    return () => {
      setAvailableCompanies([]);
      setSelectedCompanyId(companyId);
    };
  }, [selectedCompanyId, inputText]);

  return (
    <div className="company-menu-container">
      <Input
        prefix={<SearchOutlined className="search-icon" />}
        className="search-companies-menu"
        placeholder="Search"
        allowClear
        value={inputText}
        onChange={onSearchCompany}
      />
      <div className="company-menu">{availableCompanies}</div>
    </div>
  );
}
