import { unwrapResult } from "@reduxjs/toolkit";

import { Button, Card, Row } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../store/store";
import { InspectionCheckpoint } from "../../../types/inspection";
import { UploadModalType } from "../../../types/utility";
import useNewEntityRecord from "../../../utils/hooks/useNewEntityRecord";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";
import { useMobileSingleViewTabContext } from "../context/useMobileSingleViewTabContext";
import InspectionCheckpointModal from "./templates/inspectionCheckpoints/InspectionCheckpointModal";

interface TProps {
  setIsOptionsShown: (value: boolean) => void;
}

const MobileCardAddEntitySectionOptions: React.FC<TProps> = ({ setIsOptionsShown }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setErrors } = useMobileSingleViewContext();
  const { tabConfig, stagingEntity } = useMobileSingleViewTabContext();

  const [isUploadModalVisible, setUploadModalVisibility] = useState(false);

  const addButton = tabConfig.addButton;
  const fields = addButton?.addModalInputFields;

  const { entity, setEntity, onChange, onFiledValueChange } = useNewEntityRecord({ fields });

  useEffect(() => {
    if (stagingEntity) {
      setEntity(stagingEntity);
    }
  }, [stagingEntity]);

  if (!addButton) return null;

  const renderAddButtonTemplate = () => {
    const Template = addButton?.mobileAddButtonTemplate;
    if (Template) {
      return <Template entity={entity} onChange={onChange} onFiledValueChange={onFiledValueChange} />;
    }
    return null;
  };

  const onCancel = () => {
    setIsOptionsShown(false);
  };

  const onSave = async () => {
    const getDispatchFunc = () => {
      if (addButton?.uploadFileAction && entity.file) {
        return addButton?.uploadFileAction({
          file: entity.file,
          expiration: dayjs().toISOString(),
        });
      }

      if (addButton?.addRecordModalAction) {
        return addButton?.addRecordModalAction(entity);
      }

      const actionField = addButton.actionField;
      if (actionField) {
        return actionField.stagingActions?.[0]?.action(entity);
      }

      const addCommentAction = addButton.addCommentAction;
      if (addCommentAction && entity.comment) {
        return addCommentAction({ comment: entity.comment as string });
      }
    };

    const dispatchFunc = getDispatchFunc();

    if (dispatchFunc) {
      await dispatch(dispatchFunc)
        // TODO: check in PAS-10564
        .then(unwrapResult)
        .catch((customError: Record<string, string[]>) => {
          setErrors(customError);
        });
      setIsOptionsShown(false);
    } else if (addButton.actionField?.stagingActions?.[0]?.openUploadModal) {
      setUploadModalVisibility(true);
    } else {
      setIsOptionsShown(false);
    }
  };

  const closeModal = () => {
    setIsOptionsShown(false);
    setUploadModalVisibility(false);
  };

  return (
    <>
      <Card className="mobile-add-entity-section">
        {renderAddButtonTemplate()}
        <Row className="mobile-control-buttons margin-top-15">
          <Button className="cancel" onClick={onCancel}>
            {t("Cancel")}
          </Button>
          <Button className="save" type="primary" onClick={onSave}>
            {t("Add")}
          </Button>
        </Row>
      </Card>
      <InspectionCheckpointModal
        uploadModalType={UploadModalType.MannualyUpdateFixedInspection}
        isVisible={isUploadModalVisible}
        entity={(entity as unknown) as InspectionCheckpoint}
        closeModal={closeModal}
      />
    </>
  );
};

export default MobileCardAddEntitySectionOptions;
