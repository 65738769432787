import {
  ColumnManagementAction,
  ExportAction,
  GetFiltersUrl,
  MannualyUpdateInspection,
  ResetColumnsWidthAction,
} from "../../../../actions/menuActions";
import { MobileEvent } from "../../../../components/EmbeddedModal/useFormManagmentEvents";
import AddManualInspectionSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddManualInspectionSection";
import ContextActionsMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/ContextActionsMobileCardTemplate";
import HistoryLogMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/HistoryLogMobileCardTemplate";
import InspectionCheckpointMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/inspectionCheckpoints/InspectionCheckpointMobileCardTemplate";
import { ReactComponent as Logo } from "../../../../media/inspection-icon.svg";
import { inspectionSelectors, pageSelectors } from "../../../../selectors";
import { fetchHistoryLog } from "../../../../store/slices/commonThunks";
import {
  cancelCheckpointEdit,
  clearInspectionError,
  confirmChangeLocalStatusFromEmbeded,
  createNewCheckpointEntryTemplate,
  fetchInspectionCustomProps,
  fetchPaginatedInspection,
  fetchSingleInspection,
  resetCurrentPage,
  resetSearchResults,
  searchInspection,
} from "../../../../store/slices/inspection";
import { Inspection, InspectionCheckpoint, InspectionPage } from "../../../../types/inspection";
import { CategoryPage, SectionType } from "../../../../types/page";
import { HistoryLogEntityType, TableAddButtonType } from "../../../../types/utility";
import { contextActionsTableColumns, historyLogTableColumns } from "../common";
import { prepareDynamicColumns, removeColumnFilters } from "../utilities";
import {
  equipmentNameField,
  inspectionTypeNameField,
  lastCheckField,
  locationField,
  nextCheckField,
  primaryFields,
  secondaryFields,
  statusFieldInspection,
} from "./fields";
import {
  checkpointsColumns,
  equipmentInspectionTableColumns,
  inspectionCheckpointActionField,
  inspectionCheckpointStatusField,
  inspectionTableColumns,
} from "./inspectionConfigPageData";

const LOCALIZED_KEY_PREFIX = "MobileFirstTitle";

const inspectionConfigPage = (pageId: "inspectionEquipment" | "inspectionLocation"): CategoryPage<InspectionPage> => ({
  id: pageId || "inspection",
  title: "Inspection",
  //Capitalize first letter
  mobileFirstTitle: `${LOCALIZED_KEY_PREFIX}${pageId.charAt(0).toUpperCase()}${pageId.slice(1)}`,
  entityEndpoint: "/EquipmentInspection",
  PageIcon: Logo,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: inspectionSelectors.error,
  clearError: clearInspectionError,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.inspectionTypeName };
  },
  fetchSingle: fetchSingleInspection,
  intializeDataActions: [fetchInspectionCustomProps],
  fetchCustomProps: fetchInspectionCustomProps,
  listView: {
    table: {
      tableSelector: inspectionSelectors.all,
      fetchPaginatedData: fetchPaginatedInspection,
      searchResultsSelector: inspectionSelectors.inspectionSearchResults,
      possibleResultsSelector: inspectionSelectors.inspectionPossibleResults,
      columns: pageId === "inspectionEquipment" ? equipmentInspectionTableColumns : inspectionTableColumns,
      rowSelection: true,
      defaultColumnKeys: [
        "inspectionTypeId",
        "eqSerialNumber",
        "equipmentId",
        "equipmentTypeId",
        "location",
        "expirationDate",
        "inspectionDate",
        "status",
      ],
      type: "paginatedTable",
      searchPaginatedData: searchInspection,
      resetSearchResult: resetSearchResults,
      resetCurrentPage: resetCurrentPage,
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<Inspection>(props, data),
  },
  primarySingleEntitySelector: inspectionSelectors.single,
  customPropertiesSelector: inspectionSelectors.inspectionCustomProperties,
  isLoading: pageSelectors.isInspectionPageLoading,
  lastUpdatedSelector: inspectionSelectors.inspectionLastUpdatedText,
  summaryCard: {
    CardIcon: Logo,
    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
    mobileSingleView: {
      summary: {
        icon: Logo,
        title: inspectionTypeNameField,
        status: statusFieldInspection,
        mainContextRelation: equipmentNameField,
        additionalContextRelation: locationField,
        date: nextCheckField,
      },
      sections: [
        {
          type: SectionType.ContextSection,
          fields: [],
        },
        {
          type: SectionType.DatesSection,
          fields: [lastCheckField, nextCheckField],
        },
      ],
    },
  },
  tabsPanel: [
    {
      key: "actions",
      label: "FieldActions",
      tabSelector: inspectionSelectors.actions,
      columns: removeColumnFilters(contextActionsTableColumns()),
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: ContextActionsMobileCardTemplate,
    },
    {
      key: "checkpoints",
      label: "Checkpoints",
      tabSelector: inspectionSelectors.inspectionCheckpoints,
      type: "table",
      addButton: {
        action: createNewCheckpointEntryTemplate,
        label: "AddCheckpoint",
        type: TableAddButtonType.AddInline,
        mobileAddButtonTemplate: AddManualInspectionSection,
        actionField: inspectionCheckpointActionField,
        addEntityFields: [inspectionCheckpointStatusField],
      },
      rowSelection: false,
      columns: removeColumnFilters(checkpointsColumns) as [],
      generateDynamicColumns: (props, data) =>
        removeColumnFilters(prepareDynamicColumns<InspectionCheckpoint>(props, data)),
      customPropertiesSelector: inspectionSelectors.inspectionCustomProperties,
      mobileCardTemplate: InspectionCheckpointMobileCardTemplate,
    },
    {
      key: "historyLog",
      label: "HistoryLog",
      tabDataThunk: id => fetchHistoryLog({ entityId: +id, entityType: HistoryLogEntityType.Inspection }),
      tabSelector: inspectionSelectors.inspectionHistoryLog,
      columns: removeColumnFilters(historyLogTableColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: HistoryLogMobileCardTemplate,
    },
  ],
  listViewActions: [
    ExportAction,
    ColumnManagementAction,
    ResetColumnsWidthAction,
    MannualyUpdateInspection,
    GetFiltersUrl,
  ],
  mobileEvents: [
    {
      type: MobileEvent.FormAnsweringFinished,
      listener: confirmChangeLocalStatusFromEmbeded,
      dispatch: true,
    },
    {
      type: MobileEvent.ExitFromMobile,
      listener: cancelCheckpointEdit,
      dispatch: true,
    },
  ],
});

export default inspectionConfigPage;
