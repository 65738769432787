import { Language } from "../types/user";

export type TQuestionType =
  | "ComboBoxTemplate"
  | "RadioButtonTemplate"
  | "TextOnlyTemplate"
  | "CheckBoxTemplate"
  | "AutoCompleteTemplate"
  | "RadioButtonWithCheckBoxListTemplate"
  | "RadioButtonWithOptionsTemplate"
  | "SignatureTemplate"
  | "ComboBoxWithCheckBoxListTemplate"
  | "ComboBoxWithRadioButtonsTemplate"
  | "DigitsOnlyTemplate"
  | "DateOnlyTemplate"
  | "MandatoryCheckboxTemplate"
  | "ImageOnlyTemplate"
  | "MultilineTextTemplate"
  | "InfoTextTemplate"
  | "EmailPdfTemplate"
  | "ScanTextTemplate"
  | "DocumentTemplate"
  | "TimeOnlyTemplate"
  | "MediaContentTemplate"
  | "FileContentTemplate"
  | "ImageContentTemplate"
  | "MultiSelectTemplate"
  | "AutoCompleteMultiValuesTemplate"
  | "PluginEmployeeTemplate"
  | "PluginEquipmentTemplate"
  | "EquipmentTemplate"
  | "LocationTemplate"
  | "EmployeeTemplate"
  | "DependFieldTemplate"
  | "GroupHeader"
  | "GroupFooter"
  | "HolderGroupHeader"
  | "HolderGroupFooter"
  | "EquipmentGroupHeader"
  | "EquipmentGroupFooter"
  | "ScanGroupHeader"
  | "ScanGroupFooter";

export type TApiQuestionType = {
  id: number;
  reviewResultQuestionGroupId: string | null;
  categoryId: number;
  title: string;
  title_i18n?: I18nObject;
  isPrintable: boolean;
  // Replaces the 'title' value
  printText?: string | null;
  // TODO: type should be updated.
  hideAnswer?: string | null;
  dataKey?: string | null;
  questionType: TQuestionType;
  answersType:
    | "Dictionary"
    | "ChildDictionary"
    | "ProjectUsers"
    | "ProjectOfficials"
    | "Date"
    | "Image"
    | "Text"
    | "CompanyUser";
  status: "Answered" | "ScannedNotAnswered" | "NotAnswered";
  scoreWeight: number | null;
  position: number;
  selectedAnswers: {
    text: string | null;
    text_i18n?: I18nObject;
    comment?: string;
    answerId: number | null;
    parentAnswerId: number | null;
    image?: string;
    date?: string;
    extraId?: number;
    score: number | null;
    severity: "Critical" | "Defect" | "None" | "Positive";
  }[];
};

export type TAppointmentInfo = {
  title?: string;
  title_i18n?: I18nObject;
  completedDate?: string;
  submittedDate?: string | null;
  latX?: number;
  longY?: number;
  reviewName?: string;
  reviewName_i18n?: I18nObject;
  projectName?: string | null;
  personName?: string;
};

export type I18nObject = {
  value: string;
  i18n: I18nTranslation[];
};

export type I18nTranslation = {
  locale: Language;
  translation: string;
};

export const I18N_SUFFIX = "_i18n";

export enum I18N_FIELDS {
  TITLE = "title",
  TEXT = "text",
  REVIEW_NAME = "reviewName",
  NAME = "name",
  ADRESS_TEXT = "addressText",
}

export type TApiResponse = {
  isRightToLeft?: boolean | undefined;
  appointmentId: number;
  actionId?: number;
  latX?: number;
  longY?: number;
  reviewName: string;
  categories: {
    id: number;
    isPrintable: boolean;
    // Replaces the 'name' value
    printText?: string | null;
    name: string;
    name_i18n?: I18nObject;
    type: "Default" | "Details" | "Official" | "Audit" | "Hidden" | "ScanCode";
    position: number;
    image: string | null;
  }[];
  reviewResultQuestionGroups?: {
    id: string;
    parentReviewResultQuestionGroupId: string | null;
    categoryId: number;
    questionGroupId: number;
    title: string;
    isPrintable: boolean;
    printText?: string | null;
    hideHeaderInPdf: boolean;
  }[];
  questions: TApiQuestionType[];
  appointmentInfo?: TAppointmentInfo;
};

export type TBranding = {
  company: {
    companyName: string;
    logoUrl: string | null;
    mainColor: string | null;
    pdfTitle: string | null;
    timeZone?: string;
    dateFormat: string | null;
    dateTimeFormat: string | null;
    timeFormat: string | null;
  };
  project: {
    projectName: string | null;
    logoUrl: string | null;
    pdfTitle: string | null;
  };
  pdf: {
    imageSize: 320 | 640 | 1024;
    addressText: string | null;
    showScoreType: ScoreType;
    showCategoriesIcons: boolean;
    showCompanyLogoInFooter: boolean;
    showUnansweredQuestions: boolean;
  };
};

/***
 * This type describes different translation labels that the PDF can use
 */
export type TLabels = {
  appointmentId?: string;
  actionId?: string;
  project: string;
  date: string;
  submittedDate: string;
  time: string;
  personName: string;
  location: string;
  score: string;
  printedAt: string;
  page: string;
  outOf: string;
  sum: string;
  executiveSummary: string;
};

export type TAnswerSeverityLevel = "Critical" | "Defect" | "None" | "Positive";

export type Answer = {
  text: string | null;
  docJson: string | null;
  image?: string;
  date?: string;
  comment?: string;
  answerId: number | null;
  severity?: TAnswerSeverityLevel;
  extraId?: number;
  score?: number | null;
  parentAnswerId?: number | null;
};

export type AnswerWithSubAnswers = Answer & { subAnswers?: Answer[] };

export type Question = {
  id: number;
  type: TQuestionType;
  title: string;
  image?: string;
  answers: AnswerWithSubAnswers[];
  reviewResultQuestionGroupId: string | null;
  position: number | null;
  hideAnswer?: string | null;
};

export type Category = {
  name: string;
  categoryId?: number;
  questions: Question[];
  questionGroups?: QuestionGroup[];
  image?: string | null;
  position: number;
};

export type TInitialFormattedState = {
  mainColor: string;
  reviewName: string;
  companyLogo: string;
  projectLogo: string;
  submittedDate?: string | null;
  createdAtDate?: string;
  createdAtTime?: string;
  appointmentId?: number;
  actionId?: number;
  personName: string[];
  location?: { latx?: number; longY?: number };
  projectName: string | null;
  score: string;
  categories: Category[];
};

export type TPDFHeaderProps = {
  isRightToLeft: boolean;
  headerProps: {
    projectName: string | null;
    appointmentId?: number;
    actionId?: number;
    mainColor: string;
    submittedDate?: string | null;
    createdAtDate?: string;
    createdAtTime?: string;
    personName: string[];
    location?: { latx?: number; longY?: number };
    score: string;
    scoreType: ScoreType;
  };

  translations: TLabels;
};

export type TPDFSummaryTableProps = {
  isRightToLeft: boolean;
};

export type TPDFBody = {
  isRightToLeft: boolean;
  categories: Category[];
  mainColor: string;
  imageSize: 320 | 640 | 1024;
  summaryControls: ISummaryControl[];
  translations: TLabels;
  executiveSummary: TExecutiveSummaryItem[];
};

export type QuestionGroup = {
  id: string;
  parentReviewResultQuestionGroupId: string | null;
  categoryId: number;
  questionGroupId: number;
  title: string;
  isPrintable: boolean;
  printText?: string | null;
  hideHeaderInPdf: boolean;
};

export type TQuestionLine = {
  question: Question;
  index: number;
  shouldBeIndented?: boolean;
  imageSize: 320 | 640 | 1024;
  isRightToLeft: boolean;
};

export type TEmptyQuestionLine = {
  index: number;
  shouldBeIndented?: boolean;
};

export enum ScoreType {
  None = "None",
  Text = "Text",
  Percent = "Percent",
  Numeric = "Numeric",
}

export interface ISummaryControl {
  title: string;
  sumType: SumType;
  categoryId?: number | null;
  tableData: string[][];
}

export enum SumType {
  None = "None",
  Rows = "Rows",
  Columns = "Columns",
  Both = "Both",
}

export interface ISummaryTableProps {
  isRightToLeft: boolean;
  summaryControlInfo: ISummaryControl;
  mainColor: string;
  translations: TLabels;
}

export type TExecutiveSummaryItem = {
  question: string;
  answer: string;
};

export type TReviewData = {
  reviewData: TInitialFormattedState;
  brandingData: TBranding;
  translations: TLabels;
  score: string;
  isRightToLeft: boolean;
  summaryControls: ISummaryControl[];
  executiveSummary: TExecutiveSummaryItem[];
};
