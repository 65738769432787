import "./actionsummarytooltip.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import PDFActions from "../../pages/TableActions/PDFActions";
import { fetchActionSummary } from "../../store/slices/tasks";
import { useAppDispatch } from "../../store/store";
import { ActionSummary } from "../../types/tasks";
import useRouter from "../../utils/hooks/useRouter";

const ActionSummaryTooltipComponent = ({ actionId }: { actionId: number | null }) => {
  const dispatch = useAppDispatch();
  const { history } = useRouter();
  const [actionSummary, setActionSummary] = useState<ActionSummary | null>(null);
  const { t } = useTranslation();

  const onOpenChange = () => {
    if (actionId && !actionSummary)
      dispatch(fetchActionSummary(actionId))
        .then(response => unwrapResult(response))
        .then(response => setActionSummary({ ...response }));
  };

  return actionId ? (
    <div>
      <Tooltip
        zIndex={999}
        overlayStyle={{ whiteSpace: "pre-line" }}
        onOpenChange={onOpenChange}
        title={
          <div className="action-summary-tooltip-wrapper">
            <span>
              {`${t("FieldTitle")}: ${actionSummary?.title}\n ${
                actionSummary?.reviewName ? `${t("FieldReview")}: ${actionSummary.reviewName}\n` : ""
              }  ${t("FieldStatus")}: ${t(actionSummary?.status || "")}`}
            </span>
            <span>
              {actionSummary?.pdfInfo && (
                <PDFActions
                  textOnly={true}
                  pdfLink={actionSummary.pdfInfo?.livePdfGuid}
                  title={actionSummary.title}
                  fileLink={actionSummary.pdfInfo?.fileUrl}
                />
              )}
            </span>
          </div>
        }
      >
        <div
          onClick={e => {
            e.stopPropagation();
            history.push(`actions/${actionId}`);
          }}
        >
          {actionId}
        </div>
      </Tooltip>
    </div>
  ) : null;
};

export default ActionSummaryTooltipComponent;
