import { Space, Checkbox, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useState } from "react";
import { useSelector } from "react-redux";
import { updateStagedChart } from "../../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { UserFilter } from "../../../../../types/dashboard";
import DrillDownConfigurationPanel from "./DrillDownConfigurationPanel";
import SlicersConfigurationPanel from "./SlicersConfigurationPanel";

const { Option } = Select;

const CHECKBOXES_IDS = [1, 3, 4, 5];

export default function SlicersConfiguration() {
  const stagedChart = useSelector((state: RootState) => state.dashboard.stagedChart);
  const dispatch = useAppDispatch();
  const [dictionaryFilter, setDictionaryFilter] = useState(
    stagedChart?.userFilters?.find(f => f.id === 7)?.isSelected || false
  );
  const { companyDictionaries } = useSelector((state: RootState) => state.customProperty);

  const SLICERS = [
    {
      id: 1,
      type: 1,
      title: "Date Filter",
      entity: "date",
      field:
        stagedChart?.query.timeDimensions && stagedChart?.query.timeDimensions?.length > 0
          ? stagedChart.query.timeDimensions[0].dimension
          : "",
      readonly: true,
      dependsOn: 2,
      isSelected: false,
      disabled: stagedChart?.query?.timeDimensions?.length === 0,
    },
    {
      id: 3,
      type: 2,
      title: "Project filter",
      entity: "project",
      field: "Project.name",
      readonly: true,
      dependsOn: 2,
      isSelected: false,
      disabled: false,
    },
    {
      id: 4,
      type: 2,
      title: "User filter",
      entity: "user",
      field: "User.username",
      readonly: true,
      isSelected: false,
      disabled: false,
    },
    {
      id: 5,
      type: 3,
      title: "Review comparison",
      entity: "review",
      field: "Review.name",
      readonly: true,
      dependsOn: 3,
      isSelected: false,
      disabled: false,
    },
    {
      id: 7,
      type: 2,
      title: "Dictionary filter",
      entity: "dictionaryValue",
      setupEntity: "dictionary",
      field: "DictionaryValue.value",
      readonly: true,
      isSelected: false,
      selectedEntity: -1,
      disabled: false,
    },
  ];

  const onDictionaryFilterChange: (e: CheckboxChangeEvent) => void = e => {
    setDictionaryFilter(e.target.checked);
    if (!e.target.checked) {
      const initialFilters: UserFilter[] = stagedChart?.userFilters || [];
      const updatedFilters = initialFilters.reduce<UserFilter[]>((acc, filter) => {
        if (filter.id !== 7) {
          acc.push(filter);
        }
        return acc;
      }, []);
      if (stagedChart) {
        dispatch(
          updateStagedChart({
            ...stagedChart,
            userFilters: updatedFilters,
            filters: stagedChart.filters ? stagedChart.filters : {},
          })
        );
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterCheckboxesInitialValues = SLICERS.reduce<any[]>((acc, filter) => {
    if (CHECKBOXES_IDS.includes(filter.id)) {
      const userFilter = stagedChart?.userFilters?.find(f => f.id === filter.id);
      if (userFilter) {
        acc.push({
          label: filter.title,
          value: filter.id,
          isSelected: userFilter.isSelected,
        });
      } else {
        acc.push({
          label: filter.title,
          value: filter.id,
          isSelected: filter.isSelected,
        });
      }
    }
    return acc;
  }, []);

  const onPresetFiltersChange: (checkedValue: CheckboxValueType[]) => void = checkedValue => {
    const updatedFilters = SLICERS.reduce<UserFilter[]>((acc, filter) => {
      if (CHECKBOXES_IDS.includes(filter.id)) {
        const selected = checkedValue.find(c => filter.id === c);
        if (selected) {
          acc.push({ ...filter, isSelected: selected ? true : false });
        }
      } else {
        const existingFilter = stagedChart?.userFilters?.find(f => filter.id === f.id);
        if (existingFilter) acc.push({ ...existingFilter });
      }
      return acc;
    }, []);
    if (stagedChart) {
      dispatch(
        updateStagedChart({
          ...stagedChart,
          userFilters: updatedFilters,
          filters: stagedChart.filters ? stagedChart.filters : {},
        })
      );
    }
  };

  const onDictionaryFilterSelected = (selected: number) => {
    const initialFilters: UserFilter[] = [...(stagedChart?.userFilters || SLICERS.filter(s => s.id === 7))];
    const initialDictionaryFilter = SLICERS.find(s => s.id === 7);
    if (initialDictionaryFilter !== undefined) {
      if (initialDictionaryFilter) initialFilters.push(initialDictionaryFilter);
    }
    const updatedFilters = initialFilters.reduce<UserFilter[]>((acc, filter) => {
      if (filter.id === 7) {
        acc.push({ ...filter, isSelected: true, selectedEntity: selected });
      } else {
        acc.push({ ...filter });
      }
      return acc;
    }, []);
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, userFilters: updatedFilters }));
    }
  };

  return (
    <Space id="slicersConfiguration" direction="vertical" size={10}>
      <Checkbox.Group
        onChange={onPresetFiltersChange}
        options={filterCheckboxesInitialValues}
        defaultValue={filterCheckboxesInitialValues?.filter(f => f.isSelected).map(f => f.value)}
      />

      <Space id="dictionaryFilter">
        <Checkbox onChange={onDictionaryFilterChange} defaultChecked={dictionaryFilter} />
        <div>DictionaryFilter</div>
        {dictionaryFilter && (
          <Select
            showSearch
            placeholder="Select a Dictionary"
            onSelect={onDictionaryFilterSelected}
            className="dictionarySelector"
            defaultValue={stagedChart?.userFilters?.find(f => f.id === 7)?.selectedEntity || undefined}
            filterOption={(input, option) => {
              return (
                ((option!.label as unknown) as string).toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
                (option!.value
                  ? option!.value.toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())
                  : false)
              );
            }}
          >
            {companyDictionaries.map(dictionary => (
              <Option
                style={{ width: "100%" }}
                disabled={!dictionary.isActive}
                key={dictionary.id}
                value={dictionary.id}
                label={dictionary.name}
              >
                <div className="dictionaryOptionContainer">
                  <div>{dictionary.name}</div>
                  <div>{dictionary.id}</div>
                </div>
              </Option>
            ))}
          </Select>
        )}
      </Space>
      <SlicersConfigurationPanel />
      <DrillDownConfigurationPanel />
    </Space>
  );
}
