import "./virtualizedTable.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { Button, Col, Empty, Form, Modal, Row, Table, TableColumnType, Typography } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import ResizeObserver from "rc-resize-observer";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { VList } from "virtual-table-ant-design";

import PageConfigurationContext from "../../context/pageContext";
import { ReactComponent as FilterIcon } from "../../media/filter-icon.svg";
import { userSelectors } from "../../selectors";
import { RootState, useAppDispatch } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { InspectionStatus } from "../../types/inspection";
import { InspectionRecurrencyType } from "../../types/inspectionType";
import { CategoryPage, ColumnAction, ColumnType, ColumnWithDifferentCells, Tab } from "../../types/page";
import { TableAddButtonType, UploadModalType } from "../../types/utility";
import { isAddButonVisible } from "../../utils/helpers";
import useBulkActions from "../../utils/hooks/useBulkActions";
import useRouter from "../../utils/hooks/useRouter";
import { useUIErrorHandling } from "../../utils/hooks/useUIErrorHandling";
import { ArrayElement } from "../../utils/types";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import { HBAddTableRecordModal } from "../HBComponents/AddTableRecordModal/HBAddTableRecordModal";
import { HbAddCommentModal } from "../HBComponents/CommentModal/HbAddCommentModal";
import HBUploadModal, { hasSpecialChars } from "../HBComponents/UploadModal/HBUploadModal";
import { ConfirmationType, ModalInfo } from "../SingleViewCard/types";
import DotsPopover from "../SingleViewCommon/DotsMenu/DotsPopover";
import { CellRenderer } from "../TableCellRenderer/TableCellRenderer";
import FilterRenderer from "./filters/FilterRenderer";

type TProps = {
  tabData: Tab<Record<string, unknown>, Record<string, unknown>>;
  selected?: boolean;
};

interface ErrorModalData {
  title: string;
  message: string;
}

export type AddCustomComponentProps = {
  modalVisible: boolean;
  setModalVisible: (value: boolean) => void;
  setErrors: (errors: Record<string, string[]>, errorType?: ConfirmationType) => void;
};

// NOTE: This component works with the single view tabs
const SingleViewVTable = ({ tabData: currentTab, selected }: TProps): JSX.Element => {
  const { primarySingleEntitySelector } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id: itemId } = useParams<{ id: string }>();
  const tabData = useSelector(currentTab.tabSelector);
  const { customPropertiesSelector, generateDynamicColumns } = currentTab;

  const { errorMessages, setErrors, handleClearErrors } = useUIErrorHandling();
  const [modalInfo, setModalInfo] = useState<ModalInfo | null>(null);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [error, setError] = useState<ErrorModalData>();

  const {
    isExporting,
    setIsExporting,
    selectedEntities,
    setColumns,
    rowSelection,
    handleDotsMenuClick,
  } = useBulkActions({
    setModalInfo,
    setIsTableDataLoading,
    setErrors,
    isExportEnabled: true,
    setError,
    currentTab,
  });

  const [hasTabDataFetched, setHasTabDataFetched] = useState(false);
  // const settings = useSelector((state: RootState) => state.user.settings);
  const { history, location } = useRouter();
  // const { filterData, filters, onFilterChange } = useFilters(id, currentTab.key);
  // const [searchKeys, setSearchKeys] = useState<string[]>([]);
  // const [searchResultColumns, setSearchResultColumns] = useState<Partial<ColumnType<Record<string, unknown>>>[]>([]);
  const [height, setHeight] = useState<number | undefined>();
  // const [entitiesToDisplay, setEntitiesToDisplay] = useState<Record<string, unknown>[]>([]);
  const [form] = Form.useForm();
  const [isUploadModalVisible, setUploadModalVisibility] = useState(false);
  const [isAddRecordModalVisible, setAddRecordModalVisibility] = useState(false);
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [uploadModalType, setUploadModalType] = useState(UploadModalType.FileUpload);
  const [isAddCommentModalVisibility, setAddCommentModalVisibility] = useState(false);
  const [addCommentModalType, setAddCommentModalType] = useState(UploadModalType.AddComment);
  const defaultCustomProperties = useSelector(customPropertiesSelector);
  const [currentEntityId, setCurrentEntityId] = useState<number | undefined>(undefined);
  const [currentEntity, setCurrentEntity] = useState<Record<string, unknown> | undefined>(undefined);
  const user = useSelector(userSelectors.getCurrentUser);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const listExportLimit = useSelector((state: RootState) => state.user.companySettings?.listExportLimit);
  const singleData = useSelector(primarySingleEntitySelector);
  const [addCheckPointButtonVisible, setAddCheckPointButtonVisible] = useState<boolean>(true);

  useEffect(() => {
    setAddCheckPointButtonVisible(isAddButonVisible({ singleData }));
  }, [singleData]);

  // useEffect(() => {
  //   if (tabData) {
  //     if (currentTab.defaultFilters && currentTab.defaultFilters.length) {
  //       currentTab.defaultFilters.forEach(item => {
  //         if (!filters || !filters.some(x => x.key === item.key))
  //           onFilterChange({
  //             key: item.key,
  //             value: item.value,
  //             textValue: item.textValue || undefined,
  //             filterType: item.filterType,
  //             label: item.label,
  //             fieldType: item.fieldType,
  //           });
  //       });
  //     }
  //     setEntitiesToDisplay(pipe(filterData)(tabData));
  //   }
  // }, [tabData, filters, currentTab]);

  // START-TODO: This is a temporary solution to PAS-1736. In the future the tab data has to live inside the singleData slice property and the implementation will be much cleaner.

  useEffect(() => {
    setHasTabDataFetched(false);
  }, [location]);

  useEffect(() => {
    if (currentTab.tabDataThunk && itemId && !hasTabDataFetched) {
      setHasTabDataFetched(true);
      setIsTableDataLoading(true);
      dispatch(currentTab.tabDataThunk(itemId))
        .then(unwrapResult)
        .then(() => {
          setIsTableDataLoading(false);
        })
        .catch(customError => {
          setErrors(customError);
          setIsTableDataLoading(false);
        });
    }
  }, [selected, hasTabDataFetched]);

  // END-TODO: This is a temporary solution to PAS-1736. In the future the tab data has to live inside the singleData slice property and the implementation will be much cleaner.

  const handleSetHeight = ({ height }: { height: number | undefined }) => {
    setHeight(height);
  };

  const handleAddButtonClick = () => {
    if (currentTab.addButton?.privilege && !currentTab.addButton.privilege(user)) {
      // No access, no clicks
      return;
    }
    if (currentTab.addButton && currentTab.addButton.type === TableAddButtonType.AddModal) {
      return setAddRecordModalVisibility(true);
    }
    if (currentTab.addButton && currentTab.addButton.type === TableAddButtonType.CustomModal) {
      return setCustomModalVisible(true);
    }
    if (currentTab.addButton && currentTab.addButton.type === TableAddButtonType.AddComment) {
      setAddCommentModalType(UploadModalType.AddComment);
      return setAddCommentModalVisibility(!isAddRecordModalVisible);
    }
    if (currentTab.addButton && currentTab.addButton.type === TableAddButtonType.UploadFile) {
      setUploadModalType(UploadModalType.FileUpload);
      return setUploadModalVisibility(!isUploadModalVisible);
    }
    if (currentTab.addButton && currentTab.addButton.type === TableAddButtonType.UploadTaskFile) {
      setUploadModalType(UploadModalType.TaskFileUpload);
      return setUploadModalVisibility(!isUploadModalVisible);
    }
    if (currentTab.addButton && currentTab.addButton.type === TableAddButtonType.AddSubTask) {
      const newActionNavigationPath = `/actions/new?parentId=${itemId}`;
      singleData?.closeDate
        ? Modal.confirm({
            content: t("ParentActionIsCompletedMessage"),
            onOk: () => history.push(newActionNavigationPath),
          })
        : history.push(newActionNavigationPath);
    }
    if (
      currentTab.addButton &&
      currentTab.addButton.type === TableAddButtonType.AddInline &&
      currentTab.addButton.action
    ) {
      return dispatch(currentTab.addButton.action());
    }
  };

  const handlePrimaryColumnNavigation = (c: ColumnType<Record<string, unknown>>, entity: Record<string, unknown>) => {
    if (c.primaryColumn && !entity.staging) {
      const navigationTarget = c.primaryColumn(entity)?.navigationTarget;
      navigationTarget && history.push(navigationTarget);
    }
  };

  // Note: This corresponds to the actions coming from a column button
  const handleColumnActionClick = async (
    columnAction: ColumnAction<Record<string, unknown>>,
    entity: Record<string, unknown>,
    skipConfirmation?: boolean
  ) => {
    const { changesConfirmation, openUploadModal } = columnAction;
    if (changesConfirmation && !skipConfirmation) {
      return setModalInfo({
        body: changesConfirmation.addValueToBody ? changesConfirmation.body : changesConfirmation.body,
        okText: changesConfirmation.addValueToOkText ? changesConfirmation.okText : changesConfirmation.okText,
        cancelText: changesConfirmation.cancelText,
        onConfirm: onColumnActionConfirmationClick(true, columnAction, entity),
        onCancel: onColumnActionConfirmationClick(false, columnAction, entity),
      });
    } else if (openUploadModal && !columnAction.action(entity)) {
      setUploadModalType(
        entity.recurrencyType === InspectionRecurrencyType.Fixed
          ? UploadModalType.MannualyUpdateFixedInspection
          : UploadModalType.InspectionStatus
      );
      setCurrentEntityId(entity.id as number);
      setCurrentEntity(entity);
      setUploadModalVisibility(!isUploadModalVisible);
    }

    try {
      setIsTableDataLoading(true);
      const actionResponse = await dispatch(columnAction.action(entity));
      await unwrapResult(actionResponse);
      setIsTableDataLoading(false);
    } catch (customError) {
      setErrors(customError);
      setIsTableDataLoading(false);
    }
  };

  const handleColumnDropdownOnChange = async (
    newValue: string | number | boolean | null,
    entity: Record<string, unknown>,
    c: ColumnWithDifferentCells<Record<string, unknown>>
  ) => {
    try {
      if (c.cellDropdownOnChange) {
        setIsTableDataLoading(true);
        const actionResponse = await dispatch(c.cellDropdownOnChange(entity, newValue));
        await unwrapResult(actionResponse);
        setIsTableDataLoading(false);
      }
    } catch (customError) {
      setErrors(customError);
      setIsTableDataLoading(false);
    }
  };

  const onColumnActionConfirmationClick = (
    confirmed: boolean,
    columnAction: ColumnAction<Record<string, unknown>>,
    entity: Record<string, unknown>
  ) => () => {
    setModalInfo(null);
    if (confirmed) {
      handleColumnActionClick(columnAction, entity, true);
    }
  };

  const columns = useMemo(() => {
    const tabColumns = (currentTab.columns as unknown) as ColumnType<Record<string, unknown>>[];
    return [...tabColumns, ...generateDynamicColumns(defaultCustomProperties || [], tabData)]
      .filter(c => c.hidden !== true)
      .map(c => {
        return {
          dataIndex: c.id,
          exportPropertyId: c.exportPropertyId ?? c.id,
          title: t(c.label),
          renderValue: c.renderValue,
          sortDirections: ["descend", "ascend"],
          // sorter: c.sortable ? sortData(c.id) : false,
          width: c.width,
          key: c.id,
          filterIcon: <FilterIcon stroke={primaryColor} />,
          render: (_, entity) => (
            <Row
              onClick={() => handlePrimaryColumnNavigation(c, entity)}
              className={c.primaryColumn ? "primaryColumn HB-table-cell" : "HB-table-cell"}
            >
              <CellRenderer
                c={c}
                entity={entity}
                columnButtonsOnClick={handleColumnActionClick}
                columnDropdownOnChange={handleColumnDropdownOnChange}
              />
            </Row>
          ),
          filterDropdown: c.filterType
            ? ({ visible }) => (
                <FilterRenderer
                  entityKey={currentTab.key}
                  id={c.id}
                  type={c.filterType!}
                  label={c.label}
                  optionSelector={c.optionsSelector}
                  filterOpen={visible}
                />
              )
            : undefined,
        } as TableColumnType<ArrayElement<typeof tabData>>;
      });
  }, [currentTab.columns, primaryColor, t]);

  useEffect(() => {
    if (setColumns) {
      setColumns(columns);
    }
  }, [columns]);

  const uploadFile = (value: string, status?: InspectionStatus, date?: string) => async (
    file: RcFile
  ): Promise<void | File | Blob | boolean> => {
    if (currentTab.addButton && currentTab.addButton.uploadFileAction) {
      setUploadModalVisibility(!isUploadModalVisible);
      try {
        if (hasSpecialChars(file.name)) {
          setError({ title: t("Error"), message: t("ErrorSpecialCharactersInFileName") });
        } else {
          const res = await dispatch(currentTab.addButton.uploadFileAction({ file, expiration: value }));
          await unwrapResult(res);
        }
      } catch (customError) {
        setErrors(customError);
      }
    }

    if (currentTab.columns && currentTab.columns[currentTab.columns.length - 1]) {
      const action =
        currentTab.columns[currentTab.columns.length - 1].stagingActions?.find(x => x.uploadFileAction !== undefined)
          ?.uploadFileAction || null;
      if (action) {
        setUploadModalVisibility(!isUploadModalVisible);
        try {
          const res = await dispatch(
            action({ value, file, entityId: currentEntityId as number, entity: currentEntity, date: date })
          );
          await unwrapResult(res);
        } catch (customError) {
          setErrors(customError);
        }
      }
    }
    setCurrentEntity(undefined);
    setCurrentEntityId(undefined);
    setUploadModalVisibility(!isUploadModalVisible);

    return false;
  };

  const closeModal = () => {
    setCurrentEntity(undefined);
    setCurrentEntityId(undefined);
    setUploadModalVisibility(!isUploadModalVisible);
  };

  const addComment = async (value: string) => {
    if (currentTab.addButton && currentTab.addButton.addCommentAction) {
      try {
        const res = await dispatch(currentTab.addButton.addCommentAction({ comment: value }));
        await unwrapResult(res);
      } catch (customError) {
        setErrors(customError);
      }
    }

    setCurrentEntity(undefined);
    setCurrentEntityId(undefined);
    setAddCommentModalVisibility(!isAddCommentModalVisibility);
  };

  const closeAddCommentModal = () => {
    setAddCommentModalVisibility(!isAddCommentModalVisibility);
  };

  const getRowKey = (record: Record<string, unknown>) => (record.id ? record.id : v4());

  const addButtonVisible = currentTab.addButton?.enablingFor ? currentTab.addButton.enablingFor(user) : true;

  return (
    <Row className="tab">
      <ConfirmationModal
        visible={isExporting}
        confirmOkClick={() => setIsExporting(false)}
        confirmationType="notification"
        messages={[t("Processing"), t("DownloadBegin", { ListExportLimit: listExportLimit })]}
      />
      {currentTab.addButton?.type === "addModal" &&
        (!currentTab.addButton.privilege || currentTab.addButton.privilege(user)) && (
          <HBAddTableRecordModal
            isVisible={isAddRecordModalVisible}
            fields={currentTab.addButton?.addModalInputFields}
            closeModal={() => setAddRecordModalVisibility(false)}
            onSave={currentTab.addButton?.addRecordModalAction}
            setErrors={setErrors}
          ></HBAddTableRecordModal>
        )}
      {currentTab.addButton?.type === TableAddButtonType.CustomModal && currentTab.addButton.CustomComponent ? (
        <currentTab.addButton.CustomComponent
          key={String(customModalVisible)}
          modalVisible={customModalVisible}
          setModalVisible={setCustomModalVisible}
          setErrors={setErrors}
        />
      ) : null}
      <HBUploadModal
        uploadModalType={uploadModalType}
        isVisible={isUploadModalVisible}
        beforeUpload={uploadFile}
        closeModal={closeModal}
        setErrors={setErrors}
      />
      <HbAddCommentModal
        uploadModalType={addCommentModalType}
        isVisible={isAddCommentModalVisibility}
        addComment={addComment}
        closeModal={closeAddCommentModal}
      />
      <ConfirmationModal
        visible={!!error}
        confirmOkClick={() => setError(undefined)}
        confirmationType="notification"
        messages={[error?.title ?? t("SomethingWentWrong"), error?.message ?? t("ContactAdministrator"), "error"]}
      />
      <ConfirmationModal
        visible={errorMessages.length > 0}
        confirmationType="cardValidationErrors"
        confirmOkClick={handleClearErrors}
        messages={errorMessages}
      />
      <ConfirmationModal
        visible={modalInfo !== null}
        message={modalInfo?.body || ""}
        okText={modalInfo?.okText || ""}
        cancelText={modalInfo?.cancelText || ""}
        confirmOkClick={modalInfo?.onConfirm || (() => undefined)}
        cancelClick={modalInfo?.onCancel || (() => undefined)}
      />
      <Col span={24} className="tab__search-bar" style={{ marginBottom: "1rem" }}>
        <Typography.Text className="noselect result-count">{`${t("Showing")} ${tabData.length} ${t(
          "ShowResults"
        )}`}</Typography.Text>
        {/* <TableSearchBar
          data={entitiesToDisplay}
          searchKeys={searchKeys}
          resultColumns={searchResultColumns}
          entityKey={currentTab.key}
        /> */}
        {currentTab.addButton &&
          addButtonVisible &&
          addCheckPointButtonVisible &&
          (!currentTab.addButton.privilege || currentTab.addButton.privilege(user)) && (
            <Button className="add-button" onClick={handleAddButtonClick}>
              {t(currentTab.addButton.label)}
            </Button>
          )}
        <DotsPopover
          tabConfig={currentTab}
          selectedEntities={selectedEntities}
          handleDotsMenuClick={handleDotsMenuClick}
        />
      </Col>
      <Form form={form} component={false}>
        <Col span={24} className="bordered-single-view-table">
          <ResizeObserver onResize={handleSetHeight}>
            <Table
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NoData")} />,
                triggerDesc: t("SortDesc"),
                triggerAsc: t("SortAsc"),
                cancelSort: t("SortCancel"),
              }}
              columns={columns}
              className="single-view-table"
              loading={isTableDataLoading}
              dataSource={tabData}
              pagination={false}
              scroll={{ y: height ? height : "calc(32rem)" }}
              onRow={(record: Record<string, unknown>) => ({
                onClick: () => currentTab.onRowClick?.(record),
              })}
              // eslint-disable-next-line no-console
              onChange={(_, filters) => console.log(filters)}
              rowKey={getRowKey}
              components={{
                ...VList({
                  height: height ? height : "calc(32rem)",
                }),
              }}
              rowSelection={
                currentTab.rowSelection
                  ? {
                      ...rowSelection,
                    }
                  : undefined
              }
            />
          </ResizeObserver>
        </Col>
      </Form>
    </Row>
  );
};

export default SingleViewVTable;
