import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import { Location } from "history";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getRedirectToLegacy } from "../../store/slices/user";
import { RootState, useAppDispatch } from "../../store/store";
import { ParamsKeys } from "../../types/utility";
import useRouter from "../../utils/hooks/useRouter";

export default (): JSX.Element => {
  const { location } = useRouter<{ referer: Location }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const returnUrl = urlSearchParams.get(ParamsKeys.RETURN_URL) ?? "actions";
  const lang = useSelector((state: RootState) => state.user.settings.lang);
  const jwt = useSelector((state: RootState) => state.user.jwt);

  useEffect(() => {
    if (jwt) {
      dispatch(getRedirectToLegacy({}))
        .then(res => unwrapResult(res))
        .then(data => {
          const vitreUrl = `${process.env.REACT_APP_VITRE_URL}/${ParamsKeys.LOCAL_REDIRECT}?${
            ParamsKeys.PAGE
          }=${returnUrl}&${ParamsKeys.LANGUAGE}=${lang}&${ParamsKeys.KEY}=${new URLSearchParams(
            new URL(data.returnUrl).search
          ).get(ParamsKeys.RETURN_URL_KEY)}`;

          window.location.href = vitreUrl;
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      window.location.href = `${process.env.REACT_APP_VITRE_URL}/login?${ParamsKeys.RETURN_URL}=${returnUrl}`;
    }
  }, [jwt]);

  return (
    <div className="full-login-container" style={{ height: "100vh" }}>
      <Spin />
    </div>
  );
};
