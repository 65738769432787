import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import unionBy from "lodash/unionBy";

import { Project, ProjectsEndpoint, ProjectsPaginatedData, ProjectState } from "../../types/projects";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: ProjectState = {
  data: [],
  isLoading: false,
  singleData: null,
  error: null,
  lastUpdated: dayjs().toISOString(),
  paginationInfo: {
    count: 0,
    currentPage: 0,
  },
};

export const fetchPaginatedProjects = createAsyncThunk<
  {
    primaryData: Project[];
    possibleResults: number;
    defaultPageSize?: number;
  },
  { page?: number; pageSize?: number; forceUpdate?: boolean } | undefined,
  { state: RootState }
>(
  "@@PROJECTS/FETCH_PAGINATED",
  async (params, { getState }) => {
    const { user, projects } = getState();

    const response = await hbApi.post<ProjectsPaginatedData>(
      `${ProjectsEndpoint}/pagedList`,
      {
        gridifyQuery: {
          Page: params?.page || projects.paginationInfo.currentPage + 1,
          PageSize: 9999,
        },
      },
      hbApiOptions(user.jwt)
    );

    return {
      primaryData: response.data.data,
      possibleResults: response.data.count,
      defaultPageSize: user.companySettings.defaultPageSize,
    };
  },
  {
    condition: (arg, { getState }) => {
      const { projects } = getState();
      return !projects.data.length;
    },
  }
);

const slice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPaginatedProjects.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPaginatedProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchPaginatedProjects.fulfilled, (state, action) => {
        if (action.meta.arg?.page === 1) {
          state.data = action.payload.primaryData;
          state.paginationInfo.currentPage = 1;
        } else {
          state.data = unionBy(state.data, action.payload.primaryData, "id");
          state.paginationInfo.currentPage = state.paginationInfo.currentPage + 1;
        }
        state.paginationInfo.count = action.payload.possibleResults;
        state.error = null;
        state.lastUpdated = dayjs().toISOString();
        state.isLoading = false;
      });
  },
});

export default slice.reducer;
