import i18n from "i18next";
import LngDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { getApiUrl } from "./store/api";

const lngDetector = new LngDetector(null, {
  lookupQuerystring: "lang",
});

i18n
  .use(HttpApi)
  .use(lngDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    backend: {
      loadPath: `${getApiUrl()}/Localization/{{lng}}`,
    },
    fallbackLng: "en-US",
    load: "currentOnly",
    // debug: process.env.REACT_APP_ENVIRONMENT === "development",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      useSuspense: false,
      // wait: true,
    },

    nsSeparator: false,
  });

export default i18n;
