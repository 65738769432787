import React, { Suspense } from "react";
import { Switch } from "react-router-dom";

import CubeConfigurationPage from "./components/Cube/CubeConfigurationPage";
import CustomRoute from "./components/CustomRoute";
import LandingPage from "./components/LandingPage/LandingPage";
import ManagmentToolsPage from "./components/ManagmentTools/ManagmentToolsPage";
import OtpComponent from "./components/OtpComponent/OtpComponent";
import ReporterPage from "./components/ReporterPage/ReporterPage";
import Categories from "./pages/Categories/Categories";
import Category from "./pages/Categories/CategorySingle/CategoryWrapper";
import NotAllowedPage from "./pages/ErrorPages/NotAllowedPage";
import NotFoundPage from "./pages/ErrorPages/NotFoundPage";
import ImportPage from "./pages/Import/Import";
import FullLogin from "./pages/Login/FullLogin";
import GetKey from "./pages/Login/GetKey";
import LoginFromPas from "./pages/Login/LoginFromPas";
import RedirectToLegacy from "./pages/Login/RedirectToLegacy";
import VitreEntrance from "./pages/Login/VitreEntrance";
import RootPath from "./pages/RootPage/RootPath";
import TodoList from "./pages/TodoList/TodoList";
import { UserRole } from "./types/user";

const LivePDF = React.lazy(() => import("./components/LivePDF/LivePDF"));
const MapPage = React.lazy(() => import("./components/Map/MapPage"));

// Note: Keep strings alphabetize for faster development search
export const routPaths = {
  allCategories: "/:category",
  category: "/:category/:id",
  createNew: "/:category/new",
  import: "/:category/import",
  login: "/login",
  error: "/error",
  todoList: "/todolist",
  otp: "/otp",
  landingPage: "/landingPage/:id",
  livePdf: "/formResults/:id",
  localRedirect: "/localRedirect",
  redirectToLegacy: "/redirectToLegacy",
  //remove after vitre migration
  vitreEntrance: "/vitreEntrance",
  localCheck: "/localCheck",
  categoryMobileFirst: "/:category/:id/mobile",
  categoryNewMobileFirst: "/:category/new_mobile",
  reporting: "/reporting",
};

const Router = (): JSX.Element => (
  <Switch>
    <CustomRoute path={routPaths.localRedirect} privateRoute={false}>
      <LoginFromPas />
    </CustomRoute>
    <CustomRoute path={routPaths.redirectToLegacy} privateRoute>
      <RedirectToLegacy />
    </CustomRoute>
    <CustomRoute path={routPaths.vitreEntrance} privateRoute={false}>
      <VitreEntrance />
    </CustomRoute>
    <CustomRoute path="/getkeyforoldpas" privateRoute={false}>
      <GetKey />
    </CustomRoute>
    <CustomRoute path="/notAllowed" privateRoute>
      <NotAllowedPage />
    </CustomRoute>
    <CustomRoute path="/notFound" privateRoute={false}>
      <NotFoundPage />
    </CustomRoute>
    <CustomRoute exact path="/cube" privateRoute allowedUserRoles={[UserRole.SU]}>
      <CubeConfigurationPage />
    </CustomRoute>
    <CustomRoute exact path="/managmentTools" privateRoute allowedUserRoles={[UserRole.SU]}>
      <ManagmentToolsPage />
    </CustomRoute>
    <CustomRoute exact path="/hb-map" privateRoute>
      <Suspense fallback={<div />}>
        <MapPage />
      </Suspense>
    </CustomRoute>
    <CustomRoute path={routPaths.error}>
      <NotFoundPage />
    </CustomRoute>
    <CustomRoute path={routPaths.login}>
      <FullLogin />
    </CustomRoute>
    <CustomRoute privateRoute path={routPaths.todoList}>
      <TodoList />
    </CustomRoute>
    <CustomRoute path={routPaths.otp}>
      <OtpComponent />
    </CustomRoute>
    <CustomRoute path={routPaths.landingPage}>
      <LandingPage />
    </CustomRoute>
    <CustomRoute path={routPaths.reporting} allowedUserRoles={[UserRole.Reporter, UserRole.SU]} privateRoute>
      <ReporterPage />
    </CustomRoute>
    <CustomRoute exact path={routPaths.livePdf} privateRoute>
      <Suspense fallback={<div />}>
        <LivePDF />
      </Suspense>
    </CustomRoute>
    <CustomRoute type="category" privateRoute path={routPaths.categoryMobileFirst}>
      <Category isMobile />
    </CustomRoute>
    <CustomRoute exact type="category" privateRoute path={routPaths.categoryNewMobileFirst}>
      <Category isNewEntity isMobile />
    </CustomRoute>
    <CustomRoute type="category" exact privateRoute path={routPaths.allCategories}>
      <Categories />
    </CustomRoute>
    <CustomRoute exact type="category" privateRoute path={routPaths.createNew}>
      <Category isNewEntity />
    </CustomRoute>
    <CustomRoute type="category" exact privateRoute path={routPaths.import}>
      <ImportPage />
    </CustomRoute>
    <CustomRoute type="category" privateRoute path={routPaths.category}>
      <Category />
    </CustomRoute>
    <CustomRoute path="/" privateRoute={false}>
      <RootPath />
    </CustomRoute>
  </Switch>
);

export default Router;
