import "./singleViewTabs.less";

import { Col, Row, Tabs } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageConfigurationContext from "../../context/pageContext";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage, Tab } from "../../types/page";
import SingleViewTree from "../SingleViewTree/SingleViewTree";
import VirtualizedTable from "../SingleViewVTable/SingleViewVTable";

const { TabPane } = Tabs;

type TProps = {
  isNewEntity?: boolean;
  isEdited: boolean;
  activeTab?: string;
  handleSetActiveTab: (tabKey: string) => void;
};

const SingleViewTabs = ({ isNewEntity, activeTab, handleSetActiveTab, isEdited }: TProps): JSX.Element => {
  const { t } = useTranslation();
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const pageTabs = pageConfig.tabsPanel;
  useEffect(() => {
    handleSetActiveTab(pageTabs[0]?.key);
  }, [pageTabs]);

  const getTabs = () => {
    const filteredTabs = pageTabs.filter(t => t.type !== "tree");
    return filteredTabs.map(tab => (
      <TabPane tab={t(tab.label)} key={tab.key}>
        {renderContent(tab)}
      </TabPane>
    ));
  };

  const renderContent = (tab: Tab<Record<string, unknown>, Record<string, unknown>>) => {
    switch (tab.type) {
      case "table":
        return <VirtualizedTable tabData={tab} selected={activeTab === tab.key} />;

      case "tree":
        return <SingleViewTree tabData={tab} isNewEntity={isNewEntity} />;

      default:
        return null;
    }
  };
  return (
    <Col sm={24} md={24} lg={24} className="tabs-panel" style={{ height: "98%", paddingTop: "2rem", flex: 4 }}>
      <Row style={{ height: "50vh" }}>
        {!isNewEntity && !isEdited && (
          <Tabs className="single-view-tabs" onTabClick={handleSetActiveTab}>
            {getTabs()}
          </Tabs>
        )}
      </Row>
    </Col>
  );
};

export default SingleViewTabs;
