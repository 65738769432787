import {
  ColumnManagementAction,
  ExportAction,
  GetFiltersUrl,
  ResetColumnsWidthAction,
} from "../../../../actions/menuActions";
import AddEmployeeSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddEmployeeSection";
import AddFileSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddFileSection/AddFileSection";
import OrgUnitAccountableMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/accountable/OrgUnitAccountableMobileCardTemplate";
import FilesMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/FilesMobileCardTemplate";
import HistoryLogMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/HistoryLogMobileCardTemplate";
import TeamMembersMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/TeamMembersMobileCardTemplate";
import { ReactComponent as PageIcon } from "../../../../media/employee-page-icon.svg";
import { orgUnitSelectors, pageSelectors } from "../../../../selectors";
import { fetchHistoryLog } from "../../../../store/slices/commonThunks";
import {
  addOrgUnit,
  clearOrgUnitError,
  createNewAccountableEntryTemplate,
  createOrgUnitTemplate,
  deleteBulkEmployees,
  fetchOrgUnits,
  fetchSingleOrgUnit,
  patchOrgUnitParentId,
  removeFiles,
  updateOrgUnit,
  updateParentId,
  uploadFile,
} from "../../../../store/slices/orgUnit";
import { deleteMultipleEntityPrivileges, getEntityPrivileges } from "../../../../store/slices/privileges";
import { Employee } from "../../../../types/employee";
import { OrgUnit, OrgUnitPage } from "../../../../types/orgUnit";
import { CategoryPage, SectionType } from "../../../../types/page";
import {
  HistoryLogEntityType,
  PrivilegeData,
  PrivilegedEntityType,
  TableAddButtonType,
} from "../../../../types/utility";
import { enableForAdminOrUserOwner, filesTableColumns, historyLogTableColumns } from "../common";
import { localizeText, pickSpecificColumns, prepareDynamicColumns, removeColumnFilters } from "../utilities";
import {
  externalIdField,
  managerUserField,
  nameField,
  parentOUField,
  primaryFields,
  secondaryFields,
  statusField,
} from "./fields";
import {
  employeesTabColumns,
  orgUnitAccountableActionField,
  orgUnitAccountableExternalIdField,
  orgUnitAccountableIsAssignedToOUBranchField,
  orgUnitAccountableNameField,
  orgUnitAccountableRoleField,
  orgUnitAccountableTabColumns,
  orgUnitsTableColumns,
} from "./orgUnitConfigPageData";

const orgUnitConfigPage = (): CategoryPage<OrgUnitPage> => ({
  id: "orgUnit",
  title: "PluginOrgUnitName",
  entityEndpoint: "/OrgUnit",
  PageIcon: PageIcon,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: orgUnitSelectors.error,
  isLoading: pageSelectors.isOrgUnitPageLoading,
  lastUpdatedSelector: orgUnitSelectors.orgUnitLastUpdatedText,
  primarySingleEntitySelector: orgUnitSelectors.single,
  customPropertiesSelector: orgUnitSelectors.orgUnitCustomProperties,
  fetchSingle: fetchSingleOrgUnit,
  clearError: clearOrgUnitError,
  intializeDataActions: [fetchOrgUnits],
  createNewEntityTemplate: createOrgUnitTemplate,
  createNewEntity: addOrgUnit,
  updateEntity: updateOrgUnit,
  listViewActions: [ColumnManagementAction, ResetColumnsWidthAction, ExportAction, GetFiltersUrl],
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  addButton: {
    label: "AddOrgUnit",
    enablingFor: enableForAdminOrUserOwner,
    // eslint-disable-next-line no-console
    action: () => console.log("Create new OU"),
  },
  summaryCard: {
    remotelyUpdatedFieldIDs: ["parentId"],
    canEdit(user) {
      return enableForAdminOrUserOwner(user);
    },
    cardInformationalMessasge: e => {
      return e.parentId === null ? localizeText("RootOrgUnitWarning") : "";
    },
    globalEditForbidden: e => !!(e.parentId === null),
    CardIcon: PageIcon,
    mobileSingleView: {
      summary: {
        icon: PageIcon,
        title: nameField,
        id: externalIdField,
        status: statusField,
        mainContextRelation: managerUserField,
        date: parentOUField,
      },
      sections: [
        {
          type: SectionType.ContextSection,
          fields: [parentOUField],
        },
        {
          type: SectionType.DatesSection,
          fields: [],
        },
      ],
    },
    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
  },
  listView: {
    table: {
      tableSelector: orgUnitSelectors.all,
      fetchListViewData: fetchOrgUnits,
      columns: orgUnitsTableColumns,
      rowSelection: false,
      defaultColumnKeys: ["name", "externalId", "parentId", "status", "managerUserId"],
      type: "tree",
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumns<OrgUnit>(props, data),
  },
  listSearch: {
    columns: pickSpecificColumns<OrgUnit>(orgUnitsTableColumns, "name", "externalId"),
    keys: ["name", "externalId"],
  },
  tabsPanel: [
    {
      key: "orgUnit",
      label: "ParentOU",
      tabSelector: orgUnitSelectors.basic,
      columns: removeColumnFilters(orgUnitsTableColumns) as [],
      rowSelection: true,
      type: "tree",
      changeNewEntityParent: updateParentId,
      changeExistingEntityParent: patchOrgUnitParentId,
      selectedKeyId: "parentId",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<OrgUnit>(props, data)),
      customPropertiesSelector: orgUnitSelectors.orgUnitCustomProperties,
    },
    {
      key: "employees",
      label: "OrgUnitEmployees",
      tabSelector: orgUnitSelectors.orgUnitEmployees,
      columns: removeColumnFilters(employeesTabColumns) as [],
      rowSelection: true,
      type: "table",
      selectedKeyId: "employeeId",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<Employee>(props, data)),
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities => deleteBulkEmployees(entities as Employee[]),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
      mobileCardTemplate: TeamMembersMobileCardTemplate,
    },
    {
      key: "files",
      label: "Files",
      tabSelector: orgUnitSelectors.files,
      columns: removeColumnFilters(filesTableColumns("orgUnit")) as [],
      addButton: {
        action: createNewAccountableEntryTemplate,
        label: "AddFile",
        enablingFor: enableForAdminOrUserOwner,
        // controlUploadModal: true,
        type: TableAddButtonType.UploadFile,
        uploadFileAction: uploadFile,
        mobileAddButtonTemplate: AddFileSection,
      },
      rowSelection: true,
      type: "table",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<File>(props, data)),
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: localizeText("DeleteBulk"),
          action: entities => removeFiles(entities.map(x => x.id) as number[]),
          type: "bulk",
          changesConfirmation: {
            body: "Are you shure you want to delete the selected files? This action can not be undone.",
            okText: "Delete",
            cancelText: "Cancel",
          },
        },
      ],
      mobileCardTemplate: FilesMobileCardTemplate,
    },
    {
      key: "accountable",
      label: "Accountable",
      tabSelector: orgUnitSelectors.orgUnitAccountableRelations,
      tabDataThunk: id => getEntityPrivileges({ id: +id, entityType: PrivilegedEntityType.OrgUnit }),
      columns: removeColumnFilters(orgUnitAccountableTabColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewAccountableEntryTemplate,
        enablingFor: enableForAdminOrUserOwner,
        label: "AddOrgUnitRelation",
        type: TableAddButtonType.AddInline,
        mobileAddButtonTemplate: AddEmployeeSection,
        actionField: orgUnitAccountableActionField,
        addEntityFields: [
          orgUnitAccountableNameField,
          orgUnitAccountableExternalIdField,
          orgUnitAccountableRoleField,
          orgUnitAccountableIsAssignedToOUBranchField,
        ],
      },
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities =>
            deleteMultipleEntityPrivileges({
              entity: entities as PrivilegeData[],
              entityType: PrivilegedEntityType.OrgUnit,
              id: null,
            }),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
      mobileCardTemplate: OrgUnitAccountableMobileCardTemplate,
    },
    {
      key: "historyLog",
      label: "HistoryLog",
      tabDataThunk: id => fetchHistoryLog({ entityId: +id, entityType: HistoryLogEntityType.OrgUnit }),
      tabSelector: orgUnitSelectors.orgUnitHistoryLog,
      columns: removeColumnFilters(historyLogTableColumns),
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: HistoryLogMobileCardTemplate,
    },
  ],
});

export default orgUnitConfigPage;
