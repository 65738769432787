import "./MobileCardTemplate.less";

import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { File } from "../../../../types/files";
import { MobileCardTemplateTProps } from "../../../../types/page";
import { openFilePreview } from "../../../../utils/files";
// import useFormManagmentEvents, { MobileEvent } from "../../../EmbeddedModal/useFormManagmentEvents";
import CardLabel from "../core/CardLabel/CardLabel";
import CardLabelWithIcon, { ICON_NAMES } from "../core/CardLabelWithIcon/CardLabelWithIcon";
import CardTitle from "../core/CardTitle/CardTitle";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import LabelWithDate from "../core/LabelWithDate/LabelWithDate";

const FilesMobileCardTemplate: React.FC<MobileCardTemplateTProps<File>> = ({ entity }) => {
  const { t } = useTranslation();
  // const { fireMobileEvent } = useFormManagmentEvents();
  const fileType = entity.name.split(".").pop() || "";
  // const onDownload = () => {
  //   fireMobileEvent(MobileEvent.DownloadFileInMobile, { url: entity.url });
  // };

  const onFilePreview = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    openFilePreview({
      url: entity.url,
      createdDate: entity.createdDate,
      createdByUser: {
        id: +entity.createdByUser.id,
        name: entity.createdByUser.name,
      },
    });
  };

  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.createdDate && <LabelWithDate labelTranslationKey="" date={entity.createdDate} />}
        {entity.createdByUser.name && (
          <CardLabelWithIcon label={entity.createdByUser.name} iconName={ICON_NAMES.USER} />
        )}
      </ContainerWithDivider>
      <ContainerWithDivider>
        <CardTitle title={entity.name} />
      </ContainerWithDivider>
      <ContainerWithDivider>
        {entity.expirationDate && <LabelWithDate labelTranslationKey="FieldExpiration" date={entity.expirationDate} />}
        {fileType && <CardLabel label={fileType} />}
      </ContainerWithDivider>
      {/* <Button onClick={onDownload} type="text" size="small" className="btn mobile-action-button">
        {t("Download")}
      </Button> */}
      <Button onClick={onFilePreview} type="text" size="small" className="btn mobile-action-button">
        {t("View")}
      </Button>
    </CardWithSelect>
  );
};

export default FilesMobileCardTemplate;
