import "chart.js/auto";

import { Chart as ChartJS } from "chart.js";
import ChartAnnotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import chartTrendline from "chartjs-plugin-trendline";
import React, { useContext, useMemo } from "react";
import { Chart } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useSelector } from "react-redux";

import { RootState } from "../../../../store/store";
import { CustomResultSet } from "../../../../utils/dashboard/resultSet/resultSetFactory";
import { spliceIntoChunks } from "../../../../utils/functions";
import useLocalizeDimensions, { formatCategoryLabel } from "../../Localization/useLocalizeDimensions";
import { generateColorsForCharts } from "../ColorHelpers";
import { ChartContext } from "../HBChart";
import { commaify, labelColor, precentageTooltip, precetageLabels, roundNumber } from "../Helpers";

ChartJS.register(ChartAnnotationPlugin);
ChartJS.register(ChartDataLabels);
ChartJS.register(chartTrendline);

type GraphProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: any;
  data: CustomResultSet | undefined;
  grouping: string;
  showNumber: boolean;
  showPrecent: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (values: string[], axis: "x" | "y") => (event: any) => void;
};

const Graph = React.forwardRef<ChartJSOrUndefined, GraphProps>(
  ({ type, data, showNumber, showPrecent, onClick }, graphRef) => {
    const { chart } = useContext(ChartContext);
    const { localizeDimension, formatSeriesLabel } = useLocalizeDimensions();
    const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
    const language = useSelector((state: RootState) => state.user.settings.lang);

    const yValues = useMemo(() => {
      if (data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return data.series(chart?.pivotConfig).map((item: any) => {
          return formatSeriesLabel(data, item.key, item.title);
        });
      } else {
        return [];
      }
    }, [data]);

    const xValues = useMemo(() => {
      if (data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return data.categories(chart?.pivotConfig).map((c: any) => {
          return formatCategoryLabel(data, c.x, chart?.pivotConfig);
        });
      } else {
        return [];
      }
    }, [data]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chartJsData: (resultSet: any) => any = function (resultSet: CustomResultSet) {
      if (resultSet) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let datasets: any = [];
        switch (type) {
          case "bar": {
            const colors = generateColorsForCharts(resultSet.series(chart?.pivotConfig).length, primaryColor);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            datasets = resultSet.series(chart?.pivotConfig).map((item: any, index: number) => {
              const formattedLable = formatSeriesLabel(resultSet, item.key, item.title);
              const seriesColor =
                chart?.customColors?.find(rule => rule?.label === formattedLable)?.color || colors[index].toHexString();

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const data = item.series.map((s: any) => roundNumber(s.value));
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const xcolors = item.series.map((s: any) => {
                return chart?.customColors?.find(rule => rule?.label === s.x)?.color || seriesColor;
              });

              return {
                label: formattedLable,
                data: data,
                axis: chart?.chartAxis,
                backgroundColor: xcolors,
                pointHoverBackgroundColor: xcolors,
                stack: chart?.barGrouping === "dodge" ? "Stack " + index : undefined,
                pointStyle: "rectRounded",
              };
            });
            break;
          }
          case "line": {
            const colors = generateColorsForCharts(resultSet.series(chart?.pivotConfig).length, primaryColor);
            datasets = resultSet?.series(chart?.pivotConfig).map((s, index) => {
              const formattedLable = formatSeriesLabel(resultSet, s.key, s.title);
              const seriesColors: string =
                chart?.customColors?.find(rule => rule?.label === formattedLable)?.color || colors[index].toHexString();
              return {
                label: formattedLable,
                data: s.series.map(r => roundNumber(r.value)),
                pointStyle: "rectRounded",
                pointBackgroundColor: seriesColors,
                borderColor: seriesColors,
                pointRadius: 1,
                tension: 0.1,
                yAxisId: "y" + index,
                pointHoverRadius: 1,
                borderWidth: 1,
                tickWidth: 1,
                fill: false,
              };
            });
            break;
          }
          case "pie": {
            const colors = generateColorsForCharts(resultSet?.categories().length, primaryColor);
            datasets = resultSet.series(chart?.pivotConfig).map(s => {
              const seriesColors =
                chart?.customColors && chart.customColors.length > 0
                  ? resultSet?.categories().map((c, index) => {
                      return (
                        chart.customColors.find(rule => rule?.label?.toLocaleLowerCase() === c.x.toLocaleLowerCase())
                          ?.color || colors[index].toHexString()
                      );
                    })
                  : colors.map(c => c.toHexString());
              return {
                label: localizeDimension(s.key, s.title),
                data: s.series.map(r => roundNumber(r.value)),
                yValues: [s.key],
                backgroundColor: seriesColors,
                hoverBackgroundColor: seriesColors,
                pointStyle: "rectRounded",
              };
            });
            break;
          }
          case "area": {
            const colors = generateColorsForCharts(resultSet.series.length, primaryColor);
            datasets = resultSet.series(chart?.pivotConfig).map((s, index) => ({
              label: s.title,
              data: s.series.map(r => roundNumber(r.value)),
              yValues: [s.key],
              pointRadius: 1,
              pointHoverRadius: 1,
              backgroundColor: colors[index],
              borderWidth: 0,
              fill: true,
              tension: 0,
            }));
            break;
          }
          default: {
            datasets = [];
            break;
          }
        }
        return {
          datasets,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          labels: resultSet?.categories(chart?.pivotConfig).map((c: any) => {
            return formatCategoryLabel(resultSet, c.x, chart?.pivotConfig);
          }),
          responsive: true,
        };
      }
    };

    const getChartPlugins = () => {
      const chartPlugins = [];
      if (showNumber) {
        chartPlugins.push(ChartDataLabels);
      }
      chartPlugins.push(ChartAnnotationPlugin);
      chartPlugins.push(chartTrendline);
      return chartPlugins;
    };

    const getChartAnnotations = () => {
      if (chart?.annotations) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const annotations: any = {};
        chart.annotations
          //hotfix for existing bad data
          .filter(x => x !== null)
          .forEach(
            (bound, index) =>
              (annotations[`bound${index}`] = {
                type: "line",
                borderColor: bound.color,
                borderWidth: 4,
                display: chart.annotations ? true : false,
                label: {
                  display: true,
                  position: "end",
                  backgroundColor: "transparent",
                  color: "gray",
                  yAdjust: chart.chartAxis === "x" ? -8 : undefined,
                  xAdjust: chart.chartAxis === "y" ? 8 : undefined,
                  content: () => bound?.label,
                  rotation: "auto",
                },
                scaleID: chart.chartAxis === "x" ? "y" : "x",
                value: bound.value,
                // For simple property changes, you can directly modify the annotation
                // element's properties then return true to force chart re-drawing.  This is faster.
                // enter(x: any, event: any) {
                //   x.element.label.options.display = true;
                //   return true;
                // },
                // leave(x: any, event: any) {
                //   x.element.label.options.display = false;
                //   return true;
                // },
              })
          );
        return annotations;
      }
      return {};
    };

    const getChartOptions = () => {
      const annotations = getChartAnnotations();
      switch (type) {
        case "pie":
          return {
            plugins: {
              tooltip: {
                callbacks: {
                  label: showPrecent ? precentageTooltip : undefined,
                },
              },
              legend: {
                labels: {
                  usePointStyle: true,
                },
                title: {
                  display: "true",
                  padding: 5,
                },
                position: "bottom",
              },
              datalabels: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                backgroundColor: function (context: any) {
                  return context.dataset.backgroundColor;
                },
                borderColor: "white",
                borderRadius: 25,
                borderWidth: 2,
                color: "white",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                display: function (context: any) {
                  if (context.dataset.data[context.dataIndex] > 0) {
                    return "auto";
                  } else {
                    return false;
                  }
                },
                font: {
                  weight: "bold",
                },
                formatter: showPrecent ? precetageLabels : commaify,
                anchor: "end",
                align: "start",
                textAlign: "center",
              },
              annotation: {
                annotations: annotations,
              },
            },
            responsive: true,
            interaction: {
              mode: "point" as const,
              axis: "xy",
              intersect: false,
            },
            layout: {
              padding: 30,
            },
            locale: language,
            maintainAspectRatio: false,
            scales: {
              x: {
                display: false,
                stacked: true,
              },
              y: {
                display: false,
                stacked: true,
              },
            },
          };

        case "line":
          return {
            plugins: {
              legend: {
                labels: {
                  usePointStyle: true,
                },
                position: "bottom",
              },
              annotation: {
                annotations: annotations,
              },
              datalabels: {
                color: "black",
                clamp: true,
                display: "auto",
                font: {
                  weight: "bold",
                },
                align: -17,
                offset: 4,
                tension: 0.1,
                textAlign: "center",
                formatter: commaify,
              },
            },
            responsive: true,
            interaction: {
              mode: "point",
              // axis: "xy",
              intersect: false,
            },
            layout: {
              padding: 30,
            },
            maintainAspectRatio: false,
            locale: language,
            scales: {
              x: {
                display: true,
                stacked: false,
              },
              y: {
                display: true,
                stacked: false,
              },
            },
          };

        default:
          return {
            plugins: {
              legend: {
                labels: {
                  usePointStyle: true,
                },
                position: "bottom",
              },
              annotation: {
                annotations: annotations,
              },
              datalabels: {
                color: labelColor,
                clamp: true,
                display: chart?.showAllLabels
                  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    function (context: any) {
                      return context.dataset.data[context.dataIndex] !== 0;
                    }
                  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    function (context: any) {
                      return context.dataset.data[context.dataIndex] !== 0 ? "auto" : false;
                    },
                font: {
                  weight: "bold",
                },
                formatter: commaify,
                align: type === "line" ? -17 : "center",
                offset: type === "line" ? 4 : 0,
                tension: 0.1,
                textAlign: "center",
              },
            },
            responsive: true,
            interaction: {
              mode: "point" as const,
              axis: "xy",
              intersect: false,
            },
            layout: {
              padding: 30,
            },
            indexAxis: chart?.chartAxis,
            maintainAspectRatio: false,
            locale: language,
            scales: {
              x: {
                type: chart?.chartAxis === "y" ? "linear" : "category",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                beforeUpdate(axis: any) {
                  const labels = axis.chart.data.labels;
                  for (let i = 0; i < labels.length; i++) {
                    const lbl = labels[i];
                    if (typeof lbl === "string" && lbl.length > 10) {
                      labels[i] = lbl.substring(0, 10); // cutting
                    }
                  }
                },
                display: type !== "pie",
                stacked: type !== "bar" ? true : chart?.barGrouping === "stack",
              },
              y: {
                type: chart?.chartAxis === "y" ? "category" : "linear",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                beforeUpdate(axis: any) {
                  const labels = axis.chart.data.labels;
                  for (let i = 0; i < labels.length; i++) {
                    const lbl = labels[i];
                    if (typeof lbl === "string" && lbl.length > 10) {
                      labels[i] = spliceIntoChunks(lbl.split(" "), 5);
                    }
                  }
                },
                display: type !== "pie",
                stacked: type !== "bar" ? true : chart?.barGrouping === "stack",
              },
            },
          };
      }
    };

    const calculatedData = chartJsData(data);
    if (!calculatedData) return null;
    return (
      <Chart
        plugins={getChartPlugins()}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        options={getChartOptions() as any}
        type={type}
        ref={graphRef}
        data={calculatedData}
        onClick={onClick(
          chart?.hierarchicalNavigationState?.dimensionKey &&
            chart?.pivotConfig?.x?.includes(chart.hierarchicalNavigationState.dimensionKey)
            ? xValues
            : yValues,
          chart?.hierarchicalNavigationState?.dimensionKey &&
            chart?.pivotConfig?.x?.includes(chart.hierarchicalNavigationState.dimensionKey)
            ? "x"
            : "y"
        )}
      />
    );
  }
);

export default Graph;
