import { Breadcrumb, Button } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../../../../store/store";
import { HierarchicalState } from "../../../../../types/dashboard";
import { ChartContext } from "../../HBChart";
import { ROOT_MARKER } from "./HierarchicalDataControlContextProvider";
import ViewDataSwitch from "./ViewDataSwitch";

export type HBHierarchicalDataControlContext = {
  hierarchicalState: HierarchicalState;
  pushValue: (value: string, depth: number, dimensionKey?: string) => void;
  goBack: (historyRecord: HierarchicalState) => void;
  valueHistory: HierarchicalState[];
  viewDataEnabled: boolean;
  handleSetViewData: (enabled: boolean) => void;
};

export const HierarchicalDataControlContext = React.createContext<HBHierarchicalDataControlContext>({
  hierarchicalState: { depth: 0 },
  pushValue: (value: string) => {
    console.warn("push value", value);
  },
  goBack: () => {
    console.warn("go back");
  },
  valueHistory: [],
  viewDataEnabled: false,
  handleSetViewData: (enabled: boolean) => {
    console.warn("set view data enabled", enabled);
  },
});

export default function HierarchicalDataControl() {
  const { valueHistory, goBack, hierarchicalState: hirarchialState, viewDataEnabled, handleSetViewData } = useContext(
    HierarchicalDataControlContext
  );

  const { t } = useTranslation();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const { chart } = useContext(ChartContext);

  const getBreadCrumbs = () => {
    const breadcrubs = [
      <Breadcrumb.Item key={hirarchialState.value}>
        <Button type="link" shape="round" className="hirarchial-data-control-parent" style={{ color: primaryColor }}>
          {hirarchialState.value}
        </Button>
      </Breadcrumb.Item>,
    ].concat(
      valueHistory.map(item => (
        <Breadcrumb.Item key={item.value}>
          <Button
            type="text"
            shape="round"
            className="hirarchial-data-control-parent"
            onClick={() => {
              goBack(item);
            }}
          >
            {item.value === ROOT_MARKER || !item.value ? t("Root") : item.value}
          </Button>
        </Breadcrumb.Item>
      ))
    );

    if (direction === "ltr") {
      breadcrubs.reverse();
    }
    return breadcrubs;
  };
  if (valueHistory && valueHistory.length > 0) {
    return (
      <>
        <div className="hb-hirarchial-data-control">
          <Breadcrumb>{getBreadCrumbs()}</Breadcrumb>
        </div>
        {chart?.useViewData && (
          <ViewDataSwitch handleSetViewData={handleSetViewData} viewDataEnabled={viewDataEnabled} />
        )}
      </>
    );
  } else {
    return chart?.useViewData ? (
      <ViewDataSwitch handleSetViewData={handleSetViewData} viewDataEnabled={viewDataEnabled} />
    ) : null;
  }
}
