import { Empty, SpinProps, Table, TableColumnType } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CategoryId } from "../../types/page";
import { useConstructTableTree } from "../../utils/hooks/useConstructTableTree";
import "./tableTree.less";
import ResizableTitle from "../HBComponents/PaginatedTable/ResizableTitle";

type TProps = {
  tableRef: React.RefObject<HTMLDivElement>;
  pageId: CategoryId;
  tableData: Record<string, unknown>[];
  fullData?: Record<string, unknown>[];
  columns: TableColumnType<Record<string, unknown>>[];
  rowSelection?: TableRowSelection<Record<string, unknown>>;
  isLoading?: boolean;
  onRowClick?: (record: Record<string, unknown>) => void;
};

const TableTree = ({
  tableRef,
  pageId,
  tableData,
  fullData,
  columns,
  rowSelection,
  isLoading,
  onRowClick,
}: TProps): JSX.Element | null => {
  const treeData = useConstructTableTree(tableData, fullData);
  const [ready, setReady] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (treeData && treeData.length) {
      setReady(true);
    }
  }, [treeData]);

  return ready ? (
    <Table
      ref={tableRef}
      key={`tree-${pageId}`}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NoData")} />,
        triggerDesc: t("SortDesc"),
        triggerAsc: t("SortAsc"),
        cancelSort: t("SortCancel"),
      }}
      loading={{ spinning: isLoading, style: { top: "20rem" } } as SpinProps}
      className="table-tree"
      dataSource={treeData}
      pagination={false}
      columns={columns}
      expandable={{
        defaultExpandAllRows: true,
      }}
      rowSelection={rowSelection}
      scroll={{ y: "auto" }}
      onRow={record => {
        return onRowClick ? { onClick: () => onRowClick(record) } : {};
      }}
      components={{ header: { cell: ResizableTitle } }}
    />
  ) : null; //for first rendering;
};

export default TableTree;
