import { BaseEntityType } from "../../types/entityBase";
import { BasePage, CategoryId } from "../../types/page";
import tasksConfigPage from "./category/actions/tasksConfigPage";
import certificationConfigPage from "./category/certification/certifcationConfigPage";
import customPropertyConfigPage from "./category/customPropertyConfigPage";
import dashboardConfigPage from "./category/dashboardConfigPage";
import employeeConfigPage from "./category/employee/employeeConfigPage";
import equipmentConfigPage from "./category/equipment/equipmentConfigPage";
import equipmentTypeConfigPage from "./category/equipmentTypeConfigPage";
import inspectionConfigPage from "./category/inspection/inspectionConfigPage";
import inspectionTypeConfigPage from "./category/inspectionTypeConfigPage";
import issueTypeConfigPage from "./category/issueType/issueTypeConfigPage";
import locationConfigPage from "./category/location/locationConfigPage";
import locationTypeConfigPage from "./category/locationTypeConfigPage";
import orgUnitConfigPage from "./category/orgUnit/orgUnitConfigPage";
import recurrentActionConfigPage from "./category/recurringAction/recurringActionConfigPage";

import trainingConfigPage from "./category/training/trainingConfigPage";
import webhooksConfigPage from "./category/webhooks/webhooksConfigPage";

// Note: due to tight schedule, this was the only any for the type that was left
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryPageConfig = (): BasePage<any>[] => [
  equipmentConfigPage(),
  inspectionConfigPage("inspectionEquipment"),
  inspectionConfigPage("inspectionLocation"),
  employeeConfigPage(),
  trainingConfigPage(),
  locationConfigPage(),
  certificationConfigPage(),
  orgUnitConfigPage(),
  tasksConfigPage(),
  recurrentActionConfigPage(),
  equipmentTypeConfigPage(),
  inspectionTypeConfigPage("inspectionEquipmentType"),
  inspectionTypeConfigPage("inspectionLocationType"),
  locationTypeConfigPage(),
  customPropertyConfigPage("employee"),
  customPropertyConfigPage("equipment"),
  customPropertyConfigPage("location"),
  customPropertyConfigPage("training"),
  customPropertyConfigPage("inspectionEquipment"),
  customPropertyConfigPage("inspectionLocation"),
  issueTypeConfigPage(),
  dashboardConfigPage(),
  webhooksConfigPage(),
];

const getPageByCategoryId = (pageId: CategoryId): BasePage<BaseEntityType> | null => {
  const config = categoryPageConfig().find(page => page.id === pageId);

  if (!config) {
    return null;
  }

  return config;
};

export { categoryPageConfig, getPageByCategoryId };
