import { Layout } from "react-grid-layout";

import { DASHBOARD_FULL_SIZE_BREAKPOINT } from "../../types/constants";
import { Chart } from "../../types/dashboard";

const COMPARE_DATE_RANGE_QUERY = "compareDateRangeQuery";

export const isCompareDateRangeQuery = (queryType?: string) => queryType === COMPARE_DATE_RANGE_QUERY;

export const shouldUpdateChartPosition = (chart: Chart | undefined, newChart: Layout) => {
  return (
    chart?.coordinates.x !== newChart.x ||
    chart?.coordinates.y !== newChart.y ||
    chart?.coordinates.width !== newChart.w ||
    chart?.coordinates.height !== newChart.h
  );
};

const LG_COLUMNS_NUMBER = 6;
const SM_COLUMNS_NUMBER = 3;
const XXS_COLUMNS_NUMBER = 1;

export const needMegration = (charts: Chart[]) => {
  return charts.some(chart => {
    const coordinates = chart.coordinates;
    return coordinates.x + coordinates.width > LG_COLUMNS_NUMBER;
  });
};

export const migrateCoordinates = (charts: Chart[]): Chart[] => {
  const newWidth = 3;
  const newHeight = 2;
  const updatedCharts: Chart[] = [];

  let currentX = 0;
  let currentY = 0;

  charts.forEach(chart => {
    chart.coordinates.width = newWidth;
    chart.coordinates.height = newHeight;

    chart.coordinates.x = currentX;
    chart.coordinates.y = currentY;

    currentX += chart.coordinates.width;
    if (currentX >= LG_COLUMNS_NUMBER) {
      currentX = 0;
      currentY += chart.coordinates.height;
    }

    updatedCharts.push(chart);
  });

  return updatedCharts;
};

export enum BreakPointKey {
  LG = "lg",
  SM = "sm",
  XXS = "xxs",
}

export class BreakpointConfig {
  private static config = {
    [BreakPointKey.LG]: { cols: LG_COLUMNS_NUMBER, breakpoint: DASHBOARD_FULL_SIZE_BREAKPOINT },
    [BreakPointKey.SM]: { cols: SM_COLUMNS_NUMBER, breakpoint: 500 },
    [BreakPointKey.XXS]: { cols: XXS_COLUMNS_NUMBER, breakpoint: 0 },
  };

  private static _colsNumberCache?: Record<string, number>;
  private static _breakpointsCache?: Record<string, number>;

  static getColsNumber(key: BreakPointKey): number {
    return this.config[key]?.cols ?? this.config[BreakPointKey.LG].cols;
  }

  static get colsNumber(): Record<string, number> {
    if (!this._colsNumberCache) {
      this._colsNumberCache = Object.fromEntries(Object.entries(this.config).map(([key, value]) => [key, value.cols]));
    }
    return this._colsNumberCache;
  }

  static get breakpoints(): Record<string, number> {
    if (!this._breakpointsCache) {
      this._breakpointsCache = Object.fromEntries(
        Object.entries(this.config).map(([key, value]) => [key, value.breakpoint])
      );
    }
    return this._breakpointsCache;
  }
}
