import { Typography } from "antd";
import { ReactNode } from "react";

import { ReactComponent as FileIcon } from "../../media/file.svg";
import { ROW_SVG_SIZE } from "../../types/constants";
import { BaseFile } from "../../types/files";
import { openFilePreview } from "../files";

export const showFileIcon = (url: string, fileInfo: Omit<BaseFile, "name">): ReactNode => {
  if (!url) return null;

  const onClick = () => {
    openFilePreview(fileInfo);
  };

  return (
    <Typography.Text className="w-100-col clickable" ellipsis title={url} onClick={onClick}>
      <FileIcon height={ROW_SVG_SIZE} width={ROW_SVG_SIZE} />
    </Typography.Text>
  );
};
