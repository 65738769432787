import { TreeSelect } from "antd";
import { DataNode } from "antd/lib/tree";
import { useMemo } from "react";

import { CubeReview, CubeReviewCategory, CubeReviewQuestion } from "../../../../types/cubeTypes/Review";
import usePagedCubeQuery from "../../../../utils/hooks/usePagedCubeQuery";

const { SHOW_PARENT } = TreeSelect;
type SelectQuestionsProps = {
  value: number[] | undefined;
  onChange: (selection: number[] | undefined) => void;
};
export default function SelectQuestions({ value, onChange }: SelectQuestionsProps) {
  const { resultSet: availableQuestions, loading: isLoading } = usePagedCubeQuery({
    getAllPages: true,
    pageSize: 5000,
    query: {
      dimensions: [
        CubeReview.id,
        CubeReview.name,
        CubeReviewCategory.id,
        CubeReviewCategory.name,
        CubeReviewQuestion.id,
        CubeReviewQuestion.text,
      ],
      segments: [CubeReview.active, CubeReviewCategory.active, CubeReviewQuestion.active],
    },
  });

  type TreeNode = Omit<DataNode, "children"> & { children: TreeNode[] | undefined; value: string | undefined };

  const generateDataNode: (
    key: string,
    value: string | undefined,
    title: string,
    selectable: boolean,
    isLeaf: boolean
  ) => TreeNode = (key, value, title, selectable, isLeaf) => {
    return {
      key: key,
      value: value,
      title: title,
      selectable: selectable,
      checkable: selectable,
      children: isLeaf ? undefined : [],
    };
  };

  enum TreeLevel {
    Review,
    Category,
    Question,
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getKeyByLevel: (entity: any, level: TreeLevel) => string = (entity, level) => {
    switch (level) {
      case TreeLevel.Question: {
        return `R${entity[CubeReview.id]}C${entity[CubeReviewCategory.id]}Q${entity[CubeReviewQuestion.id]}`;
      }
      case TreeLevel.Category: {
        return `R${entity[CubeReview.id]}C${entity[CubeReviewCategory.id]}`;
      }
      case TreeLevel.Review: {
        return `R${entity[CubeReview.id]}`;
      }
      default: {
        return "";
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generateDepth: (entity: any, level: TreeLevel) => TreeNode = (entity, level) => {
    const questionlevel = generateDataNode(
      entity[CubeReviewQuestion.id],
      entity[CubeReviewQuestion.id],
      `${entity[CubeReviewQuestion.text]} - ${entity[CubeReviewQuestion.id]}`,
      true,
      true
    );
    if (level === TreeLevel.Question) {
      return questionlevel;
    }
    const categoryLevel = generateDataNode(
      getKeyByLevel(entity, TreeLevel.Category),
      getKeyByLevel(entity, TreeLevel.Category),
      entity[CubeReviewCategory.name],
      false,
      false
    );
    categoryLevel.children?.push(questionlevel);
    if (level === TreeLevel.Category) {
      return categoryLevel;
    }
    const reviewLevel = generateDataNode(
      getKeyByLevel(entity, TreeLevel.Review),
      getKeyByLevel(entity, TreeLevel.Review),
      entity[CubeReview.name],
      false,
      false
    );
    reviewLevel.children?.push(categoryLevel);
    return reviewLevel;
  };

  const questionData = useMemo(() => {
    const treeData: TreeNode[] = [];
    if (availableQuestions?.rawData() && availableQuestions?.rawData().length > 0)
      availableQuestions?.rawData().forEach(value => {
        if (treeData.length === 0) {
          treeData.push(generateDepth(value, TreeLevel.Review));
        } else {
          const reviewLevel = treeData.find(node => node.key === getKeyByLevel(value, TreeLevel.Review));
          if (!reviewLevel) {
            treeData.push(generateDepth(value, TreeLevel.Review));
          } else {
            const categoryLevel = reviewLevel.children?.find(
              node => node.key === getKeyByLevel(value, TreeLevel.Category)
            );
            if (!categoryLevel) {
              if (!reviewLevel.children) reviewLevel.children = [];
              reviewLevel.children.push(generateDepth(value, TreeLevel.Category));
            } else {
              const questionLevel = categoryLevel.children?.find(
                node => node.key === getKeyByLevel(value, TreeLevel.Question)
              );
              if (!questionLevel) {
                if (!categoryLevel.children) categoryLevel.children = [];
                categoryLevel.children.push(generateDepth(value, TreeLevel.Question));
              }
            }
          }
        }
      });
    return treeData;
  }, [availableQuestions]);

  return (
    <TreeSelect
      treeCheckable
      allowClear
      multiple
      showCheckedStrategy={SHOW_PARENT}
      loading={isLoading}
      placeholder="please select questions"
      treeNodeFilterProp="title"
      value={value}
      onChange={onChange}
      //   defaultValue={questions}
      treeData={questionData}
      autoClearSearchValue={false}
    />
  );
}
