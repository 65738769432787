import dayjs from "dayjs";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { ReactComponent as DatePickerIcon } from "../../../../media/datePicker-icon.svg";
import { ReactComponent as LocationIcon } from "../../../../media/location-icon.svg";
import { locationEntitySelectors } from "../../../../selectors";
import { Equipment } from "../../../../types/equipment";
import { BaseField, InputTypes } from "../../../../types/page";
import {
  constructTableTree,
  fetchEquipmentTypeById,
  fetchLocationById,
  localizeText,
  renderOptionIcon,
} from "../utilities";
import { eqStatusOptions } from "./equipmentConfigPageData";

export const typeIdField: BaseField<Equipment> = {
  id: "typeId",
  title: "FieldType",
  value: entity => entity.typeId,
  valueText: entity => fetchEquipmentTypeById(entity.typeId),
  type: InputTypes.AUTOCOMPLETE,
  required: true,
  placeholder: "Choose",
  optionsSelector: state =>
    (state.equipmentType.data || []).map(et => ({
      id: et.id,
      label: et.name,
    })),
};

export const serialNumberField: BaseField<Equipment> = {
  id: "serialNumber",
  title: "ID",
  value: e => e.serialNumber,
  type: InputTypes.INPUT,
  placeholder: "Enter",
  required: true,
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const nameField: BaseField<Equipment> = {
  id: "name",
  title: "FieldName",
  value: e => e.name,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const statusField: BaseField<Equipment> = {
  id: "status",
  title: "FieldStatus",
  Icon: ({ value }) => renderOptionIcon(value.status),
  value: e => e.status,
  localizedValue: e => localizeText(e.status),
  type: InputTypes.DROPDOWN,
  options: eqStatusOptions,
  optionsSelector: () => null,
  changesConfirmation: {
    body: "ConfirmEquipmentStatusChange",
    okText: "ChangeStatusTo",
    cancelText: "KeepCurrentStatus",
    addValueToBody: true,
    addValueToOkText: true,
  },
};

export const expirationField: BaseField<Equipment> = {
  id: "expiration",
  title: "FieldExpiration",
  Icon: DatePickerIcon,
  value: e => (e.expiration ? dayjs(e.expiration).format(DATE_FORMAT_WITHOUT_TIME) : null),
  type: InputTypes.DATE,
  placeholder: "Undefined",
  optionsSelector: () => null,
  changesConfirmation: {
    body: "EquipmentShouldHaveExpirationDate",
    okText: "KeepWithoutExpiration",
    cancelText: "LeaveCurrentExpiration",
    showConditionally: value => !value,
  },
};

export const locationIdField: BaseField<Equipment> = {
  id: "locationId",
  title: "AppointmentLocationLink",
  Icon: LocationIcon,
  value: e => e.locationId,
  valueText: entity => fetchLocationById(entity.locationId),
  type: InputTypes.TREESELECT,
  treeData: {
    treeDataSelector: locationEntitySelectors.basic,
    treeDataSet: dataSet =>
      constructTableTree({
        data: dataSet,
        itemValueKey: "id",
        itemLabelKey: "name",
      }),
  },
  placeholder: "ROOT",
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "location",
  },
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const primaryFields: BaseField<Equipment>[] = [typeIdField, serialNumberField, nameField, statusField];

export const secondaryFields: BaseField<Equipment>[] = [expirationField, locationIdField];
