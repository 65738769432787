import "../MobileCardTemplate.less";

import { Space } from "antd";
import { useTranslation } from "react-i18next";

import { InspectionSingleView } from "../../../../../types/inspection";
import { CategoryId } from "../../../../../types/page";
import { SHORT_DATE_FORMAT_WITHOUT_TIME } from "../../../../HBComponents/DatePicker/HBDatePicker";
import CardLabel from "../../core/CardLabel/CardLabel";
import CardTitle from "../../core/CardTitle/CardTitle";
import CardWithSelect from "../../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../../core/ContainerWithDivider/ContainerWithDivider";
import LabelWithDate from "../../core/LabelWithDate/LabelWithDate";
import StatusMark from "../../core/StatusMark/StatusMark";

type TProps = {
  entity: InspectionSingleView;
  category: CategoryId;
};

const InspectionMobileCardTemplate: React.FC<TProps> = ({ entity, category }) => {
  const { t } = useTranslation();

  const dateFormat = SHORT_DATE_FORMAT_WITHOUT_TIME;
  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.inspectionTypeName && (
          <CardTitle title={entity.inspectionTypeName} category={category} id={entity.id} />
        )}
        <Space direction="horizontal" size={10} wrap={true}>
          {entity.status && <StatusMark statusLabel={entity.status} />}
          {entity.recurrencyType && <CardLabel label={t(entity.recurrencyType) as string} />}
        </Space>
      </ContainerWithDivider>
      <ContainerWithDivider>
        {entity.expirationDate && (
          <LabelWithDate labelTranslationKey="FieldExpiration" date={entity.expirationDate} dateFormat={dateFormat} />
        )}
        {entity.lastCheck && (
          <LabelWithDate
            labelTranslationKey="PluginPropertyGroupCheck_LastCheck"
            date={entity.lastCheck}
            dateFormat={dateFormat}
          />
        )}
      </ContainerWithDivider>
    </CardWithSelect>
  );
};

export default InspectionMobileCardTemplate;
