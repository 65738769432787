import clsx from "clsx";
import { debounce } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Resizable, ResizableProps } from "react-resizable";
import { RootState } from "../../../store/store";

const ResizableTitle = (props: ResizableProps & { onClick?: React.MouseEventHandler<HTMLTableCellElement> }) => {
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const isRtl = direction === "rtl";

  const { onResize, width, ...restProps } = props;
  const [isDragging, setIsDragging] = useState(false);

  if (!onResize || width === undefined) {
    return <th {...restProps} />;
  }

  const startResize = () => {
    setIsDragging(true);
  };

  const stopResize = debounce(() => {
    setIsDragging(false);
  }, 1000);

  return (
    <Resizable
      width={width}
      height={0}
      onResizeStart={startResize}
      onResizeStop={stopResize}
      handle={
        <span
          className={clsx("react-resizable-handle-container", { rtl: isRtl, ltr: !isRtl })}
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      minConstraints={[100, 100]}
      resizeHandles={isRtl ? ["w"] : ["e"]}
    >
      <th
        {...restProps}
        onClick={e => {
          if (!isDragging) {
            restProps?.onClick?.(e);
          }
        }}
      />
    </Resizable>
  );
};

export default ResizableTitle;
