import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { loginTfa } from "../../../store/slices/user";
import { RootState, useAppDispatch } from "../../../store/store";
import { HBEventName } from "../../../types/analyticsTypes/HBEvent";
import useInitTrackEvents from "../../../utils/hooks/useInitTrackEvents";
import { useLoginContext } from "../context/useLoginContext";

const TfaForm = (): JSX.Element => {
  const { track } = useInitTrackEvents();
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const dispatch = useAppDispatch();

  const { darkBackground, tfaInfo, setTfaInfo, errorMessage, setErrorMessage } = useLoginContext();

  const { t } = useTranslation();

  const handleTfaSubmit = () => {
    if (!jwt) {
      dispatch(loginTfa(tfaInfo))
        .then(unwrapResult)
        .then(() => {
          track({ eventName: HBEventName.Passed2FA });
          track({ eventName: HBEventName.LoginSuccessful });
        })
        .catch(e => {
          track({ eventName: HBEventName.Failed2FA });
          if (e.userError) {
            setErrorMessage(e.userError);
          }
          console.error(e);
        });
    }
  };

  return (
    <Form className="login-wrapper" name="basic" initialValues={{ remember: true }} onFinish={handleTfaSubmit}>
      <div className={`message-info ${darkBackground && "login-text-white"}`}>{t("TFAHeader")}</div>
      <Form.Item className="login-input" data-testid="tfaCodeInput" name="tfaCode">
        <Input
          placeholder={t("HintTfa")}
          onChange={a => setTfaInfo(prev => ({ temporaryJwt: prev.temporaryJwt, tfaCode: a.target.value }))}
          data-testid="tfa-code-input"
        />
      </Form.Item>
      <div className="login-error-message" data-testid="login-error-message">
        {errorMessage.includes(" ") ? errorMessage : t(errorMessage)}
      </div>
      <Row justify="center">
        <Button
          type="primary"
          disabled={!tfaInfo.tfaCode}
          className="button-submit"
          htmlType="submit"
          data-testid="tfa-submit-button"
        >
          {t("LoginTitle")}
        </Button>
      </Row>
    </Form>
  );
};

export default TfaForm;
