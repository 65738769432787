import { Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { UserRole } from "../../../types/user";
import { handleRedirectToLegacy } from "../utility";

enum formSettingsMenuPrivilege {
  HasForm = "hasForm",
  Admin = "admin",
  SuOnly = "suOnly",
}

export const formSettingsMenu = [
  {
    page: "review",
    title: "MenuReview",
    privilege: formSettingsMenuPrivilege.HasForm,
  },
  {
    page: "dictionary",
    title: "FieldDictionary",
    privilege: formSettingsMenuPrivilege.HasForm,
  },
  {
    page: "scancodebatch",
    title: "ScanCodeBatchTitle",
    privilege: formSettingsMenuPrivilege.SuOnly,
  },
  {
    page: "project",
    title: "MenuProject",
    privilege: formSettingsMenuPrivilege.HasForm,
  },
  {
    page: "projectreview",
    title: "ProjectReviewTitle",
    privilege: formSettingsMenuPrivilege.Admin,
  },
  {
    page: "hbtasksetup",
    title: "MenuTaskSetup",
    privilege: formSettingsMenuPrivilege.Admin,
  },
  {
    page: "customreport",
    title: "CustomReportTitle",
    privilege: formSettingsMenuPrivilege.SuOnly,
  },
  {
    page: "userpoints",
    title: "MenuUserPoints",
    privilege: formSettingsMenuPrivilege.SuOnly,
  },
];

const FormSettingsMenu = () => {
  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.user.settings.role);
  const hasForms = useSelector((state: RootState) => state.user.personalSettings.hasForms);
  const filteredFormSettingsMenu =
    userRole !== UserRole.SU && userRole !== UserRole.TenantAdmin && userRole !== UserRole.Admin && hasForms
      ? formSettingsMenu.filter(x => x.privilege === formSettingsMenuPrivilege.HasForm)
      : userRole !== UserRole.SU
      ? formSettingsMenu.filter(x => x.privilege !== formSettingsMenuPrivilege.SuOnly)
      : formSettingsMenu;

  const handleClickMenuItem = (returnUrl: string) => {
    handleRedirectToLegacy({
      returnUrl: returnUrl,
      triggerLoader: false,
      newTab: true,
    });
  };
  return (
    <Menu selectable={false} className="regular-black form-settings-menu">
      {filteredFormSettingsMenu.map(item => {
        return (
          <MenuItem className="form-settings-menu-item" key={item.page} onClick={() => handleClickMenuItem(item.page)}>
            {t(item.title)}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default FormSettingsMenu;
