import { inspectionTypeSelectors } from "../../../selectors";
import { InspectionType, InspectionRecurrencyType, InspectionOwnershipType } from "../../../types/inspectionType";
import { ColumnType, TOption } from "../../../types/page";
import { fetchEmployeeById, fetchOrgUnitById, localizeText, renderBoolStatus } from "./utilities";

export const inspectionRecurrencyTypeOptions: TOption[] = [
  {
    id: InspectionRecurrencyType.Daily,
    label: "Daily",
  },
  {
    id: InspectionRecurrencyType.Weekly,
    label: "Weekly",
  },
  {
    id: InspectionRecurrencyType.Monthly,
    label: "Monthly",
  },
  {
    id: InspectionRecurrencyType.Quarterly,
    label: "Quarterly",
  },
  {
    id: InspectionRecurrencyType.Yearly,
    label: "Yearly",
  },
  {
    id: InspectionRecurrencyType.Fixed,
    label: "Fixed",
  },
];

export const inspectionOwnershipTypeOptions: TOption[] = [
  {
    id: InspectionOwnershipType.EntityOwner,
    label: "EntityOwner",
  },
  {
    id: InspectionOwnershipType.User,
    label: "User",
  },
  {
    id: InspectionOwnershipType.OrgUnit,
    label: "OrgUnit",
  },
];

export const inspectionTypeTableColumns: ColumnType<InspectionType>[] = [
  {
    id: "id",
    label: "ID",
    renderValue: entity => (entity.id && entity.id.toString()) || "",
    sortable: true,
    optionsSelector: () => null,
    // hidden: true,
  },
  {
    id: "name",
    label: "FieldName",
    renderValue: entity => entity.name,
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "recurrencyType",
    label: "FieldRecurrencyType",
    renderValue: entity => localizeText(entity.recurrencyType),
    optionsSelector: () =>
      inspectionRecurrencyTypeOptions.map(option => ({
        id: option.id,
        label: localizeText(option.label),
      })),
    sortable: true,
  },
  {
    id: "recurrency",
    label: "FieldRecurrency",
    renderValue: entity => (entity.recurrency && entity.recurrency.toString()) || "",
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "isReviewRequired",
    label: "FieldIsReviewRequired",
    renderValue: entity => renderBoolStatus(entity.isReviewRequired),
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "reviewId",
    label: "FieldReviewId",
    renderValue: entity => (entity.reviewId && entity.reviewId.toString()) || "",
    optionsSelector: () => null,
    sortable: true,
  },
  {
    id: "orgUnitId",
    label: "FieldOrgUnit",
    renderValue: entity => fetchOrgUnitById(entity.orgUnitId) || null,
    optionsSelector: inspectionTypeSelectors.uniqueOrgUnitIds,
    sortable: true,
  },
  {
    id: "ownershipType",
    label: "FieldOwnershipType",
    renderValue: entity => localizeText(entity.ownershipType),
    optionsSelector: () =>
      inspectionOwnershipTypeOptions.map(option => ({
        id: option.id,
        label: localizeText(option.label),
      })),
    sortable: true,
  },
  {
    id: "userId",
    label: "FieldUser",
    renderValue: entity => fetchEmployeeById(entity.userId) || null,
    optionsSelector: inspectionTypeSelectors.uniqueUserIds,
    sortable: true,
  },
];
