import { Tabs } from "antd";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import PageConfigurationContext from "../../../context/pageContext";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage, Tab } from "../../../types/page";
import {
  ABOUT_TAB_KEY,
  ENTITIES_WITHOUT_ABOUT_TAB,
  useMobileSingleViewContext,
} from "../context/useMobileSingleViewContext";
import { MobileSingleViewTabProvider } from "../context/useMobileSingleViewTabContext";
import CardList from "./CardList";
import SingleViewCardMobileAboutTab from "./SingleViewCardMobileAboutTab";

const { TabPane } = Tabs;

const SingleViewMobileTabs: FC = (): JSX.Element => {
  const { isNewEntity, isEdited, activeTab, handleSetActiveTab, filteredTabs } = useMobileSingleViewContext();

  const { t } = useTranslation();
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;

  // in case of new entity we should not show all tabs
  if (isNewEntity || isEdited) {
    return <SingleViewCardMobileAboutTab />;
  }

  // disable tabs for other entities
  if (
    pageConfig.id !== "employee" &&
    pageConfig.id !== "actions" &&
    pageConfig.id !== "equipment" &&
    pageConfig.id !== "inspectionEquipment" &&
    pageConfig.id !== "inspectionLocation" &&
    pageConfig.id !== "location" &&
    pageConfig.id !== "training" &&
    pageConfig.id !== "orgUnit" &&
    pageConfig.id !== "issueType" &&
    pageConfig.id !== "certification"
  ) {
    return <SingleViewCardMobileAboutTab />;
  }

  const getTabs = () => {
    const nodes = [];
    if (!ENTITIES_WITHOUT_ABOUT_TAB.includes(pageConfig.id)) {
      nodes.push(
        <TabPane tab={t(ABOUT_TAB_KEY)} key={ABOUT_TAB_KEY}>
          <SingleViewCardMobileAboutTab />
        </TabPane>
      );
    }
    filteredTabs.forEach(tab =>
      nodes.push(
        <TabPane tab={t(tab.label)} key={tab.key}>
          {renderContent(tab)}
        </TabPane>
      )
    );

    return nodes;
  };

  const renderContent = (tab: Tab<Record<string, unknown>, Record<string, unknown>>) => {
    return (
      <MobileSingleViewTabProvider tabConfig={tab}>
        <CardList />
      </MobileSingleViewTabProvider>
    );
  };

  return (
    <>
      <Tabs
        className="single-view-tabs"
        onTabClick={handleSetActiveTab}
        activeKey={activeTab}
        renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile={false} />}
      >
        {getTabs()}
      </Tabs>
    </>
  );
};

export default SingleViewMobileTabs;
