/* eslint-disable @typescript-eslint/no-explicit-any */
import { unwrapResult } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PageConfigurationContext from "../../context/pageContext";
import { getEmptyValue } from "../../pages/pageConfig/category/utilities";
import { filterSelector } from "../../selectors";
import {
  addFilterIds,
  initializeFiltersFromDefaults,
  initializeStoredUserFilters,
  slice,
  storeUserFilters,
} from "../../store/slices/filter";
import { RootState, useAppDispatch } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { UserSettingsConstants } from "../../types/constants";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryId, CategoryPage } from "../../types/page";
import { AdditionalProps, ParamsKeys } from "../../types/utility";
import useInitTrackEvents from "./useInitTrackEvents";
import useRouter from "./useRouter";

const filterActions = slice.actions;

export type FieldType = "dateRange" | "date" | "text";

type onFilterChangeProps = {
  key: string;
  value: FilterValueType;
  filterType: FilterType;
  fieldType?: FieldType;
  label?: string;
  textValue?: string;
  scope?: FilterScope;
  isBlank?: boolean;
  additionalInfo?: any;
};

type ReturnType = {
  promoteStagingToActive: () => void;
  filterData: <TEntity>(data: TEntity[]) => TEntity[];
  onFilterRemove: (key: string, value?: FilterValueType, scope?: FilterScope) => void;
  onFilterChange: ({ key, value, filterType, fieldType, label, textValue, scope }: onFilterChangeProps) => void;
  resetFilters: (scope: FilterScope) => void;
  // FIXME: add basic entity type
  filters: Filter[];
  filtersReady: boolean;
};

type FilterEntityType =
  | "HbTask"
  | "Location"
  | "User"
  | "OrgUnit"
  | "Equipment"
  | "IssueType"
  | "Project"
  | "Review"
  | "InspectionType"
  | "EquipmentType"
  | "LocationType"
  | "Inspection"
  | "Certificate"
  | "Training"
  | "CustomPropertyValue";

type FilterType = "equals" | "includes" | "lessThan" | "moreThan" | "equalsBool" | "tags";

enum FilterScope {
  LIVE = "live",
  STAGE = "stage",
  IDS = "ids",
}

type FilterValueType = string | string[] | number | number[] | null | boolean;

type Filter = {
  /**
   * Key of the filter
   */
  key: string;
  /**
   * Text representation for the key (e.g. Location, Type, etc.)
   */
  label: string;
  /**
   * Usually ID representation of the value (e.g. 1,2,3 etc.) coming from the 'key'
   */
  value: FilterValueType;
  /**
   * Text representation for the value (if available and if the key ends with 'Id')
   */
  textValue: string | null;
  filterType: FilterType;
  fieldType?: FieldType;
  isBlank?: boolean;
  additionalInfo?: any;
};

export type DefaultFilter = Omit<Filter, "value" | "textValue"> & {
  value?: FilterValueType;
  textValue?: string;
  userBasedValue?: (state: RootState) => string;
  userBasedTextValue?: (state: RootState) => string;
  scope?: FilterScope;
};

type OnFilterChangedHandler = <TValue>(key: string, value: TValue, type: FilterType, scope: FilterScope) => void;

const filterTypeFunctions: {
  [index in FilterType]: (filterValue: FilterValueType, itemValue: unknown, isBlank?: boolean) => boolean;
} = {
  equals: (filterValue: unknown, itemValue: unknown) => {
    if (!itemValue && typeof itemValue !== "boolean") {
      return false;
    }

    if (filterValue === null) {
      return itemValue === filterValue;
    }

    const result = filterValue
      ? (itemValue as string | number).toString().toLowerCase() ===
        (filterValue as string | number).toString().toLowerCase()
      : true;

    return result;
  },

  equalsBool: (filterValue: unknown, itemValue: unknown) => {
    return filterValue === itemValue;
  },

  includes: (filterValue: unknown | unknown[], itemValue: unknown | unknown[]) => {
    if (Array.isArray(filterValue) && !Array.isArray(itemValue)) {
      return filterValue
        .map(i => i.toString())
        .some(f => f.toLowerCase() === (itemValue as string | number).toString().toLowerCase());
    }
    if (!Array.isArray(filterValue) && !Array.isArray(itemValue)) {
      return (itemValue as string | number)
        .toString()
        .toLowerCase()
        .includes((filterValue as string | number).toString().toLowerCase());
    }

    if (Array.isArray(filterValue) && Array.isArray(itemValue)) {
      const formattedItemValues = itemValue.map(i => i.toString().toLowerCase());
      const formattedFilterValues = filterValue.map(i => i.toString().toLowerCase());

      return formattedFilterValues.some(i => formattedItemValues.includes(i));
    }

    if (!Array.isArray(filterValue) && Array.isArray(itemValue)) {
      const formattedItems = itemValue.map(i => i.toString().toLowerCase());
      const formattedFilter = (filterValue as string | number).toString().toLowerCase();
      return formattedItems.some(f => f === formattedFilter);
    }

    return false;
  },
  lessThan: (filterValue: unknown, itemValue: unknown, isBlank?: boolean) => {
    if (isBlank && !itemValue) return true;
    if (typeof itemValue === "string" && typeof filterValue === "string" && dayjs(itemValue).isValid()) {
      const newValue = dayjs(itemValue).startOf("day");
      const oldValue = dayjs(filterValue).startOf("day");

      return newValue.isSame(oldValue) || newValue.isBefore(oldValue);
    }
    return (itemValue as number) <= (filterValue as number);
  },
  moreThan: (filterValue: unknown, itemValue: unknown, isBlank?: boolean) => {
    if (isBlank && !itemValue) return true;
    if (typeof itemValue === "string" && typeof filterValue === "string" && dayjs(itemValue).isValid()) {
      const newValue = dayjs(itemValue).startOf("day");
      const oldValue = dayjs(filterValue).startOf("day");

      return newValue.isSame(oldValue) || newValue.isAfter(oldValue);
    }

    return (itemValue as number) >= (filterValue as number);
  },
  tags: (filterValue: unknown, itemValue: unknown) => {
    if (typeof itemValue === "string" && typeof filterValue === "string") {
      return itemValue.toLowerCase().includes(filterValue.toLowerCase());
    }
    return false;
  },
};

const doFilterData = <TEntity>(data: TEntity[], filters: Filter[]): TEntity[] => {
  let filteredData = data as Record<string, unknown>[];

  if (!filters || filters.length === 0) {
    return filteredData as TEntity[];
  }

  if (!filteredData || filteredData.length === 0) {
    return [] as TEntity[];
  }

  const groupedFilters: Record<string, string[] | number[] | boolean[]> = {};
  for (const filter of filters) {
    if (!groupedFilters[filter.key]) {
      groupedFilters[filter.key] = [filter.value] as string[];
    } else {
      groupedFilters[filter.key] = [...groupedFilters[filter.key], filter.value] as string[] | number[] | boolean[];
    }
  }
  // debugger;

  filteredData = filteredData.filter(item => {
    let doesMeetConditions = true;
    // debugger;/ TODO: Null filter not working
    // Note: We need to compare the item agains ALL the applied filters and return true/false depending on that
    for (const filter of filters) {
      const customProps = item["customPropertyValues"] as AdditionalProps[]; // Note: for filtering through the customProps as well.
      const itemValue =
        item[filter.key] === undefined ? customProps?.find(prop => prop.name === filter.key)?.value : item[filter.key]; // Note: After the '||' is for filtering through the customProps as well.

      if (
        !filterTypeFunctions[filter.filterType](filter.value, itemValue, filter.isBlank) &&
        !(groupedFilters[filter.key].includes(itemValue as never) || (filter.isBlank && !itemValue))
      ) {
        doesMeetConditions = false;
      }
      if (!filterTypeFunctions[filter.filterType](filter.value, itemValue) && filter.filterType === "tags") {
        doesMeetConditions = false;
      }
      if (groupedFilters[filter.key] && groupedFilters[filter.key][0] === getEmptyValue().key && !itemValue) {
        doesMeetConditions = true;
      }
    }
    return doesMeetConditions;
  });
  return filteredData as TEntity[];
};

const useFilters = (pageId: CategoryId, entityKey: string, init?: true): ReturnType => {
  const dispatch = useAppDispatch();
  const { track } = useInitTrackEvents();
  const { pathname, location } = useRouter();
  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const { defaultFilters } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  // debugger;
  const filters = useSelector(filterSelector(pageId, entityKey));
  const loading = useSelector((state: RootState) => state.filter.loading);

  useEffect(() => {
    if (init && !loading) {
      setFiltersReady(false);
      dispatch(initializeStoredUserFilters({ entityKey: entityKey, pageId: pageId }))
        .then(response => unwrapResult(response))
        .then(response => {
          if (defaultFilters && !Array.isArray(response)) {
            dispatch(
              initializeFiltersFromDefaults({ defaultFilters: defaultFilters, entityKey: entityKey, pageId: pageId })
            ).then(() => setFiltersReady(true));
          } else {
            setFiltersReady(true);
          }
        })
        .catch(() => setFiltersReady(true));

      const urlSearchParams = new URLSearchParams(location.search);
      const filtersQuery = urlSearchParams.get(ParamsKeys.FILTERS_SEARCH_PREFIX);

      if (filtersQuery && pathname.replace("/", "") === pageId) {
        const filterDecodeString: string = jwtDecode(filtersQuery);
        const filterObj = JSON.parse(filterDecodeString);
        setFiltersFromUrl(filterObj.activeFilters);
      }

      const idsQuery = urlSearchParams.get(ParamsKeys.FILTERS_IDS_PREFIX);
      if (idsQuery && pathname.replace("/", "") === pageId) {
        const ids: any = jwtDecode(idsQuery);
        const filterObj: Filter[] = ids.map((id: string) => ({ key: "id", value: id }));
        dispatch(addFilterIds({ filter: filterObj, pageId, entityKey }));
      }
    }
  }, [pageId]);

  const [filtersFromUrl, setFiltersFromUrl] = useState<Filter[]>();
  useEffect(() => {
    if (location.search) {
      filtersFromUrl?.forEach(e => {
        if (!filters?.find(x => x.key === e.key))
          onFilterChange({
            filterType: e.filterType,
            key: e.key,
            label: e.label,
            textValue: e.textValue as string,
            value: e.value,
          });
      });
    }
  }, [pageId, filtersFromUrl]);

  const onFilterRemove = (key: string, value?: FilterValueType, scope: FilterScope = FilterScope.LIVE) => {
    dispatch(
      storeUserFilters({
        method: "post",
        newValues: {
          key: pageId + UserSettingsConstants.FILTER_KEYS_SUFFIX,
          value: [...(filters?.filter(f => !(f.key === key)) ?? [])],
        },
      })
    );
    dispatch(
      scope === FilterScope.LIVE
        ? filterActions.removeActive({ key, value, pageId, entityKey })
        : filterActions.removeStaging({ key, value, pageId, entityKey })
    );
  };

  const onFilterChange = ({
    key,
    value,
    filterType,
    fieldType = "text",
    label,
    textValue,
    scope = FilterScope.LIVE,
    isBlank,
    additionalInfo,
  }: onFilterChangeProps) => {
    // debugger;
    const payload = { key, value, filterType, fieldType, label, textValue, isBlank, additionalInfo } as Filter;

    if (!value && value !== false && value !== null) {
      onFilterRemove(key, scope);

      return;
    }

    dispatch(
      storeUserFilters({
        method: "post",
        newValues: { key: pageId + UserSettingsConstants.FILTER_KEYS_SUFFIX, value: [...(filters ?? []), payload] },
      })
    );

    dispatch(
      scope === FilterScope.LIVE
        ? filterActions.addActive({ filter: payload, pageId, entityKey })
        : filterActions.addStaging({ filter: payload, pageId, entityKey })
    );
  };

  function filterData<TEntity>(data: TEntity[]) {
    track({ eventName: HBEventName.FilterEntityList, data: { currentFilters: filters } });
    return doFilterData(data, filters);
  }

  const resetFilters = (scope: FilterScope) => {
    switch (scope) {
      case FilterScope.IDS:
        dispatch(filterActions.resetIdsFilters({ pageId, entityKey }));
        break;
      case FilterScope.LIVE:
        dispatch(filterActions.resetActiveFilters({ pageId, entityKey }));
        break;
      default:
        dispatch(filterActions.resetStagingFilters());
        break;
    }
    dispatch(
      storeUserFilters({
        method: "post",
        newValues: {
          key: pageId + UserSettingsConstants.FILTER_KEYS_SUFFIX,
          value: [],
        },
      })
    );
  };

  const promoteStagingToActive = () => dispatch(filterActions.promoteStagingFilters());

  return {
    promoteStagingToActive,
    filterData,
    onFilterRemove,
    onFilterChange,
    resetFilters,
    filters,
    filtersReady,
  };
};

export default useFilters;

export type { Filter, FilterType, OnFilterChangedHandler, FilterValueType, FilterEntityType };
export { FilterScope };
