import "../MobileCardTemplate.less";

import {
  certificationAccountableIsAssignedToOUBranchField,
  certificationAccountableRoleField,
} from "../../../../../pages/pageConfig/category/certification/certificationConfigPageData";
import { MobileCardTemplateTProps } from "../../../../../types/page";
import { PrivilegeData } from "../../../../../types/utility";
import AccountableMobileCardTemplateWithSelection from "./AccountableMobileCardTemplateWithSelection";

const CertificationAccountableMobileCardTemplate: React.FC<MobileCardTemplateTProps<PrivilegeData>> = ({ entity }) => {
  const roleSelectConfig = certificationAccountableRoleField;
  const isAssignedToOUBranchSelectConfig = certificationAccountableIsAssignedToOUBranchField;

  return (
    <AccountableMobileCardTemplateWithSelection
      entity={entity}
      roleSelectConfig={roleSelectConfig}
      isAssignedToOUBranchSelectConfig={isAssignedToOUBranchSelectConfig}
    />
  );
};

export default CertificationAccountableMobileCardTemplate;
