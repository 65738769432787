import "./addFilterComponent.less";

import { Button, Popover, Tag } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PageConfigurationContext from "../../../context/pageContext";
import { idsFilterSelector } from "../../../selectors";
import { RootState } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage, ColumnType } from "../../../types/page";
import { NoValueKey } from "../../../types/utility";
import useDebounce from "../../../utils/hooks/useDebounce";
import useFilters, { FilterScope, FilterType } from "../../../utils/hooks/useFilter";
import { DATE_FORMAT_WITHOUT_TIME } from "../../HBComponents/DatePicker/HBDatePicker";
import { HBSelect } from "../../HBComponents/Select/HBSelect";
import FilterRenderer from "./FilterRenderer";

interface Props {
  entityKey: string;
  children?: React.ReactNode;
}

interface LocalFilter {
  id: string;
  label: string;
  type?: FilterType;
  values?: (string | string[] | number | number[] | null | boolean)[];
}

const addPopoverFlag = "addPopover";

const AddFilterStripeComponent: React.FC<Props> = ({ entityKey, children }) => {
  const [localFilters, setLocalFilters] = useState<LocalFilter[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<ColumnType<Record<string, unknown>> | null>(null);
  const { t } = useTranslation();
  const { listView, id, customPropertiesSelector } = useContext(
    PageConfigurationContext
  ) as CategoryPage<BaseEntityType>;
  const defaultCustomProperties = useSelector(customPropertiesSelector);
  const data = useSelector(listView?.table?.tableSelector ? listView?.table.tableSelector : () => []);
  const { filters, resetFilters, onFilterRemove } = useFilters(id, entityKey);
  const [visiblePopover, setVisiblePopover] = useState<string | null>(null);
  const [visibleAddPopover, setVisibleAddPopover] = useState<string | null>(null);
  const prevFiltersLengthRef = useRef(localFilters.length);
  const [runEffect, setRunEffect] = useState(false);
  const [popoverContent, setPopoverContent] = useState<Record<string, JSX.Element>>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const popoverPlacement = useMemo(() => {
    return direction === "ltr" ? "bottomLeft" : "bottomRight";
  }, [direction]);

  const idsFilters = useSelector(idsFilterSelector(id, entityKey));

  useEffect(() => {
    const newContent: Record<string, JSX.Element> = {};
    localFilters.forEach(filter => {
      if (selectedColumn && filter.id === selectedColumn.id) {
        newContent[filter.id] = (
          <FilterRenderer
            className="filter-popover-content"
            defaultOpen={false}
            entityKey={id}
            id={selectedColumn.id}
            type={selectedColumn.filterType!}
            label={selectedColumn.label}
            optionSelector={selectedColumn.optionsSelector}
            filterOpen={!!visiblePopover}
            asyncFetchFilterOptionsEntityType={selectedColumn.asyncFetchFilterOptionsEntityType}
            asyncFetchFilterOptionsUseKeyValue={selectedColumn.asyncFetchFilterOptionsUseKeyValue}
            asyncFetchFilterOptionsIsId={selectedColumn.asyncFetchFilterOptionsIsId}
            propName={selectedColumn.propName || selectedColumn.id}
            customPropInfo={selectedColumn.cutomPropInfo}
          />
        );
      }
    });
    setPopoverContent(newContent);
  }, [selectedColumn, localFilters, visiblePopover]);

  const prevId = useRef(id);
  useEffect(() => {
    setLocalFilters(prev => {
      const filterMap: { [key: string]: LocalFilter } = {};

      filters?.forEach(filter => {
        if (filterMap[filter.key]) {
          filterMap[filter.key].values?.push(filter.value);
        } else {
          filterMap[filter.key] = {
            id: filter.key,
            label: filter.label,
            values: [filter.textValue ?? filter.value],
            type: filter.filterType,
          };
        }
      });

      const updatedFilters: LocalFilter[] = [];

      if (prevId.current === id) {
        prev.forEach(filter => {
          if (filterMap[filter.id]) {
            updatedFilters.push(filterMap[filter.id]);
            delete filterMap[filter.id];
          } else {
            updatedFilters.push({ ...filter, values: [] });
          }
        });
      }
      updatedFilters.push(...Object.values(filterMap));

      prevId.current = id;
      return updatedFilters;
    });
  }, [filters]);

  useEffect(() => {
    setRunEffect(true);
  }, [localFilters]);

  useEffect(() => {
    if (!runEffect) {
      return;
    }

    if (localFilters.length > prevFiltersLengthRef.current && selectedColumn && !visiblePopover) {
      const lastFilter = localFilters[localFilters.length - 1];
      setVisiblePopover(lastFilter.id);
    }

    prevFiltersLengthRef.current = localFilters.length;

    setRunEffect(false);
  }, [localFilters, runEffect]);

  const handleClose = useCallback(
    (removedTag: string) => {
      const newFilters = localFilters.filter(tag => tag.id !== removedTag);
      setLocalFilters(newFilters);
      onFilterRemove(removedTag);
    },
    [localFilters, onFilterRemove]
  );

  const columns = useMemo(
    () => [
      ...(listView?.table.columns || []),
      ...(listView?.generateDynamicColumns(defaultCustomProperties, data) || []),
    ],
    [listView, defaultCustomProperties, data]
  );

  const handleCheckboxChange = useCallback(
    (value: string, checked: boolean) => {
      if (checked || (!checked && localFilters.length === 1)) {
        setVisibleAddPopover(null);
      }
      setTimeout(() => {
        const selectedCol = columns.find(column => column.id === value);
        if (selectedCol) {
          setSelectedColumn(selectedCol);
        }
        if (checked && !localFilters.some(filter => filter.id === value)) {
          setLocalFilters([
            ...localFilters,
            {
              id: value,
              label: selectedCol?.label || "",
              type: selectedCol?.filterType === "dateRange" ? "lessThan" : "equals",
            },
          ]);
        } else if (!checked && localFilters.some(filter => filter.id === value)) {
          handleClose(t(value));
        }
      }, 200);
    },
    [localFilters, listView, handleClose, t]
  );

  const handleClearFilters = useCallback(() => {
    resetFilters(FilterScope.LIVE);
    setLocalFilters([]);
  }, [resetFilters]);

  const options = useMemo(() => {
    return columns.filter(x => x.filterType).map(column => ({ id: column.id, label: t(column.label) })) || [];
  }, [listView, t, columns]);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const filteredOptions = useMemo(() => {
    return options.filter(option => option.label.toLowerCase().includes(debouncedSearchValue.toLowerCase()));
  }, [options, debouncedSearchValue]);

  const handleTagClick = useCallback(
    (filterId: string) => () => {
      const selectedCol = columns.find(column => column.id === filterId);
      if (selectedCol) {
        setSelectedColumn(selectedCol);
        setVisiblePopover(filterId);
      }
    },
    [listView, columns]
  );

  useEffect(() => {
    setSelectedColumn(null);
    setVisiblePopover(null);
  }, [id]);

  const handleCloseWithId = useCallback(
    (id: string) => () => {
      handleClose(id);
    },
    [handleClose]
  );

  const handleClearIdsFilters = () => {
    resetFilters(FilterScope.IDS);
  };

  const formatDate = useCallback((date: string) => dayjs(date).local().format(DATE_FORMAT_WITHOUT_TIME), []);

  const formatValue = (value: string, type?: string) => {
    if (type === "lessThan" || type === "moreThan") {
      return formatDate(value);
    }
    if (value === NoValueKey) {
      return t("NoValue");
    }
    return value;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatValues = useCallback((values: any[], type?: string, chunk?: boolean) => {
    let firstValue = formatValue(values[0], type);

    if (chunk) {
      firstValue = firstValue.length > 25 ? `${firstValue.substring(0, 25)}...` : firstValue;
    }

    if (values.length > 1) {
      if (type === "lessThan" || type === "moreThan") {
        return `${firstValue} - ${formatDate(values[1])}`;
      }

      return `${firstValue} +${values.length - 1}`;
    }
    return firstValue;
  }, []);

  if (!options.length) {
    return <></>;
  }

  return (
    <div className="filter-bar-container">
      <span className="search-box"> {children}</span>
      {!!idsFilters?.length && (
        <Tag className="search results-container" closable onClose={handleClearIdsFilters}>
          <span className="filter-label">{t("Results")}</span>
        </Tag>
      )}
      {localFilters.map(filter => (
        <Popover
          key={filter.id}
          content={popoverContent[filter.id]}
          trigger="click"
          placement={popoverPlacement}
          open={visiblePopover === filter.id}
          onOpenChange={visible => {
            if (visible) {
              setVisibleAddPopover(null);
              setVisiblePopover(filter.id);
            } else {
              setVisiblePopover(null);
            }
          }}
        >
          <Tag
            onClick={handleTagClick(filter.id)}
            key={filter.id}
            className="filter-bar-tag"
            closable
            onClose={handleCloseWithId(filter.id)}
          >
            <span className={`filter-label ${direction}`} title={t(filter.label)}>
              <span>
                {t(filter.label)}
                {filter?.values?.length ? (
                  <>
                    <>&nbsp;:&nbsp;</>
                    <span title={formatValues(filter.values, filter?.type)} className={`filter-value ${direction}`}>
                      {formatValues(filter.values, filter?.type, true)}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </span>
            </span>
          </Tag>
        </Popover>
      ))}
      <Popover
        placement={popoverPlacement}
        open={visibleAddPopover === addPopoverFlag}
        onOpenChange={visible => {
          if (visible) {
            setVisiblePopover(null);
            setVisibleAddPopover(addPopoverFlag);
          } else {
            setSearchValue("");
            setVisibleAddPopover(null);
          }
        }}
        content={
          visibleAddPopover === addPopoverFlag && (
            <HBSelect
              showClear={false}
              options={filteredOptions.sort((a, b) => a.label.localeCompare(b.label))}
              onChange={e => e && handleCheckboxChange(e?.toString(), true)}
              onSearch={setSearchValue}
              defaultOpen={false}
              onDeselect={(e, o) => o && handleCheckboxChange(o?.toString(), false)}
              id={id}
              label={"AddFilter"}
              loading={false}
              open={!!visibleAddPopover}
              value={localFilters.map(filter => filter.id)}
            />
          )
        }
        trigger="click"
      >
        <Button className="add-filter-button">+ {t("AddFilter")}</Button>
      </Popover>
      {localFilters.length > 0 && (
        <Button className="clear-filter-button" onClick={handleClearFilters}>
          {t("ClearFilters")}
        </Button>
      )}
    </div>
  );
};

export default AddFilterStripeComponent;
