import { Modal } from "antd";
import { RcFile } from "antd/lib/upload";
import dayjs, { Dayjs } from "dayjs";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HBUploadModal from "../../components/HBComponents/UploadModal/HBUploadModal";
import { ReactComponent as ArrowsIcon } from "../../media/arrows.svg";
import { ReactComponent as ClockIcon } from "../../media/clock.svg";
import { changeCheckpointStatus } from "../../store/slices/inspection";
import { openNewAppointment, replaceFilteredData } from "../../store/slices/todolist";
import { RootState, useAppDispatch } from "../../store/store";
import { InspectionCheckpoint, InspectionRecurrencyType, InspectionStatus } from "../../types/inspection";
import { InspectionLinkedEntityType, TodoListInpection, TodoListSectioned } from "../../types/todolist";
import { UploadModalType } from "../../types/utility";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useRouter from "../../utils/hooks/useRouter";
import { renderInspectionStatusIcon } from "../pageConfig/category/inspection/inspectionConfigPageData";

interface InspectionCardProps {
  x: TodoListInpection;
}

const InspectionCard = ({ x }: InspectionCardProps) => {
  const dispatch = useAppDispatch();
  const { history } = useRouter();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { filteredTodoList } = useSelector((state: RootState) => state.todoList);
  const isMobile = useIsMobile();
  const handleOpenModal = () => setOpenModal(!openModal);

  const onInspectionClick = (
    inspectionEquipmentId: number,
    linkedEntityType: InspectionLinkedEntityType,
    status: InspectionStatus,
    inspectionId?: number
  ) => () => {
    status !== InspectionStatus.Passed
      ? dispatch(openNewAppointment({ inspectionEquipmentId, linkedEntityType }))
      : isMobile
      ? Modal.confirm({
          className: "re-evaluate-modal",
          content: t("ReEvaluateModalText"),
          okText: t("ReEvaluateButton"),
          cancelText: t("ReEvaluateCloseButton"),
          onOk: () => {
            dispatch(openNewAppointment({ inspectionEquipmentId, linkedEntityType }));
          },
        })
      : history.replace(`/inspection${linkedEntityType}/${inspectionId}`);
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs(new Date());
  };

  const uploadFile = (comment: string, status?: InspectionStatus, date?: string) => async (
    file: RcFile
  ): Promise<void | File | Blob | boolean> => {
    setOpenModal(false);
    dispatch(
      changeCheckpointStatus({
        file: file,
        status: status,
        comment: comment,
        entityId: x.id,
        isLocationExists: x.linkedEntityType === InspectionLinkedEntityType.Location,
        completedAt: date ?? "",
      })
    ).then(y => {
      const checkpointStatus = (y.payload as { checkpoint: InspectionCheckpoint | null })?.checkpoint?.status;
      if (checkpointStatus) {
        const changedInspection = { ...x, status: checkpointStatus };

        if (filteredTodoList) {
          const data = JSON.parse(JSON.stringify(filteredTodoList)) as TodoListSectioned;

          Object.keys(data).map(section => {
            const typedSection = section as keyof TodoListSectioned;
            data[typedSection] = {
              appointments: data[typedSection].appointments,
              inspections: (() => {
                const foundIndex = data[typedSection].inspections.findIndex(insp => insp.id === x.id);
                if (foundIndex >= 0) {
                  data[typedSection].inspections[foundIndex] = { ...changedInspection };
                }
                return data[typedSection].inspections;
              })(),
              tasks: data[typedSection].tasks,
            };
          });

          dispatch(replaceFilteredData(data));
        }
      }
    });
    return true;
  };
  return (
    <>
      <div
        className={`todo-cart ${(x.reviewId && x.id) || (!x.reviewId && x.id) ? "todo-cart-pointer" : ""} ${
          !x.id ? "todo-cart-grey" : ""
        }`}
        onClick={
          x.reviewId
            ? onInspectionClick(x.id, x.linkedEntityType, x.status, x.inspectionId)
            : x.id
            ? handleOpenModal
            : undefined
        }
      >
        <div className="cart-content">
          <div className="cart-title">
            {x.inspectionTypeName} - {x.mappedEntityName}
          </div>
          <div className="recurrency">
            {t("Inspection")}
            {" | "}
            <ArrowsIcon />
            {t(x.recurrencyType)}
          </div>
          <div className="date-wrapper">
            <ClockIcon className="p-2" />
            <span className="date">{x.endPeriod}</span>
          </div>
        </div>
        <div className="status">
          {t(x.status)}
          {renderInspectionStatusIcon(x.status)}
        </div>
      </div>
      <HBUploadModal
        container="#todo-list"
        isVisible={openModal}
        closeModal={handleOpenModal}
        beforeUpload={uploadFile}
        uploadModalType={
          x.recurrencyType === InspectionRecurrencyType.Fixed
            ? UploadModalType.TodoInspectionCheckpointFixed
            : UploadModalType.TodoInspectionCheckpointNotFixed
        }
        disabledDate={disabledDate}
      />
    </>
  );
};

export default memo(InspectionCard);
