import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import { CubeDimension, CubeDimensionsState, TDimensionResponse } from "../../types/cubeDimensions";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";

export const initialState: CubeDimensionsState = {
  dimensions: [],
  isLoading: false,
};

export const fetchDimensions = createAsyncThunk<TDimensionResponse, void, { state: RootState }>(
  "@@CUBE_DIMENSIONS/DIMENSIONS",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<TDimensionResponse>(
      `/Dimension/${user.settings.companyId}`,
      hbApiOptions(user.jwt)
    );
    const modifiedResponse: TDimensionResponse = response.data;

    return Array.isArray(modifiedResponse) ? modifiedResponse : [];
  }
);
export const createDimension = createAsyncThunk<TDimensionResponse, CubeDimension, { state: RootState }>(
  "@@CUBE_DIMENSIONS/CREATE_DIMENSION",
  async ({ name, joins, questionIds }, { getState }) => {
    const { user } = getState();
    const { data: dimension } = await hbApi.post<CubeDimension>(
      "/Dimension",
      { name: name, companyId: user.settings.companyId, questionIds: questionIds },
      hbApiOptions(user.jwt)
    );
    if (dimension) {
      joins.forEach(join => (join.dimensionId = dimension.id));
      await hbApi.put<CubeDimension>("/Dimension/write-joined-dimension", joins, hbApiOptions(user.jwt));
    }
    const response = await hbApi.get<TDimensionResponse>(
      `/Dimension/${user.settings.companyId}`,
      hbApiOptions(user.jwt)
    );
    return Array.isArray(response.data) ? response.data : [];
  }
);

export const updateDimension = createAsyncThunk<TDimensionResponse, CubeDimension, { state: RootState }>(
  "@@CUBE_DIMENSIONS/UPDATE_DIMENSION",
  async ({ id, name, joins, questionIds }, { getState }) => {
    const { user, cubeDimensions } = getState();
    const currentDimension = cubeDimensions.dimensions.find(d => d.id === id);

    const toAdd = questionIds.filter(q => !currentDimension?.questionIds.includes(q));
    const toRemove = currentDimension?.questionIds.filter(q => !questionIds.includes(q));
    await hbApi.put<CubeDimension>(
      "/Dimension",
      {
        dimensionId: id,
        dimensionName: name,
        questions: toAdd.concat(toRemove!),
      },
      hbApiOptions(user.jwt)
    );
    const joinsToRemove = currentDimension?.joins
      .filter(j => !joins.find(join => join.joinedDimensionId === j.joinedDimensionId))
      .map(j => {
        return {
          dimensionId: id,
          joinedDimensionId: j.joinedDimensionId,
        };
      });
    await hbApi.put<CubeDimension>("/Dimension/write-joined-dimension", joinsToRemove, hbApiOptions(user.jwt));
    await hbApi.put<CubeDimension>(
      "/Dimension/write-joined-dimension",
      joins.map(j => {
        const tmp = cloneDeep(j);
        tmp["dimensionId"] = id;
        return tmp;
      }),
      hbApiOptions(user.jwt)
    );
    const response = await hbApi.get<TDimensionResponse>(
      `/Dimension/${user.settings.companyId}`,
      hbApiOptions(user.jwt)
    );
    return Array.isArray(response.data) ? response.data : [];
  }
);

export const deleteDimension = createAsyncThunk<TDimensionResponse, number, { state: RootState }>(
  "@@CUBE_DIMENSIONS/DELETE_DIMENSION",
  async (dimensionId, { getState }) => {
    const { user, cubeDimensions } = getState();
    await hbApi.delete<number>(`/Dimension/${dimensionId}`, hbApiOptions(user.jwt));
    const joins = cubeDimensions.dimensions
      .find(d => d.id === dimensionId)
      ?.joins.map(join => {
        return {
          dimensionId: dimensionId,
          joinedDimensionId: join.joinedDimensionId,
        };
      });
    await hbApi.put<CubeDimension>("/Dimension/write-joined-dimension", joins, hbApiOptions(user.jwt));
    const response = await hbApi.get<TDimensionResponse>(
      `/Dimension/${user.settings.companyId}`,
      hbApiOptions(user.jwt)
    );
    return Array.isArray(response.data) ? response.data : [];
  }
);

const slice = createSlice({
  name: "cubeDimensions",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {},
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchDimensions.pending, state => {
        state.isLoading = true;
      })
      .addCase(createDimension.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateDimension.pending, state => {
        state.isLoading = true;
      })
      .addCase(deleteDimension.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchDimensions.rejected, state => {
        state.isLoading = false;
      })
      .addCase(createDimension.rejected, state => {
        state.isLoading = false;
      })
      .addCase(updateDimension.rejected, state => {
        state.isLoading = false;
      })
      .addCase(deleteDimension.rejected, state => {
        state.isLoading = false;
      })
      .addCase(fetchDimensions.fulfilled, (state, action) => {
        state.dimensions = action.payload;
        state.isLoading = false;
      })
      .addCase(createDimension.fulfilled, (state, action) => {
        state.dimensions = action.payload;
        state.isLoading = false;
      })
      .addCase(updateDimension.fulfilled, (state, action) => {
        state.dimensions = action.payload;
        state.isLoading = false;
      })
      .addCase(deleteDimension.fulfilled, (state, action) => {
        state.dimensions = action.payload;
        state.isLoading = false;
      });
  },
});

export const {} = slice.actions;
export default slice.reducer;
