import { Space } from "antd";
import { useTranslation } from "react-i18next";

import { MobileAddEntitySectionTProps } from "../../../../types/page";
import HBInput from "../../../HBComponents/Input/HBInput";

const AddCommentSection: React.FC<MobileAddEntitySectionTProps> = ({ entity, onFiledValueChange }) => {
  const { t } = useTranslation();

  const onCommentChanged = (value: string | null | number) => {
    onFiledValueChange({ comment: value?.toString() || "" });
  };

  return (
    <Space direction="vertical" size={9} className="buttons-space">
      <div className="upload-modal-comment">
        <span>{t("Comment")}</span>
        <span>
          <HBInput onChange={onCommentChanged} value={entity.comment as string} type={"textArea"} />
        </span>
      </div>
    </Space>
  );
};

export default AddCommentSection;
