import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import { forgotPassword } from "../../../store/slices/user";
import { useAppDispatch } from "../../../store/store";
import { MessageType, useLoginContext } from "../context/useLoginContext";

const ForgotPasswordForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    darkBackground,
    setCustomTitle,
    username,
    setUsername,
    email,
    setEmail,
    setIsForgotPassword,
    errorMessage,
    setErrorMessage,
    setBackToLoginMessage,
  } = useLoginContext();

  const { t } = useTranslation();

  const handleForgotPassword = async (values: { username: string; email: string }) => {
    try {
      await dispatch(forgotPassword(values))
        .then(unwrapResult)
        .then(res => {
          if (res) {
            setIsForgotPassword(false);
            setBackToLoginMessage({ message: "ForgotPasswordSuccess", type: MessageType.Info });
            setErrorMessage("");
          } else {
            setErrorMessage(t("ErrorInvalidEmail"));
          }
        });
    } catch (error) {
      console.error(error);
      setErrorMessage(t("ErrorInvalidEmail"));
    }
  };

  const handleInputChange = (setInput: React.Dispatch<React.SetStateAction<string>>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setInput(e.target.value);

  return (
    <Form
      initialValues={{
        username: username || "",
        email: email || "",
        remember: true,
      }}
      name="basic"
      onFinish={handleForgotPassword}
    >
      <Button
        onClick={() => {
          setErrorMessage("");
          setIsForgotPassword(false);
          setCustomTitle("LoginHeader");
        }}
        className={`back-button-forgot  ${darkBackground && "login-text-white"}`}
        data-testid="forgot-password-back-button"
        type="text"
        shape="round"
      >
        {t("OtpBack")}
      </Button>
      <Row justify="center">
        <div className={`message-info ${darkBackground && "login-text-white"}`}>{t("ForgotPasswordInfo")}</div>
        <Form.Item name="username" className="login-input" data-testid="usernameInput">
          <Input
            placeholder={t("AuthTypeUsername")}
            value={username}
            onChange={handleInputChange(setUsername)}
            data-testid="forgot-password-username-input"
          />
        </Form.Item>

        <Form.Item
          className="login-input"
          data-testid="emailInput"
          name="email"
          rules={[
            {
              validator: (_, value) => {
                if (/^\S+@\S+\.\S+$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("IncorrectEmailEntered")));
              },
            },
          ]}
        >
          <Input
            placeholder={t("FieldEmail")}
            value={email}
            onChange={handleInputChange(setEmail)}
            data-testid="forgot-password-email-input"
          />
        </Form.Item>
        <div className="login-error-message" data-testid="login-error-message">
          {errorMessage.includes(" ") ? errorMessage : t(errorMessage)}
        </div>

        <Button
          type="primary"
          className="button-submit"
          htmlType="submit"
          data-testid="forgot-password-submit-button"
          disabled={!username || !email}
        >
          {t("ResetPasswordTitle")}
        </Button>
        <div className={`tech-message-info ${darkBackground && "login-text-white"}`}>{t("ContactTechMessage")}</div>
      </Row>
    </Form>
  );
};

export default ForgotPasswordForm;
