import "./MobileCardTemplate.less";

import { Row } from "antd";

import { tasksLinkedActionsField } from "../../../../pages/pageConfig/category/actions/tasksConfigPageData";
import { MobileCardTemplateTProps } from "../../../../types/page";
import { LinkedAction } from "../../../../types/tasks";
import CardLabel from "../core/CardLabel/CardLabel";
import CardLabelWithIcon, { ICON_NAMES } from "../core/CardLabelWithIcon/CardLabelWithIcon";
import CardTitle from "../core/CardTitle/CardTitle";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import LabelWithDate from "../core/LabelWithDate/LabelWithDate";
import RowContainer from "../core/RowContainer/RowContainer";
import StatusMark from "../core/StatusMark/StatusMark";

const LinkedActionsMobileCardTemplate: React.FC<MobileCardTemplateTProps<LinkedAction>> = ({ entity }) => {
  const actionsConfig = tasksLinkedActionsField;
  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.id && <CardTitle title={entity.id} category="actions" id={entity.id} />}
        {entity.title && <CardLabel label={entity.title} />}
      </ContainerWithDivider>
      <RowContainer>
        {entity.relationship && <CardLabelWithIcon label={entity.relationship} iconName={ICON_NAMES.USER} />}
        {entity.status && <StatusMark statusLabel={entity.status} />}
      </RowContainer>
      <ContainerWithDivider>
        {entity.createDate && <LabelWithDate labelTranslationKey="CreateDate" date={entity.createDate} />}
      </ContainerWithDivider>
      <Row className="mobile-card-row" align="middle">
        {actionsConfig.actions?.map(action => {
          if (action.component) {
            return action.component(entity);
          }
          return null;
        })}
      </Row>
    </CardWithSelect>
  );
};

export default LinkedActionsMobileCardTemplate;
