import { Spin, Tag, Tooltip, Typography } from "antd";
import { useState, useEffect, ReactNode } from "react";
import { CubeReview, CubeReviewQuestion } from "../../../../types/cubeTypes/Review";
import useRenewCubeQuery from "../../../../utils/hooks/useRenewCubeQuery";

const { Text } = Typography;

type QuestionTagListProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dimensionQuestions: any[];
};

export default function QuestionTagList({ dimensionQuestions }: QuestionTagListProps) {
  const [convertedQuestions, setconvertedQuestions] = useState<ReactNode[] | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { resultSet: dictionaryQuestions } = useRenewCubeQuery<any>({
    query: {
      dimensions: [CubeReviewQuestion.id, CubeReviewQuestion.text, CubeReview.name],
      filters: [
        {
          member: CubeReviewQuestion.id,
          operator: "equals",
          values: dimensionQuestions.map(x => `${x}`),
        },
      ],
    },
    options: {
      skip: dimensionQuestions.length === 0,
    },
  });

  useEffect(() => {
    if (dictionaryQuestions) {
      setconvertedQuestions(
        dimensionQuestions.map(question => {
          const dictionary = dictionaryQuestions.rawData().find(x => x[CubeReviewQuestion.id] === question);
          return (
            <Tooltip key={question} title={`Question Id: ${question}`}>
              <Tag key={question}>
                <div className="tag">
                  <Text strong>{`${dictionary[CubeReview.name]}`}</Text>
                  <Text>{`${dictionary[CubeReviewQuestion.text]}`}</Text>
                </div>
              </Tag>
            </Tooltip>
          );
        })
      );
    }
  }, [dictionaryQuestions]);

  if (dimensionQuestions.length === 0) {
    return null;
  }

  if (convertedQuestions) {
    return <div className="dimensions-tag-list-container">{convertedQuestions}</div>;
  }
  return <Spin />;
}
