import {
  ChartPivotRow,
  PivotConfig,
  PivotRow,
  Query,
  QueryAnnotations,
  QueryType,
  ResultSet,
  Series,
  SeriesNamesColumn,
  TableColumn,
} from "@cubejs-client/core";

import { HBChartType } from "../../../types/dashboard";
import DefaultResultSet from "./DefaultResultSet";
import HeatmapResultSet from "./HeatmapResultSet";

export interface CustomResultSet {
  pivot(pivotConfig?: PivotConfig): PivotRow[];
  tablePivot(pivotConfig?: PivotConfig): Array<{ [key: string]: string | number | boolean }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  series<SeriesItem = any>(pivotConfig?: PivotConfig): Series<SeriesItem>[];
  categories(pivotConfig?: PivotConfig): ChartPivotRow[];
  tableColumns(pivotConfig?: PivotConfig): TableColumn[];
  totalRow(pivotConfig?: PivotConfig): ChartPivotRow;
  decompose(): ResultSet[];
  seriesNames(pivotConfig?: PivotConfig): SeriesNamesColumn[];
  annotation(): QueryAnnotations;
  query(): Query;
  queryType: QueryType;
}

const createResultSet = (chartType: HBChartType, resultSet: ResultSet): CustomResultSet => {
  switch (chartType) {
    case HBChartType.heatmap:
      return new HeatmapResultSet(resultSet);
    default:
      return new DefaultResultSet(resultSet);
  }
};

export default createResultSet;
