import { CubeProvider } from "@cubejs-client/react";

import { useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import useCubeApi from "../../../utils/hooks/useCubeApi";
import QueryBuilderPage from "./QueryBuilderPage";

function CubePlayground() {
  const API_URL = process.env.REACT_APP_CUBEJS_API_URL;
  const cubejsApi = useCubeApi({ crossProjects: undefined });

  const { cubeToken } = useSelector((state: RootState) => state.user);

  if (!cubeToken) return null;
  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <QueryBuilderPage apiUrl={`${API_URL}/cubejs-api/v1`} token={cubeToken} />
    </CubeProvider>
  );
}

export default CubePlayground;
