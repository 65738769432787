import "./LivePDF.less";

import { Spin } from "antd";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import { useLivePdf } from "./useLivePDF";

const ReviewViewer = React.lazy(() => import("../../review-viewer/ReviewViewer"));
type LivePDFParams = {
  livePDFId?: string;
};

const LivePDF = React.forwardRef<HTMLDivElement, LivePDFParams>(({ livePDFId }, ref) => {
  const { review, loading } = useLivePdf({ livePDFId });
  const { t } = useTranslation();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const direction = useSelector((state: RootState) => state.user.settings.direction);

  return (
    <div ref={ref}>
      {loading ? (
        <Spin className="live-pdf-spinner" />
      ) : (
        review && (
          <div className="live-pdf-wrapper">
            <Suspense fallback={<Spin spinning />}>
              <ReviewViewer
                isRightToLeft={direction === "rtl"}
                reviewData={review.modelData}
                brandingData={{
                  ...review.brandingData,
                  company: { ...review.brandingData.company, mainColor: primaryColor },
                }}
                translations={{
                  ...review.translations,
                  actionId: t("FieldAction"),
                  appointmentId: t("FieldAppointment"),
                  project: t("ProjectName"),
                  date: t("Date"),
                  location: t("FieldLocation"),
                  page: t("Page"),
                  score: t("FieldScore"),
                  time: t("Time"),
                  personName: t("PersonName"),
                  printedAt: t("PrintedAt"),
                  sum: t("Sum"),
                  executiveSummary: t("PdfAllDefectsCategory"),
                }}
                score={review.score ?? "0"}
                summaryControls={review.summaryControls}
                executiveSummary={review.executiveSummary}
              />
              <footer dir={direction} style={{ borderTop: `1px solid ${primaryColor}` }}>
                <div className="footer-details" style={{ marginTop: "5px" }}>
                  {review.brandingData.pdf.addressText && <div>{review.brandingData.pdf.addressText}</div>}
                  {/* <div>{{{generateFooterText @index @root.$pdf.pages.length @root.reviewResult.questions}}}</div> */}
                </div>
                <div className="logo">
                  {review.brandingData.pdf.showCompanyLogoInFooter && review.brandingData.company.logoUrl ? (
                    <div className="logo__image">
                      <img src={review.brandingData.company.logoUrl} />
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center" }}>
                      <div>{t("PrintedAt")}</div>
                      <img src="/media/vitre-logo.png" className="logo__image_svg" />
                    </div>
                  )}
                </div>
              </footer>
            </Suspense>
          </div>
        )
      )}
    </div>
  );
});

export default LivePDF;
