import "./webhookContextCondition.less";

import { SearchOutlined } from "@ant-design/icons";

import { Button, Input, Menu, Modal, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReviewOptions } from "../../types/tasks";

const mapStringToIds = (input: string): number[] => {
  return input
    .split("|")
    .map(item => parseInt(item.split("=")[1], 10))
    .filter(value => !isNaN(value));
};

export type SelectListModalProps = {
  onChange: (v: number[]) => void;
  managedValue?: string | null | boolean | number;
  modalVisible: boolean;
  setModalVisible: (value: boolean) => void;
  data: {
    id: number;
    label: string;
    projectId: number | null;
  }[];
  title: string;
};

export const SelectListModal: React.FC<SelectListModalProps> = ({
  onChange,
  managedValue,
  data,
  modalVisible,
  setModalVisible,
  title,
}) => {
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [options, setOptions] = useState<ReviewOptions[] | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<ReviewOptions[] | null>(null);
  const selectedProjectFilter = null;

  useEffect(() => {
    const uniqueItems = [...new Map(data.map(item => [item.id, item])).values()];
    setOptions(uniqueItems);
    setFilteredOptions(uniqueItems);
  }, [data]);

  useEffect(() => {
    if (modalVisible && managedValue) {
      setSelectedItems(mapStringToIds(String(managedValue)));
    }
  }, [modalVisible]);

  const handleClickItem = useCallback((itemId: number) => {
    setSelectedItems(prevSelected =>
      prevSelected.includes(itemId) ? prevSelected.filter(id => id !== itemId) : [...prevSelected, itemId]
    );
  }, []);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value.toLowerCase();
      setFilteredOptions(
        options?.filter(
          x =>
            x.label.toLowerCase().includes(searchValue) &&
            (selectedProjectFilter === 0
              ? !x.projectId
              : selectedProjectFilter
              ? x.projectId === selectedProjectFilter
              : true)
        ) || []
      );
    },
    [options, selectedProjectFilter]
  );

  const searchInput = (
    <Input
      className="search"
      prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
      bordered={false}
      id="review-search"
      placeholder={t("SearchButton")}
      allowClear={true}
      onChange={handleSearchChange}
    />
  );

  return (
    <Modal
      onOk={() => {
        onChange(selectedItems);
        setModalVisible(false);
      }}
      open={modalVisible}
      wrapClassName="modalWrapper"
      okButtonProps={{ disabled: selectedItems.length === 0 }}
      onCancel={() => setModalVisible(false)}
      centered
      className="review-creation-modal"
    >
      <div>
        <Typography.Paragraph className="new-review-title">{t(title)}</Typography.Paragraph>
      </div>
      <Menu selectedKeys={selectedItems.map(String)} className="new-review-menu" multiple>
        <div className="search-bar">{searchInput}</div>

        <span className="selectall-wrapper">
          <Button
            onClick={() => {
              setSelectedItems(Array.from(new Set([...selectedItems, ...(filteredOptions ?? []).map(x => x.id)])));
            }}
            type="text"
          >
            {t("FieldSelectAll")}
          </Button>

          <Button
            onClick={() => {
              setSelectedItems([...selectedItems].filter(id => !(filteredOptions ?? []).map(x => x.id).includes(id)));
            }}
            type="text"
          >
            {t("FieldUnselectAll")}
          </Button>
        </span>

        {filteredOptions?.map(x => (
          <Menu.Item onClick={() => handleClickItem(x.id)} key={x.id}>
            {x.label}
          </Menu.Item>
        ))}
      </Menu>
    </Modal>
  );
};
