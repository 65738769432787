import dayjs from "dayjs";

import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import { ReactComponent as CertificateIcon } from "../../../../media/certification-page-icon.svg";
import { Certification, ManualCertificate } from "../../../../types/certification";
import { BaseField, InputTypes, PartialBaseField } from "../../../../types/page";
import { boolOptions } from "../common";
import { fetchCertificateById, fetchEmployeeById, localizeText, renderBoolStatus } from "../utilities";

export const nameField: BaseField<Certification> = {
  id: "name",
  title: "FieldName",
  value: e => e.name,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
};

export const supplierField: BaseField<Certification> = {
  id: "supplier",
  title: "Supplier",
  value: e => e.supplier,
  type: InputTypes.INPUT,
  placeholder: localizeText("Enter"),
  optionsSelector: () => null,
};

export const externalIdFieldCert: BaseField<Certification> = {
  id: "externalId",
  title: "ExternalID",
  value: e => e.externalId,
  type: InputTypes.INPUT,
  placeholder: localizeText("Enter"),
  required: false,
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const durationField: BaseField<Certification> = {
  id: "duration",
  title: "FieldDuration",
  value: e => e.duration,
  type: InputTypes.INPUT,
  placeholder: localizeText("Unlimited"),
  optionsSelector: () => null,
};

export const aboutToExpireDaysField: BaseField<Certification> = {
  id: "aboutToExpireDays",
  title: "AboutToExpire",
  value: e => e.aboutToExpireDays,
  type: InputTypes.INPUT,
  optionsSelector: () => null,
};

export const isManualField: BaseField<Certification> = {
  id: "isManual",
  title: "Manual",
  value: e => renderBoolStatus(e.isManual),
  type: InputTypes.BOOLEAN,
  options: boolOptions,
  optionsSelector: () => null,
};

export const ownerUserIdField: BaseField<Certification> = {
  id: "ownerUserId",
  title: "Owner",
  value: e => e.ownerUserId,
  valueText: e => fetchEmployeeById(e.ownerUserId),
  type: InputTypes.AUTOCOMPLETE,
  optionsSelector: state =>
    state.employee.basicData?.map(l => ({
      id: l.id,
      label: l.displayName,
    })),
  placeholder: localizeText("Select"),
};

export const trainingDateTabModalField: PartialBaseField = {
  id: "trainingDate",
  type: InputTypes.DATE,
  title: localizeText("TrainingDate"),
  required: true,
  optionsSelector: () => null,
  defaultValue: dayjs().format("MM/DD/YYYY"),
  value: (e: unknown) => {
    const castEntity = (e as unknown) as ManualCertificate;
    if (castEntity.trainingDate === null) {
      return null;
    }
    if (castEntity.trainingDate && dayjs(castEntity.trainingDate).isValid()) {
    }
    return castEntity.trainingDate && dayjs(castEntity.trainingDate).isValid()
      ? dayjs(castEntity.trainingDate).format(DATE_FORMAT_WITHOUT_TIME)
      : dayjs().format(DATE_FORMAT_WITHOUT_TIME);
  },
  placeholder: "TrainingDate",
};

export const expirationDateModalField: PartialBaseField = {
  id: "expirationDate",
  type: InputTypes.DATE,
  title: localizeText("FieldExpirationDate"),
  required: false,
  optionsSelector: () => null,
  defaultValue: null,
  value: e => {
    const castEntity = (e as unknown) as ManualCertificate;
    const certificateDuration = fetchCertificateById(castEntity.certificateId)?.duration;
    const expirationDate = dayjs(castEntity.expirationDate);
    const trainingDate = dayjs(castEntity.trainingDate);

    if (expirationDate.isValid() && expirationDate < trainingDate && certificateDuration) {
      return trainingDate.add(certificateDuration, "days").format(DATE_FORMAT_WITHOUT_TIME);
    }

    if (expirationDate.isValid()) {
      return expirationDate.format(DATE_FORMAT_WITHOUT_TIME);
    }

    if (certificateDuration && trainingDate.isValid()) {
      return trainingDate.add(certificateDuration, "days").format(DATE_FORMAT_WITHOUT_TIME);
    }

    return null;
  },
  disabledDate: e => current => {
    const castEntity = (e as unknown) as ManualCertificate;
    return current && current < dayjs(castEntity.trainingDate);
  },
  placeholder: "FieldExpirationDate",
};

export const certificateIdModalField: PartialBaseField = {
  id: "certificateId",
  type: InputTypes.AUTOCOMPLETE,
  title: localizeText("FieldCertificate"),
  required: true,
  value: e => {
    const castEntity = (e as unknown) as ManualCertificate;
    return castEntity.certificateId;
  },
  optionsSelector: state =>
    state.certification.basicData
      .filter(r => r.isManual === true)
      .map(l => ({
        id: l.id,
        label: l.name,
      })),
  cellPrefixIcon: <CertificateIcon className="certificate-icon" />,
  placeholder: "Certificate",
};

export const employeeIdModalField: PartialBaseField = {
  id: "employeeId",
  type: InputTypes.AUTOCOMPLETE,
  title: localizeText("FieldEmployee"),
  required: true,
  value: e => {
    const castEntity = (e as unknown) as ManualCertificate;
    return castEntity.employeeId;
  },
  optionsSelector: state =>
    state.employee.basicData?.map(l => ({
      id: l.id,
      label: l.displayName,
    })),
};

export const primaryFields: BaseField<Certification>[] = [nameField];

export const secondaryFields: BaseField<Certification>[] = [
  supplierField,
  externalIdFieldCert,
  durationField,
  aboutToExpireDaysField,
  isManualField,
];
