import { Button, Radio, Row, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import OtpComponent from "../../components/OtpComponent/OtpComponent";
import { ReactComponent as HBLogo } from "../../media/hb-logo-new.svg";
import { RootState } from "../../store/store";
import { HBEventName } from "../../types/analyticsTypes/HBEvent";
import { Language } from "../../types/user";
import { LocalStorageKeys, ParamsKeys } from "../../types/utility";
import { getDeviceLang } from "../../utils/functions";
import { applyTheme } from "../../utils/hooks/useCompanyColors";
import useInitTrackEvents from "../../utils/hooks/useInitTrackEvents";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useRouter from "../../utils/hooks/useRouter";
import LanguagePicker from "../ProfileMenu/LanguagePicker/LanguagePicker";
import { LoginProvider } from "./context/useLoginContext";
import Login from "./Login";
import SSO from "./SSO";
import "./fullLogin.less";

enum FullLoginMode {
  LOGIN = "Login",
  OTP = "OTP",
  SSO = "SSO",
}

type TProps = {
  customReferer?: string;
  isLandingPage?: boolean;
  darkBackground?: boolean;
  notCentered?: boolean;
};

const FullLogin: FC<TProps> = ({ customReferer, isLandingPage, darkBackground, notCentered }) => {
  const { location } = useRouter<{ referer: Location }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const returnUrl = urlSearchParams.get(ParamsKeys.RETURN_URL);
  const resetPasswordKey = urlSearchParams.get(ParamsKeys.KEY);
  const modeFromUrl = urlSearchParams.get(ParamsKeys.MODE);

  const getInitialMode = (): FullLoginMode => {
    if (returnUrl?.includes("redirectToLegacy") || resetPasswordKey) {
      return FullLoginMode.LOGIN;
    }
    return (modeFromUrl ||
      localStorage.getItem(LocalStorageKeys.PAS_LOGIN_MODE) ||
      FullLoginMode.LOGIN) as FullLoginMode;
  };

  const [mode, setMode] = useState<FullLoginMode>(getInitialMode);
  const [customTitle, setCustomTitle] = useState<string>("");
  const { t } = useTranslation();
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const direction = useSelector((state: RootState) => state.user.settings.direction);
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const { track } = useInitTrackEvents();
  const history = useHistory();
  const isMobile = useIsMobile();
  const isVitreOrigin = window.location.origin.includes(`${process.env.REACT_APP_NEW_UI_ORIGIN_PART}`);
  const [showAnimation, setShowAnimation] = useState(!isMobile);

  //remove after Vitre migration
  useEffect(() => {
    if (!isVitreOrigin) {
      applyTheme("@primary-color", "#7f2a90");
    }
  }, []);

  useEffect(() => {
    track({ eventName: HBEventName.LoginScreenPresented });
  }, []);

  useEffect(() => {
    const modeFromQuery = new URLSearchParams(location.search).get(ParamsKeys.MODE);

    if (modeFromQuery && Object.values(FullLoginMode).includes(modeFromQuery as FullLoginMode)) {
      setMode(modeFromQuery as FullLoginMode);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (resetPasswordKey) {
      setMode(FullLoginMode.LOGIN);
      searchParams.delete(ParamsKeys.MODE);
    } else {
      searchParams.set(ParamsKeys.MODE, mode);
      history.push({ search: searchParams.toString() });
    }
  }, [mode]);

  const ModeSwitcher = ({
    currentMode,
    setMode,
  }: {
    currentMode: FullLoginMode;
    setMode: (mode: FullLoginMode) => void;
  }) => {
    const modeValues = !isLandingPage
      ? Object.values(FullLoginMode)
      : Object.values(FullLoginMode).filter(mode => mode !== FullLoginMode.SSO);

    const modes = modeValues.filter(mode => mode !== currentMode);

    return (
      <div className="mode-switcher">
        {modes.map(mode => (
          <p
            className={isVitreOrigin ? "light" : ""}
            key={mode}
            onClick={() => {
              setMode(mode);
              setCustomTitle("");
            }}
          >
            {t(mode)}
          </p>
        ))}
      </div>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <div className={`full-login-container ${!isLandingPage && "full-login-container-regular"}`}>
        {isVitreOrigin && !isLandingPage && (
          <>
            {showAnimation ? (
              <>
                <video
                  autoPlay
                  loop
                  muted
                  style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: -1,
                  }}
                >
                  <source src="/media/vitre-background.mp4" type="video/mp4" />
                </video>
                <div
                  className="mask"
                  style={{
                    background: "#8d10ce",
                    mixBlendMode: "color",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
                <Button
                  className="remove-animation-button"
                  onClick={() => setShowAnimation(false)}
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    left: "20px",
                  }}
                >
                  {t("RemoveAnimationVitre")}
                </Button>
              </>
            ) : (
              <div
                style={{
                  position: "absolute",
                  bottom: "0%", // Adjust this value to move the ellipse higher
                  background: "#8d10ce",
                  width: "170%",
                  height: "45%",
                  borderRadius: "50% 50% 0 0 / 100% 100% 0 0",
                  overflow: "hidden",
                }}
              />
            )}
            {!isMobile && (
              <div
                className="vitre-banner"
                style={{
                  direction: direction,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "10rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 1px 15px 0px #0000001A",
                  background: "#FFFFFF",
                  gap: "2rem",
                }}
              >
                <img
                  src="/media/pas.png"
                  style={{
                    height: "3rem",
                  }}
                />
                <span>{t("IsNowVitre")}</span>
                <img
                  src="/media/vitre-horizontal.png"
                  style={{
                    height: "3rem",
                  }}
                />
                <span>{t("DiscoverMoreTextVitre")}</span>
                <Button
                  className="vitre-button"
                  type="primary"
                  onClick={() => {
                    window.open(process.env.REACT_APP_VITRE_MARKETING_URL, "_blank");
                  }}
                >
                  <span>{t("LearnMoreVitre")}</span>
                  <svg
                    style={{ transform: direction === "rtl" ? "rotate(180deg)" : "none" }}
                    width="20"
                    height="12"
                    viewBox="0 0 20 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41543 6.65855H16.6915L13.6791 9.87224C13.4383 10.1291 13.4383 10.5505 13.6791 10.8074C13.9198 11.0642 14.3087 11.0642 14.5495 10.8074L18.6175 6.46757C18.8583 6.21073 18.8583 5.79585 18.6175 5.53902L14.5557 1.19262C14.3149 0.935792 13.926 0.935792 13.6852 1.19262C13.4445 1.44946 13.4445 1.86434 13.6852 2.12117L16.6915 5.34146H1.41543C1.07591 5.34146 0.798119 5.6378 0.798119 6C0.798119 6.3622 1.07591 6.65855 1.41543 6.65855Z"
                      fill="#8D10CE"
                      stroke="#8D10CE"
                    />
                  </svg>
                </Button>
              </div>
            )}
          </>
        )}
        {isLandingPage && (
          <Row className="switch-mode-container" justify="center" align="middle">
            <Radio.Group
              onChange={e => {
                setMode(e.target.value);
                localStorage.setItem(LocalStorageKeys.PAS_LOGIN_MODE, mode);
              }}
              defaultValue={mode}
            >
              <Radio.Button
                className={direction === "rtl" ? "rtl-login-button" : "ltr-login-button"}
                value={FullLoginMode.LOGIN}
              >
                {t("AuthTypeUsername")}
              </Radio.Button>
              <Radio.Button
                className={direction === "rtl" ? "rtl-otp-button" : "ltr-otp-button"}
                value={FullLoginMode.OTP}
              >
                {t("AuthTypeOTP")}
              </Radio.Button>
            </Radio.Group>
          </Row>
        )}
        <div className={`login-frame${darkBackground ? "-dark" : ""}`}>
          {!isLandingPage &&
            (isVitreOrigin ? (
              <img src="media/vitre-logo.png" className="hb-logo-new" />
            ) : (
              //remove after vitre migration
              <HBLogo className="hb-logo-new" />
            ))}
          {!isLandingPage && (
            <h1 className={`${isVitreOrigin ? "login-title" : "login-title-old"}`}>
              {customTitle ? t(customTitle) : t(mode)}
            </h1>
          )}
          {mode === FullLoginMode.LOGIN && (
            <LoginProvider
              setCustomTitle={setCustomTitle}
              darkBackground={darkBackground}
              customReferer={customReferer}
            >
              <Login notCentered={notCentered} />
            </LoginProvider>
          )}
          {mode === FullLoginMode.OTP && (
            <Row justify="center" align="middle">
              <OtpComponent backButtonWhite={darkBackground} customReferer={customReferer} />
            </Row>
          )}
          {mode === FullLoginMode.SSO && (
            <Row justify="center" align="middle">
              <SSO />
            </Row>
          )}
        </div>
        {!jwt && !isLandingPage && (
          <ModeSwitcher
            currentMode={mode}
            setMode={mode => {
              setMode(mode);
              localStorage.setItem(LocalStorageKeys.PAS_LOGIN_MODE, mode);
            }}
          />
        )}
        {!isLandingPage && (
          <LanguagePicker
            defaultLanguage={(localStorage.getItem(LocalStorageKeys.LANG) as Language) || getDeviceLang()}
            isLandingPageBlack={!isVitreOrigin}
            isLandingPage={true}
          />
        )}
      </div>
    </Spin>
  );
};

export default FullLogin;
