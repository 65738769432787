import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDashboard, setMyDefaultDashboard } from "../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../store/store";
import { HBEventName } from "../../../types/analyticsTypes/HBEvent";
import { DashboardDetails } from "../../../types/dashboard";
import useInitTrackEvents from "../../../utils/hooks/useInitTrackEvents";

type DashboardSelectorItemProps = {
  dashboard: DashboardDetails;
};

export default function DashboardSelectorItem({ dashboard }: DashboardSelectorItemProps) {
  const dispatch = useAppDispatch();
  const { track } = useInitTrackEvents();
  const { t } = useTranslation();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const selectedDashboardId = useSelector((state: RootState) => state.dashboard.single.id);
  const [isHoveringDashboard, setIsHoveringDashboard] = useState(false);

  const onSetMyDefault: React.MouseEventHandler<HTMLElement> = useCallback(() => {
    dispatch(setMyDefaultDashboard(dashboard.id));
  }, []);

  const onDashboardSelected: React.MouseEventHandler<HTMLElement> = () => {
    dispatch(selectDashboard(dashboard.id));
    track({
      eventName: HBEventName.DashboardSelected,
      data: { dashboardName: dashboard.name, dashboardId: dashboard.id },
    });
  };

  const getDefaultSelectionButton = () => {
    if (dashboard.isMyDefault) {
      return (
        <a className={dashboard?.isMyDefault ? "disabled-selector-icon" : ""} onClick={onSetMyDefault}>
          <StarFilled style={{ color: primaryColor }} />
        </a>
      );
    }
    if (isHoveringDashboard) {
      return (
        <a className={dashboard?.isMyDefault ? "disabled-selector-icon" : ""} onClick={onSetMyDefault}>
          <StarOutlined />
        </a>
      );
    }
    return <div></div>;
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
      key={dashboard.id}
      onMouseEnter={() => setIsHoveringDashboard(true)}
      onMouseLeave={() => {
        setIsHoveringDashboard(false);
      }}
    >
      <Button
        type="text"
        shape="round"
        onClick={onDashboardSelected}
        style={{ color: selectedDashboardId === dashboard.id ? primaryColor : "black" }}
      >
        <div style={{ maxWidth: "80%", textOverflow: "ellipsis" }}>{dashboard.name}</div>
      </Button>
      <Tooltip
        placement="right"
        mouseEnterDelay={0.5}
        title={dashboard.isMyDefault ? undefined : t("SetAsMyDefaultButton")}
      >
        {getDefaultSelectionButton()}
      </Tooltip>
    </div>
  );
}
