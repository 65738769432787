import "./MobileCardTemplate.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { employeeCertificateStatusField } from "../../../../pages/pageConfig/category/employee/employeeConfigPageData";
import { useAppDispatch } from "../../../../store/store";
import { EmployeeCertificate } from "../../../../types/employee";
import { FieldType } from "../../../../types/page";
import EditableInputMobileFirst from "../../../EditableInput/EditableInputMobileFirst";
import { useMobileSingleViewContext } from "../../context/useMobileSingleViewContext";
import { useMobileSingleViewTabContext } from "../../context/useMobileSingleViewTabContext";
import CardTitle from "../core/CardTitle/CardTitle";
import CardWithSelect from "../core/CardWithSelect/CardWithSelect";
import ContainerWithDivider from "../core/ContainerWithDivider/ContainerWithDivider";
import LabelWithDate from "../core/LabelWithDate/LabelWithDate";
import StatusMark from "../core/StatusMark/StatusMark";

type TProps = {
  entity: EmployeeCertificate;
};

const CertificateMobileCardTemplate: React.FC<TProps> = ({ entity }) => {
  const dispatch = useAppDispatch();
  const { setIsTableDataLoading } = useMobileSingleViewTabContext();
  const { setErrors } = useMobileSingleViewContext();

  const statusSelectConfig = employeeCertificateStatusField;

  const handleColumnDropdownOnChange = async (newValue: string | number | boolean | null) => {
    try {
      if (statusSelectConfig.cellDropdownOnChange) {
        setIsTableDataLoading(true);
        const actionResponse = await dispatch(statusSelectConfig.cellDropdownOnChange(entity, newValue));
        await unwrapResult(actionResponse);
        setIsTableDataLoading(false);
      }
    } catch (customError) {
      setErrors(customError as Record<string, string[]>);
      setIsTableDataLoading(false);
    }
  };

  return (
    <CardWithSelect entity={entity}>
      <ContainerWithDivider>
        {entity.certificateName && (
          <CardTitle title={entity.certificateName} category="certification" id={entity.certificateId} />
        )}
        {entity.status && <StatusMark statusLabel={entity.status} />}
      </ContainerWithDivider>
      <ContainerWithDivider>
        {entity.certificateExpirationDate && (
          <LabelWithDate labelTranslationKey="FieldExpiration" date={entity.certificateExpirationDate} />
        )}
        {entity.nextTrainingSessionDate && (
          <LabelWithDate labelTranslationKey="FieldNextTrainingSession" date={entity.nextTrainingSessionDate} />
        )}
      </ContainerWithDivider>

      <EditableInputMobileFirst
        key={String(statusSelectConfig.id)}
        isEditMode={true}
        id={String(statusSelectConfig.id)}
        type={statusSelectConfig.cellType?.(entity) as FieldType}
        optionSelector={statusSelectConfig.optionsSelector}
        globalEdit={true}
        onChange={handleColumnDropdownOnChange}
        fieldValue={entity.status}
        options={statusSelectConfig.cellDropdownOptions?.(entity) || []}
        selector={statusSelectConfig.cellSearchSelector!}
        prefixIcon={statusSelectConfig.cellPrefixIcon}
        placeholder={statusSelectConfig.placeholder}
      />
    </CardWithSelect>
  );
};

export default CertificateMobileCardTemplate;
