import { Select, Row, Typography, Col } from "antd";
import { LabeledValue } from "antd/lib/select";
import groupBy from "lodash/groupBy";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TOption } from "../../../types/page";
import { FilterValueType } from "../../../utils/hooks/useFilter";
import "./hbSelect.less";

type TProps = {
  /**
   * List of available options to choose from
   */
  options: TOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: string | null | number, textValue: string, additionalInfo?: any) => void;
  onSearch?: (value: string) => void;
  onDeselect: (key: string, value?: FilterValueType) => void;
  label: string;
  /**
   * Currently selected values coming from the active filter corresponding to this id.
   */
  value?: string[];
  /**
   * locationId/typeId/orgUnitId/etc.
   */
  id: string;
  loading?: boolean;
  defaultOpen?: boolean;
  open?: boolean;
  showClear?: boolean;
};

export const HBSelect = ({
  options,
  onChange,
  onSearch,
  value,
  onDeselect,
  id,
  label,
  loading,
  defaultOpen = true,
  open,
  showClear = true,
}: TProps): JSX.Element => {
  const { t } = useTranslation();
  const optionsMap = useMemo(() => {
    const groupedOptions = groupBy(options, "label");
    return options?.map((o, index) => {
      const value = groupedOptions[o.label].length > 1 ? `${o.label}${"\u200B".repeat(index)}` : o.label;
      return { ...o, id: o.id, value: value };
    });
  }, [options]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  const valuesWithLabels = useMemo(() => {
    if (value) {
      const selectedValues = optionsMap.filter(option => value.includes(option.id as string));
      return selectedValues.map(value => ({
        value: value.value,
        label: value.value,
      }));
    }
    return [];
  }, [value, optionsMap]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnDeselect = (v: LabeledValue, o: any) => {
    onDeselect(id, o.id);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSelect = (v: LabeledValue, option: any) => {
    if (option) {
      if (option.propId) {
        onChange(option.id, option.value, option);
      } else {
        onChange(option.id, option.value);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        ref.current.focus();
      }, 250);
    }
  }, [open]);

  return (
    <Row className="HBSelect-container">
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Text className="HBSelect-container__title">{t("Filter")}</Typography.Text>
        </Col>
      </Row>
      <Row className="HBSelect-container__label-container">
        <Typography.Text className="HBSelect-container__filter-label">{t(label)}</Typography.Text>

        {showClear && value && value.length > 0 && (
          <Typography.Text onClick={() => onDeselect(id)} className="HBSelect-container__clear-label">
            {t("ClearFilters")}
          </Typography.Text>
        )}
      </Row>
      <Select
        ref={ref}
        mode="multiple"
        autoFocus
        defaultOpen={defaultOpen}
        loading={loading}
        showAction={["focus"]}
        style={{ width: "20rem" }}
        placeholder={t("SearchButton")}
        onSelect={handleOnSelect}
        onSearch={onSearch}
        onDeselect={handleOnDeselect}
        options={optionsMap}
        labelInValue
        listHeight={120}
        maxTagCount={1}
        dropdownStyle={{ maxHeight: "20rem" }}
        popupClassName="HBSelect-dropdown"
        value={valuesWithLabels.length > 0 ? valuesWithLabels : undefined}
      />
    </Row>
  );
};
