import dayjs from "dayjs";

import { InspectionRecurrencyType, InspectionSingleView, InspectionStatus, isInspection } from "../types/inspection";

export const clearMobileData = async () => {
  await window.indexedDB.databases().then(r => {
    for (let i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name as string);
  });
};

export const isAddManualInspectionVisible = (singleData: InspectionSingleView, isMobile = false) => {
  const lastCheckpoint = singleData?.checkpoints.reduce((prev, current) => {
    return prev.id > current.id ? prev : current;
  });

  return (
    singleData?.recurrencyType === InspectionRecurrencyType.Fixed &&
    !dayjs().isSame(lastCheckpoint?.completedAt, "day") &&
    lastCheckpoint?.status !== InspectionStatus.Pending &&
    singleData?.recurrency > 1 &&
    (isMobile || !singleData?.checkpoints.some(x => typeof x.id === "string"))
  );
};

export const isAddButonVisible = ({
  singleData,
  isMobile = false,
}: {
  singleData: Record<string, unknown> | null | undefined;
  isMobile?: boolean;
}) => {
  if (isInspection(singleData) && singleData?.checkpoints.length) {
    return isAddManualInspectionVisible(singleData, isMobile);
  }
  return true;
};

export const isEmptyObject = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0;
};
