import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { updateDashboardOrgUnits, updateDashboardUsers } from "../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../store/store";
import { DashboardOrgUnit, DashboardUser } from "../../../types/dashboard";
import UsersOrgUnitsSelector from "./UsersOrgUnitsSelector";

type DashboardUserSelectionProps = {
  userType: "Owner" | "User";
};

export default function DashboardUserSelection({ userType }: DashboardUserSelectionProps) {
  const dispatch = useAppDispatch();
  const employeeBasicData = useSelector((state: RootState) => state.employee.basicData);
  const orgUnitBasicData = useSelector((state: RootState) => state.orgUnit.basicData);
  const dashboardUsers = useSelector((state: RootState) => state.dashboard.single.dashboardUsers);
  const dashboardId = useSelector((state: RootState) => state.dashboard.single.id);
  const dashboardOrgUnits = useSelector((state: RootState) => state.dashboard.single.dashboardOrgUnits);
  const { dashboardOwners, dashboardUsersWhoAreNotOwners } = useMemo(
    () =>
      dashboardUsers.reduce<{
        dashboardOwners: DashboardUser[];
        dashboardUsersWhoAreNotOwners: DashboardUser[];
      }>(
        ({ dashboardOwners, dashboardUsersWhoAreNotOwners }, user) => {
          user.isOwner ? dashboardOwners.push(user) : dashboardUsersWhoAreNotOwners.push(user);
          return { dashboardOwners, dashboardUsersWhoAreNotOwners };
        },
        { dashboardOwners: [], dashboardUsersWhoAreNotOwners: [] }
      ),
    [dashboardUsers]
  );

  const onEmployeeSelected = (value: number[]) => {
    let updatedEmployees: DashboardUser[] = value.reduce<DashboardUser[]>((acc, v) => {
      const employee = employeeBasicData.find(e => e.id === v);
      const dashboardUser = dashboardUsers.find(e => e.userId === v);
      if (dashboardUser) {
        acc.push(dashboardUser);
      } else if (employee) {
        acc.push({
          userId: employee.id,
          dashboardId: dashboardId,
          isOwner: userType === "Owner" ? true : undefined,
          isUser: true,
        });
      }
      return acc;
    }, []);
    if (userType === "User") {
      updatedEmployees = dashboardOwners
        .reduce<DashboardUser[]>((acc, owner) => {
          if (!updatedEmployees.find(dashboardUser => dashboardUser.userId === owner.userId)) {
            acc.push(owner);
          }
          return acc;
        }, [])
        .concat(updatedEmployees);
    } else if (userType === "Owner") {
      updatedEmployees = updatedEmployees.concat(dashboardUsersWhoAreNotOwners);
    }
    dispatch(updateDashboardUsers({ users: updatedEmployees, dashboardId }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onOrgUnitSelected = (value: any[]) => {
    const updatedOrgUnits: DashboardOrgUnit[] = value.reduce<DashboardOrgUnit[]>((acc, v) => {
      const orgUnit = orgUnitBasicData.find(ou => ou.id === v.value);
      const dashboardOrgUnit = dashboardOrgUnits.find(ou => ou.orgUnitId === v.value);
      if (dashboardOrgUnit) {
        acc.push(dashboardOrgUnit);
      } else if (orgUnit) {
        acc.push({
          orgUnitId: orgUnit.id,
          dashboardId: dashboardId,
        });
      }
      return acc;
    }, []);
    dispatch(updateDashboardOrgUnits({ orgunits: updatedOrgUnits, dashboardId }));
  };

  return (
    <UsersOrgUnitsSelector
      employeeValues={dashboardUsers
        .filter(u => (userType === "User" ? u.isUser : u.isOwner))
        .map(u => u.userId)
        .sort((a, b) => a - b)}
      orgUnitValues={dashboardOrgUnits.map(ou => ou.orgUnitId)}
      onEmployeeSelected={onEmployeeSelected}
      onOrgUnitSelected={onOrgUnitSelected}
      selectOU={userType === "User"}
      selectUsers={true}
    />
  );
}
