import { BaseEntityType } from "./entityBase";
import { ExplicitAdditionalProps } from "./utility";

export type Webhook = Record<string, unknown> & {
  id: number;
  name: string;
  url: string;
  active: boolean;
};

export interface WebhookState {
  data: Webhook[];
  isLoading: boolean;
  singleData: Webhook | null;
  subData: {
    webhookLogs: WebhookLog[];
    webhookEvents: WebhookEvent[];
  };
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
  paginationInfo: {
    currentPage: number;
    count: number;
  };
  eventTypes: EventType[];
}

export type EventType = {
  eventTypeId: number;
  eventTypeName: string;
  eventSubTypes: EventSubType[];
};

export type EventSubType = {
  eventSubTypeId: number;
  eventSubTypeName: string;
};

export type WebhookPage = BaseEntityType & {
  entityData: {
    primaryData: Webhook;
  };
  subData: {
    webhookLogs: WebhookLog[];
    webhookEvents: WebhookEvent[];
  };
  listViewData: Webhook;
};

export type WebhooksPaginatedData = {
  data: Webhook[];
  count: number;
};

export type WebhookLogPaginatedData = {
  data: WebhookLog[];
  count: number;
};

export type WebhookLog = {
  url: string;
  payload: string;
  createdAt: string;
  sendAt: string;
  sendRetryCount: 0;
  error: string;
  eventId: string;
  responseCode: number;
  eventType: number;
  eventSubType: number;
  webhookEventConfigId: number;
};

export type WebhookEvent = {
  id: number | string;
  eventType: number | null;
  eventSubType?: number | null;
  contextCondition: string;
  metaData: string;
  active: boolean;
  description: string;

  //operational
  staging: boolean;
  action: string;
};

export const WebhookConfigEndpoint = "/WebhookConfig";

export enum WebhookKeys {
  Id = "id",
  Name = "name",
  Url = "url",
  Active = "active",
}
