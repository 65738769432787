import "../MobileCardTemplate.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { RcFile } from "antd/lib/upload";

import { useAppDispatch } from "../../../../../store/store";
import { InspectionCheckpoint, InspectionStatus } from "../../../../../types/inspection";
import { UploadModalType } from "../../../../../types/utility";
import HBUploadModal from "../../../../HBComponents/UploadModal/HBUploadModal";
import { useMobileSingleViewContext } from "../../../context/useMobileSingleViewContext";
import { useMobileSingleViewTabContext } from "../../../context/useMobileSingleViewTabContext";

type TProps = {
  closeModal: () => void;
  entity: InspectionCheckpoint;
  isVisible: boolean;
  uploadModalType: UploadModalType;
};

const InspectionCheckpointModal: React.FC<TProps> = ({ closeModal, entity, isVisible, uploadModalType }) => {
  const { tabConfig } = useMobileSingleViewTabContext();
  const { setErrors } = useMobileSingleViewContext();
  const dispatch = useAppDispatch();

  const uploadFile = (value: string, status?: InspectionStatus, date?: string) => async (
    file: RcFile
  ): Promise<void | File | Blob | boolean> => {
    if (tabConfig.addButton && tabConfig.addButton.uploadFileAction) {
      closeModal();
      try {
        const res = await dispatch(tabConfig.addButton.uploadFileAction({ file, expiration: value }));
        await unwrapResult(res);
      } catch (customError) {
        setErrors(customError);
      }
    }

    if (tabConfig.columns && tabConfig.columns[tabConfig.columns.length - 1]) {
      const action =
        tabConfig.columns[tabConfig.columns.length - 1].stagingActions?.find(x => x.uploadFileAction !== undefined)
          ?.uploadFileAction || null;
      if (action) {
        closeModal();
        try {
          const res = await dispatch(
            action({ value, file, entityId: entity.id as number, entity: entity, date: date })
          );
          await unwrapResult(res);
        } catch (customError) {
          setErrors(customError);
        }
      }
    }
    closeModal();

    return false;
  };

  return (
    <HBUploadModal
      uploadModalType={uploadModalType}
      isVisible={isVisible}
      beforeUpload={uploadFile}
      closeModal={closeModal}
      setErrors={setErrors}
    />
  );
};

export default InspectionCheckpointModal;
