export type BaseFile = {
  url: string;
  name: string;
  expirationDate?: string;
  createdDate?: string;
  createdByUser?: FileCreatedByUser;
};

export type File = Record<string, unknown> &
  Required<BaseFile> & {
    id: number;
    isVirusScanningPassed: boolean;
  };

export type FileUploadResponse = {
  data: File[];
  errors: string;
};

export type FileCreatedByUser = {
  id: number;
  name: string;
};

export type TaskFile = {
  id: number;
  name: string;
  url: string;
  contentType: string;
  createdByUser: {
    id: number;
    name: string;
  };
  createdDate: string;
};

export const FILE_FIELD_NAME = "File";
