import { BarChartOutlined, MoreOutlined, TableOutlined } from "@ant-design/icons";
import { Input, message, Modal } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "svg2pdf.js";
import { ExportData } from "../../../../../actions/menuActions";
import {
  deleteChart,
  saveStagedChart,
  stageChart,
  toggleDrillDownMode,
  updateStagedChart,
} from "../../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { UserRole } from "../../../../../types/user";
import { ChartContext } from "../../HBChart";
import EditChart from "../EditChart/EditChart";
import ChartMenu from "./ChartMenu";
import { ChartMenuItemProperties } from "./ChartMenuItem";

import "../../../Dashboard.less";

export default function ChartOptionsMenu() {
  const { chart, graph } = useContext(ChartContext);
  const { t } = useTranslation();
  const stagedChart = useSelector((state: RootState) => state.dashboard.stagedChart);
  const [showEditModal, setShowEditModal] = useState(false);
  const dispatch = useAppDispatch();
  const showEditChartModal = () => {
    if (chart) {
      dispatch(stageChart(chart));
    }
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?query=${JSON.stringify(chart?.query)}`;
    window.history.pushState({ path: newurl }, "", newurl);

    setShowEditModal(true);
  };

  const onChartNameChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (stagedChart) {
      dispatch(updateStagedChart({ ...stagedChart, name: event.target.value }));
    }
  };

  const options: ChartMenuItemProperties[] = [
    {
      key: "exportExcel",
      label: "ExportToExcel",
      icon: undefined,
      disabled: undefined,
      action: () => {
        if (chart?.resultSet) {
          message.loading({
            content: `Downloading Data for ${chart?.name} As Excel...`,
            duration: 0,
            key: "queryLoading",
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const template = chart?.resultSet.tableColumns().map((column: any) => {
            const template = {
              type: "Header",
              property: column.dataIndex,
              value: column.title,
            };

            return template;
          });

          template.push({
            type: "Option",
            property: "RTL",
            value: "true",
          });

          const today = new Date();
          const filename = `${chart?.name}-${today.toLocaleString().split(",")[0].replaceAll(".", "-")}.xlsx`;

          ExportData(chart?.resultSet.tablePivot(), template, filename).then(() => message.destroy("queryLoading"));
        }
      },
    },
    {
      key: "exportImage",
      label: "ExportToPNG",
      icon: undefined,
      disabled: chart && ["table", "number"].includes(chart.chartType) === true,
      action: () => {
        const a = document.createElement("a");
        a.href = graph!.current!.toBase64Image();
        const today = new Date();
        a.download = `${chart?.name}-${today.toLocaleString().split(",")[0].replaceAll(".", "-")}.png`;
        a.click();
      },
    },
    // {
    //   key: "exportPDF",
    //   label: "Export to SVG",
    //   icon: undefined,
    //   disabled: undefined,
    //   action: () => {
    //     let today = new Date();

    //     if (graph?.current) {
    //       let cachedAnimationState = graph.current.options.animation;
    //       let cachedResponsiveState = graph.current.options.responsive;
    //       graph.current.options.animation = false;
    //       graph.current.options.responsive = false;
    //       downloadSVG(
    //         `${chart.name}-${today.toLocaleString().split(",")[0].replaceAll(".", "-")}.svg`,
    //         "SVG",
    //         graph?.current
    //       );
    //       graph.current.options.animation = cachedAnimationState;
    //       graph.current.options.responsive = cachedResponsiveState;
    //     }
    //   },
    // },
    {
      key: "editChart",
      label: "EditChartModal",
      icon: undefined,
      disabled: undefined,
      action: () => showEditChartModal(),
      allowedUserRoles: [UserRole.SU],
    },
    {
      key: "deleteChart",
      label: "DeleteChartButton",
      icon: undefined,
      allowedUserRoles: [UserRole.SU],
      action: () => {
        if (chart) {
          dispatch(deleteChart(chart.id));
        }
      },
      className: "deleteButton",
    },
    {
      key: "drillDownMode",
      label: "DrillIntoChart",
      icon: chart?.drillDownModeEnabled ? <BarChartOutlined /> : <TableOutlined />,
      disabled: !chart?.isAllowedDrillDown,
      action: () => {
        if (chart) {
          dispatch(toggleDrillDownMode(chart.id));
        }
      },
    },
  ];

  return (
    <>
      {showEditModal && (
        <Modal
          open={true}
          title={
            <Input
              placeholder={t("EnterChartName")}
              bordered={false}
              className="chartTitle"
              value={stagedChart?.name}
              onChange={onChartNameChange}
            />
          }
          width="100rem"
          bodyStyle={{ height: "100%" }}
          destroyOnClose={true}
          onCancel={() => {
            if (window.location.search.includes("?")) {
              const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
              window.history.pushState({ path: newurl }, "", newurl);
            }
            setShowEditModal(false);
          }}
          onOk={() => {
            if (window.location.search.includes("?")) {
              const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
              window.history.pushState({ path: newurl }, "", newurl);
            }
            dispatch(saveStagedChart());
            setShowEditModal(false);
          }}
        >
          <EditChart />
        </Modal>
      )}
      <ChartMenu options={options} icon={<MoreOutlined className="dashboard-menu-icon" />} />
    </>
  );
}
