import "./singleViewTree.less";

import { unwrapResult } from "@reduxjs/toolkit";

import { Button, Col, Row, TableColumnType, Typography } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PageConfigurationContext from "../../context/pageContext";
import { ReactComponent as FilterIcon } from "../../media/filter-icon.svg";
import { RootState, useAppDispatch } from "../../store/store";
import { BaseEntityType } from "../../types/entityBase";
import { CategoryPage, ColumnType, Tab } from "../../types/page";
import useRouter from "../../utils/hooks/useRouter";
import { useUIErrorHandling } from "../../utils/hooks/useUIErrorHandling";
import { ArrayElement } from "../../utils/types";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import FilterRenderer from "../SingleViewVTable/filters/FilterRenderer";
import { CellRenderer } from "../TableCellRenderer/TableCellRenderer";
import TableTree from "../TableTree/TableTree";

type TProps = {
  tabData: Tab<Record<string, unknown>, Record<string, unknown>>;
  isNewEntity?: boolean;
};

const SingleViewTree = ({ tabData: currentTab, isNewEntity }: TProps): JSX.Element => {
  const { primarySingleEntitySelector, id } = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const { t } = useTranslation();
  const tabData = useSelector(currentTab.tabSelector);
  const { generateDynamicColumns, customPropertiesSelector } = currentTab;
  const defaultCustomProperties = useSelector(customPropertiesSelector);
  const { errorMessages, setErrors, handleClearErrors } = useUIErrorHandling();
  const { params } = useRouter<{ id: string }>();
  const dispatch = useAppDispatch();
  const { history } = useRouter();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<typeof tabData>();
  const currentEntity = useSelector(primarySingleEntitySelector);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);

  // Note: we need to set the selected key based on the current record's locationId
  useEffect(() => {
    if (currentEntity) {
      const parentId = currentEntity[currentTab.selectedKeyId as keyof typeof currentEntity] as number;
      if (parentId) {
        setSelectedKeys([parentId.toString()]);
        setSelectedRow([currentEntity]);
      }
    }
  }, [currentEntity]);

  const handlePrimaryColumnNavigation = (c: ColumnType<Record<string, unknown>>, entity: Record<string, unknown>) => {
    if (c.primaryColumn) {
      const rootTarget = c.primaryColumn(entity)?.navigationTarget;
      rootTarget && history.push(`${rootTarget}/${entity.id}`);
    }
  };

  const handleModalClose = () => {
    setIsWarningVisible(false);
  };

  const isValidParent = (selectedEntity: ArrayElement<typeof tabData>): boolean => {
    // Note: if the relation is not parent/child and just a reference - there is no validation required. It should always be true.
    if ((currentTab.selectedKeyId as keyof typeof currentEntity) !== "parentId") {
      return true;
    }
    if (selectedEntity.id === currentEntity?.id) {
      return false;
    }
    const item = tabData.find(row => row.id === selectedEntity.parentId);
    if (item) {
      if (item.id === currentEntity?.id) {
        return false;
      }
      return isValidParent(item);
    }
    return true;
  };

  const handleMoveButton = () => {
    // Dispatch local change
    if (isNewEntity && currentTab.changeNewEntityParent && selectedKeys.length === 1) {
      return dispatch(currentTab.changeNewEntityParent(Number(selectedKeys[0])));
    }
    // Dispatch thunk action
    if (!isNewEntity && currentTab.changeExistingEntityParent && selectedKeys.length === 1) {
      const currentRow = selectedRow && selectedRow[0];
      const isValid = isValidParent(currentRow!);
      if (!isValid) {
        return setIsWarningVisible(true);
      }
      return dispatch(
        currentTab.changeExistingEntityParent({ objectId: Number(params.id), newValue: Number(selectedKeys[0]) })
      )
        .then(unwrapResult)
        .then()
        .catch(customError => {
          setErrors(customError);
        });
    }
  };

  const rowSelection: TableRowSelection<Record<string, unknown>> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: typeof tabData) => {
      // Allow only one element to be selected at a time. Alternative is to use a 'radio' type
      setSelectedKeys(selectedRowKeys.slice(selectedRowKeys.length - 1));
      setSelectedRow(selectedRows.slice(selectedRows.length - 1));
    },
    type: "checkbox",
    selectedRowKeys: selectedKeys,
    columnWidth: 25,
  };

  const columns = useMemo(() => {
    const tabColumns = (currentTab.columns as unknown) as ColumnType<Record<string, unknown>>[];
    return [...tabColumns, ...generateDynamicColumns(defaultCustomProperties || [], tabData)]
      .filter(c => c.hidden !== true)
      .map(c => {
        return {
          dataIndex: c.id,
          title: t(c.label),
          key: c.id,
          sortDirections: ["descend", "ascend"],
          // sorter: c.sortable ? sortData(c.id) : false,
          // width: c.width,
          filterIcon: <FilterIcon stroke={primaryColor} />,
          render: (_, entity) => (
            <Row
              onClick={() => handlePrimaryColumnNavigation(c, entity)}
              className={c.primaryColumn ? "primaryColumn HB-table-cell" : "HB-table-cell"}
            >
              <CellRenderer c={c} entity={entity} />
            </Row>
          ),
          filterDropdown: c.filterType
            ? ({ visible }) => (
                <FilterRenderer
                  entityKey={currentTab.key}
                  id={c.id.toString()}
                  type={c.filterType!}
                  label={c.label}
                  optionSelector={c.optionsSelector}
                  filterOpen={visible}
                />
              )
            : undefined,
        } as TableColumnType<ArrayElement<typeof tabData>>;
      });
  }, [currentTab.columns, defaultCustomProperties]);

  return (
    <Row className="tab">
      <ConfirmationModal
        visible={isWarningVisible}
        confirmationType="notValidParent"
        confirmOkClick={handleModalClose}
      />
      <ConfirmationModal
        visible={errorMessages.length > 0}
        confirmationType="cardValidationErrors"
        confirmOkClick={handleClearErrors}
        messages={errorMessages}
      />
      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <Typography.Text className="noselect result-count">{`${t("Showing")} ${tabData.length} ${t(
          "ShowResults"
        )}`}</Typography.Text>
        <Button onClick={handleMoveButton} className="move-button">
          {t("Move")}
        </Button>
      </Col>
      <Col span={24} className="bordered-single-view-table">
        {tabData && tabData.length > 0 && (
          <TableTree
            pageId={id}
            tableData={tabData}
            columns={columns}
            rowSelection={currentTab.rowSelection ? rowSelection : undefined}
          />
        )}
      </Col>
    </Row>
  );
};

export default SingleViewTree;
