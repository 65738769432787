import { ExportAction, ColumnManagementAction, GetFiltersUrl } from "../../../../actions/menuActions";
import { ReactComponent as Logo } from "../../../../media/inspection-icon.svg";
import { pageSelectors, webhooksSelectors } from "../../../../selectors";
import { fetchTasksAdditionalData, resetCurrentPage } from "../../../../store/slices/tasks";
import {
  addWebhook,
  clearWebhookError,
  createNewEventEntryTemplate,
  createWebhookTemplate,
  deleteEventsBulk,
  fetchEventTypes,
  fetchPaginatedWebhookLogs,
  fetchPaginatedWebhooks,
  fetchSingleWebhook,
  fetchWebhookEvents,
  updateWebhook,
} from "../../../../store/slices/webhooks";
import { CategoryPage, InputTypes } from "../../../../types/page";
import { UserRole } from "../../../../types/user";
import { TableAddButtonType } from "../../../../types/utility";
import { Webhook, WebhookKeys, WebhookPage } from "../../../../types/webhooks";
import {
  localizeText,
  pickSpecificColumns,
  prepareDynamicColumns,
  removeColumnFilters,
  renderBoolStatus,
} from ".././utilities";
import { boolOptions, enableForAdminOrUserOwner } from "../common";
import { webhookEventsTableColumns, webhookLogsTableColumns, webhooksTableColumns } from "./webhooksConfigPageData";

const webhooksConfigPage = (): CategoryPage<WebhookPage> => ({
  id: "webhooks",
  title: "WebhookPlugin",
  entityEndpoint: "/webhooks",
  PageIcon: Logo,
  hasGroupView: false,
  hasListView: true,
  primaryErrorSelector: webhooksSelectors.error,
  clearError: clearWebhookError,
  createNewEntityTemplate: createWebhookTemplate,
  createNewEntity: addWebhook,
  updateEntity: updateWebhook,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  fetchSingle: fetchSingleWebhook,
  customPropertiesSelector: webhooksSelectors.webhooksCustomProperties,
  intializeDataActions: [fetchEventTypes, fetchTasksAdditionalData],
  primarySingleEntitySelector: webhooksSelectors.single,
  isLoading: pageSelectors.isWebhooksLoading,
  lastUpdatedSelector: webhooksSelectors.webhooksLastUpdatedText,
  addButton: {
    enablingFor: userState => userState.settings.role === UserRole.SU,
    label: "AddWebhook",
    // eslint-disable-next-line no-console
    action: () => console.log("Create new Webhook"),
  },

  listView: {
    table: {
      resetCurrentPage: resetCurrentPage,
      tableSelector: webhooksSelectors.all,
      fetchPaginatedData: fetchPaginatedWebhooks,
      searchResultsSelector: webhooksSelectors.all,
      possibleResultsSelector: webhooksSelectors.webhookPossibleResults,
      columns: webhooksTableColumns,
      rowSelection: true,
      defaultColumnKeys: [WebhookKeys.Id, WebhookKeys.Name, WebhookKeys.Url, WebhookKeys.Active],
      type: "paginatedTable",
    },

    generateDynamicColumns: (props, data) => prepareDynamicColumns<Webhook>(props, data),
  },
  listSearch: {
    columns: pickSpecificColumns<Webhook>(webhooksTableColumns, WebhookKeys.Name, WebhookKeys.Id),
    keys: [WebhookKeys.Name, WebhookKeys.Id],
  },
  summaryCard: {
    canEdit(user) {
      return user.settings.role === UserRole.SU;
    },
    primaryFields: [
      {
        id: WebhookKeys.Name,
        title: "FieldName",
        value: entity => entity.name,
        type: InputTypes.INPUT,
        required: true,
        placeholder: "Enter",
        optionsSelector: () => null,
      },
      {
        id: WebhookKeys.Url,
        title: "FieldUrl",
        value: entity => entity.url,
        type: InputTypes.INPUT,
        required: true,
        placeholder: "Enter",
        optionsSelector: () => null,
      },
      {
        id: WebhookKeys.Active,
        title: "FieldActive",
        value: e => renderBoolStatus(e.active),
        type: InputTypes.BOOLEAN,
        options: boolOptions,
        optionsSelector: () =>
          boolOptions.map(l => ({
            id: l.id,
            label: localizeText(l.label),
          })),
      },
    ],
    secondaryFields: [],
  },
  tabsPanel: [
    {
      key: "events",
      label: "WebhookEvents",
      tabDataThunk: id => fetchWebhookEvents(id),
      tabSelector: webhooksSelectors.webhookEvents,
      columns: removeColumnFilters(webhookEventsTableColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      addButton: {
        action: createNewEventEntryTemplate,
        enablingFor: enableForAdminOrUserOwner,
        label: "AddEvent",
        type: TableAddButtonType.AddInline,
      },
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities => deleteEventsBulk(entities.map(x => x.id) as number[]),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
    },
    {
      key: "webhookLogs",
      label: "WebhookLogs",
      tabDataThunk: id => fetchPaginatedWebhookLogs(id),
      tabSelector: webhooksSelectors.webhookLogs,
      columns: removeColumnFilters(webhookLogsTableColumns) as [],
      rowSelection: false,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
    },
  ],
  listViewActions: [ExportAction, ColumnManagementAction, GetFiltersUrl],
  allowedUserRoles: [UserRole.SU, UserRole.Admin],
});

export default webhooksConfigPage;
