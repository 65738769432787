import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import dayjs from "dayjs";

import { CustomError, getEmptyValue } from "../../pages/pageConfig/category/utilities";
import { IssueType, IssueTypeState } from "../../types/issueType";
import { ContextActions, ContextActionsPaginatedData } from "../../types/tasks";
import { ExplicitAdditionalProps, Status } from "../../types/utility";
import { hbApi, hbApiOptions } from "../api";
import { RootState } from "../store";
import { shouldUpdate } from "./common";
import {
  updateContextActionAfterClose,
  updateContextActionAfterFillFormOnMobile,
  updateContextActionAfterReopen,
} from "./commonReducers";
import { closeTask, closeTaskFromMobile, reopenTask } from "./tasks";

export const initialState: IssueTypeState = {
  data: [],
  isLoading: false,
  singleData: null,
  subData: {
    actions: [],
  },
  error: null,
  defaultCustomProperties: [],
  lastUpdated: dayjs().toISOString(),
};

export const newIssueType: IssueType = {
  id: 0,
  name: "",
  externalId: "",
  parentId: "",
  status: Status.Active,
  iconType: "10",
};

export const fetchIssueTypes = createAsyncThunk<
  { primaryData: IssueType[]; defaultCustomProperties: ExplicitAdditionalProps[] },
  boolean | undefined,
  { state: RootState }
>(
  "@@ISSUETYPE/FETCH",
  async (_, { getState }) => {
    const { user } = getState();
    const response = await hbApi.get<IssueType[]>("/IssueType", hbApiOptions(user.jwt));

    return {
      primaryData: response.data,
      defaultCustomProperties: [],
    };
  },
  {
    condition: (forceUpdate, { getState }) => {
      const { issueType } = getState();
      return shouldUpdate(issueType.lastUpdated, issueType.isLoading, forceUpdate);
    },
  }
);

export const fetchSingleIssueType = createAsyncThunk<
  { singleData: IssueType; subData: { actions: ContextActions[] }; defaultCustomProperties: ExplicitAdditionalProps[] },
  string,
  { state: RootState }
>(
  "@@SINGLE_ISSUETYPE/FETCH",
  async (id, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      const cardDataResponse = await hbApi.get<IssueType>(`/IssueType/${id}`, hbApiOptions(user.jwt));
      const contextActionsResponse = await hbApi.get<ContextActionsPaginatedData>(
        `/Task/context-actions?entityId=${id}&entityType=IssueType`,
        hbApiOptions(user.jwt)
      );
      return {
        singleData: cardDataResponse.data,
        subData: {
          actions: contextActionsResponse.data.data,
        },
        defaultCustomProperties: [],
      };
    } catch (e) {
      if (e.status === 400) {
        return rejectWithValue(e.data);
      }
      throw new CustomError(e.message || e.Message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { issueType, user } = getState();
      return !issueType.isLoading && !!user.jwt;
    },
  }
);

export const addIssueType = createAsyncThunk<IssueType, { entity: IssueType }, { state: RootState }>(
  "@@ISSUE_TYPE/ADD",
  async ({ entity }, { getState }) => {
    const { user } = getState();
    entity.parentId = entity.parentId === getEmptyValue().key ? "" : entity.parentId;
    const response = await hbApi.post<IssueType>("/IssueType", entity, hbApiOptions(user.jwt));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { issueType, user } = getState();
      return !issueType.isLoading && !!user.jwt;
    },
  }
);

export const updateIssueType = createAsyncThunk<IssueType, IssueType, { state: RootState }>(
  "@@ISSUE_TYPE/UPDATE",
  async (entity, { getState }) => {
    const { user } = getState();
    const response = await hbApi.put<IssueType>(
      `/IssueType/${entity.id}`,
      {
        ...entity,
        parentId: entity.parentId === getEmptyValue().key ? null : entity.parentId,
      },
      hbApiOptions(user.jwt)
    );
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { issueType, user } = getState();
      return !issueType.isLoading && !!user.jwt;
    },
  }
);

export const updateIssueTypeParentId = createAsyncThunk<
  IssueType,
  { objectId: number; newValue: number },
  { state: RootState }
>(
  "@@ISSUE_TYPE/PATCH_PARENT_ID",
  async ({ objectId, newValue }, { getState }) => {
    const { user, issueType } = getState();
    const response = await hbApi.put<IssueType>(
      `/IssueType/${objectId}`,
      { ...issueType.singleData, parentId: newValue },
      hbApiOptions(user.jwt)
    );
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { issueType, user } = getState();
      return !issueType.isLoading && !!user.jwt;
    },
  }
);

export const slice = createSlice({
  name: "issueType",
  initialState,
  // Note: User reducers only for synchronous logic
  reducers: {
    createIssueTypeTemplate: state => {
      state.singleData = newIssueType;
    },
    clearIssueTypeError: state => {
      state.error = null;
    },
    updateParentId: {
      prepare: (payload: number) => ({ payload }),
      reducer: (state, action: PayloadAction<number>) => {
        if (state.singleData) {
          state.singleData = { ...state.singleData, parentId: action.payload };
        }
      },
    },
  },
  // Note: User reducers only for asynchronous logic with AsyncThunk
  extraReducers: builder => {
    builder
      // Note - Pending:
      .addCase(fetchIssueTypes.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleIssueType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addIssueType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateIssueType.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateIssueTypeParentId.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      // Note - Rejected:
      .addCase(fetchIssueTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchSingleIssueType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(addIssueType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateIssueType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updateIssueTypeParentId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // Note - Fulfilled:
      .addCase(fetchIssueTypes.fulfilled, (state, action) => {
        state.data = action.payload.primaryData;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchSingleIssueType.fulfilled, (state, action) => {
        state.singleData = action.payload.singleData;
        state.subData.actions = action.payload.subData.actions;
        state.defaultCustomProperties = action.payload.defaultCustomProperties;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(addIssueType.fulfilled, (state, action) => {
        state.singleData = action.payload;
        state.data = state.data ? [...state.data, action.payload] : [action.payload];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateIssueType.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(ou => ou.id === action.payload.id);
        state.data[updatedEntityIndex] = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateIssueTypeParentId.fulfilled, (state, action) => {
        state.singleData = action.payload;
        const updatedEntityIndex = state.data.findIndex(ou => ou.id === action.payload.id);
        state.data[updatedEntityIndex].parentId = action.payload.parentId;
        state.isLoading = false;
        state.error = null;
      })
      // Change Context Action
      .addCase(closeTask.fulfilled, (state, action) => {
        updateContextActionAfterClose(state, action.payload);
      })
      .addCase(closeTaskFromMobile.fulfilled, (state, action) => {
        updateContextActionAfterFillFormOnMobile(state, action.payload);
      })
      .addCase(reopenTask.fulfilled, (state, action) => {
        updateContextActionAfterReopen(state, action.payload);
      });
  },
});

export const { createIssueTypeTemplate, clearIssueTypeError, updateParentId } = slice.actions;
export default slice.reducer;
