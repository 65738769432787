import { BaseEntityType } from "./entityBase";
import { Equipment } from "./equipment";
import { ContextActions } from "./tasks";
import { AdditionalProps, ExplicitAdditionalProps, HistoryLog } from "./utility";

export type InspectionBase = Record<string, unknown> & {
  id: number;
  createdAt: string;
  expirationDate: string;
  inspectionTypeId: number;
  inspectionTypeName: string;
  status: InspectionStatus;
  activeCheckpointId?: number;
  lastCheck: string;
  nextCheck: string;
  recurrencyType: InspectionRecurrencyType;
};

export type BaseInspectionResponseModel = {
  createdAt: string;
  expirationDate: string;
  id: number;
  inspectionTypeId: number;
  inspectionTypeName: string;
  status: InspectionStatus;
  //neeed to add
  activeCheckpointId?: number;
  lastCheck: string;
  nextCheck: string;
  recurrencyType: InspectionRecurrencyType;
};

export type InspectionEquipmentResponseModel = BaseInspectionResponseModel & {
  equipmentId: number;
  equipmentLocationName: string;
  equipmentName: string;
  equipmentSerialNumber: string;
  equipmentTypeName: string;
};

export type InspectionLocationResponseModel = BaseInspectionResponseModel & {
  locationExternalId: string;
  locationId: number;
  locationName: string;
};

export type Inspection = InspectionBase & {
  linkedEntityExternalId: string;
  linkedEntityId: number;
  linkedEntityLocation: string;
  linkedEntityName: string;
  linkedEntityTypeId: number;
  linkedEntityTypeName: string;
  linkedEntityLocationId?: number;
};

export type InspectionResponseModel<T> = T extends "equipment"
  ? InspectionEquipmentResponseModel
  : T extends "location"
  ? InspectionLocationResponseModel
  : never;

export type InspectionPaginatedData<T extends string> = {
  data: InspectionResponseModel<T>[];
  count: number;
};

export type InspectionLinkedEntity = {
  externalId: string;
  id: number;
  location: string;
  name: string;
  type: string;
  typeId: number;
  locationId?: number;
  linkedEntityType: string;
};

export enum InspectionRecurrencyType {
  Fixed = "Fixed",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export type BaseInspection = {
  id: number | string;
  inspectionTypeName: string;
  status: InspectionStatus;
  endPeriod: string;
  completedAt: string;
  completedByUserId: string;
  completedByUserName: string;
  startPeriod: string;
  recurrencyType: InspectionRecurrencyType;
  recurrency: number;
  customPropertyValues?: AdditionalProps[];
  comment: string;
};

export type InspectionCheckpoint = BaseInspection & {
  fileUrl: string;
  staging: boolean;
  inspectionTypeReviewId?: number;
  inspectionId: number;
  inspectionTypeId: number;
  linkedEntityId: number;
};

export type InspectionSingleView = {
  id: number;
  inspectionTypeId: number;
  inspectionTypeName: string;
  status: InspectionStatus;
  expirationDate: string;
  lastCheck: string;
  nextCheck: string;
  checkpoints: InspectionCheckpoint[];
  recurrencyType: string;
  linkedEntity: InspectionLinkedEntity;
  customPropertyValues?: AdditionalProps[];
  reviewId: number;
  recurrency: number;
};

export type InspectionSingleViewEquipmentLocation = {
  id: number;
  name: string;
};

export interface InspectionState {
  data: Inspection[];
  isLoading: boolean;
  singleData: InspectionSingleView | null;
  subData: {
    equipments: Equipment[];
    actions: ContextActions[];
    historyLog: HistoryLog[];
  };
  searchResults: Inspection[];
  error: string | null;
  defaultCustomProperties: ExplicitAdditionalProps[];
  lastUpdated: string;
  paginationInfo: {
    currentPage: number;
    count: number;
  };
  editCheckpointData?: {
    id: BaseInspection["id"];
    prevStatus: BaseInspection["status"];
  };
}

export enum InspectionStatus {
  Passed = "Passed",
  Failed = "Failed",
  Pending = "Pending",
  PassedWithConditions = "PassedWithConditions",
  Expired = "Expired",
  Missing = "Missing",
  IsNoLongerNeeded = "IsNoLongerNeeded",
}

export type InspectionPage = BaseEntityType & {
  entityData: {
    primaryData: InspectionSingleView;
  };
  subData: {
    equipments: Equipment[];
    actions: ContextActions[];
    historyLog: HistoryLog[];
  };
  listViewData: Inspection;
  tabs: ContextActions | InspectionCheckpoint | HistoryLog;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isInspection(singleData: any): singleData is InspectionSingleView {
  return singleData?.inspectionTypeId !== undefined;
}
