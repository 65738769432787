import { Badge, Layout as AntdLayout, Menu, Tooltip, MenuProps } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageConfigurationContext from "../../context/pageContext";
import { ReactComponent as ActionsSelectedLogo } from "../../media/sidebar/actions-icon-selected.svg";
import { ReactComponent as ActionsLogo } from "../../media/sidebar/actions-icon.svg";
import { ReactComponent as CubeLogo } from "../../media/sidebar/cubejs-icon.svg";
import { ReactComponent as DashboardSelectedLogo } from "../../media/sidebar/dashboard-icon-selected.svg";
import { ReactComponent as DashboardLogo } from "../../media/sidebar/dashboard-icon.svg";
import { ReactComponent as EmployeeSelectedIcon } from "../../media/sidebar/employee-selected.svg";
import { ReactComponent as EmployeeIcon } from "../../media/sidebar/employee.svg";
import { ReactComponent as EquipmentSelectedIcon } from "../../media/sidebar/equipment-selected.svg";
import { ReactComponent as EquipmentIcon } from "../../media/sidebar/equipment.svg";
import { ReactComponent as MapLogo } from "../../media/sidebar/hb-map.svg";
import { ReactComponent as LocationSelectedIcon } from "../../media/sidebar/location-selected.svg";
import { ReactComponent as LocationIcon } from "../../media/sidebar/location.svg";
import { ReactComponent as ToolsManagmentIcon } from "../../media/sidebar/managment-tools.svg";
import { ReactComponent as TrainingSelectedIcon } from "../../media/sidebar/training-selected.svg";
import { ReactComponent as TrainingIcon } from "../../media/sidebar/training.svg";
import { RootState } from "../../store/store";
import { UserRole } from "../../types/user";
import { isRoleAdminLevel } from "../../utils/functions";
import useRouter from "../../utils/hooks/useRouter";
import CompanyButton from "../CompanyMenu/CompanyButton";
import CompanySettings from "../ProfileMenu/CompanySettings";
import ProfileButton from "../ProfileMenu/ProfileButton";
import RedirectToPAS from "./RedirectToPAS";

const { Sider } = AntdLayout;

type ISideBarConfigurationItem = {
  id: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  SelectedIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  alsoSelectedOn?: string[];
  isExternalUrl?: boolean;
  isVisibleBySUOnly?: boolean;
  isBetaFeature?: boolean;
  allowedRoles?: UserRole[];
};

const SideBar: React.FC = () => {
  const { id } = useContext(PageConfigurationContext);
  const { settings, companySettings } = useSelector((state: RootState) => state.user);
  const { history } = useRouter<{ viewType: null }>();
  const { t } = useTranslation();

  const getDashboardAllowedRoles = () => {
    if (companySettings.newPassSmartDashboardAccess === "TenantAdmin") {
      return [UserRole.Admin, UserRole.TenantAdmin];
    } else if (companySettings.newPassSmartDashboardAccess === "AllUsers") {
      return undefined;
    }
    return [];
  };

  const sideBarConfiguration: ISideBarConfigurationItem[] = [
    {
      id: "dashboard",
      Icon: DashboardLogo,
      SelectedIcon: DashboardSelectedLogo,
      title: "Dashboard",
      allowedRoles: getDashboardAllowedRoles(),
    },
    {
      id: "employee",
      Icon: EmployeeIcon,
      SelectedIcon: EmployeeSelectedIcon,
      title: "Employee",
      alsoSelectedOn: ["orgUnit", "employeeCustomProperty"],
    },
    {
      id: "location",
      Icon: LocationIcon,
      SelectedIcon: LocationSelectedIcon,
      title: "PluginLocationName",
      alsoSelectedOn: [
        "inspectionLocation",
        "locationType",
        "locationCustomProperty",
        "inspectionLocationCustomProperty",
        "inspectionLocationType",
      ],
    },
    {
      id: "equipment",
      Icon: EquipmentIcon,
      SelectedIcon: EquipmentSelectedIcon,
      title: "Equipment",
      alsoSelectedOn: [
        "inspectionEquipment",
        "equipmentType",
        "equipmentCustomProperty",
        "inspectionEquipmentCustomProperty",
        "inspectionEquipmentType",
      ],
    },
    {
      id: "training",
      Icon: TrainingIcon,
      SelectedIcon: TrainingSelectedIcon,
      title: "Training",
      alsoSelectedOn: ["certification", "trainingCustomProperty"],
    },
    {
      id: "actions",
      Icon: ActionsLogo,
      SelectedIcon: ActionsSelectedLogo,
      title: "Actions",
    },
    {
      id: "hb-map",
      Icon: MapLogo,
      SelectedIcon: MapLogo,
      title: "AppointmentsMap",
      isExternalUrl: false,
      isBetaFeature: true,
      allowedRoles: companySettings.isMapEnabled ? undefined : [],
    },
    {
      id: "cube",
      Icon: CubeLogo,
      SelectedIcon: CubeLogo,
      title: "CubeConfigurations",
      isExternalUrl: false,
      isVisibleBySUOnly: true,
    },
    {
      id: "managmentTools",
      Icon: ToolsManagmentIcon,
      SelectedIcon: ToolsManagmentIcon,
      title: "ManagmentTools",
      isExternalUrl: false,
      isVisibleBySUOnly: true,
    },
    {
      id: "BackToLegacy",
      Icon: RedirectToPAS,
      SelectedIcon: RedirectToPAS,
      title: "BackToLegacy",
      isExternalUrl: true,
      allowedRoles: companySettings.legacyEnabled ? undefined : [],
    },
  ];

  const filteredSideBarConfig = settings.isSysAdmin
    ? sideBarConfiguration
    : sideBarConfiguration.filter(x => x.allowedRoles?.includes(settings.role) || !x.allowedRoles);

  const selectedKeys = filteredSideBarConfig
    .filter(x => x.alsoSelectedOn && x.alsoSelectedOn.includes(id))
    .map(x => x.id);

  selectedKeys.push(id);

  const onMenuItemSelected: MenuProps["onClick"] = props => {
    const menuItem = filteredSideBarConfig.find(x => x.id === props.key);

    if (menuItem && !menuItem.isExternalUrl) {
      history.push(`/${menuItem.id}`, { referrer: window.location.pathname });
    }
  };
  const getIcon: (x: ISideBarConfigurationItem) => React.ReactNode = x => {
    const isAlsoSelected = filteredSideBarConfig.find(z => z.id === x.id)?.alsoSelectedOn?.includes(id) ?? false;
    const Icon = x.id === id || isAlsoSelected ? x.SelectedIcon : x.Icon;

    if (x.isBetaFeature) {
      return (
        <Badge.Ribbon text="Beta">
          <Icon className="sidebar-ribbon-style" />
        </Badge.Ribbon>
      );
    } else {
      return <Icon className="sidebar-ribbon-style" />;
    }
  };

  return (
    <Sider className="sidebar" collapsible collapsedWidth="0" breakpoint="md" trigger={null} width="7.3rem">
      <div className="sidebar-wrapper">
        <div>
          <CompanyButton />
          <Menu
            className="sidebar-menu"
            onClick={onMenuItemSelected}
            selectedKeys={selectedKeys}
            theme="light"
            mode="inline"
            inlineIndent={18}
          >
            {filteredSideBarConfig.map(x => {
              if ((x.isVisibleBySUOnly && settings.isSysAdmin) || !x.isVisibleBySUOnly)
                return (
                  <Menu.Item key={x.id} className="sidebar-menu-item" title={null}>
                    <Tooltip
                      mouseEnterDelay={0}
                      trigger={["hover", "click"]}
                      mouseLeaveDelay={0.1}
                      placement="right"
                      title={t(x.title)}
                    >
                      {getIcon(x)}
                    </Tooltip>
                  </Menu.Item>
                );
            })}
          </Menu>
        </div>

        <div className="profile-button">
          {isRoleAdminLevel(settings.role) && <CompanySettings />}
          <ProfileButton />
        </div>
      </div>
    </Sider>
  );
};

export default SideBar;
