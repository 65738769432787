import "../LandingPage/landingPage.less";

import { Spin } from "antd";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import ProfileButton from "../../pages/ProfileMenu/ProfileButton";
import { RootState } from "../../store/store";
import { EmbeddedPASProMode } from "../EmbeddedModal/useFormManagmentEvents";
import BackgroundWithoutImage from "../LandingPage/BackgroundWithoutImage";
import LandingPageFooter from "../LandingPage/LandingPageFooter";

const EmbeddedFormManagmentComponent = React.lazy(() => import("../EmbeddedModal/EmbeddedFormManagmentComponent"));

type LandingPageFormFillingProps = {
  onLoad?: () => void;
};

export default function ReporterFormFilling({ onLoad }: LandingPageFormFillingProps) {
  const jwt = useSelector((state: RootState) => state.user.jwt);
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);

  return (
    <div
      className="reporter landing-page-container"
      style={{
        backgroundColor: primaryColor,
      }}
    >
      <Helmet>
        <meta name="theme-color" content={primaryColor} media="(prefers-color-scheme: light)" />
      </Helmet>

      <div className={"landing-page-flow-container"}>
        <Suspense fallback={<Spin spinning />}>
          <div className={"iframe"}>
            <EmbeddedFormManagmentComponent
              initialJWT={jwt}
              isOpen={true}
              mode={EmbeddedPASProMode.FormManagment}
              onLoad={onLoad}
            />
          </div>
        </Suspense>
      </div>
      <LandingPageFooter isDarkBackground={false} />
      <div className="profile">
        <ProfileButton />
      </div>

      <BackgroundWithoutImage />
    </div>
  );
}
