import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { userSelectors } from "../../../selectors";
import { useAppDispatch } from "../../../store/store";
import { TableAddButtonType } from "../../../types/utility";
import { NEW_MOBILE_PATH } from "../../../utils/functions";
import { isAddButonVisible } from "../../../utils/helpers";
import { useMobileSingleViewContext } from "../context/useMobileSingleViewContext";
import { useMobileSingleViewTabContext } from "../context/useMobileSingleViewTabContext";
import MobileCardAddEntitySectionOptions from "./MobileCardAddEntitySectionOptions";
import MobileCardBulkActions from "./MobileCardBulkActions";

const MobileCardAddEntitySection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { singleData, openNextPage } = useMobileSingleViewContext();
  const { tabConfig } = useMobileSingleViewTabContext();
  const user = useSelector(userSelectors.getCurrentUser);

  const [isOptionsShown, setIsOptionsShown] = useState(false);

  const addButton = tabConfig.addButton;

  if (!addButton) return null;

  const addButtonVisible =
    isAddButonVisible({ singleData, isMobile: true }) && (addButton.enablingFor ? addButton.enablingFor(user) : true);

  if (!addButtonVisible) return null;

  return (
    <>
      <div className="mobile-buttons-section">
        <Button
          className={"add-button mobile-add-button"}
          onClick={() => {
            if (addButton && addButton.type === TableAddButtonType.AddSubTask) {
              const newActionNavigationPath = `/actions${NEW_MOBILE_PATH}?parentId=${singleData?.id}`;
              singleData?.closeDate
                ? Modal.confirm({
                    content: t("ParentActionIsCompletedMessage"),
                    onOk: () => openNextPage(newActionNavigationPath),
                  })
                : openNextPage(newActionNavigationPath);
              return;
            }
            setIsOptionsShown(value => !value);
            if (addButton && addButton.type === TableAddButtonType.AddInline && addButton.action) {
              return dispatch(addButton.action());
            }
          }}
        >
          {`+ ${t(addButton.label)}`}
        </Button>
        <MobileCardBulkActions />
      </div>
      {isOptionsShown && <MobileCardAddEntitySectionOptions setIsOptionsShown={setIsOptionsShown} />}
    </>
  );
};

export default MobileCardAddEntitySection;
