import React from "react";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { RootState } from "../../store/store";

import LandingPageBubble from "./LandingPageBubble";

const BUBBLE_COLOR = "#ffffff2b";

const BackgroundWithoutImage: React.FC = () => {
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);

  const BUBBLE_CONFIG: Array<{ color: string; radius: string; top: string; left: string }> = [
    {
      color: BUBBLE_COLOR,
      radius: "90vw",
      top: "80vh",
      left: "5vw",
    },
    {
      color: BUBBLE_COLOR,
      radius: "10vw",
      top: "76vh",
      left: "-5vw",
    },
    {
      color: BUBBLE_COLOR,
      radius: "5vw",
      top: "50vh",
      left: "20vw",
    },
    {
      color: tinycolor(primaryColor).complement().darken(5).toHexString(),
      radius: "10vw",
      top: "95vw",
      left: "50vh",
    },
  ];

  return (
    <div
      style={{
        backgroundColor: primaryColor,
        zIndex: 0,
      }}
    >
      {BUBBLE_CONFIG.map((config, index) => {
        return (
          <LandingPageBubble
            key={index}
            color={config.color}
            radius={config.radius}
            top={config.top}
            left={config.left}
          />
        );
      })}
    </div>
  );
};

export default BackgroundWithoutImage;
