/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chart as ChartJS } from "chart.js";
import isObject from "lodash/isObject";
import tinycolor from "tinycolor2";

const C2S = require("canvas2svg");

export const hirachialColumns = ["Location", "OrgUnit", "IssueType"];

export const roundNumber = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const precetageLabels = (value: any, ctx: any) => {
  let sum = 0;
  const dataArr = ctx.chart.data.datasets[0].data;
  dataArr.map((data: number) => {
    sum += data as number;
  });
  const percentage = ((value * 100) / sum).toFixed(2) + "%";
  return percentage;
};

export const commaify: (value: any, ctx: any) => string = (value, ctx) => {
  let v: string | undefined = undefined;
  if (isObject(value)) {
    v = (value as any)["v"] || undefined;
  } else {
    v = value as string;
  }
  const result = ("" + v).replace(/^(-?\d+)(\d{3})/, "$1,$2");
  return v == result ? result : commaify(result, ctx);
};

export const precentageTooltip = (ctx: any) => {
  const value = ctx.dataset.data[ctx.dataIndex];
  const seriesName = ctx.label;
  return `${seriesName}: ${precetageLabels(value, ctx)}`;
};

export const commafiyTooltip = (ctx: any) => {
  const value = ctx.dataset.data[ctx.dataIndex];
  const seriesName = ctx.label;
  return `${seriesName}: ${commaify(value, ctx)}`;
};

export const labelColor = (context: any) => {
  const index = context.dataIndex;
  const baseColor = context.dataset.backgroundColor[index];
  return tinycolor
    .mostReadable(tinycolor(baseColor), [tinycolor(baseColor)], { includeFallbackColors: true })
    .toHexString();
};

export function downloadPngLink(filename: string, linkText: string, chart: ChartJS) {
  if (chart.options.animation !== false) {
    console.warn("Cannot create PNG: 'animation' is not set to false (see the options section)");
    return;
  }
  if (chart.options.responsive !== false) {
    console.warn("Cannot create PNG: 'responsive' is not set to false (see the options section)");
    return;
  }

  // create the download link
  const link = document.createElement("a");
  link.href = chart.toBase64Image();
  link.download = filename;
  link.text = linkText;

  // and add it to the page
  // document.getElementById("wrapper").appendChild(link);
  link.click();
}

export function downloadSVG(filename: string, linkText: string, chart: ChartJS) {
  if (chart.options.animation !== false) {
    console.warn("Cannot create PNG: 'animation' is not set to false (see the options section)");
    return;
  }
  if (chart.options.responsive !== false) {
    console.warn("Cannot create PNG: 'responsive' is not set to false (see the options section)");
    return;
  }

  tweakLib();

  // get the dimensions of our original chart
  const chartCanvas = document.createElement("canvas");
  const width = chartCanvas.offsetWidth;
  const height = chartCanvas.offsetHeight;

  // create an svg version of the chart
  const svgContext = C2S(width, height);
  // const svgChart = new ChartJS(svgContext, chart.config);

  // create download link
  const link = document.createElement("a");
  link.href = "data:image/svg+xml;utf8," + encodeURIComponent(svgContext.getSerializedSvg());
  link.download = filename;
  link.text = linkText;
  link.click();
}

// some tweaks to the canvas2svg library are required for this to work
// via: https://stackoverflow.com/questions/62249315/export-canvas-to-svg-file
function tweakLib() {
  C2S.prototype.getContext = function (contextId: any) {
    if (contextId === "2d" || contextId === "2D") {
      return this;
    }
    return null;
  };
  C2S.prototype.style = function () {
    return this.__canvas.style;
  };
  C2S.prototype.getAttribute = function (name: any) {
    return this[name];
  };
  C2S.prototype.addEventListener = function () {
    // C2S.prototype.addEventListener = function (type: any, listener: any, eventListenerOptions: any) {
    // nothing to do here, but we need this function :)
  };
}

export const getStartAndEndOfDay = (date: Date) => ({
  startDate: new Date(date.setHours(0, 0, 0, 0)).toISOString(),
  endDate: new Date(date.setHours(23, 59, 59, 999)).toISOString(),
});

export const getStartAndEndOfWeek = (date: Date) => {
  const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
  const endOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + 6));
  return {
    startDate: new Date(startOfWeek.setHours(0, 0, 0, 0)).toISOString(),
    endDate: new Date(endOfWeek.setHours(23, 59, 59, 999)).toISOString(),
  };
};

export const getStartAndEndOfMonth = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  return {
    startDate: new Date(year, month, 1).toISOString(),
    endDate: new Date(year, month + 1, 0, 23, 59, 59, 999).toISOString(),
  };
};

export const getStartAndEndOfQuarter = (date: Date) => {
  const year = date.getFullYear();
  const quarter = Math.floor((date.getMonth() + 3) / 3);
  return {
    startDate: new Date(year, (quarter - 1) * 3, 1).toISOString(),
    endDate: new Date(year, quarter * 3, 0, 23, 59, 59, 999).toISOString(),
  };
};

export const getStartAndEndOfYear = (date: Date) => {
  const year = date.getFullYear();
  return {
    startDate: new Date(year, 0, 1).toISOString(),
    endDate: new Date(year, 11, 31, 23, 59, 59, 999).toISOString(),
  };
};
