import { unwrapResult } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import { getRedirectToLegacy } from "../../store/slices/user";
import store from "../../store/store";
import { UserRole } from "../../types/user";

const USER_NOTIFICATION_BASE_PATH = "User/UserSetting";
const PROJECT_BASE_PATH = "Project/View";

export const getAlignment = (direction: "rtl" | "ltr") => {
  if (direction === "ltr") {
    return { offset: [-5, 80] };
  } else {
    return { offset: [-5, -90] };
  }
};

export const getNotificationsPath = (currentUserId: number): string => {
  return `${USER_NOTIFICATION_BASE_PATH}/${currentUserId}`;
};

export const getProjectPath = (projectId: number): string => {
  return `${PROJECT_BASE_PATH}/${projectId}`;
};

export const handleRedirectToLegacy = ({
  returnUrl,
  triggerLoader,
  newTab,
}: {
  returnUrl: string;
  triggerLoader: boolean;
  newTab: boolean;
}) => {
  store
    .dispatch(getRedirectToLegacy({ returnUrl, triggerLoader }))
    .then(res => unwrapResult(res))
    .then(data => (newTab ? window.open(data.returnUrl, "_blank") : (window.location.href = data.returnUrl)))
    .catch(e => {
      console.error("Error on getting redirect to legacy: ", e);
    });
};

export enum MenuItemType {
  Menu,
  Action,
}

export type ProfileMenuItem = {
  title: string;
  icon: ReactNode;
  type?: MenuItemType;
  content?: ReactNode;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: any;
  allowedUserRoles?: UserRole[];
};
