import { Table, TableProps } from "antd";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useVT } from "virtualizedtableforantd4";
import PageConfigurationContext from "../../../context/pageContext";
import { RootState, useAppDispatch } from "../../../store/store";
import { BaseEntityType } from "../../../types/entityBase";
import { CategoryPage } from "../../../types/page";
import ResizableTitle from "./ResizableTitle";

/**
 * Create an Ant table that is wrapped inside ResizeObserver
 * @param {RecordType} props takes Table props
 * @returns Table Component
 */
function VirtualListAntd<RecordType extends Record<string, unknown>>(
  props: TableProps<RecordType> & { tableRef: React.RefObject<HTMLDivElement> }
) {
  const pageConfig = useContext(PageConfigurationContext) as CategoryPage<BaseEntityType>;
  const defaultPageSize = useSelector((state: RootState) => state.user.companySettings?.defaultPageSize);
  const { columns, dataSource, tableRef } = props;
  const dispatch = useAppDispatch();

  // create a virtual table with specific height
  const [vt, setVt] = useVT(
    () => ({
      scroll: { y: "100%" },
      overscanRowCount: 0,
      onScroll: ({ isEnd }) => {
        if (dataSource?.length && dataSource?.length > 0 && isEnd && pageConfig.listView?.table.fetchPaginatedData) {
          dispatch(
            pageConfig.listView?.table.fetchPaginatedData({
              forceUpdate: false,
              pageSize: defaultPageSize,
            })
          );
        }
      },
      offset: 100,
    }),
    [pageConfig, dataSource]
  );

  setVt({ header: { cell: ResizableTitle } });

  return (
    <Table
      ref={tableRef}
      {...props}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      components={vt} // render the whole virtual table
    />
  );
}

export default VirtualListAntd;
